import React, { useEffect, useState } from "react";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "../common/TextField";
import MenuItem from "components/common/MenuItem";
import Grid from "components/common/Grid";
import { Chip, styled } from "@mui/material";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import dayjs from "dayjs";
import DatePicker from "components/common/DatePicker";
import { useSelector } from "react-redux";

const ChipStyle = styled(Chip, {
  shouldForwardProp: (prop) => prop !== "planStatus",
})(({ theme, planStatus }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "152px",
  backgroundColor: planStatus === "Active" ? "#4CAF50" : "#B22323",
  color: "#FFFFFF",
  fontSize: "12px",
  padding: "8px",
  fontWeight: 500,
  "&::before": {
    position: "relative",
    left: 5,
    bottom: 6,
    content: '"\\2022"',
    width: "6px",
    height: "6px",
    color: "#FFFFFF",
  },
}));

const PlanSidebar = ({
  getCustomerDetails,
  viewCustomerDetail,
  customer_id,
  closeDrawer,
  isDrawerOpen,
}) => {
  const select = useSelector((state) => state);
  const user_id = viewCustomerDetail?.customerDetails?.[0]?.user_id;
  const plan_id = viewCustomerDetail?.otherDetails?.[0]?.plan_id;
  const getCustomerPlanAPi = customersApiAction.getCustomerPlan();
  const { data: planDetails } = customersApiAction.getPlanDetails(plan_id);
  const originalexpiryDate = viewCustomerDetail?.otherDetails?.[0]?.end_date;
  const expiryDate = originalexpiryDate
    ? dayjs(originalexpiryDate).format("YYYY-MM-DD")
    : "";
  const joining_date = viewCustomerDetail?.otherDetails?.[0]?.joining_date;
  const joiningDate = joining_date
    ? dayjs(joining_date).format("YYYY-MM-DD")
    : "";
  const subscriptionData = getCustomerPlanAPi?.data ?? [];
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [handleupdatedPlanData] = customersApiAction.postPlanDetails();
  const [endDate, setEndDate] = useState(originalexpiryDate);
  const [planCode, setPlanCode] = useState("");

  const [selectedSubcategoryIndex, setSelectedSubcategoryIndex] = useState(0);
  const plan_data = viewCustomerDetail?.otherDetails?.[0]?.plan_id;
  const planIndex = subscriptionData?.plans?.findIndex(
    (plan) => plan.plan_id === plan_data
  );
  const [selectedValue, setSelectedValue] = useState(planIndex);
  const selectedPlan = planDetails?.find((plan) => plan.plan_id === plan_id);

  console.log(
    "selectedPlan",
    subscriptionData?.plans?.[selectedValue]?.subCategories
  );

  const selectedSubcategory = selectedPlan?.subCategories?.find(
    (sub) =>
      sub.frequency.toLowerCase() ===
      viewCustomerDetail?.otherDetails?.[0]?.frequency.toLowerCase()
  );


  useEffect(() => {}, [planIndex]);

  const customerDetail = [
    {
      name: "Plan Status",
      key: "Plan Status",
      value: viewCustomerDetail?.otherDetails?.[0]?.plan_status ?? "",
    },
    {
      name: "Frequency",
      key: "Frequency",
      value: viewCustomerDetail?.otherDetails?.[0]?.frequency ?? "",
    },
    {
      name: "Algo (F&O)",
      value: selectedSubcategory?.algo ?? "",
    },
    {
      name: "Custom",
      value: selectedSubcategory?.custom ?? "",
    },
    {
      name: "Model Portfolio",
      value: selectedSubcategory?.modelportfolio ?? "",
    },
    {
      name: "Expiry Date",
      key: "Expiry Date",
      value: expiryDate,
    },
  ];

  useEffect(() => {
    const selectedPlan = subscriptionData?.plans?.find(
      (plan) => plan?.subcategories?.plan_detail_id === selectedValue
    );
    if (selectedPlan) {
      setPlanCode(selectedPlan.code);
    }
  }, [selectedValue, subscriptionData]);

  const handleSaveClick = () => {
    const selectedPlan = subscriptionData?.plans[selectedValue];
    const selectedSubcategory =
      selectedPlan?.subCategories[selectedSubcategoryIndex];

    if (selectedPlan && selectedSubcategory) {
      const payload = {
        expiry_date: dayjs(endDate).format("YYYY-MM-DD"),
        plan_code: planCode,
        plan_detail_id: selectedSubcategory.plan_detail_id.toString(), // Convert to string if needed
      };

      handleupdatedPlanData({ user_id, payload })
        .unwrap()
        .then((response) => {
          console.log("API Response:", response);
          getCustomerDetails(customer_id);
          closeDrawer(false);
        })
        .catch((error) => {
          console.error("Error in API call:", error);
          // Handle error, e.g., show an error message
        });
    } else {
      console.error("Selected plan or subcategory is undefined.");
      // Handle case where selected plan or subcategory is undefined
    }
  };

  const handleChange = (index) => {
    setSelectedValue(index);
    setSelectedSubcategoryIndex(0); // Reset subcategory index when changing plan
    setPlanCode(subscriptionData?.plans?.[index]?.subCategories?.[0]?.code);
  };

  const handleSubcategoryChange = (index) => {
    setSelectedSubcategoryIndex(index);
    setPlanCode(
      subscriptionData?.plans?.[selectedValue]?.subCategories?.[index]?.code
    );
  };

  const handleExpiryDateChange = (newEndDate) => {
    setEndDate(newEndDate);
  };

  return (
    <Stack>
      {isDrawerOpen && (
        <SideDrawer
          anchor="right"
          open={isDrawerOpen}
          closeDrawer={closeDrawer}
          title="Plan Detail"
          contentTitle=""
          handleSubmit={handleSaveClick}
          cancelButtonText="Close"
          submitButtonText="Save"
          subtitle="Here you can edit the plan."
        >
          {customerDetail.map((item, index) => (
            <Grid container key={index}>
              {item.name === "Plan Status" ? (
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center">
                    <Grid item xs={3}>
                      <Text
                        variant="small"
                        sx={{
                          color: "#667085",
                          fontSize: "16px",
                          fontWeight: "400",
                        }}
                      >
                        {item.name}
                      </Text>
                    </Grid>
                    <Grid item xs={9}>
                      <Stack direction="row">
                        <Box>
                          <Stack direction="row" textAlign="center">
                            <Text
                              style={{ marginLeft: "10px", fontSize: "15px" }}
                            >
                              {":"}
                            </Text>
                            <ChipStyle
                              planStatus={
                                item.value === "Active" ? "Active" : "Expired"
                              }
                              label={
                                <Text fontSize="12px" fontWeight="500">
                                  {item.value}
                                </Text>
                              }
                              size="small"
                              style={{ marginLeft: "5px" }}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                    </Grid>
                  </Stack>
                </Grid>
              ) : item.name === "Plan" ? (
                <Grid item xs={12}>
                  <Button variant="small" sx={{ color: "#667085" }}>
                    Plan Button
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1.5}
                    sx={{ width: "100%" }}
                    alignItems="center"
                    style={{ marginTop: "2px" }}
                  >
                    <Grid item xs={3}>
                      <Text
                        variant="small"
                        fontSize="16px"
                        fontWeight="400"
                        sx={{
                          color: "#676C76",
                          textWrap: "nowrap",
                          fontSize: "16px",
                        }}
                      >
                        {item.name}
                      </Text>
                    </Grid>
                    <Grid item xs={9}>
                      <Text
                        variant="small"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "600",
                          textWrap: "nowrap",
                        }}
                      >
                        {":  "}
                        {item.value}
                      </Text>
                    </Grid>
                  </Stack>
                </Grid>
              )}
            </Grid>
          ))}

          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#E5EEFF",
              borderRadius: "4px",
            }}
          >
            <Text sx={{ fontSize: "16px", fontWeight: 500 }}>Update plan </Text>
          </Box>
          <Box sx={{ m: 2 }}>
            <Text
              variant="small"
              sx={{ color: "#242424", marginBottom: "3px" }}
              fontSize="16px"
              fontWeight="600"
            >
              Plan
            </Text>
            <TextField
              placeholder="Label"
              style={{ width: "100%" }}
              select
              value={selectedValue}
              onChange={(e) => handleChange(e.target.value)}
            >
              {subscriptionData?.plans?.map((plan, index) => (
                <MenuItem
                  key={index}
                  value={index}
                  style={{ fontSize: "12px", fontWeight: "500" }}
                >
                  {plan.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box>
            <Text
              variant="small"
              fontSize="16px"
              fontWeight="600"
              sx={{ color: "#242424", marginBottom: "2px" }}
            >
              Plan Code
            </Text>
            <TextField
              inputProps={{ style: { fontSize: "14px" } }}
              select
              value={planCode}
              onChange={(e) => setPlanCode(e.target.value)}
            >
              {subscriptionData?.plans?.[selectedValue]?.subCategories?.map(
                (subcategory, index) => (
                  <MenuItem
                    key={index}
                    value={subcategory.code}
                    onClick={() => handleSubcategoryChange(index)}
                  >
                    {subcategory.code}
                  </MenuItem>
                )
              )}
            </TextField>
          </Box>

          <Box>
            <Text
              variant="small"
              fontSize="16px"
              fontWeight="600"
              sx={{ color: "#242424", marginBottom: "3px" }}
            >
              Expiry Date
            </Text>
            {!isDatePickerOpen && (
              <TextField
                sx={{ width: "100%", marginTop: "3px" }}
                onClick={() => setDatePickerOpen(true)}
                value={dayjs(endDate).format("YYYY-MM-DD") ?? ""}
              />
            )}
            {isDatePickerOpen && (
              <DatePicker
                sx={{ width: "100%" }}
                value={endDate}
                onChange={handleExpiryDateChange}
              />
            )}
          </Box>
        </SideDrawer>
      )}
    </Stack>
  );
};

export default PlanSidebar;
