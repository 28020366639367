import SvgIcon from "@mui/material/SvgIcon";
import React from 'react'

const ReportCardIcon = (props) => {
  return (
    <SvgIcon {...props}>

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M19.7036 11.9999L11.2116 3.27291C11.1417 3.20258 11.0864 3.11908 11.049 3.02725C11.0115 2.93542 10.9927 2.83708 10.9935 2.73791C10.9943 2.63875 11.0148 2.54073 11.0538 2.44953C11.0927 2.35833 11.1494 2.27577 11.2204 2.20661C11.2915 2.13745 11.3756 2.08308 11.4678 2.04663C11.5601 2.01018 11.6586 1.99239 11.7577 1.99428C11.8569 1.99616 11.9547 2.0177 12.0455 2.05763C12.1362 2.09756 12.2182 2.1551 12.2866 2.22691L21.2866 11.4769C21.4228 11.6169 21.499 11.8046 21.499 11.9999C21.499 12.1953 21.4228 12.3829 21.2866 12.5229L12.2866 21.7729C12.2182 21.8447 12.1362 21.9023 12.0455 21.9422C11.9547 21.9821 11.8569 22.0037 11.7577 22.0055C11.6586 22.0074 11.5601 21.9896 11.4678 21.9532C11.3756 21.9167 11.2915 21.8624 11.2204 21.7932C11.1494 21.7241 11.0927 21.6415 11.0538 21.5503C11.0148 21.4591 10.9943 21.3611 10.9935 21.2619C10.9927 21.1627 11.0115 21.0644 11.049 20.9726C11.0864 20.8807 11.1417 20.7972 11.2116 20.7269L19.7036 11.9999Z" fill="black"/>
</svg>

    </SvgIcon>

  )
}

export default ReportCardIcon