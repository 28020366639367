import React, { useEffect, useState } from "react";
import Box from "components/common/Box";
import Text from "components/common/Text";
import DownloadButton from "components/common/DownloadButton";
import { styled } from "@mui/material";
import { adminDashBoardApiAction } from "stores/redux/apiSlices/admin/adminDashBoardApiSlice";
import dayjs from "dayjs";

const RiaText = styled(Text)(() => ({
  marginTop: "24px",
  marginBottom: "6px",
  fontSize: "14px",
  fontWeight: 500,
  color: "#242424",
}));

const RiaText1 = styled(Text)(() => ({
  marginBottom: "16px",
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
}));

const RiaText2 = styled(Text)(() => ({
  marginLeft: "43px",
  marginBottom: "16px",
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
}));

const RiaText3 = styled(Text)(() => ({
  marginLeft: "20px",
  marginBottom: "16px",
  fontSize: "16px",
  fontWeight: 400,
  color: "#101828",
}));

const TableDrawer = (props) => {
  const { singleRiaData } = props;
  const [fileBase64, setFileBase64] = useState(null);

  const [fetchProfileDoc] = adminDashBoardApiAction.getLazyProfileDocApi();

  const fileDownload = async (fileUrl, filename) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onload = () => {
        setFileBase64(reader.result);
        downloadFile(filename, reader.result);
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const downloadFile = (filename, base64String) => {
    const link = document.createElement("a");
    link.href = base64String;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (
    !singleRiaData ||
    !singleRiaData.data ||
    !singleRiaData.data.message ||
    !singleRiaData.data.message.result
  ) {
    return <div>Loading...</div>; // Render a loading indicator while data is being fetched
  }

  const { name, email, mobile, basl_no, sebi_reg_no, basl_expiry } = singleRiaData.data.message.result;
  const formattedBaslExpiry = dayjs(basl_expiry).format("DD-MM-YYYY");
  const { documents } = singleRiaData.data.message.result;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <RiaText1>Customer name</RiaText1>
          <RiaText1>Email address</RiaText1>
          <RiaText1>Phone number</RiaText1>
          <RiaText1>SEBI registration</RiaText1>
          <RiaText1>BASL Number</RiaText1>
          <RiaText1>Expiry date</RiaText1>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <RiaText2>:</RiaText2>
          <RiaText2>:</RiaText2>
          <RiaText2>:</RiaText2>
          <RiaText2>:</RiaText2>
          <RiaText2>:</RiaText2>
          <RiaText2>:</RiaText2>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", mr: 4 }}>
          <RiaText3>{name}</RiaText3>
          <RiaText3>{email}</RiaText3>
          <RiaText3>{mobile}</RiaText3>
          <RiaText3>{sebi_reg_no}</RiaText3>
          <RiaText3>{basl_no}</RiaText3>
          <RiaText3>{formattedBaslExpiry}</RiaText3>
        </Box>
      </Box>
      <Box>
        {documents.map((doc) => (
          <div key={doc.document_id}>
            <RiaText>{doc.document_name}</RiaText>
            <DownloadButton
              children={doc.display_name}
              handleDownload={() => fileDownload(doc.file, doc.display_name)}
            />
          </div>
        ))}
      </Box>
    </>
  );
};

export default TableDrawer;
