//Package Import
import { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";

//helpers
import useDebounce from "hooks/Debounce";
import { getRiskProfileChipColor } from "helpers/utility";

//Components
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Card from "components/common/Card";
import Text from "components/common/Text";
import SideDrawer from "components/common/SideDrawer";

import AddTradeDrawer from "components/Trade/customPortfolio/customAddTradeDrawer";
import ModelCheckPage from "components/Trade/customPortfolio/customCheckPage";
import TradeImportModal from "components/Trade/importTrades/TradeImportModal";
import TradesPreviewModal from "components/Trade/importTrades/TradesPreviewModal";
import RiskProfileModal from "components/Trade/importTrades/RiskProfileModal";
import SelectCustomerSendCallsTable from "components/Trade/SelectCustomerSendCallsTable";
import ActiveTradeDrawer from "components/Trade/importTrades/ActiveTradeDrawer";
import TradeUpdateCallDetailTable from "components/Trade/importTrades/TradeUpdateCallDetailTable";
import TradesCountStacks from "components/Trade/TradesCountStacks";
import TradeHeader from "components/Trade/TradeHeader";
import CustomAddFilter from "components/Trade/customPortfolio/CustomAddFilter";
import WarningPopup from "components/Trade/WarningPopUp";

import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import CustomDataGrid from "components/datagrid/CustomGrid";
import TradeUpdateStackTable from "components/Trade/TradeUpdateStackTable";
import { clickAndDownloadFile } from "helpers/fileFormat";

import _get from "lodash/get";

// Constants
const AddSingleTradeStep = {
  ADD_TRADE_FORM: "ADD_TRADE_FORM",
  CUSTOMER_DETAIL_TABLE: "CUSTOMER_DETAIL_TABLE",
  ADD_TRADE_UPDATE_CALL: "ADD_TRADE_UPDATE_CALL",
};

const ImportTradeSteps = {
  IMPORT_FILE_MODAL: "IMPORT_FILE_MODAL",
  IMPORT_TRADE_PREVIEW_TABLE: "IMPORT_TRADE_PREVIEW_TABLE",
  IMPORT_RISK_PROFILE_MODAL: "IMPORT_RISK_PROFILE_MODAL",
  CUSTOMER_DETAILS_TABLE: "CUSTOMER_DETAILS_TABLE",
};

// let PRODUCT_ID = 1; // product code for custom

const TradeContainer = (props) => {
  const {
    PRODUCT_ID, // product code for custom CUSTOM=1, MODEL_PORTFOLIO=2, ALGO=3
  } = props;

  //states
  const [tradesDetails, setTradesDetails] = useState([]);
  const [tradesTotalRecord, setTradesTotalRecord] = useState(0);
  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [isCustomFilterDrawerOpen, setIsCustomFilterDrawerOpen] =
    useState(false);
  const [filterData, setFilterData] = useState({
    profitFrom: null,
    profitTo: null,
    fromDate: null,
    toDate: null,
    clearAll: 0,
    search: "",
  });

  //State: Add Single Trade
  const [isTradeDrawerOpen, setIsTradeDrawerOpen] = useState(false);
  const [addTradeCurrentStep, setAddTradeCurrentStep] = useState(null);
  const [newAddedTradeDetails, setNewAddedTradeDetails] = useState(null);
  const [tradeUpdateId, setTradeUpdateId] = useState(null);
  const [addSingleTradeSelectedDetails, setAddSingleTradeSelectedDetails] =
    useState([]);
  const [callPendingData, setCallPendingData] = useState();
  const [showTradesDetailsTable, setShowTradesDetailsTable] = useState(false);
  const [showTradesUpdateDetailsTable, setShowTradesUpdateDetailsTable] =
    useState(false);
  const [checkTradePending, setCheckTradePending] = useState(false);

  //State: Import Trade
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [isImportTradeInProcess, setIsImportTradeInProcess] = useState(false);
  const [importTradeCurrentStep, setImportTradeCurrentSteps] = useState(null);
  const [importListData, setImportListData] = useState([]);

  // loading...
  const [tradeLoading, setTradeLoading] = useState(false);

  // pop up
  const [warningMessage, setWarningMessage] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  // APIs: Import flow
  const [importTradeSendFile] = tradesApiAction.importTradeSendFileApi();
  const [postAttachImportFileData] = tradesApiAction.importProceedSelectRisk();
  const [proceedToImportTradeApi] = tradesApiAction.proceedToImportTrade();

  // -----> Import Trade
  const ProceedToSelectRiskProfile = async (importListDataID) => {
    // saveDataToServer(importListDataID);
    try {
      const result = await postAttachImportFileData(importListDataID);
      if (result && result.data && result.data.length) {
        setImportListData(result.data);

        const { data } = result;
        if (data && data.length) {
          //check if any status are false
          if (data.every((d) => d.status)) {
            if (PRODUCT_ID != 2) {
              setPreviewModalOpen(false);
              setImportTradeCurrentSteps(
                ImportTradeSteps.IMPORT_RISK_PROFILE_MODAL
              );
            } else {
              proceedToImportTrade([], result.data);
            }

            // NOTE: need to handle flow for Algo trade.
          }
        }
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const backToPreviewTable = () => {
    setPreviewModalOpen(true);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_TRADE_PREVIEW_TABLE);
  };

  const backToImportFileModal = () => {
    setPreviewModalOpen(false);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
  };

  const backToImportModal = () => {
    setPreviewModalOpen(false);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
  };

  const proceedToImportTrade = async (
    riskProfile = [],
    importedChangedData
  ) => {
    try {
      const payload = {
        trades: importedChangedData || importListData,
        productId: PRODUCT_ID,
      };

      if (PRODUCT_ID == 1) {
        payload.riskProfile = riskProfile;
      }

      const res = await proceedToImportTradeApi(payload);
      if (res && res.data && res.data.length) {
        // Note: While Import trade trade id could be multiple as per the selected stocks.
        const tradeIDs = [];
        const selectUserIds = [];
        const tradeCustomer = res.data.map((d) => {
          if (!tradeIDs.includes(d.trade_id)) {
            tradeIDs.push(d.trade_id);
          }
          selectUserIds.push(d.customer_id + d.trade_id);
          return {
            ...d,
            id: d.customer_id + d.trade_id,
          };
        });
        console.log("Import trade Trade IDs", tradeIDs);

        setPreviewModalOpen(false);
        setImportTradeCurrentSteps(ImportTradeSteps.CUSTOMER_DETAILS_TABLE);

        const tradeData = {
          customers: tradeCustomer,
          // trade_id: tradeIDs[0],
        };
        setNewAddedTradeDetails(tradeData);
        setAddSingleTradeSelectedDetails(selectUserIds);
      } else {
        // alert no data found.
      }
      console.log("Responce data => ", res);
    } catch (e) {
      console.log("Error ProceedToImportTrade: ", e);
    }
  };

  // ---> Single Trade

  //Apis: single trade
  const [getTradesListing] = tradesApiAction.getTradesApi();
  const [addSingleTrade] = tradesApiAction.addTradesApi();
  const [sendSingleTradeCall] = tradesApiAction.sendTradeCallsApi();
  const [multipleTradeCalls] = tradesApiAction.sendMultipleTradeCallsApi();
  const [getStackList, { data: StackList, isFetching: stackListFetching }] =
    tradesApiAction.getStackListApi();

  // API call to get trade table data based on trade ID
  const [getTradeDetailsUsingId] = tradesApiAction.getTradeDetailsUsingId();

  // LS: did mount
  useEffect(() => {
    getStackList({ productId: PRODUCT_ID, noGlobalLoading: true });
  }, []);

  // const [downloadExcelData, { data: downloadedExcelFile = {} }] = tradesApiAction.downloadExcelData();
  const [downloadExcelData] = tradesApiAction.downloadExcelData();

  const pendingCallStackList = StackList?.result?.filter(
    (trade) => trade.flag === 0
  );

  const pendingTradeCallStackList = StackList?.result?.filter(
    (trade) => trade.flag === 1
  );

  // Filter, Search, get listing
  const debouncedValue = useDebounce(filterData?.search, 500);

  // LS: didUpdate
  useEffect(() => {
    getAndSetTradeListingData();
  }, [debouncedValue, filterData?.clearAll, tradesPaginationModel]);

  // Get Api details methods
  const getAndSetTradeListingData = async () => {
    let res = [];
    let payload = {};
    let product_id = PRODUCT_ID; // Product ID 1 for custome trade

    try {
      setTradeLoading(true);
      // add search
      payload.search = filterData?.search;

      // add filter
      if (filterData.fromDate) {
        payload.date_range_from = dayjs(filterData.fromDate).format(
          "YYYY/MM/DD"
        );
      }
      if (filterData.toDate) {
        payload.date_range_to = dayjs(filterData.toDate).format("YYYY/MM/DD");
      }
      if (filterData.profitFrom) {
        payload.profit_range_from = filterData.profitFrom;
      }
      if (filterData.profitTo) {
        payload.profit_range_to = filterData.profitTo;
      }

      const { page, pageSize } = tradesPaginationModel;

      payload.noGlobalLoading = true; // restrics global loading.
      res = await getTradesListing({
        product_id: product_id,
        page,
        pageSize,
        payload: payload,
      });
      if (res?.data) {
        const { data, totalRecord } = res.data;
        setTradesDetails(data);
        setTradesTotalRecord(totalRecord);
        setIsCustomFilterDrawerOpen(false);
      }
      setTradeLoading(false);
    } catch (e) {
      setTradeLoading(false);
      console.log("Error: getAndSetTradeListingData ->", e);
      //
    }
  };

  // Filter params changes
  const handleFilterDataChange = async ({ name, value, clearAll = 0 }) => {
    if (clearAll) {
      let newFilterData = { ...filterData };
      newFilterData.fromDate = null;
      newFilterData.toDate = null;
      newFilterData.profitFrom = null;
      newFilterData.profitTo = null;
      newFilterData.clearAll = newFilterData.clearAll + 1;
      setFilterData(newFilterData);
      return;
    }

    if (["profitFrom", "profitTo"].includes(name) && isNaN(value)) {
      return;
    }

    let newFilterData = { ...filterData };
    if (["toDate", "fromDate"].includes(name) && value && value["$d"]) {
      newFilterData[name] = value["$d"];
    } else {
      newFilterData[name] = value;
    }

    setFilterData(newFilterData);
  };

  //Handler methods
  const handleCustomFilterDrawer = () => {
    setIsCustomFilterDrawerOpen(!isCustomFilterDrawerOpen);
  };

  const handleTradeDrawerOpen = () => {
    setIsTradeDrawerOpen(true);
    setAddTradeCurrentStep(AddSingleTradeStep.ADD_TRADE_FORM);
  };

  // Add Single Trade Function
  const handleAddSingleTrade = async (tradeData) => {
    let payload = createAddSingleTradePayload(tradeData);
    let res = await addSingleTrade(payload);

    try {
      const { statusCode, result } = _get(res, "data", {});

      if (statusCode === 200) {
        let { parsedRes, selectUserIds } = parseAddSingleTradeResponse(result);
        console.log("selected usetds ", selectUserIds);
        setNewAddedTradeDetails(parsedRes);
        setAddSingleTradeSelectedDetails(selectUserIds);
        setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
      }

      console.log("res : ", res);
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const parseAddSingleTradeResponse = (res) => {
    let parsedRes = {};
    let selectUserIds = [];
    let parsedCustomer = res?.customers?.map((item) => {
      selectUserIds.push(item.customer_id);
      return {
        ...item,
        id: item.customer_id,
      };
    });
    parsedRes.trade_id = res.trade_id ?? null;
    parsedRes.customers = parsedCustomer;
    return { parsedRes, selectUserIds };
  };

  const createAddSingleTradePayload = (tradeData) => {
    let payload = {};
    console.log("tradeData", tradeData);

    payload.stock_name = tradeData?.stockName;
    payload.index = tradeData?.index;
    payload.type = tradeData?.tradeType;

    payload.is_percent = tradeData?.buySellType === "PERCENTAGE" ? "Y" : "N";

    if (tradeData.is_target) {
      payload.is_target = tradeData?.isTargetChecked ? "Y" : "N";
    } else {
      payload.is_target = "";
    }

    payload.percent =
      tradeData?.buySellType === "PERCENTAGE" ? tradeData?.percentage : "0";
    payload.quantity =
      tradeData?.buySellType === "QUANTITY" ? tradeData?.quantity : "0";
    payload.order_type = tradeData?.orderType;
    if (tradeData?.orderType === "LIMIT") {
      payload.price = tradeData?.price;
    }
    // payload.is_stop_loss = tradeData?.isStopLossChecked === true ? "Y" : "N";

    if (tradeData.is_stop_loss) {
      payload.is_stop_loss = tradeData?.isStopLossChecked ? "Y" : "N";
    } else {
      payload.is_stop_loss = "";
    }

    payload.stop_loss = tradeData?.stopLossPerc;

    payload.target = tradeData?.target;
    payload.expiry_date = dayjs(tradeData?.maxBuySellDate).format("YYYY-MM-DD");
    payload.expiry_time = dayjs(tradeData?.maxBuySellTime).format("HH:mm:ss");
    payload.is_risk_profile = tradeData?.isRiskProfileSelected;
    payload.product_id = PRODUCT_ID;

    let customers = [];
    if (tradeData?.isRiskProfileSelected) {
      if (tradeData?.isHighRiskChecked) {
        let highRiskCustomers = tradeData?.selectedHighRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...highRiskCustomers];
      }
      if (tradeData?.isModerateRiskChecked) {
        let moderateRiskCustomers = tradeData?.selectedMediumRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...moderateRiskCustomers];
      }
      if (tradeData?.isLowRiskChecked) {
        let lowRiskCustomers = tradeData?.selectedLowRiskCustomers.map(
          (item) => {
            return {
              customer_id: item.user_id,
              risk_profile_id: item.risk_profile_id,
            };
          }
        );
        customers = [...customers, ...lowRiskCustomers];
      }
    }
    if (tradeData?.isCustomersSelected) {
      let allCustomers = tradeData?.selectedCustomers?.map((item) => {
        return {
          customer_id: item.user_id,
          risk_profile_id: item.risk_profile_id,
        };
      });
      customers = [...customers, ...allCustomers];
    }

    payload.customers = customers;
    console.log(payload, "cuspayload");

    return payload;
  };

  const stepToSendCall = () => {
    // Update the state to show the Customer Details Table
    setAddTradeCurrentStep(AddSingleTradeStep.CUSTOMER_DETAIL_TABLE);
    setShowTradesDetailsTable(true); // Set this to true to show the table
  };

  const pendingTradeCheck = () => {
    // Update the state to show the Customer Details Table
    setAddTradeCurrentStep(AddSingleTradeStep.ADD_TRADE_UPDATE_CALL);
    setCheckTradePending(true); // Set this to true to show the table
  };

  const backtoMainScreen = () => {
    // Trade pending state
    setAddTradeCurrentStep(null);
    setCheckTradePending(false); // Set this to true to show the table

    // Send calls state
    setShowTradesDetailsTable(false); // Set this to true to show the table

    // back from update trade details
    setShowTradesUpdateDetailsTable(false);
    setIsTradeDrawerOpen(false);

    // Recall API: Update Stack list
    getStackList({ productId: PRODUCT_ID, noGlobalLoading: true });

    // import
    setIsImportTradeInProcess(false);
    setImportTradeCurrentSteps(null);
    setNewAddedTradeDetails(null);
    setAddSingleTradeSelectedDetails([]);

    // auto scroll to top
    document.getElementById("mainAppWrapper").scrollTo(0, 0);
  };

  const handleCustomerChangeInAddSingleTrade = (data) => {
    console.log("onRowSelectionModelChange 1", data);
    const selected = data || [];
    setAddSingleTradeSelectedDetails(selected);
  };

  const sendSingleTradeCalls = async (
    trade_id = null,
    tradeDataForTableWithId = null
  ) => {
    const payload = {};

    if (!trade_id) {
      let customers = addSingleTradeSelectedDetails.map((id) => ({
        customer_id: id,
      }));
      payload.trade_id = newAddedTradeDetails.trade_id;
      payload.customers = customers;
    } else {
      let customers1 = tradeDataForTableWithId.map((item) => ({
        customer_id: item.customer_id,
      }));
      payload.trade_id = trade_id;
      payload.customers = customers1;
    }

    try {
      let res = await sendSingleTradeCall(payload);
      const resTradeId = _get(res, "data.result.trade_id[0]", null);
      console.log(res, "API RES-->");
      setTradeUpdateId(resTradeId);

      let data = _get(res, "data.result", null);
      if (!data || !data.length || !Array.isArray(data)) {
        let res2 = await getTradeDetailsUsingId({
          productId: PRODUCT_ID,
          StackTradeId: resTradeId,
        });
        data = _get(res2, "data.result", null);
      }

      if ((data && data.length) || !Array.isArray(data)) {
        setAddTradeCurrentStep(AddSingleTradeStep.ADD_TRADE_UPDATE_CALL);
        if (trade_id) {
          setShowTradesDetailsTable(false);
          setShowTradesUpdateDetailsTable(true);
        }
      }

      // Check for lessCashUsers
      const lessCashUsers = _get(res, "data.lessCashUsers", []);
      setApiResponse(lessCashUsers);
      if (lessCashUsers.length > 0) {
        setWarningMessage(`don't have insufficient funds to send the call`);
      }

      // setCallPendingData(data);
    } catch (err) {
      console.log("err : ", err);
    }
  };

  // Send Multiple trade calls
  const sendMultipleTradeCalls = async () => {
    const tradeList = newAddedTradeDetails?.customers.filter((d) =>
      addSingleTradeSelectedDetails.includes(d.id)
    );
    const submitPayload = tradeList.map((d) => ({
      customer_id: d.customer_id,
      trade_id: d.trade_id,
    }));

    try {
      // submit API call
      const res = await multipleTradeCalls({ trades: submitPayload });
      getStackList({ productId: PRODUCT_ID, noGlobalLoading: true });
      // backtoMainScreen();
      setImportTradeCurrentSteps(null);
      pendingTradeCheck();
      setTimeout(() => {
        const element = document.getElementById("mainAppWrapper");
        element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });
      }, 1500);
    } catch (err) {
      console.log("err : ", err);
    }
  };

  //Import Trade Functions

  const initateImportTrade = () => {
    setIsImportTradeInProcess(true);
    setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_FILE_MODAL);
  };

  const resetCancelImport = () => {
    // setIsImportTradeInProcess(false);
    // setImportTradeCurrentSteps(null);
    backtoMainScreen();
  };

  // Import: upload file & set list
  const importTradeFileUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await importTradeSendFile(formData).unwrap();
      setPreviewModalOpen(true);
      setImportListData(response);
      setImportTradeCurrentSteps(ImportTradeSteps.IMPORT_TRADE_PREVIEW_TABLE);
    } catch (error) {
      console.error("Error importing file", error);
    }
  };

  const onDownloadExcel = async (filtered) => {
    let payload = {};
    let product_id = PRODUCT_ID; // Product ID 1 for custome trade

    payload.search = filtered?.search;

    // add filter
    if (filtered.fromDate) {
      payload.date_range_from = dayjs(filtered.fromDate).format("YYYY/MM/DD");
    }
    if (filtered.toDate) {
      payload.date_range_to = dayjs(filtered.toDate).format("YYYY/MM/DD");
    }
    if (filtered.profitFrom) {
      payload.profit_range_from = filtered.profitFrom;
    }
    if (filtered.profitTo) {
      payload.profit_range_to = filtered.profitTo;
    }

    payload.noGlobalLoading = true; // restrics global loading.

    try {
      let file = await downloadExcelData({
        product_id: product_id,
        payload: payload,
      });
      const filename = [
        "Custom-trade-list",
        "Model-trade-list",
        "Algo-trade-list",
      ];
      if (file && file.data) {
        await clickAndDownloadFile({
          base64File: file.data,
          fileName: filename[product_id - 1],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!StackList) {
    return <></>;
  }
  const modelTableColumns = [
    {
      field: "stockName",
      headerName: "Stock name",
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
    },
    {
      field: "buyDate",
      headerName: "Buy Date",
      flex: 1,
    },
    {
      field: "sellDate",
      headerName: "Sell Date",
      flex: 1,
    },
    {
      field: "amountToInvest",
      headerName: "Amount Invested",
      flex: 1,
    },
    {
      field: "totalGains",
      headerName: "Total Gains",
      flex: 1,
    },
    {
      field: "amountAfterGains",
      headerName: "Amount After Gains",
      flex: 1,
    },
    {
      field: "todaysChange",
      headerName: "Todays Change",
      flex: 1,
    },
    {
      field: "taxPl",
      headerName: "Tax Pl",
      flex: 1,
    },
    {
      field: "riskProfile",
      headerName: "Risk Profile",
      flex: 1,
      renderCell: () => (
        <Stack direction="row" alignItems="center">
          <Chip
            label="Aggresssive"
            sx={{
              backgroundColor: "#FFEDED",
              color: "#EB5757",
              borderRadius: "152px",
              width: "61px",
              height: "24px",
            }}
          />
        </Stack>
      ),
    },
  ];
  const selectedTradeColumns = [
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.customer_name}
          </Box>
        );
      },
    },
    {
      field: "symbol",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.symbol}
          </Box>
        );
      },
    },
    {
      field: "quantity",
      headerName: PRODUCT_ID === 1 ? "Quantity" : "LOT",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.quantity} {p.row.lot_size ? ` X ${p.row.lot_size}` : ""}
          </Box>
        );
      },
    },
    ...(PRODUCT_ID === 3
      ? [
          {
            field: "total_quantity",
            headerName: "Total Quantity",
            flex: 1,
            // valueGetter: (params) => params.row.total_quantity || '',
            renderCell: (p) => {
              return (
                <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
                  {p.row.total_quantity || 0}
                </Box>
              );
            },
          },
        ]
      : []),

    {
      field: "exchange",
      headerName: "Index",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.exchange}
          </Box>
        );
      },
    },
    {
      field: "stop_loss",
      headerName: "Stop Loss",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.stop_loss}
          </Box>
        );
      },
    },
    {
      field: "target",
      headerName: "Target",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.target}
          </Box>
        );
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      renderCell: ({ row }) => {
        console.log("Data profile => ", row);
        return (
          <Stack direction="row" alignItems="center">
            <Text
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: row.status == 3 ? "#a4a4a4" : "",
              }}
            >
              {row.order_type.length > 0
                ? row.order_type.charAt(0).toUpperCase() +
                  row.order_type.slice(1).toLowerCase()
                : ""}
            </Text>
          </Stack>
        );
      },
      flex: 1,
    },

    {
      field: "amount_invested",
      headerName: "Amount",
      flex: 1,
      renderCell: (p) => {
        return (
          <Box sx={{ color: p.row.status == 3 ? "#a4a4a4" : "" }}>
            {p.row.amount_invested}
          </Box>
        );
      },
    },
    {
      field: "risk_profile_name",
      headerName: "Risk Profile",
      renderCell: ({ row }) => {
        console.log("Data profile => ", row);
        const { bgColor, textColor } = getRiskProfileChipColor(
          row.risk_profile_id
        );
        return (
          <Stack direction="row" alignItems="center">
            <Chip
              label={row.risk_profile_name}
              sx={{
                backgroundColor: bgColor,
                color: textColor,
                borderRadius: "152px",
                height: "24px",
                opacity: row.status == 3 ? "0.5" : "",
              }}
            />
          </Stack>
        );
      },
      flex: 1,
    },
  ];

  console.log("addTradeCurrentStep ======->: ", {
    checkTradePending,
    showTradesDetailsTable,
    showTradesUpdateDetailsTable,
    addTradeCurrentStep,
    importTradeCurrentStep,
    isTradeDrawerOpen,
  });

  console.log("newAddedTradeDetails ===> ", newAddedTradeDetails);

  // -------> Start
  return (
    <>
      <div>
        {/* Your other component code */}

        {warningMessage && (
          <WarningPopup
            message={warningMessage}
            apiResponse={apiResponse}
            onClose={() => setWarningMessage("")}
          />
        )}
      </div>
      {/* Trade Listing  */}
      {checkTradePending && (
        <TradeUpdateStackTable
          customerColumns={selectedTradeColumns}
          onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
          selectedRows={addSingleTradeSelectedDetails}
          StackList={StackList}
          backtoMainScreen={backtoMainScreen}
          productId={PRODUCT_ID}
        />
      )}
      {showTradesDetailsTable && (
        <SelectCustomerSendCallsTable
          customerColumns={selectedTradeColumns}
          onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
          selectedRows={addSingleTradeSelectedDetails}
          handleStackCall={sendSingleTradeCalls}
          StackList={StackList}
          backtoMainScreen={backtoMainScreen}
          productId={PRODUCT_ID}
        />
      )}
      {showTradesUpdateDetailsTable && (
        <TradeUpdateCallDetailTable
          // callPendingData={callPendingData}
          customerColumns={selectedTradeColumns}
          trade_id={tradeUpdateId}
          onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
          StackList={StackList}
          productId={PRODUCT_ID}
          backtoMainScreen={backtoMainScreen}
        />
      )}
      {!(
        addTradeCurrentStep === AddSingleTradeStep.CUSTOMER_DETAIL_TABLE ||
        addTradeCurrentStep === AddSingleTradeStep.ADD_TRADE_UPDATE_CALL ||
        importTradeCurrentStep
      ) && (
        <Box mt={2.5} mb={4}>
          {/* Trads Stacks */}
          {PRODUCT_ID != 2 ? (
            <TradesCountStacks
              pendingTradeCallStackList={pendingTradeCallStackList}
              pendingCallStackList={pendingCallStackList}
              stepToSendCall={stepToSendCall}
              pendingTradeCheck={pendingTradeCheck}
              stackListFetching={stackListFetching}
            />
          ) : null}

          {/* Data Listing */}
          <Card>
            <TradeHeader
              handleCustomFilterDrawer={handleCustomFilterDrawer}
              handleTradeDrawerOpen={handleTradeDrawerOpen}
              handleSearch={(e) =>
                handleFilterDataChange({
                  name: "search",
                  value: e?.target?.value,
                })
              }
              initateImportTrade={initateImportTrade}
              filterData={filterData}
              totalRecord={tradesTotalRecord}
              PRODUCT_ID={PRODUCT_ID}
              onDownloadExcel={() => onDownloadExcel(filterData)}
              tradesDetailsList={tradesDetails}
            />
            <CustomDataGrid
              list={tradesDetails}
              columnHeaderHeight={46}
              rowHeight={46}
              columns={modelTableColumns}
              count={tradesDetails.length}
              loading={tradeLoading}
              sx_wrapper={{
                borderRadius: "0px",
                "& .MuiDataGrid-columnHeaders": {
                  borderRadius: "0px",
                  backgroundColor: "red",
                },
              }}
              //pagination
              pagination
              rowCount={tradesTotalRecord}
              paginationMode="server" // client, server
              paginationModel={tradesPaginationModel}
              onPaginationModelChange={setTradesPaginationModel}
            />
          </Card>
        </Box>
      )}
      {/* Drawer: Add Filter */}
      <SideDrawer
        open={isCustomFilterDrawerOpen}
        closeDrawer={handleCustomFilterDrawer}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        handleSubmit={() => getAndSetTradeListingData()}
        cancelButtonText="Clear all"
        submitButtonText="Apply"
        drawerWidth={500}
        primaryAction={() => handleFilterDataChange({ clearAll: 1 })}
      >
        <CustomAddFilter
          handleChange={handleFilterDataChange}
          filterData={filterData}
        />
      </SideDrawer>
      {/* Drawer: Update Trade */}
      <ActiveTradeDrawer isOpen={false} onClose={() => {}} />
      {isImportTradeInProcess ? (
        <>
          {/* Modal: Trades Import  */}
          {importTradeCurrentStep === ImportTradeSteps.IMPORT_FILE_MODAL && (
            <TradeImportModal
              isOpen={
                importTradeCurrentStep === ImportTradeSteps.IMPORT_FILE_MODAL
              }
              handleClose={resetCancelImport}
              handleSave={importTradeFileUpload}
            />
          )}

          {/* Modal: Import Trade Preview */}
          {importTradeCurrentStep ===
            ImportTradeSteps.IMPORT_TRADE_PREVIEW_TABLE && (
            <TradesPreviewModal
              isOpen={true}
              ProceedToSelectRiskProfile={ProceedToSelectRiskProfile}
              handleClose={() => backToImportModal()}
              handleCancel={() => backToImportModal()}
              importListData={importListData}
            />
          )}

          {/* Modal: Import Trade Risk Profile confirmation */}
          {importTradeCurrentStep ===
            ImportTradeSteps.IMPORT_RISK_PROFILE_MODAL && (
            <RiskProfileModal
              isOpen={
                importTradeCurrentStep ===
                ImportTradeSteps.IMPORT_RISK_PROFILE_MODAL
              }
              handleClose={() => {}}
              // handleRiskProfileClick={() => {}}
              backToPreviewTable={backToPreviewTable}
              proceedToImportTrade={proceedToImportTrade}
            />
          )}

          {importTradeCurrentStep ===
            ImportTradeSteps.CUSTOMER_DETAILS_TABLE && (
            <SelectCustomerSendCallsTable
              customerColumns={selectedTradeColumns}
              data={newAddedTradeDetails.customers}
              onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
              selectedRows={addSingleTradeSelectedDetails}
              handleSendCalls={sendMultipleTradeCalls}
              mode="IMPORT"
            />
          )}
        </>
      ) : (
        <></>
      )}{" "}
      {/* Import Trades Components Ends Here */}
      {/*----- Add Single Trade Components Starts Here -----*/}
      {/* Side Drwer: Add Single Trade Form */}
      {isTradeDrawerOpen ? (
        <>
          {addTradeCurrentStep === AddSingleTradeStep.ADD_TRADE_FORM && (
            <SideDrawer
              open={isTradeDrawerOpen}
              closeDrawer={() => {
                setIsTradeDrawerOpen(false);
              }}
              title="Add Trades"
              subtitle="Here you can add trade for the customers"
              drawerWidth={500}
              showActionContainer={false}
            >
              <AddTradeDrawer
                primaryAction={
                  currentStep === 2
                    ? () => {
                        setCurrentStep(1);
                      }
                    : () => {
                        setIsTradeDrawerOpen(false);
                      }
                }
                handleSubmit={
                  currentStep === 1
                    ? () => {
                        setCurrentStep(2);
                      }
                    : handleAddSingleTrade
                }
                cancelButtonText={currentStep === 1 ? "Cancel" : "Back"}
                submitButtonText={currentStep === 2 ? "Done" : "Continue"}
                currentStep={currentStep}
                PRODUCT_ID={PRODUCT_ID}
              />
            </SideDrawer>
          )}

          {/*Table to show Select customer with stocks for trades */}
          {addTradeCurrentStep === AddSingleTradeStep.CUSTOMER_DETAIL_TABLE && (
            <SelectCustomerSendCallsTable
              customerColumns={selectedTradeColumns}
              data={newAddedTradeDetails.customers}
              onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
              selectedRows={addSingleTradeSelectedDetails}
              handleSendCalls={sendSingleTradeCalls}
              productId={PRODUCT_ID}
              mode="SINGLE"
              // StackList={StackList}
            />
          )}

          {addTradeCurrentStep === AddSingleTradeStep.ADD_TRADE_UPDATE_CALL && (
            <TradeUpdateCallDetailTable
              // callPendingData={callPendingData}
              customerColumns={selectedTradeColumns}
              trade_id={newAddedTradeDetails.trade_id}
              data={newAddedTradeDetails.customers}
              onRowSelectionModelChange={handleCustomerChangeInAddSingleTrade}
              productId={PRODUCT_ID}
              backtoMainScreen={backtoMainScreen}
              // selectedRows={addSingleTradeSelectedDetails}
              StackList={StackList}
            />
          )}
        </>
      ) : (
        <></>
      )}{" "}
      {/* Add Single Trade Components ends Here */}
    </>
  );
};

export default TradeContainer;
