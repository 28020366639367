import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const AdminProfile = createApi({
    reducerPath: "adminDashBoard",
    baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
    tagTypes: ["GETPROFILE"],

    endpoints: (qb) => ({
        getProfileApi: qb.query({
            // Specify the API endpoint URL for fetching plans
            query: () => {
              return `api/admin/get-admin-profile`;
            },
            
            // Transform the API response to extract the 'result' property
            transformResponse: (response) => response,
            // Provide a tag for cache invalidation associated with this endpoint
            providesTags: ["GETPROFILE"],
          }),


        updateProfileApi: qb.mutation({
            query: ({name,email,mobile,joining_date}) => ({
              url: `api/admin/admin-update-profile`,
              method: "PUT",
              body: {name,email,mobile,joining_date },
              invalidatesTags: ["GETPROFILE"],
            }),
          }),

          
    }),
})

export const AdminProfileApiReducer = AdminProfile.reducer;

export const adminProfileApiAction = {
    middleware: AdminProfile.middleware,
    reducerPath: AdminProfile.reducerPath,
    getProfileApi: AdminProfile.useGetProfileApiQuery,
    updateProfileApi: AdminProfile.useUpdateProfileApiMutation,
}