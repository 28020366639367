import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validationHelper } from "helpers/validation";
// import CustomerRiskDetails from "components/addCustomer/CustomerRiskDetails";
import { addCustomerActions } from "stores/redux/slices/addCustomerSlice";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { CustomerPortalOnboardApiAction } from "stores/redux/slices/customerPortalOnboardSlice";
import CustomerOnBoardingRiskDetail from "./CustomerOnBoardingRiskDetail";
import Box from "components/common/Box";
import Text from "components/common/Text";
import ChangeRisk from "components/addCustomer/ChangeRisk";
import CustomerMeter from "./CustomerMeter";
import _get from "lodash/get";
// import {addCustomerActions} from "stores"

// This is Risk Assesment container of Customer Portal

function CustomerOnBoardingRiskAssesmentContainer({
  handleStepChange,
  currentStep,
}) {
  const [currentQtsId, setCurrentQtsId] = useState(1);
  const [totalQts, setTotalQts] = useState(0);
  const [riskPoints, setRiskPoints] = useState(0);
  const [riskDetailsAnswer, setRiskDetailsAnswer] = useState([]);
  const [submitRiskDetailsAction] = addCustomerApiAction.submitRiskDetails();
  const [isChangeRiskVisible, setIsChangeRiskVisible] = useState(false);
  const [apiresult, setApiResult] = useState();

  const dispatch = useDispatch();

  const {
    data: riskAssesmentQts,
    isFetching,
    refetch: refetchRiskAssesmentQts,
  } = CustomerPortalOnboardApiAction.getRiskAssesmentQuestion();

  const [previousAnswer, { isLoading: isPrevAnsApiLoading }] =
    addCustomerApiAction.getPreviousAnswer();

  // const { data: riskAssesmentQts = {}, refetch: refetchRiskAssemenetQts,isFetching } = addCustomerApiAction.getRiskAssesment();
  console.log(riskAssesmentQts, "NewQnA");

  // const userId = useSelector((state)=> state?.customerOnboarding?.userId);
  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  useEffect(() => {
    refetchRiskAssesmentQts();
  }, []);
  // useEffect(() => {
  // 	if (Object.keys(riskAssesmentQts).length) {
  // 		setTotalQts(riskAssesmentQts.qts.length);
  // 	}
  // }, [riskAssesmentQts]);

  useEffect(() => {
    initSetAnser();
  }, []);

  const initSetAnser = async () => {
    if (userDetails.user_id) {
      const selectedAnswer = await previousAnswer(
        { userId: userDetails.user_id },
        false
      );
      const answers = _get(selectedAnswer, "data", []);
      if (answers && answers.length) {
        
        const persedSelectedAns = answers.map((d) => ({
          answer_id: d.answer_id,
          question_id: d.question_id,
          weightage: d.weightage,
          option_text_value: d.option_text_value,
          text_values: d.text_values ? d.text_values.map((e) => e) : [] 
        
        }));
        setIsChangeRiskVisible(false);
        setCurrentQtsId(persedSelectedAns.length);
        setRiskDetailsAnswer(persedSelectedAns);
      }
    }
  };

  useEffect(() => {
    if (riskAssesmentQts && Object.keys(riskAssesmentQts).length) {
      setTotalQts(riskAssesmentQts.qts.length);
    }
  }, [riskAssesmentQts]);

  useEffect(() => {
    //  Refetch and reset aswers to the state.
  }, []);

  if (isFetching && riskAssesmentQts) {
    return <div>...Loading</div>;
  }

  const handleSubmit = async (data = null) => {
    const answers = data || riskDetailsAnswer;
    const newRiskDetails = answers.map((item) => {
      return {
        question_id: `${item.question_id}`,
        answer_id: `${item.answer_id}`,
        weightage: `${item.weightage}`,
        option_text_value: `${item.option_text_value}`,
        text_values: item?.text_values
      };
    });
    console.log("answerssss",answers)

    // const newRisk
    const payload = {
      userId: userDetails.user_id,
      answer: newRiskDetails,
    };
    try {
      const res = await submitRiskDetailsAction(payload);
      console.log(res, "resii");
      const resData = res.data.result;
      setApiResult(resData);
      console.log(resData, "STATE");
      // handleStepChange(2);
      setIsChangeRiskVisible(true);
      if (res && res.data && res.data.status === 1) {
        // let { result } = res.data;
        // const payload = {
        //   riskProfileScore: result.riskProfileScore,
        //   type: result.type,
        //   investmentId: result.investment_id,
        // };
        // dispatch(addCustomerActions.setCustomerRiskProfile(payload));
        // handleStepChange(2);
        return null;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const autoScrollToCurrentQues = (qId = 1) => {
    if (!qId) {
      return;
    }
    const elem = document.getElementById(`ques-ref-${qId}`);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleRiskDetailAnswers = (
    questionId,
    currentAnswer,
    textValue = null,
    isComplete,
    textValues
  ) => {
    autoScrollToCurrentQues(questionId);
    let newRiskDetails = [...riskDetailsAnswer];
    newRiskDetails = riskDetailsAnswer.filter(
      (item) => item.question_id !== questionId
    );
    
    const answer = {
      question_id: questionId,
      answer_id: currentAnswer.optionId,
      weightage: currentAnswer.weightage,
      option_text_value: textValue,
      text_values: textValues,
    };
    newRiskDetails.push(answer);
    const point = newRiskDetails.reduce(
      (acc, currValue) => acc + currValue.weightage,
      0
    );
    setRiskDetailsAnswer(newRiskDetails);
    setRiskPoints(point);
    // console.log("newRiskDetails ===>", newRiskDetails);
    if (isComplete) {
      handleSubmit(newRiskDetails);
    } else {
      setCurrentQtsId(currentQtsId + 1);
    }
  };

  const JumpToPrevQuestion = (questionId) => {
    setCurrentQtsId(questionId);
    autoScrollToCurrentQues(questionId - 2);
  };

  const handleRiskDetailPreviousStep = () => {
    autoScrollToCurrentQues(currentQtsId - 2);
    setCurrentQtsId(currentQtsId - 1);
  };

  if (isPrevAnsApiLoading) {
    return <div>Loading...</div>;
  }

  console.log("CustomerOnBording existing formate==> ", isChangeRiskVisible);
  return (
    <>
      {!isChangeRiskVisible && (
        <CustomerOnBoardingRiskDetail
          handleSubmit={handleSubmit}
          handleAnswers={handleRiskDetailAnswers}
          handlePreviousStep={handleRiskDetailPreviousStep}
          riskAssesmentQts={riskAssesmentQts}
          currentQtsId={currentQtsId}
          totalQts={totalQts}
          riskPoints={riskPoints}
          riskDetailsAnswer={riskDetailsAnswer}
          JumpToPrevQuestion={JumpToPrevQuestion}
        />
      )}

      {isChangeRiskVisible && (
        <>
          <CustomerMeter
            resData={apiresult}
            handleStepChange={(step) => {
              handleStepChange(step);
              if (step == 1) {
                initSetAnser();
              }
            }}
          />
        </>
      )}
    </>
  );
}

export default CustomerOnBoardingRiskAssesmentContainer;
