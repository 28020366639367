import CustomerLoginContainer from 'containers/Customer/CustomerLoginContainer'
import React from 'react'

function CustomerLogin() {
  return (
    <>
      <CustomerLoginContainer />
    </>
  );
}

export default CustomerLogin
