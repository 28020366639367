import React from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Dialog from "components/common/Dialog";
import CheckedDialogIcon from "asset/icons/CheckedDialogIcon";
import Button from "components/common/Button";
import { useNavigate } from "react-router-dom";

function CustomerBlank() {
  const navigate = useNavigate();

  return (
      <Stack
        direction="column"
        spacing={1.5}
        sx={{ 
          width: "100%", 
          height: "100vh",
          backgroundColor: "#e8e8e8"
        }}
        alignItems="center"
        justifyContent="center"
      >
        <CheckedDialogIcon sx={{ width: "58px", height: "58px" }} />
        <Text
          fontSize="20px"
          fontWeight="600"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Congratulations 🥳, Your Request has been sent to RIA.
        </Text>

        <Text
          fontSize="20px"
          fontWeight="600"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          Please wait for some time.⏳
        </Text>
        <Text
          width="500px"
          fontSize="14px"
          fontWeight="400"
          color="#667085"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
        >
          "Congratulations on successfully signing up! Your onboarding request
          has been submitted to the RIA (Registered Investment Advisor). Please
          be patient as they review your request. Thank you for choosing us for
          your investment journey!"
        </Text>
        <Button
          sx={{
            width: "489px",
            height: "56px",
            fontWeight: 600,
            borderRadius: "8px",
            p: "18px 20px",
            mt: "32px",
          }}
          onClick={() => navigate("/login")}
        >
          Okay
        </Button>
      </Stack>
    // <Dialog open={true} disableCloseIcon={true} enableBackdropClick={false}>
    // </Dialog>
  );
}

export default CustomerBlank;
