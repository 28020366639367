import Stack from "components/common/Stack";
import React, { useState, useEffect } from "react";
import { ButtonGroup, styled } from "@mui/material";
import Box from "components/common/Box";
import Text from "components/common/Text";
import Button from "components/common/Button";
import NewDialog from "../components/common/Dialog";
import CheckboxCommon from "components/common/CheckboxCommon";
import TextField from "components/common/TextField";
import BlockCustomer from "../components/customerSidebar/BlockCustomer";
import Divider from "components/common/Divider";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { useDispatch, useSelector } from "react-redux";
import Grid from "components/common/Grid";
import { toastActions } from "stores/redux/slices/toastSlice";
import { validationHelper } from "helpers/validation";
import { useLocation } from "react-router-dom";

const CustomerDetailHeader = ({ isValue, setIsValue }) => {
  const select = useSelector((state) => state);
  const location=useLocation();
  const BlockUserID = location.state && location.state.BlockUserID;
  const BlockUserName = location.state && location.state.BlockUserName;
  const BlockUserStatus = location.state && location.state.BlockUserStatus;
  const select_isBlock = useSelector(
    (state) => state.toast?.breadCrumbData?.is_block
  );
  const select_userId = useSelector(
    (state) => state.toast?.breadCrumbData?.user_id
  );

  //  -----------------------API -------------------------- //
  const [postBlockCustomer] = customersApiAction.postBlockCustomer();
  const customer_id = BlockUserID === null ? select_userId : BlockUserID ;
  const user_block_status = BlockUserStatus === null ? select_isBlock : BlockUserStatus ;
  const [isBlocked, setIsBlocked] = useState(user_block_status);
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [isBlockDrawerOpen, setBlockIsDrawerOpen] = useState(false);
  const [postAddMoney] = customersApiAction.postAddMoney();
  const [amountError, setAmountError] = useState(null);
  const [modalAmountError, setModalAmountError] = useState(null);
  const [formData, setFormData] = useState({
    isCustomInvestmentSelected: false,
    customAmount: "",
    isModelPortfolioSelected: false,
    modelPorfolioAmount: "",
    isHighRiskSelected: false,
    highRiskAmount: "",
    isMediumRiskSelected: false,
    mediumRiskAmount: "",
    isLowRiskSelected: false,
    lowRiskAmount: "",
    isAlgoSelected: false,
    algoAmount: "",
  });

  const [formError, setFormError] = useState({
    customAmount: "",
    modelPorfolioAmount: "",
    highRiskAmount: "",
    mediumRiskAmount: "",
    lowRiskAmount: "",
    algoAmount: "",
  });

  function capitalValidation() {
    let currentAmount = 0;
    if (formData.isCustomInvestmentSelected) {
      currentAmount += Number(formData.customAmount);
    }
    if (formData.isAlgoSelected) {
      currentAmount += Number(formData.algoAmount);
    } 
    if (formData.isModelPortfolioSelected) {
      currentAmount += Number(formData.modelPorfolioAmount);
      let modelAmount = 0;
      if (formData.isHighRiskSelected) {
        modelAmount += Number(formData.highRiskAmount);
      }
      if (formData.isMediumRiskSelected) {
        modelAmount += Number(formData.mediumRiskAmount);
      }
      if (formData.isLowRiskSelected) {
        modelAmount += Number(formData.lowRiskAmount);
      }
  
      if (modelAmount !== Number(formData.modelPorfolioAmount)) {
        setModalAmountError(
          "Total Model amount should be equal to Model Portfolio Amount"
        );
        return false; // Validation failed
      }
    } else {
      setModalAmountError(null);
    }
    return true; 
  }

  const handleValidation = () => {
    const newFormError = { ...formError };

    let customValidation = { isValid: 1 };
    if (formData.isCustomInvestmentSelected) {
        customValidation = validationHelper.required(formData.customAmount);
        newFormError.customAmount = customValidation.message;
        if (formData.customAmount <= 0 || isNaN(formData.customAmount)) {
            customValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.customAmount = customValidation.message;
        }
    } else {
        newFormError.customAmount = "";
    }

    let modelPortfolioValidation = { isValid: 1 };
    if (formData.isModelPortfolioSelected) {
        const modelPortfolioAmount = formData.modelPorfolioAmount;
        if (modelPortfolioAmount <= 0 || isNaN(modelPortfolioAmount)) {
            modelPortfolioValidation = { isValid: 0, message: "Amount must be greater than zero" };
        }
        newFormError.modelPorfolioAmount = modelPortfolioValidation.message;
    } else {
        newFormError.modelPorfolioAmount = "";
    }

    let highRiskValidation = { isValid: 1 };
    if (formData.isHighRiskSelected) {
        highRiskValidation = validationHelper.required(formData.highRiskAmount);
        newFormError.highRiskAmount = highRiskValidation.message;
        if (formData.highRiskAmount <= 0 || isNaN(formData.highRiskAmount)) {
            highRiskValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.highRiskAmount = highRiskValidation.message;
        }
    } else {
        newFormError.highRiskAmount = "";
    }

    let mediumRiskValidation = { isValid: 1 };
    if (formData.isMediumRiskSelected) {
        mediumRiskValidation = validationHelper.required(formData.mediumRiskAmount);
        newFormError.mediumRiskAmount = mediumRiskValidation.message;
        if (formData.mediumRiskAmount <= 0 || isNaN(formData.mediumRiskAmount)) {
            mediumRiskValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.mediumRiskAmount = mediumRiskValidation.message;
        }
    } else {
        newFormError.mediumRiskAmount = "";
    }

    let lowRiskValidation = { isValid: 1 };
    if (formData.isLowRiskSelected) {
        lowRiskValidation = validationHelper.required(formData.lowRiskAmount);
        newFormError.lowRiskAmount = lowRiskValidation.message;
        if (formData.lowRiskAmount <= 0 || isNaN(formData.lowRiskAmount)) {
            lowRiskValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.lowRiskAmount = lowRiskValidation.message;
        }
    } else {
        newFormError.lowRiskAmount = "";
    }

    let algoValidation = { isValid: 1 };
    if (formData.isAlgoSelected) {
        algoValidation = validationHelper.required(formData.algoAmount);
        newFormError.algoAmount = algoValidation.message;
        if (formData.algoAmount <= 0 || isNaN(formData.algoAmount)) {
            algoValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.algoAmount = algoValidation.message;
        }
    } else {
        newFormError.algoAmount = "";
    }

    setFormError(newFormError);

    return (
        customValidation.isValid &&
        modelPortfolioValidation.isValid &&
        highRiskValidation.isValid &&
        mediumRiskValidation.isValid &&
        lowRiskValidation.isValid &&
        algoValidation.isValid
    );
};


  const handleSubmit = async () => {
     const parsedPayload = payloadParser();
       if (!parsedPayload.investments ||
         parsedPayload.investments.length === 0) {
            return;
         }
  
       if (!handleValidation()) {
       return;
         }
        if (!capitalValidation()) {
       return;
         }
    try {
      let payload = {
        investments: parsedPayload,
        user_id: BlockUserID === null ? customer_id : BlockUserID ,
      };
      const res = await postAddMoney(payload);
      if (res?.data?.status === 1) {
        setIsValue(true);
        setModalOpen(false);
        setFormData("");
        setFormError('');
        setModalAmountError('')
        const toastMessage = "Cash Added successfully!";
        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: "success",
          })
        );
      } else {
        const toastMessage =
          res?.data?.message || "Failed to Cash Added!";
        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: "error",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const payloadParser = () => {
    let payload = {};
    let investments = [];

    if (formData.isCustomInvestmentSelected) {
      let investment = {
        product_id: 1,
        amount: Number(formData.customAmount),
        product_category: [],
      };
      investments.push(investment);
    }
    if (formData.isModelPortfolioSelected) {
      let investment = {
        product_id: 2,
        amount: Number(formData.modelPorfolioAmount),
      };
      let product_category = [];
      if (formData.isHighRiskSelected) {
        let categoryProd = {
          product_category_id: 1,
          amount: Number(formData.highRiskAmount),
        };
        product_category.push(categoryProd);
      }
      if (formData.isMediumRiskSelected) {
        let categoryProd = {
          product_category_id: 2,
          amount: Number(formData.mediumRiskAmount),
        };
        product_category.push(categoryProd);
      }
      if (formData.isLowRiskSelected) {
        let categoryProd = {
          product_category_id: 3,
          amount: Number(formData.lowRiskAmount),
        };
        product_category.push(categoryProd);
      }
      investment.product_category = product_category;
      investments.push(investment);
    }
    if (formData.isAlgoSelected) {
      let investment = {
        product_id: 3,
        amount: Number(formData.algoAmount),
        product_category: [],
      };
      investments.push(investment);
    }
    payload.investments = investments;
    return payload;
  };

  const handleChange = ({ name, value }) => {
    const newFormData = { ...formData };
    if (
      [
        "customAmount",
        "modelPorfolioAmount",
        "highRiskAmount",
        "mediumRiskAmount",
        "lowRiskAmount",
        "algoAmount",
      ].includes(name)
    ) {
      value = value.trim();
      if (value !== "" && isNaN(value)) {
        return;
      }
    }
    setFormData({ ...newFormData, [name]: value });
  };

  // const handleChange = ({ name, value }) => {
  //   let newFormData = { ...formData };
  //   newFormData[name] = value;
  //   setFormData(newFormData);
  // };

  useEffect(() => {
    setIsBlocked(BlockUserStatus === null ? select_isBlock : BlockUserStatus);
  }, [BlockUserStatus === null ? select_isBlock : BlockUserStatus]);

  const firstLetter = (BlockUserName === null ? select?.toast?.breadCrumbData?.name : BlockUserName)
    ?.split(" ")[0]
    ?.slice(0, 1);
  const secondLetter = (BlockUserName === null ? select?.toast?.breadCrumbData?.name : BlockUserName)
    ?.split(" ")[1]
    ?.slice(0, 1);
  const result =
    secondLetter && firstLetter
      ? `${firstLetter}${secondLetter}`
      : `${firstLetter}`;

  const handleUnblockSubmit = async () => {
    try {
      const response = await postBlockCustomer({
        user_id: BlockUserID === null ? customer_id : BlockUserID,
        reason: "",
        is_block: "N",
      });

     if(response?.data?.status === 1){
      dispatch(toastActions.setUserBlockStatus({ is_block: 0 }));
      setIsBlocked(0)
      const toastMessage = "User unblocked successfully!";
      dispatch(
        toastActions.setToastData({
          message: toastMessage,
          variant: response?.data?.status === 1 ? "success" : "error",
        })
      );
     }
    } catch (error) {
      console.error(
        `Error while calling ${isBlocked ? "unblock" : "block"} customer API:`,
        error.message
      );
    }
  };

  const openBlockDrawer = () => {
    setBlockIsDrawerOpen(true);
  };

  const closeBlockDrawer = () => {
    setBlockIsDrawerOpen(false);
  };


  return (
    <div>
      <Box p={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{ width: "100%" }}
          alignItems="center"
          style={{ marginTop: "10px" }}
        >
          <Box
            style={{
              backgroundColor: "#E5EEFF",
              width: "60px",
              height: "50px",
              borderRadius: "50%",
              textAlign: "center",
            }}
          >
            <Text fontWeight="500" style={{ marginTop: "12px" }}>
              {result}
            </Text>
          </Box>
          <Box style={{ marginTop: "12px" }}>
            <Text
              variant="small"
              component="h4"
              fontSize="20px"
              fontWeight="500"
              sx={{ textWrap: "nowrap", marginLeft: "2px" }}
            >
             { BlockUserName === null ? select?.toast?.breadCrumbData?.name : BlockUserName } 
              {/* {select?.toast?.breadCrumbData?.name} */}
            </Text>
            {console.log(select?.toast?.breadCrumbData?.user_id,"name")}
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTOp: "10px" }}
          >
            <>
              {isBlocked ? (
                <Button
                  sx={{
                    fontWeight: 600,
                    borderRadius: "8px",
                    p: 2,
                    width: "180px",
                  }}
                  onClick={handleUnblockSubmit}
                  variant="outlined"
                >
                  Unblock Customer
                </Button>
              ) : (
                <Button
                  sx={{
                    fontWeight: 600,
                    borderRadius: "8px",
                    p: 2,
                    width: "180px",
                  }}
                  onClick={openBlockDrawer}
                  variant="outlined"
                >
                  Block Customer
                </Button>
              )}
              {isBlockDrawerOpen && (
                <BlockCustomer
                  isBlocked={isBlocked}
                  setIsBlocked={setIsBlocked}
                  closeBlockDrawer={closeBlockDrawer}
                  isBlockDrawerOpen={isBlockDrawerOpen}
                />
              )}
            </>
            <Button
              sx={{
                fontWeight: 600,
                borderRadius: "8px",
                p: 2,
                width: "190px",
              }}
              onClick={() => {
                if (!isBlocked) {
                  setModalOpen(true);
                }
              }}
              disabled={isBlocked}
            >
              Add Money
            </Button>
          {console.log(BlockUserID,BlockUserName,BlockUserStatus,result,"REDUX")}
            <NewDialog
              onClose={() => setModalOpen(false)}
              open={modalOpen}
              title={
                <Text
                  variant="mediumn"
                  fontSize="20px"
                  fontWeight="600"
                  marginTop="10px"
                >
                  Add Money
                </Text>
              }
              disableCloseIcon
              maxWidth="sm"
              sx={{ borderRadius: "10px" }}
              contentComponent={() => (
                <Box
                  sx={{
                    maxWidth: "480px",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="space-between"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <CheckboxCommon
                      title="Custom"
                      value={formData.isCustomInvestmentSelected}
                      size="small"
                      handleClick={() => {
                        handleChange({
                          name: "isCustomInvestmentSelected",
                          value: !formData.isCustomInvestmentSelected,
                        });
                      }}
                    />
                    <TextField
                      style={{ marginTop: "5px" }}
                      placeholder="Enter amount in ₹ "
                      value={formData.customAmount}
                      name="customAmount"
                      onChange={(e) => {
                        handleChange({
                          name: e.target.name,
                          value: e.target.value,
                        });
                      }}
                      disabled={!formData.isCustomInvestmentSelected}
                    />
                    {formError.customAmount && (
                      <Text variant="small" color="red" py={1}>
                        {formError.customAmount}
                      </Text>
                    )}
                  </Stack>
                  <Stack
                    direction="column"
                    alignItems="space-between"
                    justifyContent="space-between"
                    spacing={1}
                    style={{ marginTop: "10px" }}
                  >
                    <CheckboxCommon
                      size="small"
                      title="Model Portfolio"
                      value={formData.isModelPortfolioSelected}
                      handleClick={() => {
                        handleChange({
                          name: "isModelPortfolioSelected",
                          value: !formData.isModelPortfolioSelected,
                        });
                      }}
                    />

                    <TextField
                      placeholder="Enter amount in ₹ "
                      value={formData.modelPorfolioAmount}
                      name="modelPorfolioAmount"
                      onChange={(e) => {
                        handleChange({
                          name: e.target.name,
                          value: e.target.value,
                        });
                      }}
                      disabled={!formData.isModelPortfolioSelected}
                    />
                    {formError.modelPorfolioAmount && (
                      <Text variant="small" color="red" py={1}>
                        {formError.modelPorfolioAmount}
                      </Text>
                    )}
                    <>
                      <Grid item md={6}>
                        <CheckboxCommon
                          sx={{ width: "100%" }}
                          title="High Risk Product Stock"
                          value={formData.isHighRiskSelected}
                          handleClick={() => {
                            handleChange({
                              name: "isHighRiskSelected",
                              value: !formData.isHighRiskSelected,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          sx={{ width: "100%" }}
                          placeholder="Enter amount to invest"
                          value={formData.highRiskAmount}
                          name="highRiskAmount"
                          onChange={(e) => {
                            handleChange({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }}
                          disabled={!formData.isHighRiskSelected}
                        />
                        {formError.highRiskAmount && (
                          <Text variant="small" color="red" py={1}>
                            {formError.highRiskAmount}
                          </Text>
                        )}
                      </Grid>

                      <Grid item md={6}>
                        <CheckboxCommon
                          sx={{ width: "100%" }}
                          title="Medium Risk Product Stock"
                          value={formData.isMediumRiskSelected}
                          handleClick={() => {
                            handleChange({
                              name: "isMediumRiskSelected",
                              value: !formData.isMediumRiskSelected,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          type="number"
                          sx={{ width: "100%" }}
                          placeholder="Enter amount to invest"
                          value={formData.mediumRiskAmount}
                          name="mediumRiskAmount"
                          onChange={(e) => {
                            handleChange({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }}
                          disabled={!formData.isMediumRiskSelected}
                        />
                        {formError.mediumRiskAmount && (
                          <Text variant="small" color="red" py={1}>
                            {formError.mediumRiskAmount}
                          </Text>
                        )}
                      </Grid>

                      <Grid item md={6}>
                        <CheckboxCommon
                          sx={{ width: "100%" }}
                          title="Low Risk Product Stock"
                          value={formData.isLowRiskSelected}
                          handleClick={() => {
                            handleChange({
                              name: "isLowRiskSelected",
                              value: !formData.isLowRiskSelected,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item md={6}>
                        <TextField
                          type="number"
                          sx={{ width: "100%" }}
                          placeholder="Enter amount to invest"
                          value={formData.lowRiskAmount}
                          name="lowRiskAmount"
                          onChange={(e) => {
                            handleChange({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }}
                          disabled={!formData.isLowRiskSelected}
                        />
                        {formError.lowRiskAmount && (
                          <Text variant="small" color="red" py={1}>
                            {formError.lowRiskAmount}
                          </Text>
                        )}
                      </Grid>
                      {modalAmountError && (
                        <Stack alignItems="flex-end" py={1}>
                          <Text variant="small" color="red">
                            {modalAmountError}
                          </Text>
                        </Stack>
                      )}
                    </>

                    <CheckboxCommon
                      title="Algo (F&O)"
                      value={formData.isAlgoSelected}
                      handleClick={() => {
                        handleChange({
                          name: "isAlgoSelected",
                          value: !formData.isAlgoSelected,
                        });
                      }}
                    />
                    <TextField
                      placeholder="Enter amount in ₹ "
                      value={formData.algoAmount}
                      name="algoAmount"
                      onChange={(e) => {
                        handleChange({
                          name: e.target.name,
                          value: e.target.value,
                        });
                      }}
                      disabled={!formData.isAlgoSelected}
                    />
                    {formError.algoAmount && (
                      <Text variant="small" color="red" py={1}>
                        {formError.algoAmount}
                      </Text>
                    )}
                    {/* {amountError && (
                      <Stack alignItems="flex-end" py={1}>
                        <Text variant="small" color="red">
                          {amountError}
                        </Text>
                      </Stack>
                    )} */}
                  </Stack>
                </Box>
              )}
              actionComponent={() => (
                <Box
                  mb={2}
                  textAlign="center"
                  direction="row"
                  alignItems="space-around"
                  justifyContent="space-around"
                  spacing={1}
                >
                  <Button
                    fontSize="20px"
                    sx={{
                      width: "100%",
                      px: 2,
                      py: 1.5,
                      fontSize: "1rem",
                      fontWeight: "600",
                    }}
                    onClick={() => {
                      setModalOpen(false);
                      setFormData('');
                      setFormError('');
                      setModalAmountError('')
                    }}
                    disableEndIcon={false}
                    variant="outlined"
                    style={{ width: "200px", margin: "10px" }}
                  >
                    No
                  </Button>
                  <Button
                    sx={{
                      width: "100%",
                      px: 2,
                      py: 1.5,
                      fontSize: "1rem",
                      fontWeight: "600",
                    }}
                    onClick={handleSubmit}
                    disableEndIcon={false}
                    style={{ width: "200px", margin: "10px" }}
                  >
                    Add
                  </Button>
                </Box>
              )}
            />
          </Stack>
        </Stack>
        <Box style={{ padding: "20px" }}>
          <Divider />
        </Box>
      </Box>
    </div>
  );
};

export default CustomerDetailHeader;
