import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AntSwitch = styled(Switch)(({ theme, opacity }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      opacity: opacity,
      "& + .MuiSwitch-track": {
        opacity: opacity,
        backgroundColor: theme.palette.mode === "dark" ? "#808080" : "#808080",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: opacity,
    backgroundColor: theme.palette.mode === "dark" ? "#808080" : "#808080",
    boxSizing: "border-box",
  },
}));

export default function OnOffButton({
  checked,
  onChange,
  opacity,
  cursor,
  onText,
  offText,
  disabled,
}) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography
        sx={{
          fontSize: "16px",
          color: "#676C76",
          fontWeight: checked ? "normal" : "bold",
          opacity: checked ? 0.5 : 1,
        }}
      >
        {offText}
      </Typography>
      <AntSwitch
        checked={checked}
        opacity={opacity}
        style={{ cursor: cursor }}
        onChange={onChange}
        inputProps={{ "aria-label": "ant design" }}
        disabled={disabled}
      />
      <Typography
        sx={{
          color: "#676C76",
          fontSize: "16px",
          fontWeight: checked ? "bold" : "normal",
          opacity: checked ? 1 : 0.5,
        }}
      >
        {onText}
      </Typography>
    </Stack>
  );
}
