import React from "react";
import Paper from "components/common/Paper";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Grid from "components/common/Grid";
import FilterIcon from "asset/icons/FilterIcon";
import { styled } from "@mui/styles";

const AddFilterBtn = styled(Button)(() => ({
  marginLeft: "auto",
  fontWeight: 500,
  borderRadius: "8px",
  borderColor: "#D0D5DD",
}));

const CustomerFamily = ({
  handleFilterDrawer,
  selectedId,
  data,
  familyList,
  handleButtonClick,
  handleButtonAll,
  is_admin
}) => {
  return (
    <Paper
      elevation={1}
      sx={{
        alignItems: "center",
        p: 3,
        backgroundColor: "white",
        border: "1px solid white",
        height: "auto",
      }}
    >
      <Stack direction="row">
        <Text
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            marginLeft: "14px",
            color: "#101828",
          }}
        >
          {data?.name}
        </Text>
        <AddFilterBtn
          startIcon={<FilterIcon />}
          variant="outlined"
          onClick={handleFilterDrawer}
          sx={{ padding: "6px 12px", marginLeft: "auto" }}
        >
          Add Filters
        </AddFilterBtn>
      </Stack>

      <Box>
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#667085",
            marginLeft: "14px",
            marginTop: "-10px",
          }}
        >
          {data?.detail}
        </Text>
      </Box>
      <Grid>
        {
          (is_admin) ?

          <Stack direction="row" m={2} flexWrap="wrap">
            {
              familyList && familyList.length > 1 ?
                <Button
                  variant="outlined"
                  style={{
                    margin: "5px",
                    width: "8%",
                    backgroundColor: selectedId === null ? "#142E56" : "initial",
                    color: selectedId === null ? "white" : "#142E56",
                  }}
                    onClick={handleButtonAll}
                >
                  All
                </Button>
              :null
            }
            {Array.isArray(familyList) &&
              familyList.length > 0 &&
              familyList?.map((member, index) => (
                <Stack key={index} direction="column" style={{ margin: "5px" }}>
                  <Button
                    variant="outlined"
                    style={{
                      whiteSpace: "nowrap",
                      backgroundColor:
                        selectedId === member.user_id ? "#142E56" : "initial",
                      color: selectedId === member.user_id ? "white" : "#142E56",
                    }}
                    onClick={() =>
                      handleButtonClick(member?.user_id)
                    }
                  >
                    {member.name}
                  </Button>
                  {/* {member?.is_blocked === 1 && selectedId === member.user_id && (
                  <Text
                    style={{
                      marginLeft:'2px',
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#EB5757",
                    }}
                  >
                    blocked user....
                  </Text>
                )} */}
                </Stack>
              ))}
          </Stack>
          : null
        }
      </Grid>
    </Paper>
  );
};

export default CustomerFamily;
