import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const CalendarIcon = (props) => {
  return (
    <div>
      <SvgIcon {...props}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.1665 1.66699V4.16699"
            stroke="#101828"
            strokeWidth="1.25"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.8335 1.66699V4.16699"
            stroke="#101828"
            strokeWidth="1.25"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.4165 7.5752H17.5832"
            stroke="#101828"
            strokeWidth="1.25"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18 7.08366V14.167C18 16.667 16.75 18.3337 13.8333 18.3337H7.16667C4.25 18.3337 3 16.667 3 14.167V7.08366C3 4.58366 4.25 2.91699 7.16667 2.91699H13.8333C16.75 2.91699 18 4.58366 18 7.08366Z"
            stroke="#101828"
            strokeWidth="1.25"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5791 11.4157H13.5866"
            stroke="#101828"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5791 13.9157H13.5866"
            stroke="#101828"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.4961 11.4157H10.5036"
            stroke="#101828"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.4961 13.9157H10.5036"
            stroke="#101828"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.41209 11.4157H7.41957"
            stroke="#101828"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.41209 13.9157H7.41957"
            stroke="#101828"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </SvgIcon>
    </div>
  );
};

export default CalendarIcon;
