import CustomerProfileCard from 'components/Customer/CustomerProfileCard'
import React from 'react'

function CustomerProfileContainer() {
  return (
    <>
    <CustomerProfileCard/>
    </>
  )
}

export default CustomerProfileContainer
