import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RiskTracker from "components/addCustomer/RiskTracker";
import ChangeRisk from "components/addCustomer/ChangeRisk";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { commonApiAction } from "stores/redux/apiSlices/commonApiSlice";
import { useLocation } from "react-router-dom";

function RiskAssesmentContainer({ handleStepChange, currentUserId }) {
	const location = useLocation();
	const [isChangeRisk, setIsChangeRisk] = useState(false);
	const [selectedRisk, setSelectedRisk] = useState(null);
	const [currentRisk, setCurrentRisk] = useState({});
	const [riskProfileData, setRiskProfileData] = useState({});

	//Redux state
	// const customerRiskDetails = useSelector((state) => state?.addCustomerSlice?.customerDetails);

	//Rtk Apis
	const [updateRiskProfileAction] = addCustomerApiAction.updateRiskProfile();
	const { data: allRiskProfiles } = commonApiAction.getRiskProfiles();
	const [getCustomerRisk, riskProfile] = addCustomerApiAction.getCustomerRiskProfile();

	const onBoardData = location.state && location.state.onBoardData;

	React.useEffect(() => {
		if (onBoardData) {
			getCustomerRisk({userId:onBoardData?.user_id},false);
		} else if (currentUserId) {
			getCustomerRisk({ userId: currentUserId }, false);
		}
	  }, [onBoardData, currentUserId]);

	//Use Effects
	React.useEffect(() => {
		if (riskProfile?.data?.riskProfile?.[0] && riskProfile?.data?.riskProfile !== undefined) {
			let risk = riskProfile?.data?.riskProfile?.[0];
			setRiskProfileData(risk);
			const currentRiskProfile =
				allRiskProfiles?.find((item) => item.name === risk.risk_profile_name) ?? {};
			setCurrentRisk(currentRiskProfile);
			setSelectedRisk(risk?.selected_risk_profile_id);
		}
	}, [riskProfile,allRiskProfiles]);

	const changeRiskClick = () => {
		setIsChangeRisk(!isChangeRisk);
	};

	const handleRiskProfileChange = (id) => {
		setSelectedRisk(id);
	};

	const handleRiskProfileSubmit = async () => {
		try {
			const payload = {
				risk_profile_id: selectedRisk,
				userId: onBoardData
				? onBoardData?.user_id : currentUserId,
			};
			const res = await updateRiskProfileAction(payload);
			if (res?.data?.status === 1) {
				handleStepChange(3);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			{!isChangeRisk ? (
				<RiskTracker
					changeRiskClick={changeRiskClick}
					currentRiskProfile={currentRisk}
					handleRiskProfileSubmit={handleRiskProfileSubmit}
					riskProfile={riskProfileData}
					handleStepChange={handleStepChange}
				/>
			) : (
				<ChangeRisk
					handleRiskProfileChange={handleRiskProfileChange}
					handleRiskProfileSubmit={handleRiskProfileSubmit}
					selectedRisk={selectedRisk}
				/>
			)}
		</>
	);
}

export default RiskAssesmentContainer;
