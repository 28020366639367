// React
import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DividendContainer from "containers/Dividend/DividendContainer";
import CustomerDetailPage from "customers/CustomerDetailPage";
import InvestmentType from "customers/InvestmentType";
import "./App.css";
// store
import { toastActions } from "stores/redux/slices/toastSlice";

// common
import Loader from "components/common/Loader";
import Snackbar from "components/common/Snackbar";
import Stack from "components/common/Stack";

// pages
import ProfileAdminContainer from "containers/ProfileAdmin/ProfileAdminContainer";
import LayoutContainer from "containers/LayoutContainer";
import RiskProfile from "pages/RiskProfile";
import DashBoardCardPages from "pages/Dashboard";
import AddCustomer from "pages/AddCustomer";
import Trades from "pages/Trades";
import Customers from "pages/Customer";
import PlansDetailPage from "pages/plansPage/PlansDetailPage";
import CreateNewPlansPage from "pages/plansPage/CreateNewPlansPage";
import FamilyMembers from "pages/FamilyMembers";
import Profile from "pages/Profile";
import SignUpPage from "pages/SignUpPage";
import LoginPage from "pages/LoginPage";
import Reports from "pages/Reports";
import DashboardAdmin from "pages/DashboardAdmin";
import CustomerLogin from "pages/Customer/CustomerLogin";
import CustomerSignUp from "pages/Customer/CustomerSignUp";
import CustomerRiskAssesment from "pages/Customer/CustomerRiskAssesment";
import CustomerOnBoarding from "pages/Customer/CustomerOnBoarding";
import CustomerDashboard from "pages/Customer/CustomerDashboard";
import CustomerPortfolio from "pages/Customer/CustomerPortfolio";
import CustomerReports from "pages/Customer/CustomerReports";
import CustomerProfile from "pages/Customer/CustomerProfile";

import PrimaryTheme from "theme/primaryTheme";
import {
  PrivateRoute,
  PublicRoute,
  AdminRoute,
  RIARoute,
  SubbrokerRoute,
  CustomerRoute,
} from "./helpers/route";

// Constants
import { appConstants } from "helpers/constants/appConstants";
import CustomerBlank from "pages/Customer/CustomerBlank";

const USER_ROLES = appConstants.userRoles;

// page not found
function PageNotFound() {
  return (
    <Stack alignItems="center" justifyContent="center" minHeight="100vh">
      <h4>Page not found</h4>
    </Stack>
  );
}

function App() {
  // Dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // State
  const auth = useSelector((state) => state.commonReducer.isAuthenticated);
  const userRoleCode = useSelector((state) => state.commonReducer.userRoleCode);
  const toastData = useSelector((state) => state?.toast.toast);
  const isLoading = useSelector((state) => state.commonReducer.isLoading);
  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  // NOTE: Set flage if Customer is pending for approval from RIA.
  const roleConfig = USER_ROLES[userRoleCode];
  const pendingCustomerApprv =
    ["PENDING", "REJECTED"].includes(userDetails.onboard_status) &&
    roleConfig.code === "CUSTOMER";

  useEffect(() => {
    const { onboard_status } = userDetails;

    // Handle if customer is pending for approval from RIA
    let customerDashboardPath = "/customer/dashboard";
    if (pendingCustomerApprv && location.pathname != customerDashboardPath) {
      navigate(customerDashboardPath);
    }

    // Handle if customer onboarding is inprogress.
    if (onboard_status == "INPROGRESS" && roleConfig.code === "CUSTOMER") {
      navigate("/customer/onboarding");
    }
  }, []);

  // Handle: Redirect to
  const redirectToHandle = () => {
    let redirectPath = "/dashboard";
    const { onboard_status } = userDetails;
    if (auth && userRoleCode) {
      redirectPath = roleConfig.entryRoute;

      // if customer onboarding is inprogress
      if (onboard_status == "INPROGRESS" && roleConfig.code === "CUSTOMER") {
        redirectPath = "/customer/onboarding";
      }
    } else {
      redirectPath = "/login";
    }
    return redirectPath;
  };

  return (
    <>
      <Snackbar
        open={toastData?.open}
        onClose={() =>
          toastData.open && dispatch(toastActions.resetToastData())
        }
        message={toastData?.message}
        autoHideDuration={toastData?.timeout}
        key={toastData?.key}
      />

      {/* {isLoading && <Loader />} */}

      <PrimaryTheme>
        <Routes>
          {/* 1 --> Public Routes */}
          <Route
            exact
            path="/"
            element={
              <PublicRoute isAuth={auth} redirectTo={redirectToHandle()} />
            }
          >
            <Route index element={<Navigate to="/login" replace />} />
            <Route element={<LoginPage />} path="/login" />
            <Route element={<SignUpPage />} path="/signup" />
          </Route>

          {/* 2 --> Private Routes */}
          <Route
            exact
            path="/"
            element={
              <PrivateRoute isAuth={auth} redirectTo={redirectToHandle()} />
            }
          >
            {/* -> with layout pages */}
            <Route exact path="/" element={<LayoutContainer />}>
              {/* -> Admin */}
              <Route
                path="/admin"
                element={
                  <AdminRoute
                    userRoleCode={userRoleCode}
                    redirectTo={redirectToHandle()}
                  />
                }
              >
                <Route
                  index
                  element={<Navigate to="/admin/dashboard" replace />}
                />
                <Route element={<DashboardAdmin />} path="/admin/dashboard" />
                <Route
                  element={<ProfileAdminContainer />}
                  path="/admin/profile"
                />
              </Route>

              {/* -> RIA */}
              <Route
                path="/"
                element={
                  userRoleCode === appConstants.userRoles.RIA.code ? 
                  <RIARoute
                    userRoleCode={userRoleCode}
                    redirectTo={redirectToHandle()}
                  /> : <SubbrokerRoute
                  userRoleCode={userRoleCode}
                  redirectTo={redirectToHandle()}
                />
                }
              >
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route element={<DashBoardCardPages />} path="/dashboard" />
                <Route element={<AddCustomer />} path="/add-customer" />
                <Route element={<Customers />} path="/customers" />
                <Route element={<Trades />} path="/trades" />
                <Route element={<Reports />} path="/reports" />
                <Route
                  element={<CustomerDetailPage />}
                  path="/customer-detail"
                />
                <Route
                  element={<InvestmentType />}
                  path="/investment-summary"
                />
                <Route element={<RiskProfile />} path="/risk-profile" />
                <Route element={<PlansDetailPage />} path="/plans" />
                <Route element={<CreateNewPlansPage />} path="/newplans" />
                <Route element={<DividendContainer />} path="/dividend" />
                <Route element={<FamilyMembers />} path="/family-members" />
                <Route element={<Profile />} path="/profile" />
              </Route>


              {/* -> Subbroker */}
              {/* <Route
                path="/"
                element={
                  <SubbrokerRoute
                    userRoleCode={userRoleCode}
                    redirectTo={redirectToHandle()}
                  />
                }
              >
                <Route index element={<Navigate to="/dashboard" replace />} />
                <Route element={<DashBoardCardPages />} path="/dashboard" />
                <Route element={<AddCustomer />} path="/add-customer" />
                <Route element={<Customers />} path="/customers" />
                <Route element={<Trades />} path="/trades" />
                <Route element={<Reports />} path="/reports" />
                <Route
                  element={<CustomerDetailPage />}
                  path="/customer-detail"
                />
                <Route
                  element={<InvestmentType />}
                  path="/investment-summary"
                />
                <Route element={<RiskProfile />} path="/risk-profile" />
                <Route element={<PlansDetailPage />} path="/plans" />
                <Route element={<CreateNewPlansPage />} path="/newplans" />
                <Route element={<DividendContainer />} path="/dividend" />
                <Route element={<FamilyMembers />} path="/family-members" />
                <Route element={<Profile />} path="/profile" />
              </Route> */}



              {/* Customer */}
              {/* below Route is for Customer Request message */}
              <Route
                path="/customer"
                element={
                  <CustomerRoute
                    userRoleCode={userRoleCode}
                    redirectTo={redirectToHandle()}
                  />
                }
              >
                <Route
                  element={<CustomerDashboard />}
                  path="/customer/dashboard"
                />
                <Route
                  element={<CustomerPortfolio />}
                  path="/customer/portfolio"
                />
                <Route element={<CustomerReports />} path="/customer/reports" />
                <Route element={<CustomerProfile />} path="/customer/profile" />
                <Route
                  element={<CustomerRiskAssesment />}
                  path="/customer/risk-assesment"
                />
              </Route>
            </Route>

            {/* -> without layout pages */}
            <Route
              element={<CustomerOnBoarding />}
              path="/customer/onboarding"
            />
            <Route element={<CustomerBlank />} path="/customer/blank" />
          </Route>

          {/* 3 --> path Not found */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </PrimaryTheme>
    </>
  );
}

export default App;
