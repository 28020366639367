import React from 'react'
import CustomerPerformace from 'containers/CustomerPortal/CustomerPerformace'

function CustomerPortfolioContainer() {
  return (
    <>
    <CustomerPerformace/>   
    </>
  )
}

export default CustomerPortfolioContainer
