import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";
import { dividendParser } from "../apiParser/dividendParser";
const Dividend = createApi({
  reducerPath: "Dividend",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: [
    "GETDIVIDEND",
    "GETDIVIDENDIDAPI",
    "GETCOMPANYAPI",
    "ADDDIVIDEND",
    "UPDATEDIVIDEND",
  ],
  endpoints: (qb) => ({
    getDividendApi: qb.query({
      query: ({ page, pageSize, searchValue, noGlobalLoading = true }) => ({
        url: `api/dividend-list/${page}/${pageSize}/undefined`, // Adjust the endpoint as needed
        params: { noGlobalLoading },
      }),

      transformResponse: (response) => ({
        data: response.result,
        dividendRecords: response.total_record,
      }),
      providesTags: ["GETDIVIDEND"],
      // transformResponse: (response) => response.result,
      // providesTags: ["GETDIVIDEND"],
    }),
    getDividendIdApi: qb.query({
      query: () => {
        return `api/dividend/3`; // Adjust the endpoint as needed
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETDIVIDENDIDAPI"],
    }),
    getCompanyApi: qb.query({
      query: ({ search = "", noGlobalLoading = true }) => {
        if (search !== "") {
          return {
            url: `api/company?search=${search}`,
            params: { noGlobalLoading },
          };
        } else {
          return { url: `api/company`, params: { noGlobalLoading } };
        }
      },

      transformResponse: dividendParser.allStocks,
    }),
    // getCompanyApi: qb.query({
    //   query: () => {
    //     return `api/company`; // Adjust the endpoint as needed
    //   },
    //   transformResponse: dividendParser.allStocks,
    //   providesTags: ["GETCOMPANYAPI"],
    // }),
    // getCompanyApi: qb.query({
    //   query: () => {
    //     return `api/company?search=reliance`; // Adjust the endpoint as needed
    //   },
    //   transformResponse: (response) => {
    //     const data = response;
    //     console.log(data,"datstockdataaa"); // Logging the data
    //     return data; // Returning the data
    //   },
    //   providesTags: ["GETCOMPANYAPI"],
    // }),

    addDividendApi: qb.mutation({
      query: (data) => ({
        url: `api/dividend`,
        method: "POST",
        body: {
          stock_symbol: data.stock_symbol,
          date: data.date,
          percentage: data.percentage,
        },
        invalidatesTags: ["ADDDIVIDEND"],
      }),
    }),
    updateDividendApi: qb.mutation({
      query: (data) => ({
        url: `api/dividend`,
        method: "PATCH",
        body: {
          dividend_id: data.dividend_id,
          stock_symbol: data.stock_symbol,
          date: data.date,
          percentage: data.percentage,
        },
        invalidatesTags: ["UPDATEDIVIDEND"],
      }),
    }),
  }),
});

export const DividendApiReducer = Dividend.reducer;

export const dividendApiAction = {
  middleware: Dividend.middleware,
  reducerPath: Dividend.reducerPath,
  getDividendApi: Dividend.useLazyGetDividendApiQuery,
  getDividendIdApi: Dividend.useGetDividendIdApiQuery,
  getCompanyApi: Dividend.useLazyGetCompanyApiQuery,
  addDividendApi: Dividend.useAddDividendApiMutation,
  updateDividendApi: Dividend.useUpdateDividendApiMutation,
};
