import React, { useState } from 'react';
import Box from 'components/common/Box';
import Stack from 'components/common/Stack';
import Text from 'components/common/Text';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const CustomerDashBoardAddFilter = ({ onFiltersChange }) => {
  // State for Order type checkboxes
  const [orderType, setOrderType] = useState({
    all: false,
    buy: false,
    sell: false,
  });

  // State for Action type checkboxes
  const [actionType, setActionType] = useState({
    limit: false,
    market: false,
  });

  // State for Action status checkboxes
  const [actionStatus, setActionStatus] = useState({
    actionTaken: false,
    actionPending: false,
  });

  // Function to handle Order type checkbox change
  // const handleOrderTypeChange = (event) => {
  //   const { name, checked } = event.target;
  //   let updatedOrderType = { ...orderType, [name]: checked };

  //   // If "All" checkbox is checked, set "Buy" and "Sell" checkboxes accordingly
  //   if (name === 'all') {
  //     updatedOrderType = { all: checked, buy: checked, sell: checked };
  //   } else if (name !== 'all' && !checked) {
  //     // If any checkbox other than "All" is unchecked, check if "All" checkbox needs to be unchecked
  //     updatedOrderType = { ...updatedOrderType, all: false };
  //   }

  //   // Update state and send data to container
  //   setOrderType(updatedOrderType);
  //   const orderTypeArray = updatedOrderType.all ? ['BUY', 'SELL'] : Object.keys(updatedOrderType).filter((key) => updatedOrderType[key]);
  //   onFiltersChange('orderType', orderTypeArray);
  // };
  // Function to handle Order type checkbox change
const handleOrderTypeChange = (event) => {
  const { name, checked } = event.target;
  let updatedOrderType = { ...orderType, [name]: checked };

  // If "All" checkbox is checked, set "Buy" and "Sell" checkboxes accordingly
  if (name === 'all') {
    updatedOrderType = { all: checked, buy: checked, sell: checked };
  } else if (name !== 'all' && !checked) {
    // If any checkbox other than "All" is unchecked, check if "All" checkbox needs to be unchecked
    updatedOrderType = { ...updatedOrderType, all: false };
  } else if (updatedOrderType.buy && updatedOrderType.sell) {
    // If both "Buy" and "Sell" checkboxes are checked, also check "All" checkbox
    updatedOrderType = { ...updatedOrderType, all: true };
  }

  // Update state and send data to container
  setOrderType(updatedOrderType);
  const orderTypeArray = updatedOrderType.all ? ['BUY', 'SELL'] : Object.keys(updatedOrderType).filter((key) => updatedOrderType[key]);
  onFiltersChange('orderType', orderTypeArray);
};


  // Function to handle Action type checkbox change
  const handleActionTypeChange = (event) => {
    const { name, checked } = event.target;
    const updatedActionType = { ...actionType, [name]: checked };
    setActionType(updatedActionType);

    // Update state and send data to container
    const actionTypesArray = Object.keys(updatedActionType).filter((key) => updatedActionType[key]);
    onFiltersChange('actionType', actionTypesArray);
  };

  // Function to handle Action status checkbox change
  const handleActionStatusChange = (event) => {
    const { name, checked } = event.target;
    const updatedActionStatus = { ...actionStatus, [name]: checked };
    setActionStatus(updatedActionStatus);

    // Determine the selected action statuses
    const selectedStatuses = Object.keys(updatedActionStatus).filter((key) => updatedActionStatus[key]);

    // Update state and send data to container
    const status = selectedStatuses.map((key) => (key === 'actionTaken' ? 'PENDING' : 'EXECUTED'));
    onFiltersChange('actionStatus', status);
  };

  return (
    <Box>
      <Box>
        <Text sx={{ color: '#242424', fontSize: '14px', fontWeight: 500 }}>
          Order type
        </Text>
        <Stack direction="row">
          <FormGroup>
            <Box sx={{ display: 'flex', mb: '24px' }}>
              <FormControlLabel
                control={<Checkbox size="small" checked={orderType.all} onChange={handleOrderTypeChange} name="all" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="All"
              />
              <FormControlLabel
                control={<Checkbox size="small" checked={orderType.buy} onChange={handleOrderTypeChange} name="buy" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="Buy"
              />
              <FormControlLabel
                control={<Checkbox size="small" checked={orderType.sell} onChange={handleOrderTypeChange} name="sell" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="Sell"
              />
            </Box>
          </FormGroup>
        </Stack>
      </Box>

      <Box>
        <Text sx={{ color: '#242424', fontSize: '14px', fontWeight: 500 }}>
          Action type
        </Text>
        <Stack direction="row">
          <FormGroup>
            <Box sx={{ display: 'flex', mb: '24px' }}>
              <FormControlLabel
                control={<Checkbox size="small" checked={actionType.limit} onChange={handleActionTypeChange} name="limit" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="Limit"
              />
              <FormControlLabel
                control={<Checkbox size="small" checked={actionType.market} onChange={handleActionTypeChange} name="market" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="Market"
              />
            </Box>
          </FormGroup>
        </Stack>
      </Box>

      <Box>
        <Text sx={{ color: '#242424', fontSize: '14px', fontWeight: 500 }}>
          Action status
        </Text>
        <Stack direction="row">
          <FormGroup direction="row">
            <Box sx={{ display: 'flex' }}>
              <FormControlLabel
                control={<Checkbox size="small" checked={actionStatus.actionTaken} onChange={handleActionStatusChange} name="actionTaken" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="Action pending"
              />
              <FormControlLabel
                control={<Checkbox size="small" checked={actionStatus.actionPending} onChange={handleActionStatusChange} name="actionPending" />}
                sx={{
                  mr: '32px',
                  color: '#242424',
                  fontSize: '16px',
                  fontWeight: 400,
                }}
                label="Action taken"
              />
            </Box>
          </FormGroup>
        </Stack>
      </Box>
    </Box>
  );
};

export default CustomerDashBoardAddFilter;
