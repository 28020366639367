import CustomerOnBoardingContainer from 'containers/CustomerOnBording'
import React from 'react'

function CustomerOnBoarding() {
  return (
    <>
      <CustomerOnBoardingContainer/>
    </>
  )
}

export default CustomerOnBoarding
