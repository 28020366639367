import React, { useState, useEffect } from "react";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import DownloadButton from "../components/common/DownloadButton";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import { useNavigate, Link } from "react-router-dom";
import ViewMember from "../components/customerSidebar/ViewMember";
import InvestmentSidebar from "../components/customerSidebar/InvestmentSideBar";
import ManageCash from "../components/customerSidebar/ManageCash";
import PlanSidebar from "../components/customerSidebar/PlanSidebar";
import ExpireDateSidebar from "../components/customerSidebar/ExpireDateSidebar";
import Chip from "@mui/material/Chip";
import Grid from "components/common/Grid";
import Divider from "components/common/Divider";
import RiskProfileSidebar from "components/customerSidebar/RiskProfileSidebar";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Avatar from "components/common/Avatar";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useLocation } from "react-router-dom";

const ChipStyle = styled(Chip)(({ theme, status }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "152px",
  backgroundColor: status === "Active" ? "#4CAF50" : "#B22323",
  color: "#FFFFFF",
  fontSize: "12px",
  padding: "8px",
  fontWeight: 500,
  "&::before": {
    position: "relative",
    left: 5,
    bottom: 6,
    content: '"\\2022"',
    width: "6px",
    height: "6px",
    color: "#FFFFFF",
  },
}));

const AntSwitch = styled(({ disabled, ...props }) => <Switch {...props} />)(
  ({ theme, disabled }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    opacity: disabled ? 0.5 : 1,
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: theme.palette.mode === "dark" ? "red" : "#219653",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#219653" : "red",
      boxSizing: "border-box",
    },
  })
);

const riskcarddata = [
  {
    id: 1,
    name: "Aggressive risk profile ",
    chart: "/images/aggressive.svg",
  },
  {
    id: 2,
    name: "Moderate risk profile ",
    chart: "/images/moderate.svg",
  },
  {
    id: 3,
    name: "Conservative risk profile ",
    chart: "/images/conservative.svg",
  },
];

const BasicDetail = ({ isValue, setIsValue }) => {
  const dispatch = useDispatch();
  const location=useLocation(); 
  const select = useSelector((state) => state);

  const BlockUserID = location.state && location.state.BlockUserID;
  const BlockUserName = location.state && location.state.BlockUserName;

  const customerId = BlockUserID === null ? select?.toast?.breadCrumbData?.user_id : BlockUserID ;


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isFamilyDrawerOpen, setIsFamilyDrawerOpen] = useState(false);
  const [isRiskDrawerOpen, setIsRiskDrawerOpen] = useState(false);
  const [isInvestmentDrawerOpen, setIsInvestmentDrawerOpen] = useState(false);
  const [isManageCashDrawerOpen, setIsManageCashDrawerOpen] = useState(false);
  const [isExpireDateDrawerOpen, setIsExpireDateDrawerOpen] = useState(false);
  const [getCustomerDetails, getCustomerDetailsData = []] =
    customersApiAction.getCustomerDetail(customerId);
  const BasicDetailData = getCustomerDetailsData?.data?.result;
  const [updateAutoTrade] = customersApiAction.updateAutoTradeApi(customerId);
  const originalJoinDate = BasicDetailData?.customerDetails[0]?.joining_date;
  const JoinedDate = dayjs(originalJoinDate).format("DD-MM-YYYY");
  const originalexpiryDate = BasicDetailData?.otherDetails[0]?.end_date;
  const expiryDate = dayjs(originalexpiryDate).format("DD-MM-YYYY");
  const [downloadDocAPI] = customersApiAction.postProfileDocApi(customerId);
  const [checked, setChecked] = React.useState(
    BasicDetailData?.customerDetails[0]?.auto_trade
  );
  const autoTrade = BasicDetailData?.customerDetails[0]?.auto_trade;

  const capital_value =
    `₹ ${BasicDetailData?.customerDetails[0]?.capital}` ?? "";

  const customerDetail = [
    {
      name: "Email Address",
      value: BasicDetailData?.customerDetails[0]?.email ?? "",
    },
    {
      name: "Phone Number",
      value: BasicDetailData?.customerDetails[0]?.mobile ?? "",
    },
    {
      name: "Joining Date",
      value: JoinedDate ?? "",
    },
    {
      name: "Customer Residency",
      value: BasicDetailData?.customerDetails[0]?.customer_residency ?? "",
    },
    {
      name: "Customer Category",
      value: BasicDetailData?.customerDetails[0]?.customer_category ?? "",
    },
    {
      name: "Customer Type",
      value: BasicDetailData?.customerDetails[0]?.customer_type ?? "",
    },
    {
      name: "Capital",
      value: capital_value ?? 0,
    },
    {
      name: "Family Name",
      value: BasicDetailData?.customerDetails[0]?.family_name ?? "",
    },
    {
      name: "Auto Trade",
      value: BasicDetailData?.otherDetails[0]?.auto_trade ?? "",
    },
    {
      name: "Investment Type",
      value: BasicDetailData?.customerDetails[0]?.investment_type ?? "",
    },
    { name: "Cash Component", value: "Cash Component" },
    { name: "Plan", value: BasicDetailData?.otherDetails[0]?.plan_name ?? "" },
    { name: "Expiry Date", value: expiryDate ?? "" },
    {
      name: "Plan Status",
      value: BasicDetailData?.otherDetails[0]?.plan_status ?? "",
    },
    {
      name: "Plan Code",
      value: BasicDetailData?.otherDetails[0]?.plan_code ?? "",
    },
    {
      name: "Assessed Risk Profile",
      value: BasicDetailData?.customerDetails[0]?.assessedriskprofile ?? "",
    },
    {
      name: "Selected Risk Profile",
      value: BasicDetailData?.customerDetails[0]?.selectedriskprofile ?? "",
    },
  ];

  // const handleAutoTradeChange = async (event) => {
  //   const isChecked = event.target.checked;
  //   setChecked(isChecked);
  //   const autoTradeValue = {
  //     auto_trade: isChecked ? "YES" : "NO",
  //   };
  //   try {
  //     const res = await updateAutoTrade({
  //       user_id: customerId,
  //       auto_trade: autoTradeValue,
  //     });
  //     console.log("res?.data?.status",res?.data?.status)
  //     if (res?.data?.status === 1) {
  //       getCustomerDetails(customerId);
  //     }
  //     if (res) {
  //       const toastMessage =
  //         res?.data?.status === 1
  //           ? "Auto Trade status updated successfully!"
  //           : res?.data?.message;
  //       dispatch(
  //         toastActions.setToastData({
  //           message: toastMessage,
  //           variant: res?.data?.status === 1 ? "success" : "error",
  //         })
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error saving data:", error);
  //   }
  // };

  // useEffect(() => {
  //   setChecked(
  //     getCustomerDetailsData?.data?.result?.customerDetails[0]?.auto_trade ===
  //       "YES"
  //   );
  // }, [getCustomerDetailsData?.data?.result]);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const openFamilyDrawer = () => {
    setIsFamilyDrawerOpen(true);
  };

  const closeFamilyDrawer = () => {
    setIsFamilyDrawerOpen(false);
  };

  const openRiskDrawer = () => {
    setIsRiskDrawerOpen(true);
  };

  const closeRiskDrawer = () => {
    setIsRiskDrawerOpen(false);
  };

  const openInvestmentDrawer = () => {
    setIsInvestmentDrawerOpen(true);
  };

  const closeInvestmentDrawer = () => {
    setIsInvestmentDrawerOpen(false);
  };

  const openManageDrawer = () => {
    setIsManageCashDrawerOpen(true);
  };

  const closeManageDrawer = () => {
    setIsManageCashDrawerOpen(false);
  };
  const openExpireDrawer = () => {
    setIsExpireDateDrawerOpen(true);
  };

  const closeExpireDrawer = () => {
    setIsExpireDateDrawerOpen(false);
  };

  const handleDownloadDoc = async (type) => {
    try {
      const requestBody = {
        user_id: customerId,
        type: type,
      };
      let res = await downloadDocAPI(requestBody).unwrap();
      console.log("API Response: ", res);
      const result = res?.result;
      if (result?.file) {
        console.log("download function called");
        const base64 = await fetch(result.file);
        const blob = await base64.blob();
        const a = document.createElement("a");
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = result.filename || "downloaded_file.png";
        a.click();
      } else {
        console.error("No file found in the API response.");
      }
    } catch (err) {
      console.error("Error during download:", err);
    }
  };

  useEffect(() => {
    getCustomerDetails(customerId);
  }, [customerId, isValue]);

  useEffect(() => {
    setIsValue(false);
  }, [isValue]);

  return (
    <Stack style={{ marginTop: "10px" }}>
      <Grid container spacing={2}>
        {customerDetail.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                {item.name === "Investment Type" ? (
                  <Box style={{ marginTop: "16px" }}>
                    <Text
                      fontSize="15px"
                      fontWeight="400"
                      sx={{ color: "#676C76", textWrap: "nowrap" }}
                    >
                      {item?.name}
                    </Text>
                  </Box>
                ) : item.name === "Plan" ? (
                  <Box style={{ marginTop: "14px" }}>
                    <Text
                      fontSize="15px"
                      fontWeight="400"
                      sx={{ color: "#676C76", textWrap: "nowrap" }}
                    >
                      {item?.name}
                    </Text>
                  </Box>
                ) : item.name === "Cash Component" ? (
                  <Box style={{ marginTop: "24px" }}>
                    <Text
                      fontSize="15px"
                      fontWeight="400"
                      sx={{ color: "#676C76", textWrap: "nowrap" }}
                    >
                      {item?.name}
                    </Text>
                  </Box>
                ) : (
                  <Box>
                    <Text
                      fontSize="15px"
                      fontWeight="400"
                      sx={{ color: "#676C76", textWrap: "nowrap" }}
                    >
                      {item?.name}
                    </Text>
                  </Box>
                )}
              </Grid>
              <Grid item xs={10}>
                {item.name === "Investment Type" ? (
                  <Stack direction="row">
                    <Text style={{ marginRight: "8px", marginTop: "12px" }}>
                      {":"}
                    </Text>
                    <Box sx={{ marginRight: "10px" }}>
                      <TextField
                        disabled={
                          !BasicDetailData?.customerDetails[0]?.investment_selected_type
                            .split(",")
                            .includes("Custom")
                        }
                        value={`${BasicDetailData?.investmentDetails[0]?.product_name} : ₹ ${BasicDetailData?.investmentDetails[0]?.investment_amount}`}
                        style={{
                          margingLeft: "5px",
                          height: "30px",
                        }}
                        inputProps={{
                          readOnly: true,
                          style: { fontSize: "14px" },
                        }}

                        // placeholder="Custom : ₹ 50,00,000"
                      >
                        {BasicDetailData?.customerDetails[0]?.product_name}
                      </TextField>
                    </Box>
                    <Box sx={{ marginRight: "10px" }}>
                      <TextField
                        disabled={
                          !BasicDetailData?.customerDetails[0]?.investment_selected_type
                            .split(",")
                            .includes("Model Portfolio")
                        }
                        value={`${BasicDetailData?.investmentDetails[1]?.product_name} : ₹ ${BasicDetailData?.investmentDetails[1]?.investment_amount}`}
                        style={{
                          margingLeft: "5px",
                          height: "30px",
                          cursor: "none",
                        }}
                        inputProps={{
                          readOnly: true,
                          style: { fontSize: "14px" },
                        }}
                        // placeholder="Algo (F&O) : ₹ 50,00,000"
                      />
                    </Box>
                    <Box sx={{ marginRight: "10px", textAlign: "center" }}>
                      <TextField
                        value={`${BasicDetailData?.investmentDetails[2]?.product_name} : ₹ ${BasicDetailData?.investmentDetails[2]?.investment_amount}`}
                        disabled={
                          !BasicDetailData?.customerDetails[0]?.investment_selected_type
                            .split(",")
                            .includes("Algo")
                        }
                        style={{
                          margingLeft: "5px",
                          height: "30px",
                          cursor: "none",
                        }}
                        inputProps={{
                          readOnly: true,
                          style: { fontSize: "14px" },
                        }}
                      />
                    </Box>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginLeft: "10px",
                        color: "#1A54B9",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      onClick={openInvestmentDrawer}
                    >
                      {" "}
                      Edit Type
                    </div>
                    {isInvestmentDrawerOpen && (
                      <InvestmentSidebar
                        getCustomerDetails={getCustomerDetails}
                        viewCustomerDetail={BasicDetailData}
                        customer_id={customerId}
                        closeInvestmentDrawer={closeInvestmentDrawer}
                        isInvestmentDrawerOpen={isInvestmentDrawerOpen}
                      />
                    )}
                    {/* <InvestmentSidebar /> */}
                  </Stack>
                ) : item.name === "Family Name" ? (
                  BasicDetailData?.customerDetails[0]?.family_id !== null ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={1.5}
                      sx={{ width: "100%" }}
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          {":  "}
                          <Text
                            fontSize="14px"
                            fontWeight="600"
                            color="#242424"
                            marginLeft="12px"
                            marginRight="8px"
                          >
                            {item?.value}
                          </Text>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              marginLeft: "10px",
                              color: "#1A54B9",
                              cursor: "pointer",
                            }}
                            onClick={openFamilyDrawer}
                          >
                            {" "}
                            View Members
                          </div>
                          {isFamilyDrawerOpen && (
                            <ViewMember
                              getCustomerDetails={getCustomerDetails}
                              viewCustomerDetail={BasicDetailData}
                              customer_id={customerId}
                              closeFamilyDrawer={closeFamilyDrawer}
                              isFamilyDrawerOpen={isFamilyDrawerOpen}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Stack>
                  ) : (
                    <Text>
                      : <span
                      style={{ marginLeft: "8px",fontSize:'14px',fontWeight:600 ,color:'#242424'}}>NA</span>{" "}
                    </Text>
                  )
                ) : item.name === "Plan" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        {":  "}
                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          color="#242424"
                          marginLeft="12px"
                          marginRight="6px"
                        >
                          {item?.value}
                        </Text>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            marginLeft: "10px",
                            color: "#1A54B9",
                            cursor: "pointer",
                          }}
                          onClick={openDrawer}
                        >
                          {" "}
                          View and update plan
                        </div>
                        {isDrawerOpen && (
                          <PlanSidebar
                            getCustomerDetails={getCustomerDetails}
                            viewCustomerDetail={BasicDetailData}
                            customer_id={customerId}
                            closeDrawer={closeDrawer}
                            isDrawerOpen={isDrawerOpen}
                            setIsDrawerOpen={setIsDrawerOpen}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ) : item.name === "Assessed Risk Profile" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Text marginRight="10px">{":"}</Text>
                        {BasicDetailData?.customerDetails[0]
                          ?.assessedriskprofile === "Aggressive" ? (
                          <Avatar
                            style={{
                              width: "52px",
                              height: "30px",
                              borderRadius: "0px",
                            }}
                            src={riskcarddata[0]?.chart}
                          ></Avatar>
                        ) : BasicDetailData?.customerDetails[0]
                            ?.assessedriskprofile === "Moderate" ? (
                          <Avatar
                            style={{
                              width: "52px",
                              height: "30px",
                              borderRadius: "0px",
                            }}
                            src={riskcarddata[1]?.chart}
                          ></Avatar>
                        ) : BasicDetailData?.customerDetails[0]
                            ?.assessedriskprofile === "Conservative" ? (
                          <Avatar
                            style={{
                              width: "52px",
                              height: "30px",
                              borderRadius: "0px",
                            }}
                            src={riskcarddata[2]?.chart}
                          ></Avatar>
                        ) : (
                          ""
                        )}

                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          color="#242424"
                          marginLeft="12px"
                          marginRight="6px"
                        >
                          {item.value}
                        </Text>

                        {/* <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            marginLeft: "10px",
                            color: "#1A54B9",
                            cursor: "pointer",
                          }}
                          onClick={openRiskDrawer}
                        >
                          {" "}
                          Change risk profile
                        </div>
                        {isRiskDrawerOpen && (
                          <RiskProfileSidebar
                            getCustomerDetails={getCustomerDetails}
                            viewCustomerDetail={BasicDetailData}
                            customer_id={customerId}
                            closeRiskDrawer={closeRiskDrawer}
                            isRiskDrawerOpen={isRiskDrawerOpen}
                          />
                        )} */}
                      </Box>
                    </Grid>
                  </Grid>
                ) : item.name === "Selected Risk Profile" ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Text marginRight="10px">{":"}</Text>
                        {BasicDetailData?.customerDetails[0]
                          ?.selectedriskprofile === "Aggressive" ? (
                          <Avatar
                            style={{
                              width: "52px",
                              height: "30px",
                              borderRadius: "0px",
                            }}
                            src={riskcarddata[0]?.chart}
                          ></Avatar>
                        ) : BasicDetailData?.customerDetails[0]
                            ?.selectedriskprofile === "Moderate" ? (
                          <Avatar
                            style={{
                              width: "52px",
                              height: "30px",
                              borderRadius: "0px",
                            }}
                            src={riskcarddata[1]?.chart}
                          ></Avatar>
                        ) : BasicDetailData?.customerDetails[0]
                            ?.selectedriskprofile === "Conservative" ? (
                          <Avatar
                            style={{
                              width: "52px",
                              height: "30px",
                              borderRadius: "0px",
                            }}
                            src={riskcarddata[2]?.chart}
                          ></Avatar>
                        ) : (
                          ""
                        )}

                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          color="#242424"
                          marginLeft="12px"
                          marginRight="6px"
                        >
                          {item.value}
                        </Text>

                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            marginLeft: "10px",
                            color: "#1A54B9",
                            cursor: "pointer",
                          }}
                          onClick={openRiskDrawer}
                        >
                          {" "}
                          Change risk profile
                        </div>
                        {isRiskDrawerOpen && (
                          <RiskProfileSidebar
                            getCustomerDetails={getCustomerDetails}
                            viewCustomerDetail={BasicDetailData}
                            customer_id={customerId}
                            closeRiskDrawer={closeRiskDrawer}
                            isRiskDrawerOpen={isRiskDrawerOpen}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                ) : item.name === "Expiry Date" ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1.5}
                    sx={{ width: "100%" }}
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        {":  "}
                        <Text
                          fontSize="14px"
                          fontWeight="600"
                          color="#242424"
                          marginLeft="12px"
                          marginRight="6px"
                        >
                          {item?.value}
                        </Text>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            marginLeft: "10px",
                            color: "#1A54B9",
                            cursor: "pointer",
                          }}
                          onClick={openExpireDrawer}
                        >
                          {" "}
                          Change Date
                        </div>
                        {isExpireDateDrawerOpen && (
                          <ExpireDateSidebar
                            getCustomerDetails={getCustomerDetails}
                            viewCustomerDetail={BasicDetailData}
                            customer_id={customerId}
                            closeExpireDrawer={closeExpireDrawer}
                            isExpireDateDrawerOpen={isExpireDateDrawerOpen}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Stack>
                ) : item.name === "Auto Trade" ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Text style={{ margingLeft: "2px", marginRight: "2px" }}>
                      {":"}
                    </Text>

                    <Text fontSize="14px" fontWeight="600">
                      Off
                    </Text>
                    <AntSwitch
                      style={{ cursor: "none" }}
                      checked={autoTrade === "YES" || autoTrade === true} // Set to true if "Yes" or true corresponds to On
                      disabled
                      inputProps={{ "aria-label": "ant design" }}
                    />
                    <Text fontSize="14px" fontWeight="600">
                      On
                    </Text>
                  </Stack>
                ) : item.name === "Cash Component" ? (
                  <Stack direction="row" style={{ marginTop: "10px" }}>
                    <Text style={{ marginRight: "8px", marginTop: "12px" }}>
                      {":"}
                    </Text>
                    <Box sx={{ marginRight: "10px" }}>
                      <TextField
                        value={`${BasicDetailData?.investmentDetails[0]?.product_name} : ₹ ${BasicDetailData?.investmentDetails[0]?.cash}`}
                        disabled
                        style={{ margingLeft: "5px", height: "30px" }}
                        inputProps={{ style: { fontSize: "14px" } }}
                        // placeholder="Custom : ₹ 1,00,000"
                      />
                    </Box>
                    <Box sx={{ marginRight: "10px" }}>
                      <TextField
                        value={`${BasicDetailData?.investmentDetails[1]?.product_name} : ₹ ${BasicDetailData?.investmentDetails[1]?.cash}`}
                        disabled
                        style={{ margingLeft: "5px", height: "30px" }}
                        inputProps={{ style: { fontSize: "14px" } }}
                        // placeholder="Algo (F&O) : ₹ 1,00,000"
                      />
                    </Box>
                    <Box sx={{ marginRight: "10px" }}>
                      <TextField
                        value={`${BasicDetailData?.investmentDetails[2]?.product_name} : ₹ ${BasicDetailData?.investmentDetails[2]?.cash}`}
                        disabled
                        style={{ margingLeft: "5px", height: "30px" }}
                        inputProps={{ style: { fontSize: "14px" } }}
                        // placeholder="Model Porfolio : ₹ 1,00,000"
                      />
                    </Box>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        marginLeft: "10px",
                        color: "#1A54B9",
                        marginTop: "12px",
                        cursor: "pointer",
                      }}
                      onClick={openManageDrawer}
                    >
                      {" "}
                      Manage Cash
                    </div>
                    {isManageCashDrawerOpen && (
                      <ManageCash
                        getCustomerDetails={getCustomerDetails}
                        viewCustomerDetail={BasicDetailData}
                        customer_id={customerId}
                        closeManageDrawer={closeManageDrawer}
                        setIsManageCashDrawerOpen={setIsManageCashDrawerOpen}
                        isManageCashDrawerOpen={isManageCashDrawerOpen}
                      />
                    )}
                  </Stack>
                ) : item.name === "Plan Status" ? (
                  <Box>
                    <Stack
                      direction="row"
                      textAlign="center"
                      paddingRight="8px"
                    >
                      {":  "}
                      <ChipStyle
                        label={
                          <Text fontSize="12px" fontWeight="500">
                            {BasicDetailData?.otherDetails[0]?.plan_status}
                          </Text>
                        }
                        size="small"
                        style={{ marginLeft: "5px" }}
                        status={
                          BasicDetailData?.otherDetails[0]?.plan_status ===
                          "Active"
                            ? "Active"
                            : "Expired"
                        }
                      />
                    </Stack>
                  </Box>
                ) : (
                  <Stack
                    direction="row"
                    spacing={1.5}
                    sx={{ width: "100%" }}
                    alignItems="center"
                  >
                    <Text>{":"}</Text>
                    <Text
                      component="h3"
                      fontSize="14px"
                      fontWeight="600"
                      color="#242424"
                    >
                      {item?.value}
                    </Text>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ marginTop: "10px" }}>
        <Divider />
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <Text fontSize="14px" fontWeight="600">
          Documents Submitted
        </Text>
      </Box>
      <Grid container spacing={1.5} marginTop="15px">
        <Grid item xs={4}>
          <Box>
            <Text
              fontSize="16px"
              fontWeight="400"
              sx={{ color: "#676C76", textWrap: "nowrap" }}
            >
              PAN Card
            </Text>
            <DownloadButton
              variant="outlined"
              onClick={() => handleDownloadDoc("PAN_CARD")}
            >
              {BasicDetailData?.documentDetails[0]?.pan_card || "PAN Card"}
            </DownloadButton>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            <Text
              variant="medium"
              fontSize="16px"
              fontWeight="400"
              sx={{ color: "#676C76", textWrap: "nowrap" }}
            >
              Aadhar Card
            </Text>
            <DownloadButton
              variant="outlined"
              onClick={() => handleDownloadDoc("AADHAR_CARD")}
            >
              {BasicDetailData?.documentDetails[1]?.aadhar_card ||
                "Aadhar Card"}
            </DownloadButton>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box>
            {BasicDetailData?.documentDetails[2]?.sign_agreement && (
              <Text
                variant="medium"
                fontSize="16px"
                fontWeight="400"
                sx={{ color: "#676C76", textWrap: "nowrap" }}
              >
                Sign Agreement
              </Text>
            )}

            {BasicDetailData?.documentDetails[2]?.sign_agreement && (
              <DownloadButton
                variant="outlined"
                onClick={() => handleDownloadDoc("SIGN_AGREEMENT")}
              >
                {BasicDetailData?.documentDetails[2]?.sign_agreement ||
                  "Sign Agreement"}
              </DownloadButton>
            )}
            {/* 
            {documentDetails?.[2]?.document_name && (
              <Grid item xs={6}>
                <DownloadButton
                  children={documentDetails?.[2]?.document_name}
                  isDwonloadIconVisible={false}
                />
              </Grid> */}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default BasicDetail;
