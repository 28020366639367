import React from "react";
import CustomerRiskAssesmentContainer from "containers/Customer/CustomerRiskAssesmentContainer";

function CustomerRiskAssesment() {
  return (
    <>
      <CustomerRiskAssesmentContainer />
    </>
  );
}

export default CustomerRiskAssesment;
