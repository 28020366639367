import React from 'react'
import CustomerPortfolioContainer from 'containers/Customer/CustomerPortfolioContainer'

function CustomerPortfolio() {
  return (
    <>
    <CustomerPortfolioContainer/>
    </>
  )
}

export default CustomerPortfolio
