import { useState, useEffect } from "react";
import { styled } from "@mui/material";
import Card from "components/common/Card";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "components/common/ListItem";
import ListItemButton from "components/common/ListItemButton";
import ListItemIcon from "components/common/ListItemIcon";
import ListItemText from "components/common/ListItemText";
import IconButton from "components/common/IconButton";
import Radio from "components/common/Radio";
import TextField from "components/common/TextField";

const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  border: "2px solid ",
  borderColor: isSelected ? "#1A54B9" : "#CBCBCB",
  margin: "8px 0",
  borderRadius: "8px",
  marginBottom: "20px",
  // padding:"16px",
  backgroundColor: isSelected ? "#EAF2FF" : "inherit",
}));
const PreviousButton = styled(Button)(({}) => ({
  width: "100%",
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "14px 28px",
  marginTop: "50px",
}));
const ContinueButton = styled(Button)(({}) => ({
  width: "100%",
  borderRadius: "8px",
  textTransform: "capitalize",
  padding: "14px 28px",
  marginTop: "50px",
}));

const Answers = (props) => {
  const [selectedValue, setSelectedValue] = useState({});
  const [textValue, setTextValue] = useState("");
  const [isError, setError] = useState(false);
  const [showTextInput, setShowTextInput] = useState(false);
  const [textBoxValues, setTextBoxValues] = useState({});
  const [validationError, setValidationError] = useState("");
  const [validationError1, setValidationError1] = useState("");

  const {
    onChange,
    qtsOptions,
    handleAnswers,
    handlePreviousStep,
    currentQtsId,
    totalQts,
    riskPoints,
    riskDetailsAnswer = [],
    hasInputText,
  } = props;

  const [textValues, setTextValues] = useState();
  // const [validationErrors, setValidationErrors] = useState(
  //   Array(qtsOptions.textbox.length).fill("")
  // );

  console.log("qtsOptions", textValues);
  useEffect(() => {
    const answerExist = riskDetailsAnswer.find(
      (obj) => obj.question_id === currentQtsId
    );

    if (!answerExist) {
      setSelectedValue({});
      setTextValue("");
      setTextBoxValues({});
      setShowTextInput(false);
      setTextValues(
        qtsOptions?.textbox ? Array(qtsOptions.textbox.length).fill("") : []
      ); // Ensure proper initialization of textValues
      return;
    }

    // Set prefilled text value.
    if (answerExist?.option_text_value && currentQtsId !== 15) {
      setTextValue(answerExist?.option_text_value);
    }

    // Set textValues if question ID is 15
    if (currentQtsId === 15 && answerExist.text_values) {
      setTextValues(answerExist.text_values);
      // console.log("initialTextValues", initialTextValues);
    }
    // Find the selected option for the current question
    const currentOptions = qtsOptions.options.find(
      (obj) => obj.optionId === answerExist.answer_id
    );

    if (currentOptions) {
      setSelectedValue(currentOptions);

      if (currentQtsId === 9 || currentQtsId === 14) {
        const isLastOptionSelected =
          qtsOptions.options[qtsOptions.options.length - 1].optionId ===
          currentOptions.optionId;
        setShowTextInput(isLastOptionSelected);
      }

      if (currentQtsId === 15) {
        setShowTextInput(
          currentOptions.optionDescription.toLowerCase() === "yes"
        );
      }
    } else {
      setSelectedValue({});
      setShowTextInput(false);
    }

    // Set initial values for text boxes
    const initialTextBoxValues = {};
    qtsOptions?.textbox?.forEach((box) => {
      const existingValue = riskDetailsAnswer.find(
        (obj) =>
          obj.question_id === box.question_id &&
          obj.textbox_info_id === box.question_textbox_info_id
      );
      initialTextBoxValues[box.question_textbox_info_id] = existingValue
        ? existingValue.textbox_value
        : "";
    });

    // Only set textBoxValues state if currentQtsId is not 15
    if (currentQtsId !== 15) {
      setTextBoxValues(initialTextBoxValues);
    }
  }, [currentQtsId, riskDetailsAnswer]);

  // Internal: Radio button change
  const handleRadioChange = (value) => {
    setSelectedValue(value);
    setError(false);
    setValidationError("");
    setValidationError1("");

    if (currentQtsId === 15) {
      setShowTextInput(value.optionDescription.toLowerCase() === "yes");
      if (value.optionDescription.toLowerCase() === "no") {
        setValidationError1("");
      }
      return;
    }

    if (currentQtsId === 9 || currentQtsId === 14) {
      const isLastOptionSelected =
        qtsOptions.options[qtsOptions.options.length - 1].optionId ===
        value.optionId;
      setShowTextInput(isLastOptionSelected);

      // If it's not the last option, submit the answer and move to the next question
      if (!isLastOptionSelected) {
        setTimeout(() => {
          handleAnswerSubmit(value);
        }, 400);
      }
      return;
    }

    setTimeout(() => {
      handleAnswerSubmit(value);
    }, 400);
  };

  const handleTextBoxChange = (id, value) => {
    setTextBoxValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleTextChange = (v, infoId) => {
    const newTextValues = [...textValues];
    const dataObj = {
      option_text_value: v,
      question_textbox_info_id: infoId,
    };

    let dataIndex = -1;
    if (newTextValues && newTextValues.length) {
      dataIndex = newTextValues.findIndex(
        (e) => e.question_textbox_info_id === infoId
      );
    }

    if (dataIndex != -1) {
      newTextValues[dataIndex] = dataObj;
    } else {
      newTextValues.push(dataObj);
    }

    setTextValues(newTextValues);

    // Clear validation error for the specific text box
    // const newValidationErrors = [...validationErrors];
    // newValidationErrors[index] = "";
    // setValidationErrors(newValidationErrors);
  };

  // Internal: previouse button
  const handlePreviousStepChange = () => {
    setError(false);
    setTextValue("");
    handlePreviousStep();
  };

  // Internal: submit button
  const handleAnswerSubmit = (val = null) => {
    let ans = val ? val : selectedValue;

    if (ans && Object.keys(ans).length) {
      if (currentQtsId === 9 || currentQtsId === 14) {
        if (showTextInput && !textValue) {
          setValidationError("Please enter text answer");
          return;
        }
      }

      if (currentQtsId === 15) {
        if (ans.optionDescription.toLowerCase() === "no") {
          // If "No" is selected, clear text values and proceed
          handleAnswers(
            qtsOptions.questionId,
            ans,
            "",
            currentQtsId === totalQts,
            []
          );
          setTextValues([]);
          setValidationError1("");
          setSelectedValue({});
          return;
        }

        if (qtsOptions?.textbox) {
          let textLength = qtsOptions?.textbox.length;
          let ansLength = textValues.length;
          if (
            textLength !== ansLength ||
            textValues.some((an) => !an.option_text_value)
          ) {
            setValidationError1("Required field.");
            return;
          }
        }
      }

      handleAnswers(
        qtsOptions.questionId,
        ans,
        textValue,
        currentQtsId === totalQts,
        textValues
      );

      setTextValue("");
      setError(false);
      setSelectedValue({});
      setValidationError("");
      setValidationError1("");
    } else {
      setError(true);
    }
  };

  const answerExist = riskDetailsAnswer.find((obj) => {
    if (obj.question_id === currentQtsId) {
      return true;
    }
    return false;
  });

  console.log("Answers => ", {
    qtsOptions,
    riskDetailsAnswer,
    currentQtsId,
    hasInputText,
  });
  return (
    <>
      <Box sx={{ p: 8 }}>
        <Card sx={{ p: 4, borderRadius: "16px", width: "100%" }}>
          <Stack direction="row" justifyContent="space-between">
            <Text
              sx={{
                color: "#242424",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Select one answer for the question.
            </Text>

            <Box>
              <Chip
                label={`${riskPoints} Points`}
                sx={{
                  color: "#1A54B9",
                  borderRadius: "112px",
                  backgroundColor: "#F7F8FF",
                  fontSize: "14px",
                }}
              />
            </Box>
          </Stack>

          {qtsOptions?.questionId == 15 ? (
            <Box>
              <Text sx={{ fontSize: "16px", mt: 1 }}>
                Have you availed any loan?
              </Text>
            </Box>
          ) : null}

          {qtsOptions?.questionId == 15 ? (
            <List
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                bgcolor: "background.paper",
              }}
            >
              {qtsOptions?.options?.map((option, index) => {
                const labelId = `radio-list-label-${index}`;
                return (
                  <Box
                    isSelected={selectedValue?.optionId === option?.optionId}
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 3,
                    }}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments"></IconButton>
                    }
                    disablePadding
                  >
                    <>
                      <>
                        <Radio
                          checked={selectedValue?.optionId === option?.optionId}
                          onClick={() => handleRadioChange(option)}
                          size="small"
                          tabIndex={-1}
                          disableRipple
                          sx={{
                            "&.Mui-checked": {
                              color: "black",
                            },
                          }}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </>
                      <ListItemText
                        onChange={onChange}
                        id={labelId}
                        primary={option.optionDescription}
                        primaryTypographyProps={{
                          variant: "body1",
                          fontSize: 16,
                        }} // Set font size of the ListItemText
                        sx={{
                          pointerEvents: "none", // Disable pointer events on the text if necessary
                          marginLeft: "8px", // Add margin as needed
                        }}
                      />
                    </>
                  </Box>
                );
              })}
              {isError ? (
                <Text variant="small" sx={{ color: "#FF3333", mb: "30px" }}>
                  Please Select one option
                </Text>
              ) : (
                <></>
              )}
            </List>
          ) : (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {qtsOptions?.options?.map((option, index) => {
                const labelId = `radio-list-label-${index}`;
                return (
                  <StyledListItem
                    isSelected={selectedValue?.optionId === option?.optionId}
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments"></IconButton>
                    }
                    disablePadding
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() => handleRadioChange(option)}
                      dense
                    >
                      <ListItemIcon>
                        <Radio
                          checked={selectedValue?.optionId === option?.optionId}
                          tabIndex={-1}
                          disableRipple
                          sx={{
                            "&.Mui-checked": {
                              color: "black",
                            },
                          }}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        onChange={onChange}
                        id={labelId}
                        primary={option.optionDescription}
                      />
                    </ListItemButton>
                  </StyledListItem>
                );
              })}
              {isError ? (
                <Text variant="small" sx={{ color: "#FF3333", mb: "30px" }}>
                  Please Select one option
                </Text>
              ) : (
                <></>
              )}
            </List>
          )}

          {/* Input field */}
          {(currentQtsId === 9 || currentQtsId === 14) && showTextInput && (
            <Stack>
              <Text
                sx={{
                  fontSize: 14,
                  color: "#344054",
                  fontWeight: 500,
                  marginBottom: "6px",
                }}
              >
                Answer in text:
              </Text>

              <TextField
                onChange={({ target }) => {
                  setTextValue(target.value);
                  setValidationError("");
                }}
                value={textValue}
                sx={{
                  width: "100%",
                  marginBottom: "24px",
                }}
                placeholder="Enter Your Answer"
                error={!!validationError}
                helperText={validationError}
              />
            </Stack>
          )}

          {showTextInput &&
            qtsOptions?.textbox?.map((box, index) => {
              const selectedVal = textValues.find(
                (tv) =>
                  tv.question_textbox_info_id === box?.question_textbox_info_id
              );
              return (
                <Stack key={box?.question_textbox_info_id}>
                  <Text
                    sx={{
                      fontSize: 14,
                      color: "#344054",
                      fontWeight: 500,
                      marginBottom: "6px",
                    }}
                  >
                    {box.text_heading}
                  </Text>
                  <TextField
                    onChange={(e) =>
                      handleTextChange(
                        e.target.value,
                        box?.question_textbox_info_id
                      )
                    }
                    value={selectedVal?.option_text_value || ""}
                    sx={{
                      width: "100%",
                      marginBottom: "24px",
                    }}
                    placeholder={box.text_placeholder}
                    error={
                      !!validationError1 && !selectedVal?.option_text_value
                    }
                    helperText={
                      !!validationError1 && !selectedVal?.option_text_value
                        ? validationError1
                        : ""
                    }
                  />
                </Stack>
              );
            })}

          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            mt={2}
          >
            {currentQtsId > 1 && (
              <PreviousButton
                variant="outlined"
                onClick={() => handlePreviousStepChange()}
              >
                Previous
              </PreviousButton>
            )}

            {currentQtsId === 9 || answerExist || hasInputText == "Y" ? (
              <ContinueButton
                // onClick={currentQtsId === totalQts ? handleSubmit : handleAnswerSubmit}
                onClick={() => handleAnswerSubmit()}
              >
                Continue
              </ContinueButton>
            ) : null}
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Answers;
