import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import OtherDetails from "components/addCustomer/OtherDetails";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { fileToBase64, clickAndDownloadFile } from "helpers/fileFormat";
import { validationHelper } from "helpers/validation";
import { useLocation } from "react-router-dom";
import { dateUtill } from "helpers/dateUtill";
import _get from "lodash/get";

import { profileApiAction } from "stores/redux/riaProfile/riaProfileSlice";

import { useDispatch } from "react-redux";

import { addCustomerActions } from "stores/redux/slices/addCustomerSlice";

function OtherDetailsContainer({ handleStepChange,currentUserId }) {
  //State
  const [formData, setFormData] = useState({
    plan: null,
    code: null,
    planStartDate: null,
    planExpiryDate: null,
    customerResidency: null,
    customerCategory: null,
    customerType: "Advisory",
    joiningDate: null,
    isAddUserToFamilyChecked: false,
    family: null,
    isAutoCheck: false,
    panNo: null,
    aadharCard: null,
    signAgreement: null,
    panNumber: null,
    aadharNo: null,
    sign_agreement: null,
    pan_card_document: null,
    aadhar_card_document: null,
    sign_agreement_document: null,
  });
  const [formError, setFormError] = useState({
    plan: null,
    code: null,
    planStartDate: null,
    planExpiryDate: null,
    customerResidency: null,
    customerCategory: null,
    customerType: null,
    joiningDate: null,
    family: null,
    panNo: null,
    aadharCard: null,
    signAgreement: null,
    panCardNumber: null,
    aadharCardNumber: null,
  });

  //Hooks
  // const customerRiskDetails = useSelector(
  //   (state) => state.addCustomerSlice.customerDetails
  // );
  const location = useLocation();
  const onBoardData = location.state && location.state.onBoardData;
  const otherDetailsData = location?.state && location?.state?.otherDetailsData;
  console.log(otherDetailsData, "otherDetailsData");
  //apis
  const [getFamilyList, familyList] = addCustomerApiAction.getFamilyDetails();
  const [getPlanList, planList] = addCustomerApiAction.getPlanDetails();
  const { data: risk } = addCustomerApiAction.getRiskAssesment();
  const [submitOtherDetails] = addCustomerApiAction.updateOtherDetails();
  const [downloadUploadedDocument] = addCustomerApiAction.downloadDocument();

  const [getCustomerDetails, customerSummary] =
    addCustomerApiAction.getCustomerSummary();

  const [fetchProfileDoc] = profileApiAction.getLazyProfileDocApi();
  const [fetchSignAgreementDoc] = profileApiAction.getLazySignAgreementApi();

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  //useEffect

  useEffect(() => {
    getPlanList();
    getFamilyList();
  }, []);

  // LS: Plan listing
  useEffect(() => {
    let newFormData = { ...formData };

    let planName = _get(customerSummary, "data.otherDetails.plan_name", null);
    newFormData = detailsDataSetter(newFormData, "plan", planName);
    setFormData(newFormData);
  }, [planList]);

  // LS: Family listing
  useEffect(() => {
    let newFormData = { ...formData };

    let familyName = _get(
      customerSummary,
      "data.otherDetails.family_group_name",
      null
    );
    newFormData = detailsDataSetter(newFormData, "family", familyName);

    setFormData(newFormData);
  }, [familyList]);

  // LS: Customer Details
  useEffect(() => {
    let userId = onBoardData?.user_id;
    // ? onBoardData?.user_id
    // : customerRiskDetails?.userId;
    if (userId) {
      dispatch(addCustomerActions.setCustomerUserId({ userId: userId }));
    }

    if (!userId) {
      userId = currentUserId;
    }

    if (userId) {
      getCustomerDetails({ userId: userId }, false);
    }
  }, [onBoardData, currentUserId]);

  // LS: Set Details
  useEffect(() => {
    if (customerSummary?.data) {
      setOtherDetails(customerSummary?.data);
    }
  }, [customerSummary.data]);

  // Handle Function: Set Pre-selected details
  const detailsDataSetter = (formData, keyName = "", value = "") => {
    if (!formData) {
      console.log(`dataSetter fail => ${{ keyName, value }}`);
      return formData;
    }

    // Plan
    if (keyName == "plan") {
      let list = _get(planList, "data.plans", null);
      let v = value;
      if (list && list.length && v) {
        formData.plan = list.find((p) => p.name == v);
      }
    }

    // Family
    if (keyName == "family") {
      let list = _get(familyList, "data.familygroups", null);
      let v = value;
      if (list && list.length && v) {
        formData.family = list?.find((f) => f.name === v);
        formData.isAddUserToFamilyChecked = true;
      }
    }

    return formData;
  };

  const setOtherDetails = ({ otherDetails, documentDetails }) => {
    let newFormData = { ...formData };

    // Plan
    let planName = _get(otherDetails, "plan_name", null);
    newFormData = detailsDataSetter(newFormData, "plan", planName);

    // family
    let familyName = _get(
      customerSummary,
      "data.otherDetails.family_group_name",
      null
    );
    newFormData = detailsDataSetter(newFormData, "family", familyName);

    // Other details
    newFormData.code = otherDetails?.plan_code ?? newFormData.code;
    newFormData.planStartDate = otherDetails?.start_date
      ? dayjs(otherDetails?.start_date)
      : newFormData.planStartDate;
    newFormData.planExpiryDate = otherDetails?.end_date
      ? dayjs(otherDetails?.end_date)
      : newFormData.planExpiryDate;
    newFormData.customerResidency = otherDetails?.customer_residency
      ? otherDetails?.customer_residency
      : newFormData.customerResidency;
    newFormData.customerCategory = otherDetails?.customer_category
      ? otherDetails?.customer_category
      : newFormData.customerCategory;

    newFormData.customerType = otherDetails?.customer_type
      ? otherDetails?.customer_type
      : newFormData.customerType;

    if (otherDetails?.joining_date) {
      newFormData.joiningDate = dayjs(otherDetails?.joining_date);
    }

    // newFormData.joiningDate = dayjs(otherDetails?.joining_date) ?? null;

    newFormData.isAutoCheck = otherDetails?.auto_trade === "YES" ? true : false;

    // Document
    newFormData.panNumber =
      documentDetails.filter((document) => document.pan_card_nbr)[0]
        ?.pan_card_nbr ?? "";
    newFormData.pan_card_document =
      documentDetails.filter(
        (document) => document.document_name === "PAN_CARD"
      )[0]?.document_name ?? "";
    newFormData.aadharNo =
      documentDetails.filter((document) => document.aadhar_card_nbr)[0]
        ?.aadhar_card_nbr ?? "";
    newFormData.aadhar_card_document =
      documentDetails.filter(
        (document) => document.document_name === "AADHAR_CARD"
      )[0]?.document_name ?? "";
    newFormData.sign_agreement =
      documentDetails.filter(
        (document) => document.document_name === "SIGN_AGREEMENT"
      )[0]?.document_name ?? "";

    setFormData(newFormData);
  };

  // Onchange: Handle update form in state.
  const handleChange = ({ name, value }) => {
    let newFormData = { ...formData, [name]: value };

    if (["plan", "code", "planStartDate"].includes(name)) {
      if (name == "plan") {
        newFormData.code = value?.subCategories[0].code || null;
      }
      newFormData = autoSetExpiryDate(newFormData);
    }
    setFormData(newFormData);
  };

  // Handle: Auto reset plan expiry Date
  const autoSetExpiryDate = (newFormData) => {
    const { plan, code, planStartDate } = newFormData;
    if (plan && code && planStartDate && planStartDate instanceof Date) {
      // get plan frequency
      let planCategory = plan.subCategories.find((d) => d.code == code);
      let newPlanExpiryDate;
      if (planCategory && planCategory.frequency) {
        newPlanExpiryDate = dateUtill.getNextDate(
          planStartDate,
          planCategory.frequency
        );
        newFormData.planExpiryDate = newPlanExpiryDate;
      }
    }

    return newFormData;
  };

  // Handle: form validate before submit
  const handleValidation = () => {
    const newFormError = { ...formError };

    const planValidation = validationHelper.required(formData.plan);
    newFormError.plan = planValidation.message;

    const codeValidation = validationHelper.required(formData.code);
    newFormError.code = codeValidation.message;

    const startDateValidation = validationHelper.required(
      formData.planStartDate
    );
    newFormError.planStartDate = startDateValidation.message;

    const expiryDateValidation = validationHelper.required(
      formData.planExpiryDate
    );
    newFormError.planExpiryDate = expiryDateValidation.message;

    const customerResidencyValidation = validationHelper.required(
      formData.customerResidency
    );
    newFormError.customerResidency = customerResidencyValidation.message;

    const customerCategoryValidation = validationHelper.required(
      formData.customerCategory !== null && formData.customerCategory !== "0"
        ? formData.customerCategory
        : ""
    );
    newFormError.customerCategory = customerCategoryValidation.message;

    const customerTypeValidation = validationHelper.required(
      formData.customerType
    );
    newFormError.customerType = customerTypeValidation.message;

    const joiningDateValidation = validationHelper.required(
      formData.joiningDate
    );
    newFormError.joiningDate = joiningDateValidation.message;

    // pancardNumber validation
    const panNumberValidation = validationHelper.pan(formData.panNumber);
    newFormError.panCardNumber = panNumberValidation.message;

    // adhaarCardValidation
    const aadhardCardNumberValidation = validationHelper.aadhar(
      formData.aadharNo
    );
    newFormError.aadharCardNumber = aadhardCardNumberValidation.message;

    //Family Validation
    let familyValidation = { isValid: 1 };
    if (formData.isAddUserToFamilyChecked) {
      familyValidation = validationHelper.required(formData.family);
      newFormError.family = familyValidation.message;
    } else {
      newFormError.family = "";
    }

    let panValidation = { isValid: true };
    if (!formData.pan_card_document) {
      panValidation = validationHelper.required(formData.panNo);
      newFormError.panNo = panValidation.message;
    }

    let aadhaarValidation = { isValid: true };
    if (!formData.aadhar_card_document) {
      aadhaarValidation = validationHelper.required(formData.aadharCard);
      newFormError.aadharCard = aadhaarValidation.message;
    }

    setFormError(newFormError);

    return (
      planValidation.isValid &&
      startDateValidation.isValid &&
      expiryDateValidation.isValid &&
      customerResidencyValidation.isValid &&
      customerCategoryValidation.isValid &&
      customerTypeValidation.isValid &&
      joiningDateValidation.isValid &&
      familyValidation.isValid &&
      panValidation.isValid &&
      aadhaarValidation.isValid &&
      panNumberValidation.isValid &&
      aadhardCardNumberValidation
    );
  };

  const handleSubmit = async () => {
    if (!handleValidation()) {
      return;
    }
    try {
      let panString, aadharString, signAgreementString;

      const getBase64String = async (file) => {
        if (Array.isArray(file)) {
          file = file[0];
        }
        const base64 = await fileToBase64(file);
        let fileType = file.type;

        if (fileType === "image/jpeg") {
          fileType = "application/jpeg";
        }

        if (fileType === "image/png") {
          fileType = "application/png";
        }

        return { base64, fileType };
      };

      if (formData.panNo) {
        const { base64, fileType } = await getBase64String(formData.panNo);
        panString = `data:${fileType};base64,${base64}`;
      }

      if (formData.aadharCard) {
        const { base64, fileType } = await getBase64String(formData.aadharCard);
        aadharString = `data:${fileType};base64,${base64}`;
      }

      if (formData.signAgreement) {
        const { base64, fileType } = await getBase64String(
          formData.signAgreement
        );
        signAgreementString = `data:${fileType};base64,${base64}`;
      }

      let planDetails;
      if (formData?.plan?.subCategories) {
        planDetails = formData.plan.subCategories.find(
          (p) => p.code == formData.code
        );
      }

      const payload = {
        userId: onBoardData ? onBoardData?.user_id : currentUserId,
        otherDetails: {
          plan_detail_id: planDetails?.plan_detail_id,
          plan_code: formData.code,
          plan_start_date: dayjs(formData.planStartDate).format("YYYY/MM/DD"),
          plan_expiry_date: dayjs(formData.planExpiryDate).format("YYYY/MM/DD"),
          customer_residency: formData.customerResidency,
          customer_category: formData.customerCategory,
          customer_type: formData.customerType,
          joining_date: dayjs(formData.joiningDate).format("YYYY/MM/DD"),
          is_family_group: formData.isAddUserToFamilyChecked,
          is_auto_trade: formData.isAutoCheck,
          pan_card: panString || "",
          aadhar_card: aadharString || "",
          pan_card_nbr: formData.panNumber,
          aadhar_card_nbr: formData.aadharNo,
          sign_agreement: signAgreementString || "",
        },
      };

      if (formData.isAddUserToFamilyChecked) {
        payload.otherDetails.family_id = formData.family.family_id;
      }

      console.log(payload, "payloadaddcustomer");
      const res = await submitOtherDetails(payload);
      if (res.data.status === 1) {
        handleStepChange(5);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadDocument = async ({ type, data }) => {
    const { user_document_id } = data;
    try {
      const payload1 = {
        documentId: user_document_id,
      };
      let res = await fetchProfileDoc(payload1, false);

      if (res.data.file) {
        const { file, filename } = res.data;
        await clickAndDownloadFile({ base64File: file, fileName: filename });
      }
    } catch (error) {
      console.error(
        "Error OtherDetailsContainer handleDownloadDocument:",
        error
      );
    }
  };

  const downloadAgreementTemplate = async () => {
    console.log("Download Agreement Clicked..");
    let userId = userDetails.user_id;
    let res = await fetchSignAgreementDoc({ userId });
    if (res.data.file) {
      const { file, filename } = res.data;
      await clickAndDownloadFile({ base64File: file, fileName: filename });
    }
  };

  return (
    <>
      <OtherDetails
        handleStepChange={handleStepChange}
        handleChange={handleChange}
        formData={formData}
        formError={formError}
        familyList={familyList?.data?.familygroups}
        planList={planList?.data?.plans}
        downloadDocument={handleDownloadDocument}
        handleSubmit={handleSubmit}
        customerSummary={customerSummary?.data}
        downloadAgreementTemplate={downloadAgreementTemplate}
      />
    </>
  );
}

export default OtherDetailsContainer;
