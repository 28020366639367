import React from 'react'
import CustomerDashboardContainer from 'containers/Customer/CustomerDashboardContainer'

function CustomerDashboard() {
  return (
    <>
    <CustomerDashboardContainer/>
    </>
  )
}

export default CustomerDashboard
