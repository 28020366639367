import React from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import { Dialog, Paper } from "@mui/material";
import Box from "components/common/Box";
import CheckedDialogIcon from "asset/icons/CheckedDialogIcon";
import Button from "components/common/Button";
import Image from "../../asset/icons/Finance_Leader";
import { useNavigate } from "react-router-dom";
import { commonActions } from "stores/redux/slices/commonSlice";
import { useDispatch } from "react-redux";
import { localStore } from "stores/localStore";

function CustomerIsPending({ onboardStatus }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    localStore.resetToken();
    dispatch(commonActions.logout());
    navigate("/");
  };

  console.log(onboardStatus, "STATUS");

  let text1 = "";
  let text2 = "";
  switch(onboardStatus){
    case "PENDING":
      text1 = `Thank you for your request. It has been submitted to our RIA team. Please await their response.`;
      text2 =`Your onboarding request has been submitted to the RIA (Registered Investment Advisor). Please be patient as they review your request. Thank you for choosing us for your investment journey!`
      break;
    case "REJECTED":
      text1 = `Your request has been rejected. Please contact your broker for further assistance.`;
      text2 = `Your onboarding request has been rejected, we appreciate your interest in our services. Please feel free to contact our customer support team for further assistance. Thank you for considering us for your investment journey.`;
      break;
    default:
  }
  return (
    <>
      <Dialog
        open={true}
        disableCloseIcon={true}
        enableBackdropClick={false}
        maxWidth="sm"
        PaperProps={{ style: { padding: "40px" } }} // Apply padding to the Paper container
      >
        <Stack
          direction="column"
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Image sx={{ width: "248px", height: "248px", mt: "-30px !important" }} />
          <Box mt="0px !important">
              <Text
                fontSize="20px"
                fontWeight="600"
                color={onboardStatus === "PENDING" ? "#242424" : "#f23711"}
                textAlign="center"
                width="600px"
              >
                {text1}
              </Text>
          </Box>

          <Box sx={{ width: "600px" }}>

              <Text
              fontSize="14px"
              fontWeight="400"
              color="#676C76"
              textAlign="center"
            >
              {text2}
            </Text>
            
          </Box>
          <Button
            sx={{ width: "100px", height: "50px", fontSize: "15px" }}
            onClick={handleLogout}
          >
            {" "}
            Log Out
          </Button>
        </Stack>
      </Dialog>
    </>
  );
}

export default CustomerIsPending;
