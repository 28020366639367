import React from "react";
import Paper from "components/common/Paper";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";

const TradeCards = ({ data }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        alignItems: "center",
        p: 2,
        width: "auto",
        backgroundColor: "white",
        border: "1px solid white",
        height: "90px",
      }}
    >
      <Stack direction="row">
        <Text
          sx={{
            fontSize: "14px",
            fontWeight: 600,
            color: "#242424",
          }}
        >
          {data?.name}
        </Text>
        {data?.chart && (
          <img
            src={data?.chart}
            alt="chart"
            style={{ height: "14px",
             marginLeft: "7px",
              marginTop: "4px",
             }}
          />
        )}

      </Stack>
      <Stack direction="row">
        <Stack
          direction="row"
          justifyContent={"space-between"}
          sx={{ marginTop: "22px" }}
        >
            <Stack  direction="row">
            <Box sx={{ display: "flex" }}>
              <Box>
                <Text
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color:"#676C76"
                  }}
                >
                  {data?.subheader1}
                </Text>

                <Text
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color:"#242424"
                  }}
                >
                  {data?.subheader1value}
                </Text>
              </Box>

              <Box>
                <Text
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color:"#676C76",
                    marginLeft:'34px'
                  }}
                >
                  {data?.subheader2}
                </Text>

                <Text
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    color:"#242424",
                    marginLeft:'34px'
                  }}
                >
                  {data?.subheader2value}
                </Text>
              </Box>
            </Box>

            <Text
              sx={{
                marginLeft:'-32px',
                marginTop:'5px',
                fontSize: "22px",
                fontWeight: 600,
              }}
            >
              {data?.value}
            </Text>
          </Stack>
          <Text
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              marginTop: "12px",
              marginLeft: "6px",
              textWrap: "nowrap",
              color: "#219653",
            }}
          >
            {data?.percentage}
          </Text>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default TradeCards;
