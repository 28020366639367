// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

// Create an API client named "Profile" using createApi function
const Profile = createApi({
  // Specify the name for the slice in the Redux store
  reducerPath: "Profile",

  // Use an interceptor for modifying API requests and responses
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,

  // Define tag types for cache invalidation
  tagTypes: ["AGREEMENT","GETPROFILE","GETDOCUMENT"],

  // Define API endpoints
  endpoints: (qb) => ({
    getProfileApi: qb.query({
      // Specify the API endpoint URL for fetching plans
      query: () => {
        return `api/ria`;
      },
      // Transform the API response to extract the 'result' property
      transformResponse: (response) => response.result,
      // Provide a tag for cache invalidation associated with this endpoint
      providesTags: ["GETPROFILE"],
    }),

    getProfileDocApi: qb.query({
      query: (payload) => {
        return `api/ria-doc/download?user_document_id=${payload.documentId}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETDOCUMENT"],
    }),

    getLazySignAgreementApi: qb.query({
      query: (payload) => {
        return `api/customer/sign-agreement/${payload.userId}`
      },
      transformResponse: (response) => response.result,
      providesTags: ["AGREEMENT"],
    }),
    
    updateProfileApi: qb.mutation({
        query: ({ email,phone,sebi_reg_no,basl_no,basl_expiry }) => ({
          url: `api/ria`,
          method: "PATCH",
          body: { email,phone,sebi_reg_no,basl_no,basl_expiry },
          invalidatesTags: ["GETPROFILE"],
        }),
      }),

      updateDocApi: qb.mutation({
        query: ({ document_id,userDocId,display_name,file}) => ({
          url: `api/ria-doc`,
          method: "PATCH",
          body: { 
            document_id,userDocId,display_name,file
           },
          invalidatesTags: ["GETDOCUMENT"],
        }),

      }),
  }),
});

// Export the reducer generated by the createApi function
export const ProfileApiReducer = Profile.reducer;

// Export an object containing middleware, reducer path, and a function for making API requests
export const profileApiAction = {
  middleware: Profile.middleware,
  reducerPath: Profile.reducerPath,
  getProfileApi: Profile.useLazyGetProfileApiQuery,
  getProfileDocApi: Profile.useGetProfileDocApiQuery,
  getLazyProfileDocApi: Profile.useLazyGetProfileDocApiQuery,
  getLazySignAgreementApi: Profile.useLazyGetLazySignAgreementApiQuery,
  updateProfileApi: Profile.useUpdateProfileApiMutation,
  updateDocApi:Profile.useUpdateDocApiMutation,
};
