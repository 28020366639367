import SvgIcon from "@mui/material/SvgIcon";

export default function SvgIconChildren(props) {
	return (
		<SvgIcon {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
			>
				<path
					d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
					stroke="#142E56"
					strokeWidth="1.67"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</SvgIcon>
	);
}
