import { useState, useEffect, useRef } from "react";

const useOtpTimer = (initialSeconds = 30, interval = 1000) => {
	const [seconds, setSeconds] = useState(initialSeconds);
	const [isActive, setIsActive] = useState(false);
	const timerRef = useRef(null);

	const startTimer = () => {
		setIsActive(true);
	};

	const resetTimer = () => {
		setIsActive(false);
		setSeconds(initialSeconds);
	};

	useEffect(() => {
		if (isActive) {
			timerRef.current = setInterval(() => {
				setSeconds((prevSeconds) => {
					if (prevSeconds === 1 || prevSeconds <= 0 ) {
						clearInterval(timerRef.current);
						resetTimer();
					}
					return prevSeconds - 1;
				});
			}, interval);
		} else {
			clearInterval(timerRef.current);
		}

		return () => clearInterval(timerRef.current);
	}, [isActive, interval]);

	return {
		seconds,
		isActive,
		startTimer,
		resetTimer,
	};
};

export default useOtpTimer;
