import React, { useState, useEffect } from "react";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import { Autocomplete, Box, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { useDispatch, useSelector } from "react-redux";
import EyeIcon from "asset/icons/EyeIcon";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "components/common/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { commonActions } from "stores/redux/slices/commonSlice";

function BrokerDetails() {
  const dispatch = useDispatch();
  const select = useSelector((state) => state);
  const user_id = select?.toast?.breadCrumbData?.user_id;
  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(true); // State to control password visibility
  const { data: brokerList = [], refetch: refetchBrokerList } =
    customersApiAction.getBrokerList();
  const { data: brokerDetail = {}, refetch: refetchBrokerDetails } =
    customersApiAction.getBrokerDetails({ user_id: userDetails.user_id }); // this is the API
  const [selectedBroker, setSelectedBroker] = useState({});
  const userDetail = useSelector((state) => state.commonReducer.userDetails); // from redux

  const [updateBrokerListApi, { isLoading: brokerUpdateLoading }] =
    customersApiAction.updateBrokerDetails(); // POst Api

  useEffect(() => {
    refetchBrokerList();
    refetchBrokerDetails({ user_id: userDetails.user_id });
  }, []);

  // Initial update of selectedBroker and formData
  useEffect(() => {
    if (Object.keys(brokerDetail).length !== 0 && brokerList.length > 0) {
      const matchingBroker = brokerList.find(
        (broker) => broker.broker_id === brokerDetail[0]?.broker_id
      );
      if (matchingBroker) {
        setSelectedBroker(matchingBroker);
      }
    }
  }, [brokerDetail, brokerList]);

  useEffect(() => {
    if (selectedBroker) {
      const {
        broker_name = "",
        broker_id = null,
        broker_auth_keys = [],
      } = selectedBroker || {};

      let matchBroker = false;
      if (brokerDetail && selectedBroker) {
        matchBroker = broker_id === brokerDetail[0]?.broker_id;
      }

      const formDataObj = {};
      if (broker_auth_keys && broker_auth_keys.length) {
        broker_auth_keys.forEach((v) => {
          formDataObj[v.key_name] = matchBroker
            ? brokerDetail[0][v.key_name]
            : "";
        });
        formDataObj.broker_name = broker_name;
        formDataObj.broker_id = broker_id;
        formDataObj.user_id = user_id;
      }
      setFormData(formDataObj);
    }
  }, [selectedBroker, brokerDetail]);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      const payload = {
        ...formData,
        user_id: userDetails.user_id,
        broker_name: selectedBroker.broker_name,
        noGlobalLoading: true,
      };
      const updatedRes = await updateBrokerListApi(payload);
      if (updatedRes && updatedRes.data && updatedRes.data.statusCode === 200) {
        setEditMode(false);
        refetchBrokerDetails();

        // set border missing flag to false.
        dispatch(
          commonActions.setUserDetails({
            ...userDetails,
            is_broker_missing: false,
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const formRenderData = selectedBroker?.broker_auth_keys || [];
  console.log("selectedBroker =>", selectedBroker);

  return (
    <Stack>
      <Text
        sx={{
          mt: "24px",
          fontSize: "14px",
          fontWeight: 400,
          color: "#676C76",
          mb: "10px",
        }}
      >
        Broker List
      </Text>
      <Autocomplete
        sx={{ width: "50%", mb: "20px" }}
        value={selectedBroker || {}}
        onChange={(e, v) => {
          console.log(v, "Onchange");
          setSelectedBroker(v || {});
        }}
        loading={loading}
        loadingText={"Loading..."}
        disablePortal
        id="combo-box-demo"
        options={brokerList}
        getOptionLabel={(option) => option.broker_name || ""}
        renderInput={(params) => (
          <TextField {...params} placeholder="Select a Broker from list" />
        )}
      />

      {selectedBroker && selectedBroker.broker_name ? (
        <>
          {/* Conditional rendering based on editMode */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Text
                sx={{
                  mb: "6px",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#242424",
                }}
              >
                Broker name :
              </Text>
              <Text
                sx={{
                  mt: "0px",
                  mb: "6px",
                  ml: "10px",
                  mr: "20px",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#242424",
                }}
              >
                {selectedBroker.broker_name}
              </Text>
              <Button
                onClick={togglePasswordVisibility}
                variant="outlined"
                size="small"
              >
                {passwordVisible ? (
                  <IconButton title="Tap to see the Keys">
                    <EyeIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                ) : (
                  <IconButton title="Tap to hide the Keys">
                    <VisibilityOffIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                )}
              </Button>
            </Box>
            {editMode ? (
              <Box sx={{ display: "flex" }}>
                <LoadingButton
                  color="secondary"
                  loading={brokerUpdateLoading}
                  loadingPosition="end"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={handleSaveClick}
                  sx={{
                    ml: "10px",
                    backgroundColor: "#142E56",
                    padding: "0px 18px",
                    color: "#FFF",
                    paddingRight: brokerUpdateLoading ? "40px" : "",
                    "&:hover": {
                      backgroundColor: "#142E56", // Same as the background color to disable hover effect
                    },
                  }}
                >
                  Save
                </LoadingButton>

                <Button
                  onClick={handleCancelClick}
                  variant="outlined"
                  sx={{ ml: "10px", width: "100px" }}
                >
                  Cancel
                </Button>
              </Box>
            ) : (
              <Button
                onClick={handleEditClick}
                variant="contained"
                sx={{ ml: "10px", width: "100px" }}
                loading={true}
              >
                Edit
              </Button>
            )}
          </Box>

          {/* Form Render */}
          <Grid container mx="-10px">
            {formRenderData.map((field, index) => {
              return (
                <Grid item sm={6} key={index}>
                  <Stack sx={{ px: "10px" }}>
                    <Text
                      sx={{
                        mt: "6px",
                        fontSize: "14px",
                        fontWeight: 500,
                        color: editMode ? "#242424" : "#808080",
                      }}
                    >
                      {field.display_field_name}
                    </Text>
                    <TextField
                      name={field.key_name}
                      value={formData[field.key_name]}
                      onChange={handleInputChange}
                      disabled={!editMode}
                      sx={{
                        mb: "20px",
                        ...(editMode ? {} : { color: "#a6a6a6" }),
                      }}
                      type={passwordVisible ? "password" : "text"}
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : null}
    </Stack>
  );
}

export default BrokerDetails;
