import { createApi } from "@reduxjs/toolkit/query/react";
import Customer from "pages/Customer";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const Customers = createApi({
  reducerPath: "Customers",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: ["CUSTOMERS"],
  endpoints: (qb) => ({
    getCustomerListApi: qb.mutation({
      query: (postData) => ({
        url: `api/customer/get-customers`,
        method: "POST",
        body: postData,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["CUSTOMERS"],
      }),
    }),
    getCustomerSearchingData: qb.mutation({
      query: (postData) => ({
        url: `api/customer/get-customers`,
        method: "POST",
        body: postData,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["CUSTOMERS"],
      }),
    }),
    getCustomerPlan: qb.query({
      query: () => {
        return `api/customer/plans`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["CUSTOMERS"],
    }),
    postCustomerEmailApi: qb.mutation({
      query: (formData) => ({
        url: `api/customer/send-email`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["CUSTOMERS"],
      }),
    }),
    getProductId: qb.query({
      query: (noGlobalLoading = true) => ({
        url: `api/customer/products`,
        params: { noGlobalLoading },
      }),
      transformResponse: (response) => response.result,
      providesTags: ["CUSTOMERS"],
    }),
    postImportFile: qb.mutation({
      query: (formData) => ({
        url: `api/customer/attach-customer-file`,
        method: "POST",
        body: formData,
        // headers: {
        //   "Content-type": "multipart/form-data",
        // },
      }),
    }),
    postAttachImportFile: qb.mutation({
      query: (data) => ({
        url: `api/customer/import-customer`,
        method: "POST",
        body: {
          customers: data.map((item) => ({
            name: item?.name,
            email: item?.email,
            mobile: item?.mobile,
            investmenttype: item?.investmenttype,
            plancode: item?.plancode,
            capital: item?.capital,
            riskprofile: item?.riskprofile,
          })),
        },
      }),
    }),
    updateCustomerApprove: qb.mutation({
      query: (payload) => {
        return {
          url: `api/customer/update-customer-approval/${payload.user_id}`,
          method: "POST",
          body: payload,
          // headers: {
          //   "Content-Type": "application/json",
          // },
        };
      },
    }),
    getCustomerDetail: qb.query({
      query: (user_id) => ({
        url: `api/customer/get-customer-details/${user_id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
        invalidatesTags: ["CUSTOMERS"],
      }),
    }),
    getFilterData: qb.query({
      query: (noGlobalLoading = true) => ({
        url: `api/customer/filters`,
        params: { noGlobalLoading },
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
        invalidatesTags: ["CUSTOMERS"],
      }),
      postDownloadDocument: qb.mutation({
        query: (user_id, data) => ({
          url: `api/customer/download-document/${user_id}`,
          method: "POST",
          body: {
            type: data.type,
          },
          // headers: {
          //   "Content-type": "application/json",
          // },
          invalidatesTags: ["CUSTOMERS"],
        }),
      }),
    }),
    postEmailApi: qb.mutation({
      query: (formData) => ({
        url: `api/customer/send-email`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["CUSTOMERS"],
      }),
    }),
    updateAutoTradeApi: qb.mutation({
      query: ({ user_id, auto_trade }) => {
        return {
          url: `api/customer/update-auto-trade/${user_id}`,
          method: "POST",
          body: auto_trade,
          headers: {
            "Content-Type": "application/json",
          },
        };
      },
    }),

    updateAdditionalDetailsApi: qb.mutation({
      query: ({ user_id, data }) => {
        // const documentPayload = data.stocks.map((doc) => ({
        //   document_id: doc.document_id,
        //   document_name: doc.document_name,
        //   document: doc.document,
        // }));
        return {
          url: `/customer/update-additional-detail/${user_id}`,
          method: "PATCH",
          body: {
            demat_acc_no: data.demat_acc_no,
            demat_acc_statement: data.demat_acc_statement,
            bank_name: data.bank_name,
            acc_no: data.acc_no,
            nominee_name: data.nominee_name,
            nominee_relationship: data.nominee_relationship,
            dob: data.dob,
            investment_policy_statement: data.investment_policy_statement,
            doc: data.stocks,
          },
        };
      },
    }),

    postProfileDocApi: qb.mutation({
      query: ({ user_id, type }) => {
        return {
          url: `api/customer/download-document/${user_id}`,
          method: "POST",
          body: {
            type: type,
          },
          headers: {
            "Content-Type": "application/json",
          },
          transformResponse: (response) => response.result,
          providesTags: ["POSTDOCUMENT"],
        };
      },
    }),
    getPlanDetails: qb.query({
      query: (plan_id) => {
        return `api/plan/${plan_id}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETPLAN"],
    }),
    postPlanDetails: qb.mutation({
      query: ({ user_id, payload }) => ({
        url: `api/customer/update-plan/${user_id}`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["UPDATECUSTOMERS"],
      }),
    }),
    updateExpiryDate: qb.mutation({
      query: ({ user_id, formData }) => ({
        url: `api/customer/update-expiry-date/${user_id}`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["EXPIRYCUSTOMERS"],
      }),
    }),
    postBlockCustomer: qb.mutation({
      query: (payload) => ({
        url: `api/customer/block`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["BLOCKCUSTOMERS"],
      }),
    }),
    getRiskProfile: qb.query({
      query: () => {
        return `api/customer/get-risk-profile`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETRISK"],
    }),
    updateRiskProfile: qb.mutation({
      query: ({ user_id, payload }) => ({
        url: `api/customer/update-manual-risk-profile/${user_id}`,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["UPDATECUSTOMERS"],
      }),
    }),

    getFamilyMembers: qb.query({
      query: (family_id) => {
        return `api/family/${family_id}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETFAMILY"],
    }),

    getFamilyApi: qb.query({
      query: () => {
        return `api/family/undefined`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETFAMILY"],
    }),

    updateFamilyAdmin: qb.mutation({
      query: ({ user_id, payload }) => ({
        url: `api/family/admin-status`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["UPDATEFAMILYADMIN"],
      }),
    }),
    postfamilyAdmin: qb.mutation({
      query: ({ name, users }) => ({
        url: `api/family`,
        method: "POST",
        body: {
          name: name,
          users: users, // Assuming users is a single user ID, wrap it in an array
        },
        invalidatesTags: ["NEW_FAMILY"],
      }),
    }),
    updateFamily: qb.mutation({
      query: ({ name, familyId, users }) => ({
        url: `api/family`,
        method: "PATCH",
        body: {
          name,
          familyId,
          users,
        },
        invalidatesTags: ["UPDATE_FAMILY"],
      }),
    }),

    // ------------Additional API----------------
    getAdditionalDetails: qb.query({
      query: (user_id) => {
        return `api/customer/get-additional-detail/${user_id}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETADDITIONAL"],
    }),
    postAdditionalDetails: qb.mutation({
      query: ({ user_id, postData }) => ({
        url: `api/customer/add-additional-detail/${user_id}`,
        method: "POST",
        body: postData,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["UPDATEFAMILYADMIN"],
      }),
    }),
    postAdditionalDetailsDoc: qb.mutation({
      query: ({ user_id, payload }) => ({
        url: `api/customer/add-additional-doc/${user_id}`,
        method: "PATCH",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["UPDATEADMIN"],
      }),
    }),

    // --------------------Additional Update API----------------
    updateAdditionalDocApi: qb.mutation({
      query: ({ user_id, payload }) => ({
        url: `api/customer/update-additional-detail/${user_id}`,
        method: "PATCH",
        body: payload,
        invalidatesTags: ["GETDOCUMENT"],
      }),
    }),

    //  --------------------Add money --------------------  //

    postAddMoney: qb.mutation({
      query: (payload) => ({
        url: `api/customer/add-money/${payload.user_id}`,
        method: "POST",
        body: {
          ...payload.investments,
        },
        // body: payload,
        // headers: {
        //   "Content-Type": "application/json",
        // },
        invalidatesTags: ["UPDATEADDMONEY"],
      }),
    }),

    updateAdmin: qb.mutation({
      query: ({ isAdmin, familyId, userId }) => ({
        url: `api/family/admin-status`,
        method: "PATCH",
        body: {
          isAdmin,
          familyId,
          userId,
        },
        invalidatesTags: ["UPDATE_ADMIN"],
      }),
    }),

    //  --------------------Manage cash --------------------  //

    postManageCash: qb.mutation({
      query: (payload) => ({
        url: `api/customer/transfer-cash`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["ManageCash"],
      }),
    }),

    //  --------------------investment Type --------------------  //
    updateInvestmentDetails: qb.mutation({
      query: (payload) => {
        return {
          url: `api/customer/update-investment-details/${payload.userId}`,
          method: "post",
          body: {
            ...payload.investmentDetails,
          },
        };
      },
    }),

    // ----------------------get-performance -------------------//
    getPerformanceApi: qb.query({
      query: (user_id) => {
        return `api/customer/get-performance/${user_id}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETPERFORMANCE"],
    }),

    // -------------------- get-performance-portfolio -------------//
    getPerformanceDetailApi: qb.query({
      query: ({ product_id, user_id, page, pageSize }) => {
        return `api/customer/get-performance-portfolio/${product_id}/${user_id}?page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETPERFORMANCEDETAIL"],
    }),

    // ------------- Summary detail Table ------------------------//
    getSummaryDetailApi: qb.query({
      query: ({ product_id, user_id, page, pageSize }) => {
        return `api/customer/get-performance-portfolio/${product_id}/${user_id}?page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.summaryDataCount,
      }),
      providesTags: ["GETPERFORMANCEDETAIL"],
    }),

    // --------------------family user list ------------------------ //
    getFamilyUsersList: qb.query({
      query: ({ family_id }) => {
        return `api/family-users-list?family_id=${family_id}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETFAMILYUSERLIST"],
    }),

    // ----------------- Post Api Trade detail ----------------//
    postTradesDetail: qb.mutation({
      query: (payload) => ({
        url: `api/trade/place-order`,
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
        invalidatesTags: ["Trades-PlaceOrder"],
      }),
    }),

    // ----------------- Detail Table ------------------ //
    getDetailTable: qb.query({
      query: ({ product_id, member_id, page, pageSize }) => ({
        url: `api/customer/gain_and_loss/years/details/${product_id}/${member_id}`,
        params: {
          page: page,
          take: pageSize,
        },
      }),
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: [`GET-Detail-Table`],
    }),

    // ----------------- Allocation Table ------------------ //
    getAllocationTable: qb.query({
      query: ({ user_id, product_id, member_id, page, pageSize }) => {
        return `api/customer/model/customer-allocation-details/${user_id}/${product_id}/${member_id}?page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: [`GET-Allocation-Table`],
    }),

    // -----------------Download Risk Assessment PDF------------------ //
    getCustomerSelectedAnswer: qb.query({
      query: ({ user_id }) => ({
        url: `api/customer/get-customer-selected-answer/${user_id}`,
        method: "GET",
        invalidatesTags: ["GETRISKSELETED"],
      }),
      transformResponse: (response) => response.result,
    }),

    getBrokerList: qb.query({
      query: () => {
        return `api/broker/get-broker-list`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETBROKERLIST"],
    }),

    // getBrokerDetails: qb.query({
    //   query: ({user_id}) => {
    //     return `api/customer/get-broker-details/${user_id}`;
    //   },
    //   transformResponse: (response) => {
    //     console.log(response,"RESPO"); // Log the response
    //     return response.result;
    //   },
    //   providesTags: ["GETBROKERDETAILS"],
    // }),

    getBrokerDetails: qb.query({
      query: ({ user_id, noGlobalLoading = true }) => ({
        url: `api/customer/get-broker-details/${user_id}`,
        params: { noGlobalLoading },
      }),
      transformResponse: (response) => {
        console.log(response, "RESPO"); // Log the response
        return response.result;
      },
      providesTags: ["GETBROKERDETAILS"],
    }),

    updateBrokerDetails: qb.mutation({
      query: ({
        user_id,
        broker_name,
        api_key,
        secret_key,
        login_id,
        access_token,
        noGlobalLoading = true,
      }) => {
        return {
          url: `api/customer/insert-broker-details`,
          method: "POST",
          body: {
            user_id,
            broker_name,
            api_key,
            secret_key,
            login_id,
            access_token,
            noGlobalLoading,
          },
          // params: { noGlobalLoading },
        };
      },
    }),
  }),
});

export const customersApiReducer = Customers.reducer;

export const customersApiAction = {
  middleware: Customers.middleware,
  reducerPath: Customers.reducerPath,
  getCustomerListApi: Customers.useGetCustomerListApiMutation,
  getCustomerSearchingData: Customers.useGetCustomerSearchingDataMutation,
  getProductId: Customers.useGetProductIdQuery,
  postCustomerEmailApi: Customers.usePostCustomerEmailApi,
  postImportFile: Customers.usePostImportFileMutation,
  postAttachImportFile: Customers.usePostAttachImportFileMutation,
  updateCustomerApprove: Customers.useUpdateCustomerApproveMutation,
  getCustomerDetail: Customers.useLazyGetCustomerDetailQuery,
  getSubscriptionPlan: Customers.useGetPlanCodeQuery,
  getCustomerPlan: Customers.useGetCustomerPlanQuery,
  getFilterData: Customers.useGetFilterDataQuery,
  postDownloadDocument: Customers.usePostDownloadDocumentMutation,
  postEmailApi: Customers.usePostEmailApiMutation,
  updateAutoTradeApi: Customers.useUpdateAutoTradeApiMutation,
  postProfileDocApi: Customers.usePostProfileDocApiMutation,
  getPlanDetails: Customers.useGetPlanDetailsQuery,
  postPlanDetails: Customers.usePostPlanDetailsMutation,
  getRiskProfile: Customers.useGetRiskProfileQuery,
  updateRiskProfile: Customers.useUpdateRiskProfileMutation,
  updateExpiryDate: Customers.useUpdateExpiryDateMutation,
  getFamilyMembers: Customers.useLazyGetFamilyMembersQuery,
  postBlockCustomer: Customers.usePostBlockCustomerMutation,
  getFamilyApi: Customers.useGetFamilyApiQuery,
  newFamily: Customers.useNewFamilyMutation,
  updateFamily: Customers.useUpdateFamilyMutation,
  getAdditionalDetails: Customers.useLazyGetAdditionalDetailsQuery,
  postAdditionalDetails: Customers.usePostAdditionalDetailsMutation,
  postAdditionalDetailsDoc: Customers.usePostAdditionalDetailsDocMutation,
  postAddMoney: Customers.usePostAddMoneyMutation,
  postManageCash: Customers.usePostManageCashMutation,
  updateInvestmentDetails: Customers.useUpdateInvestmentDetailsMutation,
  updateAdditionalDocApi: Customers.useUpdateAdditionalDocApiMutation,
  updateAdditionalDetailsApi: Customers.useUpdateAdditionalDetailsApiMutation,
  updateAdmin: Customers.useUpdateAdminMutation,
  getPerformanceApi: Customers.useLazyGetPerformanceApiQuery,
  getPerformanceDetailApi: Customers.useLazyGetPerformanceDetailApiQuery,
  getFamilyUsersList: Customers.useLazyGetFamilyUsersListQuery,
  postTradesDetail: Customers.usePostTradesDetailMutation,
  getDetailTable: Customers.useLazyGetDetailTableQuery,
  getAllocationTable: Customers.useLazyGetAllocationTableQuery,
  getSummaryDetailApi: Customers.useLazyGetSummaryDetailApiQuery,
  getCustomerSelectedAnswer: Customers.useLazyGetCustomerSelectedAnswerQuery,
  getBrokerList: Customers.useGetBrokerListQuery,
  getBrokerDetails: Customers.useGetBrokerDetailsQuery,
  updateBrokerDetails: Customers.useUpdateBrokerDetailsMutation,
};

export const {
  useCustomerListApiMutation,
  useGetProductIdQuery,
  useCustomerEmailApiMutation,
  usePostAttachImportFileMutation,
  useAttachImportFileMutation,
  useUpdateCustomerApproveMutation,
  useGetCustomerDetailQuery,
  useGetPlanCodeQuery,
  usePostCustomerEmailApi,
  usePostCustomerApiMutation,
  useGetBrokerListQuery,
} = Customers;
