import React from "react";
import { Dialog, Paper, Typography, Box, Button, Stack } from "@mui/material";
import Text from "components/common/Text";
import { warningTableRows, warningTableColumns } from "helpers/constants";
import CustomGrid from "components/datagrid/CustomGrid";
import { uuid } from "helpers/utility";

const WarningPopup = ({ message, onClose, apiResponse }) => {
  const modifiedTableData = apiResponse?.map((row) => ({
    ...row,
    id: uuid(),
  }));
  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{
        style: { padding: "10px", width: "1000px", borderRadius: "10px" },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            padding: "15px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Text sx={{ fontSize: "18px", fontWeight: "556" }}>
            Due to insufficient balance can not send call for below Customer
          </Text>
        </Box>
        <CustomGrid
          sx={{ width: "950px", Height: "600px" }}
          autoHeight
          list={modifiedTableData}
          columnHeaderHeight={46}
          rowHeight={46}
          columns={warningTableColumns}
          rowCount={warningTableRows.length}
          pagination={{ pageSize: 5 }}
          paginationModel={{ page: 0, pageSize: 5 }}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            sx={{
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
              m: 2,
              background: "#142E56",
              color: "#FFF",
              "&:hover": {
                background: "#142E56",
                color: "#FFF",
              },
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WarningPopup;
