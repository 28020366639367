import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const CustomerProfile = createApi({
    reducerPath: "CustomerProfile",
    baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
    tagTypes: [
        "GETCUSTOMERPROFILEAPI",
        "GETPROFILEDOCAPI"
    ],
    endpoints : (qb)=> ({

        getCustomerProfileApi: qb.query({
            query: ({user_id}) => {
              return `api/customer/portal/profile/${user_id}`;
            },
            transformResponse: (response) => response.customerProfile,
            providesTags: () => [
              { type: "GETCUSTOMERPROFILEAPI"},
            ],
          }),

          getProfileDocApi: qb.query({
            query: ({documentID}) => {
              return `api/customer/profile-doc/download?user_document_id=${documentID}`;
            },
            transformResponse: (response) => response.result,
            providesTags: () => [
              { type: "GETPROFILEDOCAPI"},
            ],
          }),

          updateProfileApi: qb.mutation({
            query: ({user_id,email,mobile,user_name,joining_date,user_investment_id,residency,customer_type,category,is_auto_trade,family_detail_id,family_id,family_name,is_admin,user_subscription_id,plan_id,plan_detail_id,start_date,end_date,risk_profile_id}) => ({
              url: `api/customer/portal/update-profile/${user_id}`,
              method: "PUT",
              body: {user_id,email,mobile,user_name,joining_date,user_investment_id,residency,customer_type,category,is_auto_trade,family_detail_id,family_id,family_name,is_admin,user_subscription_id,plan_id,plan_detail_id,start_date,end_date,risk_profile_id},
              invalidatesTags: ["GETPROFILE"],
            }),
          }),

    })
}) 

export const CustomerProfileApiReducer = CustomerProfile.reducer;

export const CustomerProfileApiAction = {
    middleware: CustomerProfile.middleware,
    reducerPath: CustomerProfile.reducerPath,
    getCustomerProfileApi : CustomerProfile.useGetCustomerProfileApiQuery,
    updateProfileApi : CustomerProfile.useUpdateProfileApiMutation,
    getProfileDocApi : CustomerProfile.useLazyGetProfileDocApiQuery
}