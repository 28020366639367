import React from "react";
import Paper from "components/common/Paper";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Box from "components/common/Box";
import { styled } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";

import _get from "lodash/get";

const BoxStyled = styled(Box)(({ theme }) => ({
  height: "14px",
  width: "14px",
  borderRadius: "50%",
  backgroundColor: "#9FC4FF",
}));

const InvestmentStats = ({ data, tradecallsstats=[] }) => {

  // Assign specific colors and radius to each segment

  let noofcalls = _get(tradecallsstats,"[0].noofcalls",0);
  let actiontaken = _get(tradecallsstats,"[0].actiontaken",0);

  const seriesData = [
    {
      value: noofcalls,
      label: "No of Calls",
      color: "#9FC4FF", // Specific color for "No of Calls"
      radius: 100, // Set radius for "No of Calls" segment
    },
    {
      value: actiontaken,
      label: "Action Taken",
      color: "#142E56", // Specific color for "Action Taken"
      radius: 120, // Set radius for "Action Taken" segment
    },
  ];

  return (
    <Paper
      elevation={1}
      sx={{
        alignItems: "center",
        p: 3,
        width: "auto",
        backgroundColor: "white",
        border: "1px solid white",
        height: "428px",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Text
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            color: "Black",
            marginBottom: "20px",
          }}
        >
          {data?.name}
        </Text>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <BoxStyled />
          <Text sx={{ ml: "8px", mr: "28px" }}>Number of calls</Text>
          <BoxStyled
            sx={{
              backgroundColor: "#142E56",
            }}
          />
          <Text sx={{ ml: "8px" }}>Action Taken</Text>
        </Box>
        <Stack flexDirection="row" justifyContent="center" marginTop="20px">
          <Box flexGrow={1}>
            <PieChart
              series={[{ data: seriesData }]}
              height={300}
              margin={{ right: 5 }}
              label={({ dataEntry }) =>
                `${dataEntry.label}: ${dataEntry.value}`
              } // Custom hover label
              labelPosition={60} // Adjust hover label position
            />
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};

export default InvestmentStats;
