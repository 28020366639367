import SvgIcon from "@mui/material/SvgIcon";

export default function DrawerIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99993 13.6092C8.00827 13.6092 6.3916 11.9926 6.3916 10.0009C6.3916 8.00924 8.00827 6.39258 9.99993 6.39258C11.9916 6.39258 13.6083 8.00924 13.6083 10.0009C13.6083 11.9926 11.9916 13.6092 9.99993 13.6092ZM9.99993 7.64258C8.69993 7.64258 7.6416 8.70091 7.6416 10.0009C7.6416 11.3009 8.69993 12.3592 9.99993 12.3592C11.2999 12.3592 12.3583 11.3009 12.3583 10.0009C12.3583 8.70091 11.2999 7.64258 9.99993 7.64258Z"
          fill="#242424"
        />
        <path
          d="M10.0004 17.5158C6.86706 17.5158 3.90872 15.6824 1.87539 12.4991C0.992057 11.1241 0.992057 8.88242 1.87539 7.49909C3.91706 4.31576 6.87539 2.48242 10.0004 2.48242C13.1254 2.48242 16.0837 4.31576 18.1171 7.49909C19.0004 8.87409 19.0004 11.1158 18.1171 12.4991C16.0837 15.6824 13.1254 17.5158 10.0004 17.5158ZM10.0004 3.73242C7.30872 3.73242 4.73372 5.34909 2.93372 8.17409C2.30872 9.14909 2.30872 10.8491 2.93372 11.8241C4.73372 14.6491 7.30872 16.2658 10.0004 16.2658C12.6921 16.2658 15.2671 14.6491 17.0671 11.8241C17.6921 10.8491 17.6921 9.14909 17.0671 8.17409C15.2671 5.34909 12.6921 3.73242 10.0004 3.73242Z"
          fill="#242424"
        />
      </svg>
    </SvgIcon>
  );
}
