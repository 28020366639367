import React from "react";
import { styled } from "@mui/system";
import Chip from "@mui/material/Chip";
import CustomGrid from "components/datagrid/CustomGrid";
import Stack from "components/common/Stack";
import Text from "components/common/Text";

const StyledDataGrid = styled(CustomGrid)(({ theme }) => ({
  "& .super-app-theme--approve": {
    backgroundColor: "lightgreen",
  },
  "& .super-app-theme--not-approve": {
    backgroundColor: "lightcoral",
  },
}));

const ChipStyle = styled(Chip)(({ theme, riskprofile, auto_trade }) => {
  let backgroundColor, color;

  if (riskprofile === "Aggressive") {
    backgroundColor = "#FFEDED";
    color = "#EB5757";
  } else if (riskprofile === "Moderate") {
    backgroundColor = "#FFF5E0";
    color = "#E58026";
  } else if (riskprofile === "Conservative") {
    backgroundColor = "#DEFFEC";
    color = "#219653";
  } else if (auto_trade === "ACTIVE") {
    backgroundColor = "#DEFFEC";
    color = "#219653";
  } else if (auto_trade === "INACTIVE") {
    backgroundColor = "#FFEDED";
    color = "#EB5757";
  }

  return {
    display: "flex",
    alignItems: "center",
    borderRadius: "152px",
    backgroundColor: backgroundColor,
    color: color,
    fontSize: "12px",
    padding: "8px",
    fontWeight: 500,
    "&::before": {
      position: "relative",
      left: 5,
      bottom: 6,
      content: '"\\2022"',
      width: "6px",
      height: "6px",
      color: color,
    },
  };
});
const ActiveChipStyle = styled(Chip)(({ theme, auto_trade }) => {
    let backgroundColor;
    let color;
    if (auto_trade === "ACTIVE") {
      backgroundColor = "#DEFFEC";
      color = "#219653";
    } else if (auto_trade === "INACTIVE") {
      backgroundColor = "#FFEDED";
      color = "#EB5757";
    }
  
    return {
      display: "flex",
      alignItems: "center",
      borderRadius: "152px",
      backgroundColor: backgroundColor,
      color: color,
      fontSize: "12px",
      padding: "8px",
      fontWeight: 500,
      "&::before": {
        position: "relative",
        left: 5,
        bottom: 6,
        content: '"\\2022"',
        width: "6px",
        height: "6px",
        color: color,
      },
    };
  });
const tableHeaderData = [
    {
      field: "investment_type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#6F6F6F"
              fontWeight="500"
              fontSize="16px"
              alignItems="textCenter"
            >
              {params?.row?.investment_type}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "name",
      headerName: "Customer Name",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          {params?.row?.onboard_status === "ACCEPTED" ? (
            <Text color="#242424" fontSize="14px" fontWeight="500">
              {" "}
              {params?.row?.name}
            </Text>
          ) : (
            <Text color="#242424" fontSize="14px" fontWeight="500">
              {params?.row?.name}
              {"*"}
            </Text>
          )}
        </Stack>
      ),
    },
    {
      field: "contactDetail",
      headerName: "Contact Detail",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.email}
            </Text>
            <Text
              color="#676C76"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
              marginTop="2px"
            >
              {params?.row?.mobile}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "capital",
      headerName: "Capital",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="center"
              style={{ cursor: "pointer" }}
            >
              ₹ {params?.row?.capital}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "leverage",
      headerName: "Leverage",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.leverage}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "current_value",
      headerName: "Current value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.current_value}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "riskFilter_selected",
      headerName: "Selected Risk Profile",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          {params?.row?.riskFilter_selected ? (
            <ChipStyle
              style={{ cursor: "pointer" }}
              label={params?.row?.riskFilter_selected}
              size="small"
              riskprofile={params?.row?.riskFilter_selected}
            />
          ) : (
            ""
          )}
        </Stack>
      ),
    },
 
    {
      field: "risk_profile",
      headerName: "Assessed Risk Profile",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          {params?.row?.risk_profile ? (
            <ChipStyle
              label={params?.row?.risk_profile}
              size="small"
              riskprofile={params?.row?.risk_profile}
            />
          ) : (
            ""
          )}
        </Stack>
      ),
    },
    {
      field: "auto_trade",
      headerName: "Auto Trade Status",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" textAlign="center">
          <ActiveChipStyle
            label={params?.row.auto_trade}
            size="small"
            auto_trade={params?.row?.auto_trade}
          />
        </Stack>
      ),
    },
  ];
  const graphCardData = [
    {
      id: 1,
      name: "Trade call stats",
      display: "column",
      xs: 6,
    },
  ];
  
  const graphCardData1 = [
    {
      id: 1,
      name: "Algo (F&O) progress",
      display: "column",
      xs: 6,
    },
    
  ];
export { StyledDataGrid, ChipStyle, tableHeaderData, graphCardData, graphCardData1 };
