import React, { useState } from "react";
import Grid from "@mui/system/Unstable_Grid";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Login from "components/Login/Login";
import Otp from "components/Login/Otp";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import SignUp from "components/Login/SignUp";

// Store
import { authApiAction } from "stores/redux/apiSlices/authApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import { localStore } from "stores/localStore";

// parser
import { commonParser } from "stores/redux/apiParser/commonParser";
import { commonActions } from "stores/redux/slices/commonSlice";
import { appConstants } from "helpers/constants/appConstants";


// Constant
const USER_ROLES = appConstants.userRoles;
const LoginContainer = () => {
  const [isOtpSend, setIsOtpSend] = useState(false);
  const [phoneorEmail, setPhoneorEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [sendOtpError, setSendOtpError] = useState("");
  const [signup, setSignup]= useState(false);
  const [otpError, setOtpError] = useState("");

  const [otpTimer, setOTPTimer] = useState(0);
  const [otpId, setOtpId] = useState(null);

  const [isOTPSent, setIsOTPSent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useNavigate hook

  const [handleSendOtpApi] = authApiAction.sendOtp();
  const [handleVerifyUser] = authApiAction.verifyUser();
  const [handleResendOtpApi] = authApiAction.resendOtp();
  const createOtpPayload = (input) => {
    let payload = {};
    let type;

    // Check if input contains only numbers (assumed as a mobile number)
    if (/^\d+$/.test(input)) {
      payload = {
        mobile: input,
        type: "mobile",
      };
      type = "mobile";
    } else {
      payload = {
        email: input,
        type: "email",
      };
      type = "email";
    }

    return { payload, type };
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    setPhoneorEmail(value);
  };

  const handleOtpChange = (otpValue) => {
    if (!isNaN(otpValue)) {
      // If it's a number and not empty, update the state
      setOtp(otpValue);
    }
  };

  const iconChange = () => {
    setIsOtpSend(false);
    setPhoneorEmail("");
  };

  const signupIsActive = ()=>{
    setSignup(true)
  }

  const handleLoginSubmit = () => {
    const { payload, type } = createOtpPayload(phoneorEmail);

    payload.otp = otp; // Add the OTP to the payload

    console.log("Login Submit Payload:", payload);

    handleVerifyUser(payload)
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);

        // Parse response
        const userDetails = commonParser.addUserDetails(response);

        if (!userDetails || !userDetails.user_id) {
          dispatch(toastActions.setToastData({message: "Something went wrong.", variant: "error"}));
          return;
        }
        
        // set user role code
        let userRoleCode;
        if(userDetails && userDetails.role_name){
          let roleName = userDetails.role_name.toLowerCase()
          switch(roleName){
            case "admin":
              userRoleCode = USER_ROLES.ADMIN.code;
              break;
            case "ria":
              userRoleCode = USER_ROLES.RIA.code;
              break;
            case "subbroker":
              userRoleCode = USER_ROLES.SUBBROKER.code;
              break;
            case "customer":
              userRoleCode = USER_ROLES.CUSTOMER.code;
              break;
          }
        }


        if (!userRoleCode) {
          dispatch(toastActions.setToastData({message: "User role not defined", variant: "error"}));
          return;
        }

          dispatch(commonActions.setUserDetails(userDetails));
          dispatch(commonActions.setUserRole(userRoleCode));
          dispatch(commonActions.setAuth(true));
          
          const userName = userDetails.name;

          // Store token and name in local storage
          localStore.setToken(userDetails.jwtToken);


          // Pass the user's name as a prop to the dashboard route
          navigate("/", { state: { userName } });
      })
      .catch((error) => {
        console.error("Error in API call:", error);

        // Log the complete error object for inspection
        console.log("Complete error object:", error);

        if (error && error.data && error.data.message) {
          setOtpError(error.data.message);
          dispatch(
            toastActions.setToastData({
              message: error.data.message,
              variant: "error",
            })
          );
        } else {
          // If no specific error message is available, use a generic one
          setOtpError("Failed to send OTP. Please try again.");
        }
      });
  };

  const handleSendOTP = () => {
    const { payload, type } = createOtpPayload(phoneorEmail);

    console.log(payload, "pay--------");

    handleSendOtpApi(payload)
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);
        if (response) {
          dispatch(
            toastActions.setToastData({
              message: response.message,
              variant: "error",
            })
          );

          // Log the message for further inspection
          console.log("Response Message:", response.message);

          // Check if the response message contains 'You will receive OTP shortly!'
          if (response.message.includes("You will receive OTP shortly!")) {
            console.log("Setting isOtpSend to true");
            setIsOtpSend(true);
          } else {
            console.log("Not moving to OTP screen. Message:", response.message);
          }
        } else {
          console.log("ID from API is missing or invalid:", response);
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);

        // Log the complete error object for inspection
        console.log("Complete error object:", error);

        if (error && error.data && error.data.message) {
          setSendOtpError(error.data.message);
        } else {
          // If no specific error message is available, use a generic one
          setSendOtpError("Failed to send OTP. Please try again.");
        }
      });
  };

  const handleResendOTP = () => {
    console.log("Box clicked!");
    setIsOTPSent(true);

    const { payload, type } = createOtpPayload(phoneorEmail);

    console.log(payload, "respay--------");
    handleResendOtpApi(payload)
      .unwrap()
      .then((response) => {
        console.log("API Response:", response);

        if (response) {
          dispatch(
            toastActions.setToastData({
              message: "OTP Sent Successfully",
              variant: "error",
            })
          );

          // Log the message for further inspection
          console.log("Response Messageeeeeeee:", response.message);

          // Check if the response message contains 'You will receive OTP shortly!'
          if (response.message.includes("OTP Sent Successfully")) {
            console.log("Setting isOtpSend to true");
            setIsOtpSend(true);
          } else {
            console.log("Not moving to OTP screen. Message:", response.message);
          }
        } else {
          console.log("ID from API is missing or invalid:", response);
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);
      });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ maxWidth: "100vw", m: 0, p: 0, overflow: "hidden" }}
    >
      <Grid item xs={6} sx={{ p: 0, m: 0 }}>
        <Box
          sx={{
            height: "100vh",
            background: "url(/images/loginImage.png)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Box sx={{ position: "absolute", top: "50px", left: "50px", gap: 8 }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 700,
                fontSize: 48,
                color: "white",
                lineHeight: "normal",
              }}
            >
              FundzUp
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                color: "white",
                fontSize: 20,
              }}
            >
              The most popular stock advisory platform.
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={6} sx={{ p: 0, m: 0 }}>
        {isOtpSend === true ? (
          <Otp
            onChange={handleOtpChange}
            onReset={iconChange}
            handleLoginSubmit={handleLoginSubmit}
            otp={otp}
            mobileNumberEmail={phoneorEmail}
            resendOtp={handleResendOTP}
            otpError={otpError}
          />
        ) : (
          <Login
            onChange={handlePhoneChange}
            phoneNo={phoneorEmail}
            handleSendOTP={handleSendOTP}
            sendOtpError={sendOtpError}
            signup={signupIsActive}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LoginContainer;
