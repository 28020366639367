import ErrorBoundary from "components/ErrorBoundary";
import DashboardAdminContainer from "containers/DashboardAdmin/DashboardAdminContainer";
import React from "react";

const DashboardAdmin = () => {
  return (
    <ErrorBoundary>
      <DashboardAdminContainer />
    </ErrorBoundary>
  );
};

export default DashboardAdmin;
