import _get from "lodash/get";
import { uuid } from "helpers/utility";


const tradeParser = {};

tradeParser.tradesListing = (data=[]) =>{
    let parsedData = data?.map((item) => {
        return {
          id: uuid(),
          customerName: _get(item, "customer_name", ""),
          stockName: _get(item, "stock_name", ""),
          quantity: _get(item, "quantity", ""),
          buyDate: _get(item, "buy_date", ""),
          sellDate: _get(item, "sell_date", ""),
          amountToInvest: _get(item, "amount_invested", ""),
          totalGains: _get(item, "total_gains", ""),
          amountAfterGains: _get(item, "amount_after_gains", ""),
          todaysChange: _get(item, "todays_change", ""),
          taxPl: _get(item, "tax_pl", ""),
          riskProfile: _get(item, "risk_profile", ""),
        };
      });
      return parsedData;
}

tradeParser.importTradeFile = (data=[]) =>{
  let parsedData = [];
  if(!data || !data.length){
    return parsedData;
  }
  parsedData = [...data];
  parsedData = parsedData.map((item) =>({
    action: _get(item, "action", ""),
    exchange: _get(item, "exchange", ""),
    expiry_date: _get(item, "expiry_date", ""),
    expiry_time: _get(item, "expiry_time", ""),
    is_active: _get(item, "is_active", ""),
    is_percent: _get(item, "is_percent", ""),
    is_stop_loss: _get(item, "is_stop_loss", ""),
    lot_size: _get(item, "lot_size", ""),
    message: _get(item, "message", ""),
    order_type: _get(item, "order_type", ""),
    percent: _get(item, "percent", ""),
    price: _get(item, "price", ""),
    product_id: _get(item, "product_id", ""),
    quantity: _get(item, "quantity", ""),
    status: _get(item, "status", 0),
    stop_loss: _get(item, "stop_loss", ""),
    symbol: _get(item, "symbol", ""),
    target: _get(item, "target", ""),
  }))
  
  // Sort error data to top
  parsedData.sort((a, b)=> a.status > b.status ? 1 : -1);
  return parsedData;
}

tradeParser.ProceedToImportTradeRes = (data=[])=>{
  let parsedData = [];
  if(!data || !data.length){
    return parsedData;
  }
  parsedData = data.map((item) =>({
    action: _get(item, "action", ""),
    company_id: _get(item, "company_id", ""),
    created_by: _get(item, "created_by", ""),
    created_date: _get(item, "created_date", ""),
    customer_id: _get(item, "customer_id", ""),
    customer_name: _get(item, "customer_name", ""),
    exchange: _get(item, "exchange", ""),
    expiry_date: _get(item, "expiry_date", ""),
    expiry_time: _get(item, "expiry_time", ""),
    amountToInvest: _get(item, "investment_amount", ""),
    is_active: _get(item, "is_active", ""),
    is_percent: _get(item, "is_percent", ""),
    is_stop_loss: _get(item, "is_stop_loss", ""),
    lot_size: _get(item, "lot_size", ""),
    order_type: _get(item, "order_type", ""),
    percent: _get(item, "percent", ""),
    price: _get(item, "price", ""),
    product_id: _get(item, "product_id", ""),
    quantity: _get(item, "quantity", ""),
    risk_profile_name: _get(item, "risk_name", ""),
    risk_profile_id: _get(item, "risk_profile_id", ""),
    stop_loss: _get(item, "stop_loss", ""),
    symbol: _get(item, "symbol", ""),
    target: _get(item, "target", ""),
    trade_id: _get(item, "trade_id", ""),
    updated_by: _get(item, "updated_by", ""),
    updated_date: _get(item, "updated_date", ""),
    user_id: _get(item, "user_id", ""),
  }))
  return parsedData;
}


export { tradeParser };