import React, { useState, useEffect } from "react";
import Stepper from "components/common/Stepper";
import Box from "components/common/Box";
import CustomerOtherDetailsContainer from "./CustomerOtherDetailsContainer";
import CustomerOnBoardingRiskAssesmentContainer from "./CustomerOnBoardingRiskAssesmentContainer";
import { styled } from "@mui/material";
import { customerOnBoardingSteps } from "helpers/constants";
// import CustomerRiskAssesmentContainer from "./CustomerRiskAssesmentContainer";
import CustomerMeter from "./CustomerMeter";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import { useNavigate } from "react-router-dom";

const StepperBackground = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.secondary.main,
	maxWidth: "calc(100% + 48px)",
	margin: "0 -24px",
	padding: "16px 94px",
}));

function CustomerOnBoardingContainer() {
  
  const [currentStep, setCurrentStep] = useState(1);

	const handleStepChange = (nextStep) => {
		setCurrentStep(nextStep);
	};

  const navigate = useNavigate();

  const [getCustomerDetails, {data: customerData, isFetching}] = addCustomerApiAction.getCustomerSummary();
  const userDetails = useSelector((state) => state.commonReducer.userDetails);
  console.log("userDetails",userDetails)

  useEffect(() =>{
    getCustomerDetails({ userId: userDetails.user_id }, false);
  },[]);

  useEffect(() =>{
    let step = _get(customerData, "customerDetails.current_stage", null);
    if(step){
      setCurrentStep(step);
    }
    if(step === 4){
      //NOTE: need to call user details to update store data here.
      navigate("/customer/dashboard");
    }
  },[customerData]);


  
  console.log("Current Steps =>", currentStep);

  return (
    <Box sx={{backgroundColor:"#F7F8FF",height:"100vh"}}>
    {currentStep <= 3 ? (
				<>
        
					<StepperBackground>
						<Stepper stepperData={customerOnBoardingSteps} currentStepsId={currentStep} />
					</StepperBackground>
					<Box >
            {currentStep === 1 && (
              <>
                <CustomerOnBoardingRiskAssesmentContainer
                  handleStepChange={handleStepChange}
                  currentStep={currentStep}
                />
              </>
            )}
            {currentStep === 2 && (
              <CustomerOtherDetailsContainer handleStepChange={handleStepChange} />
            )}
              {currentStep === 3 && (
              <CustomerMeter handleStepChange={handleStepChange} />
            )}
          </Box>
				</>
			) : ( 
				null
			)}
    
    </Box>
  )
}

export default CustomerOnBoardingContainer
