import CircularProgress from "@mui/material/CircularProgress";
import Stack from "./Stack";

export default function Loader() {
	return (
		<Stack
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				zIndex: 2000,
				backdropFilter: "blur(2px)",
				backgroundColor: "rgba(255, 255, 255, 0.5)",
			}}
			alignItems="center"
			justifyContent="center"
		>
			<CircularProgress />
		</Stack>
	);
}
