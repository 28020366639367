import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import Link from "components/common/Link";
import Featured from "asset/icons/Featured.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";
import Divider from "components/common/Divider";
import Button from "components/common/Button";
import Avatar from "components/common/Avatar";
import Dialog from "components/common/Dialog";
import Checkbox from "components/common/Checkbox";
import { toastActions } from "stores/redux/slices/toastSlice";

const ViewMember = ({
  viewCustomerDetail,
  closeFamilyDrawer,
  isFamilyDrawerOpen,
}) => {
  const [openModel, setOpenModel] = useState(false);
  const [selectAdminModel, setSelectAdminModel] = useState(false);
  const dispatch = useDispatch();
  const select = useSelector((state) => state);
  const family_id = viewCustomerDetail?.customerDetails[0]?.family_id;

  console.log("select check for header for family member ", family_id);

  const [getFamilyMemberDetails, familyMember] =
    customersApiAction.getFamilyMembers();



  const [handleUpdateAdminApi] = customersApiAction.updateAdmin();

  useEffect(() => {
    getFamilyMemberDetails(family_id);
  }, [family_id]);

  const handleOkay = () => {
    setOpenModel(false);
    setSelectAdminModel(true);
  };

  const handleAgree = () => {
    setSelectAdminModel(false);
  };

  const handleCancel = () => {
    setOpenModel(false);
    setSelectAdminModel(false);
  };
  const handleUpdateAdmin = async (member) => {
    const payload = {
      isAdmin: member.is_admin ? 0 : 1,
      familyId: family_id,
      userId: member.user_id,
    };

    try {
      let res = await handleUpdateAdminApi(payload);

      if (res) {
        const toastMessage =
          res.data.status === 1 ? "Admin Updated Successfully" : res.message;

        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: res.data.status === 1 ? "success" : "error",
          })
        );

        if (res.data.status === 1) {
          getFamilyMemberDetails(family_id);
        }
      }
    } catch (error) {
      console.error("Error in API call:", error);
      console.log("Complete error object:", error);
    }
  };

  //     familyId: family_id,
  //     userId: member.user_id,
  //   };

  //   console.log("handleUpdateAdminnnnnnnnnnnnnnnn", payload);

  //   try {
  //     let res = await handleUpdateAdminApi(payload);

  //     if (res) {
  //       const toastMessage =
  //         res.data.status === 1 ? "Admin Updated Successfully" : res.message;

  //       dispatch(
  //         toastActions.setToastData({
  //           message: toastMessage,
  //           variant: res.data.status === 1 ? "success" : "error",
  //         })
  //       );
  //     }
  //     if (res.data.status === 1) {
  //       setSelectedFamilyName(res.data.name);

  //     }
  //   } catch (error) {
  //     console.error("Error in API call:", error);
  //     console.log("Complete error object:", error);
  //   }
  // };

  return (
    <Stack>
      {isFamilyDrawerOpen && (
        <SideDrawer
          open={isFamilyDrawerOpen}
          closeDrawer={closeFamilyDrawer}
          title={viewCustomerDetail?.customerDetails[0]?.family_name}
          subtitle="Here you can see all the detail of family member in this family."
          handleSubmit={closeFamilyDrawer}
          submitButtonText="Done"
          showPrimaryButton={true}
          showSecondaryButton={false}
        >
          {(familyMember?.data ?? []).length > 0 &&
            familyMember?.data[0]?.family_details.map((member, index) => (
              <React.Fragment key={index}>
                <Box>
                  <Box>
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Text sx={{ fontSize: "16px", color: "#676C76" }}>
                          Name
                        </Text>
                        <Text sx={{ ml: 2.5 }}>:</Text>
                        <Text
                          sx={{
                            fontSize: "16px",
                            color: "#242424",
                            width: "50%",
                          }}
                        >
                          {member?.name ?? "durgesh"}
                        </Text>
                      </Box>

                      {member?.is_admin === 1 ? (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Text>
                              <Chip
                                label="Admin"
                                size="small"
                                sx={{
                                  fontSize: "14px",
                                  color: "#142E56",
                                  backgroundColor: "#E5EEFF",
                                }}
                              />
                            </Text>
                            <Box>
                              <Link
                                href="#"
                                onClick={() => {
                                  handleUpdateAdmin(member);
                                }}
                                sx={{
                                  color: "#EB5757 ",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                }}
                              >
                                {console.log("member : ", member)}
                                Remove admin
                              </Link>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text></Text>
                          <Link
                            href="#"
                            onClick={() => {
                              handleUpdateAdmin(member);
                            }}
                            sx={{
                              color: "#1A54B9",
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            {console.log("member : ", member)}
                            {member.isAdmin ? "Remove admin" : "Make Admin"}
                          </Link>
                        </Box>
                      )}
                    </Stack>
                  </Box>

                  <Box sx={{ display: "flex", mt: "12px" }}>
                    <Text sx={{ fontSize: "16px", color: "#676C76" }}>
                      Email <span style={{ marginLeft: "42px" }}>:</span>
                    </Text>
                    <Text
                      sx={{ fontSize: "16px", color: "#242424", ml: "20px" }}
                    >
                      {member.email}
                    </Text>
                  </Box>

                  <Box sx={{ display: "flex", mt: "12px" }}>
                    <Text sx={{ fontSize: "16px", color: "#676C76" }}>
                      Phone <span style={{ marginLeft: "36px" }}>:</span>
                    </Text>
                    <Text
                      sx={{ fontSize: "16px", color: "#242424", ml: "20px" }}
                    >
                      {member.mobile}
                    </Text>
                  </Box>
                </Box>

                <Divider />
              </React.Fragment>
            ))}

          <Box
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              position: "absolute",
              bottom: "90px",
              right: "10px",
            }}
          ></Box>
        </SideDrawer>
      )}
      <Dialog
        onClose={() => setOpenModel(false)}
        open={openModel}
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack
            direction="column"
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Avatar
              style={{ width: "50px", height: "50px" }}
              src={Featured}
            ></Avatar>
            <Text fontSize="20px" fontWeight="600" color="#101828" p={1}>
              Are you sure you want to remove this user?
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#667085"
              marginTop="-10px"
            >
              By clicking on yes you are making sure that you want to remove
              this <br /> user permanently from this family member.
            </Text>
            <Stack direction="row" style={{ padding: "0px", width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  width: "100%",
                  p: 2,
                  m: 2,
                }}
                onClick={handleCancel}
              >
                No
              </Button>
              <Button
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                  width: "100%",
                  m: 2,
                }}
                onClick={handleOkay}
              >
                Yes ,remove
              </Button>
            </Stack>
          </Stack>
        )}
      />
      <Dialog
        onClose={() => setSelectAdminModel(false)}
        open={selectAdminModel}
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack
            direction="column"
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Text fontSize="20px" fontWeight="600" color="#101828">
              Do you want to make anyone else an admin?
            </Text>
            <Stack direction="row" justifyContent="space-between" width="80%">
              <Checkbox
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text
                      variant="small"
                      fontSize="16px"
                      fontWeight="400"
                      style={{ marginLeft: "8px", marginTop: "-5px" }}
                    >
                      Jeetu Gupta
                    </Text>
                  </Box>
                }
                size="small"
                name="sendToCustom"
                style={{ marginLeft: "8px", marginTop: "-5px" }}
              />
              <Checkbox
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text
                      variant="small"
                      fontSize="16px"
                      fontWeight="400"
                      style={{ marginLeft: "8px", marginTop: "-5px" }}
                    >
                      Aditya Gupta
                    </Text>
                  </Box>
                }
                size="small"
                name="sendToModelPortfolio"
                style={{ marginTop: "-5px" }}
              />
            </Stack>
            <Stack direction="row" justifyContent="space-between" width="80%">
              <Checkbox
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text
                      variant="small"
                      fontSize="16px"
                      fontWeight="400"
                      style={{ marginLeft: "8px", marginTop: "-5px" }}
                    >
                      Jeetu Gupta
                    </Text>
                  </Box>
                }
                size="small"
                name="sendToCustom"
                style={{ marginLeft: "8px", marginTop: "-5px" }}
              />
              <Checkbox
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Text
                      variant="small"
                      fontSize="16px"
                      fontWeight="400"
                      style={{ marginLeft: "8px", marginTop: "-5px" }}
                    >
                      Aditya Gupta
                    </Text>
                  </Box>
                }
                size="small"
                name="sendToModelPortfolio"
                style={{ marginTop: "-5px" }}
              />
            </Stack>

            <Stack direction="row" style={{ padding: "0px", width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  width: "100%",
                  p: 2,
                  m: 2,
                }}
                onClick={handleAgree}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                  width: "100%",
                  m: 2,
                }}
              >
                Done
              </Button>
            </Stack>
          </Stack>
        )}
      />
    </Stack>
  );
};

export default ViewMember;
