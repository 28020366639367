import React from 'react'
import CustomerReportsContainer from 'containers/Customer/CustomerReportsContainer'

function CustomerReports() {
  return (
    <>
    <CustomerReportsContainer/>
    </>
  )
}

export default CustomerReports
