import React, { useEffect, useState } from "react";
import SideDrawer from "components/common/SideDrawer";
import Box from "components/common/Box";
import Text from "components/common/Text";
import AllocationPieChart from "components/common/AllocationPieChart";
import { modelPortFolioApiAction } from "stores/redux/apiSlices/modelPortfolio/modelPortfolio";

const StockAllocationSidebar = ({
  open,
  closeDrawer,
  allocationPercentGraphData,
  risk_profile_id,
}) => {
  const [investmentSummary, setInvestmentSummary] = useState({
    invested_amt: 0,
    remaining_cash: 0,
    total_cash: 0,
  });

  const { data: modelPortFolioData = {}, refetch: getInvestmentSummary } =
    modelPortFolioApiAction.getInvesmentSummaryApi({
      riskProfileID: risk_profile_id,
    });

  useEffect(() => {
    if (risk_profile_id) {
      getInvestmentSummary({ riskProfileID: risk_profile_id });
    }
  }, [risk_profile_id, getInvestmentSummary]);

  useEffect(() => {
    if (modelPortFolioData.length > 0) {
      const { invested_amt, remaining_cash, total_cash } =
        modelPortFolioData[0];
      setInvestmentSummary({
        invested_amt,
        remaining_cash,
        total_cash,
      });
    }
  }, [modelPortFolioData]);

  const { invested_amt, remaining_cash, total_cash } = investmentSummary;
  const investedPercentage = (invested_amt / total_cash) * 100;
  const maxInvestablePercentage = 100;
  const remainingInvestablePercentage = maxInvestablePercentage - investedPercentage;

  return (
    <SideDrawer
      open={open}
      closeDrawer={closeDrawer}
      drawerWidth="800"
      bottomSpace={0}
      sx={{ marginBottom: "0px" }}
      title={
        <div
          style={{
            color: "#FFFFFF",
            fontSize: "24px",
            fontWeight: 600,
            marginLeft: "10px",
            marginBottom: "0px",
          }}
        >
          Summary of High Risk Stocks
        </div>
      }
      subtitle={
        <div
          style={{
            color: "#FFFFFF",
            fontSize: "14px",
            fontWeight: 400,
            marginLeft: "10px",
            marginBottom: "0px",
          }}
        >
          Here you can see all the details regarding high risk stocks.
        </div>
      }
      contentTitle=""
      showPrimaryButton={false}
      showSecondaryButton={false}
    >
      <Box
        sx={{
          backgroundColor: "#EBFFF3",
          borderRadius: "8px",
          padding: "12px 16px",
          width: "728px",
        }}
      >
        <Text sx={{ fontSize: "14px", color: "#219653", fontWeight: 500 }}>
          The asset allocation of the portfolio is made by considering the
          complete portfolio worth ₹ 5 Cr
        </Text>
      </Box>

      <Box mt={2} mb={2}>
        <AllocationPieChart data={allocationPercentGraphData} />
      </Box>

      <Box
        sx={{
          backgroundColor: "#F7F8FF",
          borderRadius: "16px",
          padding: "17px",
          width: "728px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#EBEDF7",
            padding: "17px",
            borderRadius: "8px",
            width: "calc(33.33% - 10px)", // Adjusted width for "Total Capital"
          }}
        >
          <Text variant="body1" sx={{ fontWeight: 500 }}>
            Total Capital
          </Text>
          <Text variant="body1">{total_cash}</Text>
        </Box>

        <Box
          sx={{
            backgroundColor: "#EBEDF7",
            padding: "17px",
            borderRadius: "8px",
            width: "calc(33.33% - 10px)", // Adjusted width for "Invested Amount"
          }}
        >
          <Text variant="body1" sx={{ fontWeight: 500 }}>
            Invested Amount
          </Text>
          <Text variant="body1">{invested_amt}</Text>
        </Box>

        <Box
          sx={{
            backgroundColor: "#EBEDF7",
            padding: "17px",
            borderRadius: "8px",
            width: "calc(33.33% - 10px)", // Adjusted width for "Remaining Amount"
          }}
        >
          <Text variant="body1" sx={{ fontWeight: 500 }}>
            Remaining Amount
          </Text>
          <Text variant="body1">{remaining_cash}</Text>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#F7F8FF",
          borderRadius: "16px",
          padding: "17px",
          width: "728px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#EBEDF7",
            padding: "17px",
            borderRadius: "8px",
            width: "calc(50% - 10px)", // Adjusted width for "Invested Percentage"
          }}
        >
          <Text variant="body1" sx={{ fontWeight: 500 }}>
            You have till Invested (%)
          </Text>
          <Text variant="body1">{investedPercentage.toFixed(2)}%</Text>
        </Box>

        <Box
          sx={{
            backgroundColor: "#EBEDF7",
            padding: "17px",
            borderRadius: "8px",
            width: "calc(50% - 10px)", // Adjusted width for "Remaining Investable Percentage"
          }}
        >
          <Text variant="body1" sx={{ fontWeight: 500 }}>
            You can still invest up to (%)
          </Text>
          <Text variant="body1">{remainingInvestablePercentage.toFixed(2)}%</Text>
        </Box>
      </Box>
    </SideDrawer>
  );
};

export default StockAllocationSidebar;
