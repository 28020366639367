import { useState, useEffect, useMemo } from "react";
import { Autocomplete, FormControl } from "@mui/material";
import { ButtonGroup, styled } from "@mui/material";
import Box from "components/common/Box";
import Text from "components/common/Text";
import Button from "components/common/Button";
import TextField from "components/common/TextField";
import DatePicker from "components/common/DatePicker";
import Stepper from "components/common/Stepper";
import Radio from "components/common/Radio";
import Checkbox from "components/common/Checkbox";
import TimePicker from "components/common/TimePicker";
import MultiSelect from "components/common/MultiSelect";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import { useTheme } from "@mui/material/styles";
import Stack from "components/common/Stack";
import dayjs from "dayjs";
import { dividendApiAction } from "stores/redux/dividend/dividendApiSlice";
import useDebounce from "hooks/Debounce";
import { getSearchedFilterData } from "helpers/utility";
import { validationHelper } from "helpers/validation";

const CapitalButton = styled(Button)(({ theme, selected }) => ({
  padding: "8px 16px",
  borderRadius: "8px",
  width: "128px",
  backgroundColor: selected ? "#219653" : "#F8F8F8",
  color: selected ? "#FFFFFF" : "#B3B3B3",
  "&:hover": {
    backgroundColor: selected ? "#219653" : "#F8F8F8",
    color: selected ? "#FFFFFF" : "#B3B3B3",
  },
}));

const CapitalButtonSmall = styled(Button)(({ theme, selected }) => ({
  borderRadius: "8px",
  width: "44px",
  marginTop: "27px",
  height: "23px",
  backgroundColor: selected ? "#219653" : "#F8F8F8",
  color: selected ? "#FFFFFF" : "#B3B3B3",
  "&:hover": {
    backgroundColor: selected ? "#219653" : "#F8F8F8",
    color: selected ? "#FFFFFF" : "#B3B3B3",
  },
}));

const CapitalButtonSmallTarget = styled(Button)(({ theme, selected }) => ({
  borderRadius: "8px",
  width: "44px",
  marginTop: "20px",
  height: "23px",
  backgroundColor: selected ? "#219653" : "#F8F8F8",
  color: selected ? "#FFFFFF" : "#B3B3B3",
  "&:hover": {
    backgroundColor: selected ? "#219653" : "#F8F8F8",
    color: selected ? "#FFFFFF" : "#B3B3B3",
  },
}));

const TextStyled = styled(Text)(({ theme }) => ({
  fontSize: "14px",
  color: "#242424",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "14px",
}));

const OptionsTextStyled = styled(Text)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  marginTop: "20px",
  marginBottom: "6px",
}));

const CheckedTextStyled = styled(Text)(({ theme }) => ({
  marginTop: "24px",
  marginBottom: "6px",
  fontSize: "14px",
  fontWeight: 500,
  color: "#242424",
}));

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: "12px 16px",
  marginTop: "24px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  flex: "1 0 0",
  borderRadius: "8px",
  border: "1px solid var(--Blue-blue, #1A54B9)",
  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  cursor: "pointer",
}));

const tradeSteps = [
  {
    id: 1,
    label: "Basic details",
  },
  {
    id: 2,
    label: "Trade details",
  },
];

const AddTradeDrawer = (props) => {
  //Props
  const {
    currentStep,
    primaryAction,
    handleSubmit,
    cancelButtonText,
    submitButtonText,
    PRODUCT_ID, // product code for custom CUSTOM=1, MODEL_PORTFOLIO=2, ALGO=3
    stateValues,
  } = props;

  //States
  const [formData, setFormData] = useState({
    stockName: null,
    index: "NSE",
    tradeType: null,
    buySellType: "QUANTITY",
    quantity: null,
    percentage: null,
    orderType: "MARKET",
    price: null,
    isTargetChecked: true,
    isStopLossChecked: true,
    is_stop_loss: false,

    is_target: false,
    stopLossPerc: null,
    target: null,
    maxBuySellDate: null,
    maxBuySellTime: null,
    isHighRiskChecked: false,
    isModerateRiskChecked: false,
    isLowRiskChecked: false,
    selectedHighRiskCustomers: [],
    selectedMediumRiskCustomers: [],
    selectedLowRiskCustomers: [],
    isCustomersSelected: true,
    selectedCustomers: [],
    isRiskProfileSelected: PRODUCT_ID == 1 ? true : false, // only for Custom
    highRiskSearch: null,
    mediumRiskSearch: null,
    lowRiskSearch: null,
    customerSearch: null,
    currentRiskProfileId: null,
  });
  // Risk Profile wise customer
  const [highRiskCustomers, setHighRiskCustomers] = useState();
  const [lowRiskCustomer, setLowRiskCustomer] = useState();
  const [medRiskCustomer, setmedRiskCustomer] = useState();

  const [formError, setFormError] = useState({
    stockName: null,
    index: null,
    tradeType: null,
    buySellType: null,
    quantity: null,
    percentage: null,
    orderType: null,
    price: null,
    isStopLossChecked: false,
    isTargetChecked: false,
    stopLossPerc: null,
    target: null,
    maxBuySellDate: null,
    maxBuySellTime: null,
  });

  //hooks
  const theme = useTheme();

  //apis
  // const [getHighRiskCustomersApi, { data: highRiskCustomers }] =
  //   tradesApiAction.getHighCustomersApi();
  // let highRiskCustomers =[]
  // const [getModerateRiskCustomersApi, { data: moderateRiskCustomers }] =
  //   tradesApiAction.getModerateCustomersApi();
  // const [getLowRiskCustomersApi, { data: lowRiskCustomers }] =
  //   tradesApiAction.getLowCustomersApi();

  const [
    getAllCustomersApi,
    { data: allCustomers, isFetching: customerListisFetching },
  ] = tradesApiAction.getAllCustomersApi();
  const [
    getAllStockName,
    { data: companyData, isFetching: StockListisFetching },
  ] = tradesApiAction.getStockListApi();
  const [
    getAllStockNameFno,
    { data: companyDataFno, isFetching: StockListFnoisFetching },
  ] = tradesApiAction.getStockListFnoApi();

  //useEffects

  const [formDataSearch, setFormDataSearch] = useState("");

  const debouncedSearchValue = useDebounce(formDataSearch, 700);

  const [selectedCurrency, setSelectedCurrency] = useState("%");

  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
    let newFormData = { ...formData };

    setFormData(newFormData);
  };

  useEffect(() => {
    let payload = {
      search: debouncedSearchValue,
      exchange: formData.index,
      noGlobalLoading: true,
    };
    if (PRODUCT_ID == 3) {
      getAllStockNameFno(payload);
    } else {
      getAllStockName(payload);
    }
  }, [debouncedSearchValue, formData.index]);

  useEffect(() => {
    if (PRODUCT_ID == 1) {
      // call only for custom trade
      // getHighRiskCustomersApi();
      // getModerateRiskCustomersApi();
      // getLowRiskCustomersApi();
    }
    if (currentStep == 2) {
      getAllCustomersApi({
        product_id: PRODUCT_ID,
        exchange: formData.index,
        symbol: formData.stockName,
        ordertype: formData.tradeType,
        noGlobalLoading: true,
      });
    }

    // val update
    if (allCustomers && allCustomers.length) {
      // debugger;
      const hightRiskCust = allCustomers.filter((c) => c.risk_profile_id == 1);
      setHighRiskCustomers(hightRiskCust);

      const medRiskCustomer = allCustomers.filter(
        (c) => c.risk_profile_id == 2
      );
      setmedRiskCustomer(medRiskCustomer);

      const lowRiskCustomer = allCustomers.filter(
        (c) => c.risk_profile_id == 3
      );
      setLowRiskCustomer(lowRiskCustomer);
    }
  }, [allCustomers, currentStep]);

  //handler Methods
  const handleFormChange = ({ name, value }) => {
    let newFormData = { ...formData };
    if (name == "index") {
      setFormDataSearch("");
    }

    newFormData[name] = value;
    setFormData(newFormData);
  };

  const handleRiskTypeChange = ({ name, value, riskProfileId }) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    newFormData["currentRiskProfileId"] = riskProfileId;
    setFormData(newFormData);
  };

  const handleValidation = () => {
    const newFormError = { ...formError };

    const stockNameValidation = validationHelper.required(formData.stockName);
    newFormError.stockName = stockNameValidation.message;

    const orderTypeValidation = validationHelper.required(formData.tradeType);
    newFormError.tradeType = orderTypeValidation.message;

    const quantityValidation = validationHelper.required(formData.quantity);
    newFormError.quantity = quantityValidation.message;

    const dateTillStockValidation = validationHelper.required(
      formData.maxBuySellDate
    );
    newFormError.maxBuySellDate = dateTillStockValidation.message;

    const TimeTillStockValidation = validationHelper.required(
      formData.maxBuySellTime
    );
    newFormError.maxBuySellTime = TimeTillStockValidation.message;
    setFormError(newFormError);

    return (
      stockNameValidation.isValid &&
      orderTypeValidation.isValid &&
      quantityValidation.isValid &&
      dateTillStockValidation.isValid &&
      TimeTillStockValidation.isValid
    );
  };
  // Constant values
  const filteredHighRiskCustomers = getSearchedFilterData(
    highRiskCustomers,
    formData.highRiskSearch
  );

  const filteredModerateRiskCustomers = getSearchedFilterData(
    // moderateRiskCustomers?.result,
    medRiskCustomer,
    formData.mediumRiskSearch
  );
  const filteredLowRiskCustomers = getSearchedFilterData(
    lowRiskCustomer,
    // lowRiskCustomers?.result,
    formData.lowRiskSearch
  );
  const filteredAllCustomers = getSearchedFilterData(
    allCustomers,
    formData.customerSearch
  );

  const stockNameList = PRODUCT_ID == 3 ? companyDataFno : companyData;

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    const numericFields = [
      "quantity",
      "percentage",
      "price",
      "stopLossPerc",
      "target",
    ];

    if (numericFields.includes(name)) {
      if (!/^\d*\.?\d*$/.test(value)) return;
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  return (
    <>
      {currentStep === 1 && (
        <>
          <Box>
            <Box
              sx={{
                backgroundColor: "#E5EEFF",
                width: "calc(100% + 48px)",
                ml: "-50px !important",
                mt: "-32px !important",
                p: "15px 24px",
              }}
            >
              <Box sx={{ marginLeft: "24px", marginRight: "24px" }}>
                <Stepper currentStepsId={1} stepperData={tradeSteps} />
              </Box>
            </Box>

            <TextStyled>Index</TextStyled>
            <Box sx={{ display: "flex", gap: "24px", mb: "22px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.index === "NSE"}
                  onChange={() =>
                    handleFormChange({
                      name: "index",
                      value: "NSE",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  NSE
                </Text>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.index === "BSE"}
                  onChange={() =>
                    handleFormChange({
                      name: "index",
                      value: "BSE",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  BSE
                </Text>
              </Box>
            </Box>

            <Text
              sx={{
                mt: "24px",
                mb: "6px",
                fontSize: "14px",
                fontWeight: 500,
                color: "#242424",
              }}
            >
              Stock name
            </Text>

            {/* Stock Name */}
            <Autocomplete
              sx={{ width: "100%", mb: "0px" }}
              placeholder="Enter stock name"
              value={
                Array.isArray(stockNameList)
                  ? stockNameList.find(
                      (option) => option.symbol === formData.stockName
                    ) || null
                  : null
              }
              onChange={(event, value) => {
                setFormData({
                  ...formData,
                  stockName: value ? value.symbol : "", // Set stockName to symbol or an empty string if value is null
                });
              }}
              loading={StockListisFetching || StockListFnoisFetching}
              loadingText={"loading..."}
              disablePortal
              id="combo-box-demo"
              options={stockNameList || []}
              getOptionLabel={(option) => option.symbol || ""}
              renderInput={(params) => (
                <TextField
                  onChange={(event) => {
                    setFormDataSearch(event.target.value);
                  }}
                  value={formDataSearch}
                  {...params}
                  placeholder="Enter a Stock Name"
                />
              )}
            />
            {formError.stockName && (
              <Text variant="small" color="red" py={1}>
                {formError.stockName}
              </Text>
            )}

            {/* Order Type */}
            <TextStyled>Order Type</TextStyled>
            <Box sx={{ display: "flex", gap: "24px" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.tradeType === "BUY"}
                  onChange={() =>
                    handleFormChange({
                      name: "tradeType",
                      value: "BUY",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  Buy
                </Text>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.tradeType === "SELL"}
                  onChange={() =>
                    handleFormChange({
                      name: "tradeType",
                      value: "SELL",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  Sell
                </Text>
              </Box>
              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                <Radio
                  checked={formData.tradeType === "SQUARE OFF"}
                  onChange={() =>
                    handleFormChange({
                      name: "tradeType",
                      value: "SQUARE OFF",
                    })
                  }
                />
                <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
                  Square off
                </Text>
              </Box> */}
            </Box>
            {formError.tradeType && (
              <Text variant="small" color="red" sx={{ marginTop: "8px" }}>
                {formError.tradeType}
              </Text>
            )}

            <Text
              sx={{ fontSize: "14px", fontWeight: 500, mb: "12px", mt: "22px" }}
            >
              Buy/Sell stock on the basis of
            </Text>

            <ButtonGroup>
              <CapitalButton
                onClick={() =>
                  handleFormChange({
                    name: "buySellType",
                    value: "QUANTITY",
                  })
                }
                selected={formData.buySellType === "QUANTITY"}
              >
                <Text>Quantity</Text>
              </CapitalButton>
              <CapitalButton
                onClick={() =>
                  handleFormChange({
                    name: "buySellType",
                    value: "PERCENTAGE",
                  })
                }
                selected={formData.buySellType === "PERCENTAGE"}
              >
                <Text>Percentage</Text>
              </CapitalButton>
            </ButtonGroup>

            {formData.buySellType === "QUANTITY" ? (
              <>
                <OptionsTextStyled>
                  {PRODUCT_ID === 3 ? `LOT` : `Quantity`}
                </OptionsTextStyled>
                <TextField
                  placeholder={
                    PRODUCT_ID === 3 ? `Enter LOT` : `Enter Quantity`
                  }
                  name="quantity"
                  onChange={handleFormDataChange}
                  value={formData.quantity}
                  inputProps={{ inputMode: "numeric" }}
                  onKeyPress={(e) => {
                    const allowedChars = /[0-9.\b]/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
                {formError.quantity && (
                  <Text variant="small" color="red" py={1}>
                    {formError.quantity}
                  </Text>
                )}
              </>
            ) : (
              <>
                <OptionsTextStyled>Percentage</OptionsTextStyled>
                <TextField
                  placeholder="Enter percentage of capital"
                  name="percentage"
                  onChange={handleFormDataChange}
                  value={formData.percentage}
                  inputProps={{ inputMode: "numeric" }}
                  onKeyPress={(e) => {
                    const allowedChars = /[0-9.\b]/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </>
            )}

            <Text
              sx={{ fontSize: "14px", fontWeight: 500, mt: "20px", mb: "12px" }}
            >
              Order type
            </Text>

            <ButtonGroup>
              <CapitalButton
                onClick={() => {
                  handleFormChange({
                    name: "orderType",
                    value: "MARKET",
                  });
                }}
                selected={formData.orderType === "MARKET"}
              >
                <Text>Market</Text>
              </CapitalButton>
              <CapitalButton
                onClick={() => {
                  handleFormChange({
                    name: "orderType",
                    value: "LIMIT",
                  });
                }}
                selected={formData.orderType === "LIMIT"}
              >
                <Text>Limit</Text>
              </CapitalButton>
            </ButtonGroup>

            {formData.orderType === "LIMIT" && (
              <>
                <OptionsTextStyled>Price</OptionsTextStyled>
                <TextField
                  placeholder="Enter price"
                  name="price"
                  onChange={handleFormDataChange}
                  value={formData.price}
                  inputProps={{ inputMode: "numeric" }}
                  onKeyPress={(e) => {
                    const allowedChars = /[0-9.\b]/;
                    if (!allowedChars.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </>
            )}
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Checkbox
              sx={{ width: "12px", height: "12px", mt: "9px" }}
              checked={formData.is_stop_loss}
              onChange={() => {
                handleFormChange({
                  name: "is_stop_loss",
                  value: !formData.is_stop_loss,
                });
              }}
            />

            <Text
              sx={{
                ml: "12px",
                mt: "9px",
                fontSize: "16px",
                color: "#242424",
                fontWeight: 400,
              }}
            >
              Add stop loss
            </Text>
          </Box>

          {/* Stop loss */}
          {formData.is_stop_loss && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text sx={{ fontSize: "14px", fontWeight: 500, mt: "30px" }}>
                  Stop Loss
                </Text>

                <ButtonGroup>
                  <CapitalButtonSmall
                    onClick={() =>
                      handleFormChange({
                        name: "isStopLossChecked",
                        value: true,
                      })
                    }
                    selected={formData.isStopLossChecked}
                  >
                    <Text>%</Text>
                  </CapitalButtonSmall>
                  <CapitalButtonSmall
                    onClick={() =>
                      handleFormChange({
                        name: "isStopLossChecked",
                        value: false,
                      })
                    }
                    selected={!formData.isStopLossChecked}
                  >
                    <Text>₹</Text>
                  </CapitalButtonSmall>
                </ButtonGroup>
              </Box>
              <TextField
                placeholder={
                  !formData.isStopLossChecked
                    ? "Enter target value in ₹"
                    : "Enter target value in %"
                }
                name="stopLossPerc"
                onChange={handleFormDataChange}
                value={formData.stopLossPerc}
                inputProps={{ inputMode: "numeric" }}
                onKeyPress={(e) => {
                  const allowedChars = /[0-9.\b]/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </>
          )}

          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Checkbox
              sx={{
                width: "12px",
                height: "12px",
                mt: "9px",
              }}
              checked={formData.is_target}
              onChange={() => {
                handleFormChange({
                  name: "is_target",
                  value: !formData.is_target,
                });
              }}
            />

            <Text
              sx={{
                ml: "12px",
                mt: "9px",
                fontSize: "16px",
                color: "#242424",
                fontWeight: 400,
              }}
            >
              Add Target
            </Text>
          </Box>

          {/* Terget sell */}

          {formData.is_target && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <CheckedTextStyled>Target</CheckedTextStyled>
                <ButtonGroup>
                  <CapitalButtonSmallTarget
                    onClick={() =>
                      handleFormChange({
                        name: "isTargetChecked",
                        value: true,
                      })
                    }
                    selected={formData.isTargetChecked}
                  >
                    <Text>%</Text>
                  </CapitalButtonSmallTarget>
                  <CapitalButtonSmallTarget
                    onClick={() =>
                      handleFormChange({
                        name: "isTargetChecked",
                        value: false,
                      })
                    }
                    selected={!formData.isTargetChecked}
                  >
                    <Text>₹</Text>
                  </CapitalButtonSmallTarget>
                </ButtonGroup>
              </Box>
              <TextField
                placeholder={
                  !formData.isTargetChecked
                    ? "Enter target value in ₹"
                    : "Enter target value in %"
                }
                name="target"
                onChange={handleFormDataChange}
                value={formData.target}
                inputProps={{ inputMode: "numeric" }}
                onKeyPress={(e) => {
                  const allowedChars = /[0-9.\b]/;
                  if (!allowedChars.test(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </>
          )}

          <CheckedTextStyled>
            Date till the stock to buy/sell (Expiry)
          </CheckedTextStyled>

          <DatePicker
            label="Select Date"
            sx={{ mb: "2px" }}
            value={dayjs(formData.maxBuySellDate)}
            onChange={(date) => {
              handleFormChange({
                name: "maxBuySellDate",
                value: date["$d"],
              });
            }}
          />
          {formError.maxBuySellDate && (
            <Text variant="small" color="red">
              {formError.maxBuySellDate}
            </Text>
          )}

          <CheckedTextStyled>
            Time till the stock to buy/sell (Expiry)
          </CheckedTextStyled>
          <TimePicker
            label="Select Time"
            value={formData.maxBuySellTime}
            onChange={(time) => {
              handleFormChange({
                name: "maxBuySellTime",
                value: time,
              });
            }}
          />
          {formError.maxBuySellTime && (
            <Text variant="small" color="red">
              {formError.maxBuySellTime}
            </Text>
          )}
        </>
      )}

      {currentStep === 2 && (
        <>
          <Box
            sx={{
              backgroundColor: "#E5EEFF",
              width: "calc(100% + 48px)",
              ml: "-50px !important",
              mt: "-20px !important",
              p: "15px 22px",
            }}
          >
            <Box sx={{ marginLeft: "24px", marginRight: "24px" }}>
              <Stepper currentStepsId={2} stepperData={tradeSteps} />
            </Box>
          </Box>

          {/* Chechbox: Risk profile & customer listing. */}
          {PRODUCT_ID == 1 && (
            <Box sx={{ display: "flex", gap: "12px" }}>
              <BoxStyled
                sx={{
                  backgroundColor: formData.isRiskProfileSelected
                    ? "#E5EEFF"
                    : "transparent",
                }}
                onClick={() => {
                  handleFormChange({
                    name: "isRiskProfileSelected",
                    value: true,
                  });
                }}
              >
                <Radio
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                  checked={formData.isRiskProfileSelected}
                />
                <Text
                  sx={{
                    color: formData.isRiskProfileSelected
                      ? "#101828"
                      : "#242424",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                >
                  Risk Profile
                </Text>
              </BoxStyled>

              <BoxStyled
                sx={{
                  backgroundColor: !formData.isRiskProfileSelected
                    ? "#E5EEFF"
                    : "transparent",
                }}
                onClick={() => {
                  handleFormChange({
                    name: "isRiskProfileSelected",
                    value: false,
                  });
                }}
              >
                <Radio
                  sx={{
                    width: "20px",
                    height: "20px",
                  }}
                  checked={!formData.isRiskProfileSelected}
                />
                <Text
                  sx={{
                    color: !formData.isRiskProfileSelected
                      ? "#101828"
                      : "#242424",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "24px",
                    cursor: "pointer",
                  }}
                >
                  Customers
                </Text>
              </BoxStyled>
            </Box>
          )}

          {formData.isRiskProfileSelected && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "20px", height: "20px", mt: "24px" }}
                  checked={formData.isHighRiskChecked}
                  onChange={(e) =>
                    handleRiskTypeChange({
                      name: "isHighRiskChecked",
                      value: !formData.isHighRiskChecked,
                      riskProfileId: 1,
                    })
                  }
                />
                <Text
                  sx={{
                    ml: "12px",
                    mt: "24px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  High risk profile
                </Text>
              </Box>

              {formData.isHighRiskChecked && (
                <Box sx={{ width: "100%", m: 2 }}>
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      mt: "20px",
                      mb: "6px",
                    }}
                  >
                    Customer
                  </Text>
                  <MultiSelect
                    onChange={(e) => {
                      handleFormChange({
                        name: "selectedHighRiskCustomers",
                        value: e.target.value,
                      });
                    }}
                    onClose={() => {
                      handleFormChange({
                        name: "highRiskSearch",
                        value: "",
                      });
                    }}
                    onSearchChange={(e) => {
                      handleFormChange({
                        name: "highRiskSearch",
                        value: e.target.value,
                      });
                    }}
                    options={filteredHighRiskCustomers}
                    value={formData.selectedHighRiskCustomers}
                    idKeyName="user_id"
                    disabledKeyName="is_broker_missing"
                    listLoading={customerListisFetching}
                  />
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "20px", height: "20px", mt: "24px" }}
                  checked={formData.isModerateRiskChecked}
                  onChange={(e) =>
                    handleRiskTypeChange({
                      name: "isModerateRiskChecked",
                      value: !formData.isModerateRiskChecked,
                      riskProfileId: 2,
                    })
                  }
                />

                <Text
                  sx={{
                    ml: "12px",
                    mt: "24px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  Moderate risk Profile
                </Text>
              </Box>

              {formData.isModerateRiskChecked && (
                <Box sx={{ width: "100%", m: 2 }}>
                  <Text
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      mt: "20px",
                      mb: "6px",
                    }}
                  >
                    Customer
                  </Text>
                  <MultiSelect
                    onChange={(e) => {
                      handleFormChange({
                        name: "selectedMediumRiskCustomers",
                        value: e.target.value,
                      });
                    }}
                    onClose={() => {
                      handleFormChange({
                        name: "mediumRiskSearch",
                        value: "",
                      });
                    }}
                    onSearchChange={(e) => {
                      handleFormChange({
                        name: "mediumRiskSearch",
                        value: e.target.value,
                      });
                    }}
                    options={filteredModerateRiskCustomers}
                    value={formData.selectedMediumRiskCustomers}
                    idKeyName="user_id"
                    disabledKeyName="is_broker_missing"
                    listLoading={customerListisFetching}
                  />
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "20px", height: "20px", mt: "24px" }}
                  checked={formData.isLowRiskChecked}
                  onChange={(e) =>
                    handleRiskTypeChange({
                      name: "isLowRiskChecked",
                      value: !formData.isLowRiskChecked,
                      riskProfileId: 3,
                    })
                  }
                />

                <Text
                  sx={{
                    ml: "12px",
                    mt: "24px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  Conservative risk Profile
                </Text>
              </Box>

              {formData.isLowRiskChecked && (
                <>
                  <Box sx={{ width: "100%", m: 2 }}>
                    <Text
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        mt: "20px",
                        mb: "6px",
                      }}
                    >
                      Customer
                    </Text>
                    <MultiSelect
                      onChange={(e) => {
                        handleFormChange({
                          name: "selectedLowRiskCustomers",
                          value: e.target.value,
                        });
                      }}
                      onClose={() => {
                        handleFormChange({
                          name: "lowRiskSearch",
                          value: "",
                        });
                      }}
                      onSearchChange={(e) => {
                        handleFormChange({
                          name: "lowRiskSearch",
                          value: e.target.value,
                        });
                      }}
                      options={filteredLowRiskCustomers}
                      value={formData.selectedLowRiskCustomers}
                      idKeyName="user_id"
                      disabledKeyName="is_broker_missing"
                      listLoading={customerListisFetching}
                    />
                  </Box>
                </>
              )}
            </>
          )}

          {!formData.isRiskProfileSelected && (
            <Box sx={{ width: "100%", m: 2 }}>
              <Text
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  mt: "20px",
                  mb: "6px",
                }}
              >
                Customer
              </Text>
              <MultiSelect
                onChange={(e) => {
                  handleFormChange({
                    name: "selectedCustomers",
                    value: e.target.value,
                  });
                }}
                onClose={() => {
                  handleFormChange({
                    name: "customerSearch",
                    value: "",
                  });
                }}
                onSearchChange={(e) => {
                  handleFormChange({
                    name: "customerSearch",
                    value: e.target.value,
                  });
                }}
                options={filteredAllCustomers}
                value={formData.selectedCustomers}
                idKeyName="user_id"
                disabledKeyName="is_broker_missing"
                listLoading={customerListisFetching}
              />
            </Box>
          )}
        </>
      )}

      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "500px",
          left: 0,
        }}
      >
        <Stack
          direction={"row"}
          spacing={1}
          justifyContent={"center"}
          alignItems={"center"}
          mb={3}
          px={2}
        >
          <Button
            variant="outlined"
            onClick={primaryAction}
            size="medium"
            color="secondary"
            sx={{
              color: theme.palette.grey?.[900],
              borderColor: theme.palette.grey?.[900],
              background: theme.palette.common.white,
              width: "100%",
              px: 2.5,
              py: 2,
              fontSize: "1rem",
            }}
          >
            {cancelButtonText}
          </Button>

          <Button
            sx={{
              width: "100%",
              px: 2.5,
              py: 2,
              fontSize: "1rem",
            }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => {
              if (handleValidation()) {
                handleSubmit(formData);
              }

              // if(formData.index && formData.stockName && formData.tradeType && formData.buySellType &&formData.quantity && formData.maxBuySellDate && formData.maxBuySellTime) {
              //   handleSubmit(formData);
              // }
            }}
          >
            {submitButtonText}
          </Button>
        </Stack>
      </Box>
    </>
  );
};
export default AddTradeDrawer;
