import React, { useState, useEffect } from "react";
import Box from "components/common/Box";
import Grid from "components/common/Grid";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Image from "components/common/Image";
import SideDrawer from "components/common/SideDrawer";
import Button from "components/common/Button";
import Card from "components/common/Card";
import CustomerDashboardHeader from "components/dashboard/CustomerDashboardHeader";
import CustomGrid from "components/datagrid/CustomGrid";
import CustomerTradeCard from "components/Customer/CustomerTradeCard";
import TradeCallStats from "components/dashboard/TradeCallStats";
import ModelPortfolioAllocation from "components/Customer/ModelPortfolioAllocation";
import Chip from "@mui/material/Chip";
import { Alert, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { CustomerDashboardApiAction } from "stores/redux/customerDashBoard/customerDashBoardApiSlice";
import CustomerDashBoardAddFilter from "components/Customer/CustomerDashBoardAddFilter";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useDispatch } from "react-redux";
import { uuid } from "helpers/utility";
import _get from "lodash/get";
import { style } from "@mui/system";
import { appConstants } from "helpers/constants/appConstants";
import { validationHelper } from "helpers/validation";
import Tooltip from "components/common/Tooltip";

const USER_ROLES = appConstants.userRoles;

// Styled Components
const ChipStyle = styled(Chip)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "#EAFFF3",
  borderRadius: "152px",
  color: "#219653",
  fontSize: "12px",
  fontWeight: 500,
  "&::before": {
    position: "relative",
    left: 6,
    bottom: 6,
    content: '"\\2022"',
    width: "6px",
    height: "6px",
    color: "#219653",
  },
}));

const BuyChip = styled(Chip)(() => ({
  backgroundColor: "#A1F0C2",
  color: "#219653",
  borderRadius: "152px",
  width: "61px",
  height: "24px",
}));

const SellChip = styled(Chip)(() => ({
  backgroundColor: "#FFEDED",
  color: "#EB5757",
  borderRadius: "152px",
  width: "61px",
  height: "24px",
}));

const ExecuteBox = styled(Button)(({ is_broker_missing }) => ({
  width: "20px",
  height: "26px",
  mr: "0px",
  backgroundColor: "#A1F0C2 !important",
  border: "1.5px solid #219653",
  borderRadius: "4.5px",
  cursor: "pointer",
  opacity: is_broker_missing ? 0.4 : 1,
}));

const DeleteTrade = styled(Button)(({ is_broker_missing }) => ({
  width: "20px",
  height: "26px",
  mr: "0px",
  backgroundColor: "#FCB9B9 !important",
  border: "1.5px solid #EB5757",
  borderRadius: "4.5px",
  cursor: "pointer",
  opacity: is_broker_missing ? 0.4 : 1,
}));

const AddFilterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #D0D5DD",
  height: "20px",
  borderRadius: "8px",
  padding: "10px 16px",
}));
// 2 Graphs
const graphCardData = [
  {
    id: 1,
    name: "Algo (F&O) progress",
    chart: "/images/Graph2.svg",
    display: "column",
    xs: 6,
  },
  // {
  //   id: 2,
  //   name: "Custom Progress till date",
  //   chart: "/images/Graph2.svg",
  //   display: "flex",
  //   xs: 6,
  // },
];

const graphCardData2 = [
  {
    id: 1,
    name: "Custom Progress till date",
    chart: "/images/Graph2.svg",
    display: "column",
    xs: 6,
  },
];

// View Trade Drawer
const ViewTrade = ({
  companyName,
  actionTaken,
  quantity,
  buyPrice,
  invested,
  sellPrice,
}) => {
  return (
    <Card sx={{ p: "12px 16px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Text
          sx={{
            color: "#242424",
            fontSize: "16px",
            fontWeight: 500,
            mr: "16px",
          }}
        >
          {companyName}
        </Text>
        <ChipStyle label={actionTaken} size="small" />
      </Box>

      <Box
        sx={{
          display: "flex",
          mt: 2,
          mb: "8px",
        }}
      >
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Invesment amount : ₹ {invested}
        </Text>
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Quantity : {sellPrice ? `₹ ${quantity}` : "NA"}
        </Text>
      </Box>

      <Box
        sx={{
          display: "flex",
          mb: "8px",
        }}
      >
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Buy price : ₹ {buyPrice}
        </Text>
        <Text sx={{ fontSize: "14px", mr: "auto" }}>
          Sell price : {sellPrice ? `₹ ${sellPrice}` : "NA"}
        </Text>
      </Box>
    </Card>
  );
};

let intervalList;
// Below 2 array's are Rohit handling
const tradecallsstats = [
  {
    noofcalls: 20,
    actiontaken: 5,
  },
];

function CustomerDashboardContainer() {
  const [isDrawerEye, setIsDrawerEye] = useState(false);
  const [isDrawerAddFamily, setDrawerAddFamily] = useState(false);
  const [apiData, setApiData] = useState(null);
  const [headerData, setHeaderData] = useState();
  const [productID, setProductID] = useState(null);
  const [tradeID, setTradeID] = useState(null);
  const [transactionID, setTransactionID] = useState(null);
  const [viewData, setViewData] = useState(null);
  const [refetchStacksDataCounter, setRefetchStacksDataCounter] = useState(0);
  const [stateValue, setStateValue] = useState({
    formattedStartDate: null,
    formattedEndDate: null,
    dropDownData: {
      investmentType: [],
      familyMember: [],
    },
  });

  const [stacksLoading, setStacksLoading] = useState(false);

  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const [selectedFilters, setSelectedFilters] = useState({
    orderType: [],
    actionType: [],
    actionStatus: [],
    investmentType: [],
    familyMember: [],
  });

  const dispatch = useDispatch();
  const handlePaginationModelChange = (model) => {
    setTradesPaginationModel(model);
  };

  const userRoleCode = useSelector((state) => state.commonReducer.userRoleCode);
  const userDetails = useSelector((state) => state.commonReducer.userDetails);
  const roleConfig = USER_ROLES[userRoleCode];
  const pendingCustomerApprv =
    userDetails.onboard_status == "PENDING" && roleConfig.code == "CUSTOMER";
  const [
    handleAddFilterApi,
    { data: tradeList = {}, isLoading: tradeListLoading },
  ] = CustomerDashboardApiAction.addFilterDashBoardApi({
    page: tradesPaginationModel.page + 1,
    take: tradesPaginationModel.pageSize,
  });

  console.log(tradeList, "LOADING");
  const modifiedTableData = _get(tradeList, "result", []).map((row) => ({
    ...row,
    id: uuid(),
  }));
  const rowCount = _get(tradeList, "total_record", 0);

  const [executeCustomerTrade] =
    CustomerDashboardApiAction.executeCustomerTradeApi({
      product_id: productID,
      trade_id: tradeID,
      transaction_id: transactionID,
    });
  const [deleteCustomerTrade] =
    CustomerDashboardApiAction.deleteCustomerTradeApi({
      product_id: productID,
      transaction_id: transactionID,
    });

  const handlestateValues = (data) => {
    setStateValue(data);
  };
  const handleApplyFilters = () => {
    setDrawerAddFamily(false);
  };
  const handleCellClick = (row) => {
    setViewData(row);
  };
  // Eye Drawer
  const EyeDrawer = () => {
    setIsDrawerEye(true);
  };
  // Add FIlter Drawer
  const AddFamilyDrawer = () => {
    setDrawerAddFamily(true);
  };
  const handleFiltersChange = (type, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  const executeTrade = async (product_id, trade_id, transaction_id) => {
    try {
      const response = await executeCustomerTrade({
        product_id,
        trade_id,
        transaction_id,
      });
      console.log("Trade execution response:", response);
      fetchDataGridApi();
      setRefetchStacksDataCounter((prv) => prv + 1);
      // Dispatch success toast message
      dispatch(
        toastActions.setToastData({
          message: response?.data?.message,
          severity: "success",
        })
      );
    } catch (error) {
      console.error("Error executing trade:", error);

      // Dispatch error toast message
      dispatch(
        toastActions.setToastData({
          message: "Failed to execute trade. Please try again later.",
          severity: "error",
        })
      );
    }
  };

  const deleteTrade = async (product_id, transaction_id) => {
    setProductID(product_id);
    setTransactionID(transaction_id);
    try {
      const response = await deleteCustomerTrade({
        product_id,
        transaction_id,
      });
      setRefetchStacksDataCounter((prv) => prv + 1);
      console.log("Trade deletion response:", response);
      fetchDataGridApi();

      // Dispatch success toast message
      dispatch(
        toastActions.setToastData({
          // message: response?.data?.message,
          message: "Trade Deleted Succesfully ",
          severity: "success",
        })
      );
    } catch (error) {
      console.error("Error deleting trade:", error);

      // Dispatch error toast message
      dispatch(
        toastActions.setToastData({
          message: "Failed to delete trade. Please try again later.",
          severity: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (!pendingCustomerApprv) {
      fetchDataGridApi();
    }
  }, [selectedFilters, tradesPaginationModel]);

  useEffect(() => {
    // Note: Recall Trade listing API if any of them are inprogress.
    const listData = _get(tradeList, "result", []);
    if (listData && listData.length && listData.some((t) => t.status == 1)) {
      if (intervalList) {
        clearInterval(intervalList);
      }
      intervalList = setInterval(() => {
        setRefetchStacksDataCounter((prv) => prv + 1);
      }, 10000);
    } else {
      clearInterval(intervalList);
    }
  }, [tradeList]);

  useEffect(() => {
    return () => {
      if (intervalList) {
        console.log("Clear table list Interval");
        clearInterval(intervalList);
      }
    };
  }, []);

  const fetchDataGridApi = async () => {
    const {
      orderType,
      actionType,
      actionStatus,
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    } = selectedFilters;

    const payload = {
      member_ids:
        familyMember && familyMember.length
          ? familyMember
          : [userDetails?.user_id].filter(Boolean),
      product_id:
        investmentType && investmentType.length ? investmentType : [1, 2, 3],
      date_range_from: formattedStartDate?.length ? formattedStartDate : "",
      date_range_to: formattedEndDate?.length ? formattedEndDate : "",
      action: orderType,
      order_type: actionType,
      status: actionStatus,
      page: tradesPaginationModel.page + 1,
      take: tradesPaginationModel.pageSize,
      noGlobalLoading: true,
    };

    try {
      await handleAddFilterApi(payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const cbWithFilteredData = async (filterParameter) => {
    const {
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    } = filterParameter;
    setSelectedFilters({
      ...selectedFilters,
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    });
  };

  // const dashboardCard = () => {

  //   let capitalInvested = "10,00,00";
  //   let capitalRemaining = "50,00,00";
  //   let currentValue = 1000;
  //   let todaysGainVal = 200;
  //   let todaysGainPercent = "10%";
  //   let annualizedReturns = "15%";

  //   let totalTradeCall = 110;
  //   let actionTaken = 55;
  //   let actionPending = 55;
  //   let totalProfit = 4000;
  //   let profitPercentage = "15%";

  //   if (!pendingCustomerApprv) {
  //     capitalInvested = _get(headerData, "capital[0].total_amount_invested", 0);
  //     capitalRemaining = _get(headerData, "capital[0].total_pending_amount", 0);
  //     currentValue = _get(
  //       headerData,
  //       "currentValueData[0].current_total_value",
  //       0
  //     );
  //     todaysGainVal = _get(headerData, "todays_gain[0].total_todays_gain", 0);
  //     todaysGainPercent = _get(
  //       headerData,
  //       "todays_gain_percentage[0].todays_gain_percentage",
  //       0
  //     );
  //     annualizedReturns = _get(
  //       headerData,
  //       "annualized_returns[0].annualized_returns",
  //       0
  //     );

  //     totalTradeCall = _get(headerData, "tradescall[0].total_trade_call", 0);
  //     actionTaken = _get(headerData, "tradescall[0].action_taken", 0);
  //     actionPending = _get(headerData, "tradescall[0].action_pending", 0);
  //     totalProfit = _get(headerData, "total_profit[0].total_profit", 0);
  //     profitPercentage = _get(
  //       headerData,
  //       "total_profit[0].profit_percentage",
  //       0
  //     );
  //   }
  //   return [
  //     {
  //       id: 1,
  //       name: "Capital",
  //       subheader1: `₹ ${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(capitalInvested)
  //       }`,
  //       subheader1value: "Invested",
  //       subheader2: `${
  //         stacksLoading
  //           ? `-`
  //           : `₹ ${validationHelper.roundDecimal(capitalRemaining)}`
  //       }`,
  //       subheader2value: "Remaining",
  //       chart: "/images/ProfitChart.svg",
  //       display: "column",
  //       xs: 2.,
  //     },
  //     {
  //       id: 2,
  //       name: "Current value",
  //       value: `${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(currentValue)
  //       } `,
  //       chart: "/images/ProfitChart.svg",
  //       prefix: "₹ ",
  //       display: "flex",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 3,
  //       name: "Today’s gains",
  //       value: `${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(todaysGainVal)
  //       }`,
  //       prefix: "₹ ",
  //       percentage: `${todaysGainPercent}`,
  //       chart: "/images/LossChart.svg",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 4,
  //       name: "Annualized returns",
  //       value: `${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(annualizedReturns)
  //       }`,
  //       chart: "/images/LossChart.svg",
  //       suffixSign: "%",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 4,
  //       name: "Annualized returns",
  //       value: `${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(annualizedReturns)
  //       }`,
  //       chart: "/images/LossChart.svg",
  //       suffixSign: "%",
  //       xs: 2.3,
  //     },
  //     // 2nd row
  //     {
  //       id: 5,
  //       name: "Trade Calls",
  //       value: `${stacksLoading ? "fetching..." : totalTradeCall}`,
  //       chart: "/images/ProfitChart.svg",
  //       display: "column",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 6,
  //       name: "Action taken",
  //       value: `${stacksLoading ? "fetching..." : actionTaken}`,
  //       chart: "/images/ProfitChart.svg",
  //       display: "flex",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 7,
  //       name: "Action pending",
  //       value: `${stacksLoading ? "fetching..." : actionPending}`,
  //       chart: "/images/ProfitChart.svg",
  //       display: "flex",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 8,
  //       name: "Profit",
  //       value: `${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(totalProfit)
  //       }`,
  //       prefix: "₹ ",
  //       percentage: `${profitPercentage}`,
  //       chart: "/images/LossChart.svg",
  //       xs: 2.3,
  //     },
  //     {
  //       id: 4,
  //       name: "Annualized returns",
  //       value: `${
  //         stacksLoading
  //           ? "fetching..."
  //           : validationHelper.roundDecimal(annualizedReturns)
  //       }`,
  //       chart: "/images/LossChart.svg",
  //       suffixSign: "%",
  //       xs: 2.3,
  //     },
  //   ];
  // };

  // Table columns

  const dashboardCard = () => {
    let cardData = headerData?.card_data || [];

    return cardData.map((card) => {
      
      let finalData = {
        display_heading: _get(card, "display_heading", "Card"),
        sequence: _get(card, "sequence", ""),
        data_1: {
          sub_heading: _get(card, "data_1.sub_heading", null),
          value: _get(card, "data_1.value", 0),
          value_type: _get(card, "data_1.value_type", ""),
          suffix_val: _get(card, "data_1.suffix_val", null),
          suffix_val_type: _get(card, "data_1.suffix_val_type", ""),
        },
        data_2: {
          sub_heading: _get(card, "data_2.sub_heading", null),
          value: _get(card, "data_2.value", null),
          value_type: _get(card, "data_2.value_type", ""),
          suffix_val: _get(card, "data_2.suffix_val", null),
          suffix_val_type: _get(card, "data_2.suffix_val_type", ""),
        }
      }

      return finalData;
    });
  };

  const customerColumns = [
    {
      field: "symbol",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#242424", fontSize: "14px", fontWeight: 500 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
          <Box component="span" sx={{ ml: "2px", fontSize: "12px" }}>
            {params?.row?.product_id == 3 ? " (LOT)" : ""}
          </Box>
        </div>
      ),
    },
    {
      field: "order_type",
      headerName: "Order type",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "Action type",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {params.value === "BUY" && <BuyChip label="Buy" />}
          {params.value === "SELL" && <SellChip label="Sell" />}
        </Stack>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value ? `₹ ${params.value}` : "-"}
        </div>
      ),
    },
    {
      field: "stop_loss",
      headerName: "Stop loss",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "target",
      headerName: "Target (Sell)",
      flex: 1,
      renderCell: (params) => (
        <div style={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "trade_is_expired",
      headerName: "Trade Status ",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {params.value === 1 && (
            <SellChip sx={{ width: "79px" }} label="Expired" />
          )}
          {params.value === 0 && (
            <BuyChip sx={{ width: "79px" }} label="Active" />
          )}
        </Stack>
      ),
    },
    {
      field: "status",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        const { product_id, trade_id, transaction_id } = params.row;
        console.log(params.row, "DATA");

        // NOTE: 0=Pending, 1=Inprogress, 2=Completed, 3=Retjected, 3=Cancelled, 4=Cancelled, 5=Updated

        let statusChip = {};
        switch (params.value) {
          case 1:
            statusChip.chipColor = "#FF5722";
            statusChip.chipBg = "#ffc3b0";
            statusChip.chipText = "Inprogress";
            break;
          case 2:
            statusChip.chipColor = "#196d3d";
            statusChip.chipBg = "#a1f0c2";
            statusChip.chipText = "Completed";
            break;
          case 3:
            statusChip.chipColor = "#eb5757";
            statusChip.chipBg = "#fcb9b9";
            statusChip.chipText = "Rejected";
            break;
          case 4:
            statusChip.chipColor = "#d2d2d2";
            statusChip.chipBg = "gray";
            statusChip.chipText = "Cancelled";
            break;
          case 5:
            statusChip.chipColor = "#d2d2d2";
            statusChip.chipBg = "gray";
            statusChip.chipText = "Updated";
            break;
          default:
            statusChip = null;
            break;
        }

        if (params.value === 0) {
          return (
            <Stack direction="row" alignItems="center" sx={{ gap: "8px" }}>
              <ExecuteBox
                onClick={() =>
                  executeTrade(product_id, trade_id, transaction_id)
                }
                disabled={userDetails.is_broker_missing}
                is_broker_missing={userDetails.is_broker_missing}
              >
                <Image src="/images/greenTick.svg" alt="Execute Trade" />
              </ExecuteBox>

              <Tooltip
                title={tradeList.result.map(
                  (e) => e.message && <div>{e.message}</div>
                )}
              >
                <DeleteTrade
                  onClick={() => deleteTrade(product_id, transaction_id)}
                  disabled={userDetails.is_broker_missing}
                  is_broker_missing={userDetails.is_broker_missing}
                >
                  <Image src="/images/cancelTick.svg" alt="Delete Trade" />
                </DeleteTrade>
              </Tooltip>
            </Stack>
          );
        } else if (statusChip) {
          return (
            <span
              style={{
                color: statusChip.chipColor,
                background: statusChip.chipBg,
                fontSize: "11px",
                padding: "5px 10px",
                borderRadius: "50px",
              }}
            >
              {statusChip.chipText}
            </span>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const data = {
    name: "Algo (F&O) progress",
  };
  const data2 = {
    name: "Custom Progress till date",
  };

  const investmentChart = [
    {
      label: "January",
      amountInvested: 5000,
      profitMade: 1000,
    },
    {
      label: "February",
      amountInvested: 6000,
      profitMade: 1200,
    },
    // Add more data as needed
  ];

  // CustomGrid Header
  const TradeHeader = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            padding: "24px",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{}}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mb: "10px" }}
            >
              <Text
                sx={{ color: "primary", mr: 1, fontWeight: 500 }}
                color="primary"
                variant="button"
              >
                Active Trades{" "}
              </Text>
              <Chip
                color="secondary"
                label={`${rowCount} trade calls`}
                size="small"
              ></Chip>
            </Stack>
            <Stack direction="row">
              {/* <Box
                component="img"
                sx={{ width: "20px", height: "20px", mr: "6px" }}
                alt="Exclamation"
                src="/images/redclockIcon.svg"
              ></Box> */}
              {/* <Text
                variant="small"
                sx={{ color: "#EB5757", fontWeight: 600, fontSize: "14px" }}
              >
                4:30 min left to take the trade calls
              </Text> */}
            </Stack>
          </Box>
          <AddFilterBox onClick={AddFamilyDrawer}>
            <Box
              component="img"
              sx={{ width: "20px", height: "20px", mr: "6px" }}
              alt="Add Filter Icon"
              src="/images/addFilterIcon.svg"
            ></Box>
            <Text sx={{ color: "#242424", fontSize: "14px", fontWeight: 600 }}>
              Add Filters
            </Text>
          </AddFilterBox>
        </Box>
      </>
    );
  };

  console.log("stacksLoading ==> ", stacksLoading);

  return (
    <>
      <CustomerDashboardHeader
        apiData={setApiData}
        userName={userDetails}
        onDataUpdate={handlestateValues}
        apiresponse={setHeaderData}
        cbWithFilteredData={cbWithFilteredData}
        refetchStacksDataCounter={refetchStacksDataCounter}
        pendingCustomerApprv={pendingCustomerApprv}
        setStacksLoading={setStacksLoading}
      />

      {/* Stacks */}
        <CustomerTradeCard data={dashboardCard()} cardsPerRow={headerData?.cards_per_row} stacksLoading={stacksLoading} />

      {/* Table: Trade listing */}
      <Box sx={{ mb: "24px" }}>
        <CustomGrid
          autoHeight
          list={modifiedTableData}
          getRowId={(row) => row.id}
          loading={tradeListLoading}
          columnHeaderHeight={46}
          rowHeight={46}
          columns={customerColumns}
          rowCount={rowCount}
          header={TradeHeader}
          onCellClick={handleCellClick}
          pagination
          paginationMode="server"
          paginationModel={tradesPaginationModel}
          onPaginationModelChange={handlePaginationModelChange}
        />
      </Box>

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {graphCardData.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_3_${index}`}>
            <TradeCallStats data={data} investmentChart={investmentChart} />
          </Grid>
        ))}

        {graphCardData2.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_3_${index}`}>
            <TradeCallStats data={data2} investmentChart={investmentChart} />
          </Grid>
        ))}
      </Grid>
      <ModelPortfolioAllocation apiResponse={headerData} />

      <SideDrawer
        open={isDrawerAddFamily}
        closeDrawer={() => setDrawerAddFamily(false)}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        cancelButtonText="Clear all"
        submitButtonText="Apply"
        handleSubmit={handleApplyFilters}
      >
        <CustomerDashBoardAddFilter onFiltersChange={handleFiltersChange} />
      </SideDrawer>

      <SideDrawer
        open={isDrawerEye}
        closeDrawer={() => setIsDrawerEye(false)}
        title="View trade"
        subtitle="Here you can view the specific trade."
        showSecondaryButton={false}
        submitButtonText="Okay"
      >
        <ViewTrade
          companyName="Infosys Limited"
          actionTaken="Action Taken"
          quantity={10}
          buyPrice={500}
          invested={10000}
          sellPrice={null}
          date="Jan 24, 2023"
          buyTime="02:42 PM"
          sellTime="03:42 PM"
        />
      </SideDrawer>
    </>
  );
}

export default CustomerDashboardContainer;
