import _get from "lodash/get"
const customerPortalParser = {}
// Extract the necessary data for the CustomGrid

customerPortalParser.executeCustomerTrades = ({result}) =>{
    // debugger
    let parsedData = [];
    if(!result || !result.length){
        return [];
    }

    parsedData = result.map( t => ({
        id: _get(t,"trade_id", null),
        name: _get(t,"name", null),
        stockName: _get(t,"symbol", null),
        category: _get(t,"trade_id", null),
        quantity: _get(t,"quantity", null),
        orderType: _get(t,"order_type", null),
        action: _get(t,"action", null),
        price: _get(t,"price", null),
        stop_loss: _get(t,"stop_loss", null),
        targetSell: _get(t,"target", null),
        exchange: _get(t,"trade_exchange", null),
        status: _get(t,"status", null),
        is_percent: _get(t,"is_percent", null),
        trade_id :_get(t,"trade_id", null),
        transaction_id : _get(t,"transaction_id", null),
        product_id : _get(t,"product_id", null),
    }));
    return parsedData;
}

export default customerPortalParser

// import _get from "lodash/get";

// // Extract the necessary data for the CustomGrid
// const executeCustomerTrades = ({ result = [] }) => {
//     if (!result.length) return [];

//     return result.map(t => ({
//         id: _get(t, "trade_id", null),
//         name: _get(t, "name", null),
//         stockName: _get(t, "symbol", null),
//         category: _get(t, "trade_id", null),
//         quantity: _get(t, "trade_quantity", null),
//         orderType: _get(t, "trade_orderType", null),
//         action: _get(t, "trade_action", null),
//         price: _get(t, "trade_price", null),
//         stopLoss: _get(t, "trade_stopLoss", null),
//         targetSell: _get(t, "trade_targetSell", null),
//         exchange: _get(t, "trade_exchange", null),
//         status: _get(t, "trade_status", null),
//         is_percent: _get(t, "trade_is_percent", null),
//     }));
// };

// export default executeCustomerTrades;
