import React from "react";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Text from "components/common/Text";
import OTPInput from "react-otp-input";
import Card from "components/common/Card";
import { styled } from "@mui/material";
import Verified from "asset/icons/Verified";

//  This is Customer Details component
const OtpButton = styled(Button)(() => ({
	borderRadius: "8px",
	fontSize: "16px",
	fontWeight: 600,
	padding: "12px 35px",
}));
const OtpVerficationBox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	marginTop: "20px",
	marginBottom: "10px",
}));
const OtpVerficationText = styled(Text)(() => ({
	fontSize: "14px",
	color: "#242424",
	fontWeight: "500px",
}));
const OtpBox = styled(Box)(() => ({
	display: "flex",
	flexDirection: "row",
	marginLeft: "-5px",
	marginBottom: "24px",
}));

const otpFieldStyle = {
	width: 72,
	height: 60,
	borderRadius: "10px",
	border: "1px solid #CBCBCB",
	color: "black",
	margin: "0 5px",
	fontSize: "15px",
};

function CustomerDetails({
  onBoardData,
  initialOnBoardData,
  onBoardDataError,
  handleBasicFormChange,
  handleOnBoardFormChange,
  handleOnBoardEmailValidation,
  handleOnBoardEmailOtpSend,
  handleOnBoardEmailOtpSubmit,
  handleOnBoardPhoneOtpSend,
  handleOnBoardPhoneOtpSubmit,
  otpOnBoardValidation,
  formData,
  handleEmailOtpSubmit,
  handlePhoneOtpSubmit,
  handleEmailOtpSend,
  handlePhoneOtpSend,
  isEmailOtpSend,
  isPhoneOtpSend,
  isEmailVerified,
  basicDetailsError,
  emailSec,
  emailOtpActive,
  phoneSec,
  phoneOtpActive,
  isEmailOnboardVerified
}) {
  
  return (
    <>
      {initialOnBoardData === null ? (
        <Card sx={{ borderRadius: "16px", p: 5, mb: 8 }}>
          <Box sx={{ mb: "28px" }}>
            <Text variant="subtitle1" color="#242424">
              Customer Details
            </Text>
          </Box>

          <Box sx={{ mb: "24px" }}>
            <Text sx={{ mb: "10px", color: "#242424", fontSize: "14px" }}>
              Customer name
            </Text>
            <TextField
              onChange={({ target }) => {
                handleBasicFormChange(target.name, target.value);
              }}
              sx={{ width: "494px" }}
              name="name"
              placeholder="Enter Customer name"
              value={formData.name}
            />
          </Box>

          <Stack direction="row" justifyContent="start" alignItems="flex-end">
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ mb: "10px" }}
              >
                <Text sx={{ color: "#242424", fontSize: "14px", mr: 1 }}>
                  Email ID
                </Text>
                {isEmailVerified && <Verified />}
              </Stack>

              <TextField
                sx={{ mr: "20px", width: "494px" }}
                name="email"
                placeholder="Enter email ID"
                onChange={({ target }) => {
                  handleBasicFormChange(target.name, target.value);
                }}
                value={formData.email}
                disabled={isEmailVerified}
              />
            </Box>
            <OtpButton
              onClick={handleEmailOtpSend}
              disabled={!formData.name || !formData.email || isEmailOtpSend}
              type="submit"
            >
              Send OTP
            </OtpButton>
          </Stack>
          {basicDetailsError.email && (
            <Text sx={{ py: 1 }} variant="small" color="red">
              {basicDetailsError.email}
            </Text>
          )}

          {isEmailOtpSend && !isEmailVerified ? (
            <Box>
              <OtpVerficationBox>
                <OtpVerficationText>OTP Verification</OtpVerficationText>
                {emailOtpActive ? (
                  <Text
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      lineHeight: 0,
                    }}
                  >
                    Resend OTP in 00: {emailSec}
                  </Text>
                ) : (
                  <Text
                    onClick={handleEmailOtpSend}
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    Resend OTP
                  </Text>
                )}
              </OtpVerficationBox>

              <OtpBox>
                <Box>
                  <OTPInput
                    value={formData.emailOtp}
                    onChange={(value) => {
                      handleBasicFormChange("emailOtp", value);
                    }}
                    isInputNum={true}
                    numInputs={6}
                    placeholder="0"
                    inputStyle={{
                      ...otpFieldStyle,
                    }}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {basicDetailsError.emailOtp && (
                    <Text sx={{ py: 1 }} variant="small" color="red">
                      {basicDetailsError.emailOtp}
                    </Text>
                  )}
                </Box>
                <OtpButton sx={{ ml: 2.5 }} onClick={handleEmailOtpSubmit}>
                  Confirm OTP
                </OtpButton>
              </OtpBox>
            </Box>
          ) : null}

          <Box sx={{ mt: "24px" }}>
            <Text sx={{ mb: "10px", color: "#242424", fontSize: "14px" }}>
              Phone Number
            </Text>
            <TextField
              sx={{ mr: "20px", width: "494px" }}
              name="phoneNo"
              placeholder="Enter phone number"
              value={formData.phoneNo}
              onChange={({ target }) => {
                handleBasicFormChange(target.name, target.value);
              }}
              disabled={!isEmailVerified}
            />
            <OtpButton
              onClick={handlePhoneOtpSend}
              disabled={
                !formData.name ||
                !isEmailVerified ||
                !formData.phoneNo ||
                isPhoneOtpSend
              }
              type="submit" // Add type="submit" to trigger form submission
            >
              Send OTP
            </OtpButton>
          </Box>
          {basicDetailsError.phoneNo && (
            <Text sx={{ py: 1 }} variant="small" color="red">
              {basicDetailsError.phoneNo}
            </Text>
          )}

          {isPhoneOtpSend ? (
            <Box>
              <OtpVerficationBox>
                <OtpVerficationText>OTP Verification</OtpVerficationText>
                {phoneOtpActive ? (
                  <Text
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      lineHeight: 0,
                    }}
                  >
                    Resend OTP in 00: {phoneSec}
                  </Text>
                ) : (
                  <Text
                    onClick={handlePhoneOtpSend}
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    Resend OTP
                  </Text>
                )}
              </OtpVerficationBox>

              <OtpBox>
                <Box>
                  <OTPInput
                    value={formData.phoneOtp}
                    onChange={(value) => {
                      handleBasicFormChange("phoneOtp", value);
                    }}
                    isInputNum={true}
                    numInputs={6}
                    placeholder="0"
                    inputStyle={{ ...otpFieldStyle }}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {basicDetailsError.phoneOtp && (
                    <Text sx={{ py: 1 }} variant="small" color="red">
                      {basicDetailsError.phoneOtp}
                    </Text>
                  )}
                </Box>

                <OtpButton sx={{ ml: 2.5 }} onClick={handlePhoneOtpSubmit}>
                  Confirm OTP
                </OtpButton>
              </OtpBox>
            </Box>
          ) : null}
        </Card>
      ) : (
        <Card
          sx={{ borderRadius: "16px", p: 5, mb: 8 }}
        >
          <Box sx={{ mb: "28px" }}>
            <Text variant="subtitle1" color="#242424">
              Customer Details
            </Text>
          </Box>

          <Box sx={{ mb: "24px" }}>
            <Text sx={{ mb: "10px", color: "#242424", fontSize: "14px" }}>
              Customer name
            </Text>
            <TextField
              onChange={({ target }) => {
                handleOnBoardFormChange(target.name, target.value);
              }}
              sx={{ width: "494px" }}
              name="name"
              placeholder="Enter Customer name"
              value={onBoardData?.name}
            />
          </Box>

          <Stack direction="row" justifyContent="start" alignItems="flex-end">
            <Box>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                sx={{ mb: "10px" }}
              >
                <Text sx={{ color: "#242424", fontSize: "14px", mr: 1 }}>
                  Email ID
                </Text>
                {(onBoardData?.is_email_verified === "Y" || isEmailOnboardVerified) && <Verified />}
              </Stack>

              <TextField
                sx={{ mr: "20px", width: "494px" }}
                name="email"
                placeholder="Enter email ID"
                onChange={({ target }) => {
                  handleOnBoardFormChange(target.name, target.value);
                }}
                value={onBoardData?.email}
                disabled={onBoardData?.is_email_verified === "Y" || isEmailOnboardVerified}
              />
            </Box>
            <OtpButton
              onClick={handleOnBoardEmailOtpSend}
              disabled={
                !onBoardData?.name ||
                !onBoardData?.email ||
                onBoardData?.is_email_verified === "Y" ||
                isEmailOtpSend ||
                isEmailOnboardVerified
              }
              type="submit"
            >
              Send OTP
            </OtpButton>
          </Stack>
          {onBoardDataError.email && (
            <Text sx={{ py: 1 }} variant="small" color="red">
              {onBoardDataError.email}
            </Text>
          )}

          {isEmailOtpSend && !isEmailVerified ? (
            <Box>
              <OtpVerficationBox>
                <OtpVerficationText>OTP Verification</OtpVerficationText>
                {emailOtpActive ? (
                  <Text
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      lineHeight: 0,
                    }}
                  >
                    Resend OTP in 00: {emailSec}
                  </Text>
                ) : (
                  <Text
                    onClick={handleOnBoardEmailOtpSend}
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    Resend OTP
                  </Text>
                )}
              </OtpVerficationBox>

              <OtpBox>
                <Box>
                  <OTPInput
                    value={onBoardData?.emailOtp}
                    onChange={(value) => {
                      console.log("emailOTP----------------", value); // Add a console log to check the input value
                      handleOnBoardFormChange("emailOtp", value); // Call handleOnBoardFormChange with the input value
                    }}
                    isInputNum={true}
                    numInputs={6}
                    placeholder="0"
                    inputStyle={{
                      ...otpFieldStyle,
                    }}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />} // Make sure renderInput is correctly set up
                  />
                  {onBoardDataError.emailOtp && (
                    <Text sx={{ py: 1 }} variant="small" color="red">
                      {onBoardDataError.emailOtp}
                    </Text>
                  )}
                </Box>
                <OtpButton
                  sx={{ ml: 2.5 }}
                  onClick={handleOnBoardEmailOtpSubmit}
                >
                  Confirm OTP
                </OtpButton>
              </OtpBox>
            </Box>
          ) : null}

          <Box sx={{ mt: "24px" }}>
            <Text sx={{ mb: "10px", color: "#242424", fontSize: "14px" }}>
              Phone Number
            </Text>
            <TextField
              sx={{ mr: "20px", width: "494px" }}
              name="mobile"
              placeholder="Enter phone number"
              value={onBoardData?.mobile}
              onChange={({ target }) => {
                handleOnBoardFormChange(target.name, target.value);
              }}
              disabled={onBoardData?.is_mobile_verified === "Y"||  !isEmailOnboardVerified }
            />
            <OtpButton
              onClick={handleOnBoardPhoneOtpSend}
              disabled={
                !onBoardData?.name ||
                onBoardData?.is_mobile_verified === "Y" ||
                 !isEmailOnboardVerified ||
                !onBoardData?.mobile ||
                isPhoneOtpSend
              }
              type="submit" // Add type="submit" to trigger form submission
            >
              Send OTP
            </OtpButton>
          </Box>
          {onBoardDataError?.phoneNo && (
            <Text sx={{ py: 1 }} variant="small" color="red">
              {onBoardDataError?.phoneNo}
            </Text>
          )}

          {isPhoneOtpSend ? (
            <Box>
              <OtpVerficationBox>
                <OtpVerficationText>OTP Verification</OtpVerficationText>
                {phoneOtpActive ? (
                  <Text
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      lineHeight: 0,
                    }}
                  >
                    Resend OTP in 00: {phoneSec}
                  </Text>
                ) : (
                  <Text
                    onClick={handleOnBoardPhoneOtpSend}
                    variant="small"
                    sx={{
                      fontWeigth: 500,
                      color: "#1A54B9",
                      ml: 1,
                      cursor: "pointer",
                    }}
                  >
                    Resend OTP
                  </Text>
                )}
              </OtpVerficationBox>

              <OtpBox>
                <Box>
                  <OTPInput
                    value={onBoardData?.phoneOtp}
                    onChange={(value) => {
                      handleOnBoardFormChange("phoneOtp", value);
                    }}
                    isInputNum={true}
                    numInputs={6}
                    placeholder="0"
                    inputStyle={{ ...otpFieldStyle }}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {onBoardDataError.phoneOtp && (
                    <Text sx={{ py: 1 }} variant="small" color="red">
                      {onBoardDataError.phoneOtp}
                    </Text>
                  )}
                </Box>

                <OtpButton
                  sx={{ ml: 2.5 }}
                  onClick={handleOnBoardPhoneOtpSubmit}
                >
                  Confirm OTP
                </OtpButton>
              </OtpBox>
            </Box>
          ) : null}
        </Card>
      )}
    </>
  );
}

export default CustomerDetails;
