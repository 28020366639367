import React from "react";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import { styled } from "@mui/material";

const QuestionBox = styled(Box)(({}) => ({
  minWidth: "100%",
  maxHeight: "510px",
  overflowY: "auto",
  backgroundColor: "#F7F8FF",
  scrollbarWidth: "thin",
}));

const QuestionidBox = styled(Text)(({}) => ({
  marginRight: "20px",
  backgroundColor: "green",
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
}));

const Questions = ({ 
  currentQtsId, 
  qts = [], 
  JumpToPrevQuestion,
  riskDetailsAnswer=[]
}) => {
  const answeredQ = riskDetailsAnswer.map( a => a.question_id) || [];
  const qtsList = qts.map( q => {
    let tillCurrentQuestion = (currentQtsId >= q.questionId);
    let hasAnswered = answeredQ.includes(q.questionId);
    let isCurrentQtsId = currentQtsId === q.questionId;

    let circleBgColor = "#ffffff";
    let circleBrdColor = "";

    if(isCurrentQtsId){
      circleBgColor = "#e5eeff";
      circleBrdColor = "#1A54B9";
    }else{
      if(hasAnswered){
        circleBgColor = "green";
        circleBrdColor = "green";
      }
      if(hasAnswered && (currentQtsId < q.questionId)){
        circleBgColor = "#9c9c9c";
        circleBrdColor = "#9c9c9c";
      }
    }

    return {
      ...q,
      tillCurrentQuestion: tillCurrentQuestion,
      hasAnswered: hasAnswered,
      isCurrentQtsId: isCurrentQtsId,
      circleBgColor: circleBgColor,
      circleBrdColor: circleBrdColor,
      displayTickSymbol: (hasAnswered && !isCurrentQtsId)
    }
  });


  
  return (
    <>
      <QuestionBox>
        <Stack>
          {qtsList?.map((item) => (
            <Stack key={item.questionId} id={`ques-ref-${item.questionId}`} spacing={2}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "#142E56",
                  width: "100%",
                  cursor: item.hasAnswered ? "pointer": "unset",
                }}
                onClick={() => item.hasAnswered ? JumpToPrevQuestion(item.questionId): null}
              >
                  <Stack
                    sx={{
                      mr: "20px",
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid",
                      overflow: "hidden",
                      borderColor:  item.circleBrdColor,
                      backgroundColor: item.circleBgColor,
                      "&:hover .num-symbol":{
                        display: "flex",
                      },
                      "&:hover .tick-symbol":{
                        display: "none"
                      }
                    }}
                  >
                    <Box
                      sx={{
                        display: item.displayTickSymbol ? "flex":"none",
                        color: "white",
                        transition:"all 0.5s ease-in-out"
                      }}
                      className="tick-symbol"
                    >✔</Box>
                    <Text 
                      sx={{ 
                        display: !item.displayTickSymbol ? "flex" : "none",
                        fontSize: "12px", 
                        color: item.displayTickSymbol ? "#fff": "#000",
                        fontWeight: (item.hasAnswered) ? "bold": "",
                        transition:"all 0.5s ease-in-out",
                      }}
                      className="num-symbol"
                    >{item.questionId}</Text>
                  </Stack>

                {item.isCurrentQtsId ? 
                <Box 
                sx={{ 
                  width: "400px", 
                  backgroundColor:'#EAF2FF', 
                  borderRadius:'8px', 
                  borderColor : '#1A54B9',
                  border:'1px solid',
                  mb: "20px" }}>
                  <Text
                    sx={{
                      m: "8px",
                      color: "#000",
                    }}
                    variant="body2"
                  >
                    {item.questionDescription}
                  </Text>
                </Box> 
                :
                 <Box sx={{ width: "400px" }}>
                 <Text
                   sx={{
                     mb: "20px",
                     color: item.tillCurrentQuestion ? "green" : "#000",
                     "&:hover":{
                      fontWeight: item.hasAnswered? "bold": "",
                    },
                   }}
                   variant="body2"
                 >
                   {item.questionDescription}
                 </Text>
               </Box>

                }
                {/* <Box sx={{ width: "400px" }}>
                  <Text
                    sx={{
                      mb: "20px",
                      color: currentQtsId > item.questionId ? "green" : "#000",
                    }}
                    variant="body2"
                  >
                    {item.questionDescription}
                  </Text>
                </Box> */}

              </Stack>
            </Stack>
          ))}
        </Stack>
      </QuestionBox>
    </>
  );
};

export default Questions;
