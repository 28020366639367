

// Common Components.
import Box from "components/common/Box";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import TextField from "components/common/TextField";

import DatePicker from "components/common/DatePicker";


const CustomAddFilter = (props) => {
    const { handleChange, filterData } = props;
    return (
      <>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="flex-end" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Text sx={{ mb: "6px" }}>Profit range (%)</Text>
              <TextField
                placeholder="Eg. 5%"
                name="profitFrom"
                onChange={(e) => {
                  handleChange({ name: e.target.name, value: e.target.value });
                }}
                value={filterData.profitFrom}
              />
            </Box>
            <TextField
              sx={{ flex: 1 }}
              placeholder="Eg. 10%"
              name="profitTo"
              onChange={(e) => {
                handleChange({ name: e.target.name, value: e.target.value });
              }}
              value={filterData.profitTo}
            />
          </Stack>
          <Stack direction="row" alignItems="flex-end" spacing={2}>
            <Box sx={{ flex: 1 }}>
              <Text sx={{ mb: "6px" }}>Date range</Text>
              <DatePicker
                placeholder="Select Date"
                name="fromData"
                onChange={(value) => {
                  handleChange({ name: "fromDate", value: value });
                }}
                value={filterData.fromDate}
              />
            </Box>
            <DatePicker
              sx={{ flex: 1 }}
              placeholder="Select Date"
              name="toData"
              onChange={(value) => {
                handleChange({ name: "toDate", value: value });
              }}
              value={filterData.toDate}
            />
          </Stack>
        </Stack>
      </>
    );
  };



  export default CustomAddFilter;