import { Card, CardContent } from "@mui/material";
import Box from "components/common/Box";
import Button from "components/common/Button";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomDataGrid from "components/datagrid/CustomGrid";
import { modelImportTableRows } from "helpers/constants";
import { useEffect, useState } from "react";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import StockTradeDrawer from "./customStockTradeDrawer";

// icon
import KeyboardBackspaceSharpIcon from '@mui/icons-material/KeyboardBackspaceSharp';

// Component definition
const TradeUpdateStackTable = (props) => {
  const {
    StackList, // List of trades
    customerColumns, // Columns for the data grid
    onRowSelectionModelChange, // Callback function for row selection change
    backtoMainScreen,
    productId
  } = props;

  // State variables
  const [StackTradeId, setStackTradeId] = useState(); // ID of the selected trade
  const [expandedTradeId, setExpandedTradeId] = useState(null); // ID of the expanded trade
  const [updatedTradeId, setupdatedTradeId] = useState(null); // ID of the expanded trade
  const [sideDrawerOpen1, setSideDrawerOpen1] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);


  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5
  });

  // Function to handle trade detail view
  const onViewDetail = (tradeId) => {
    setStackTradeId(tradeId);
    setExpandedTradeId((prevTradeId) =>
      prevTradeId === tradeId ? null : tradeId
    );
  };

  // API call to get trade table data based on trade ID
  const [getTradeDetailsUsingId, { data: tradeTableData }] = tradesApiAction.getTradeDetailsUsingId();
  const [handleDataforUpdateCall] = tradesApiAction.getTradeUpdateData();


  useEffect(() => {
    if (StackTradeId) {
      getTradeDetailsUsingId({productId, StackTradeId});
    }
  }, [StackTradeId]);

  // Filtering trade data based on a flag
  const tradeData = StackList?.result?.filter((trade) => trade.flag === 1) || [];
  const tradeDataForTable = tradeTableData?.result || [];

  useEffect(()=>{},[StackList,tradeData])

  // Adding IDs to trade data for table
  const tradeDataForTableWithId = tradeDataForTable.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  const openSidebar = async (trade_id) => {
    const id = trade_id;
    setupdatedTradeId(id);
    if (!id) {
      console.error("No trade ID provided.");
      return;
    }

    const payload = { trades: [parseInt(id)] };
    try {
      const response = await handleDataforUpdateCall(payload);
      console.log("API Response:", response);
      setApiResponse(response);
      setSideDrawerOpen1(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const recallDataOnUpdate = ({tradeId}) =>{
    getTradeDetailsUsingId({productId, StackTradeId: tradeId});
    openSidebar(updatedTradeId);
  }

  // Function to close side drawer
  const closeSideDrawer1 = () => {
    setSideDrawerOpen1(false);
  };

  return (
    <Box>
      {/* back to main page. */}
      <Box sx={{
         marginTop: "10px", 
         cursor: "pointer",
         position: "sticky", 
         top: "0px", 
         backgroundColor: "#ffffff80", 
         zIndex: "9",
         display: "inline-block"
      }} >
        <KeyboardBackspaceSharpIcon onClick={backtoMainScreen}/>
      </Box>

      <Stack>
        {tradeData.map((trade) => (
          <div key={trade.trade_id}>
            <Card style={{ marginBottom: "10px", marginTop: "10px" }}>
              <CardContent
                sx={{
                  backgroundColor: "#FFF5E0",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid #F2994A",
                  borderRadius: "8px",
                  padding: "9px 16px !important"
                }}
              >
                {/* Displaying trade details */}
                <Text variant="body2" component="h2" sx={{flex: 1}}>
                  stock: {trade.symbol}
                </Text>
                <Text variant="body2" color="textSecondary" sx={{flex: 1}}>
                  Total Customers: {trade.total_customer}
                </Text>

                <Box sx={{flex: 2, display: "flex", justifyContent: "end", gap: "40px"}}>
                {/* Button to view trade detail */}
                  <Button
                    onClick={() => onViewDetail(trade.trade_id)}
                    variant="contained"
                    color="primary"
                    sx={{width: "110px"}}
                    >
                    {expandedTradeId === trade.trade_id ? "Close" : "View Detail"}
                  </Button>
                    {/* Button to send call */}
                  <Button
                    onClick={() => openSidebar(trade.trade_id)}
                    variant="contained"
                    sx={{ backgroundColor: "#219653" }}
                  >
                    Update Trade
                  </Button>
                </Box>
              </CardContent>
            </Card>

            {/* Displaying data grid if trade detail is expanded */}
            {expandedTradeId === trade.trade_id && (
              <CustomDataGrid
                autoHeight
                list={tradeDataForTableWithId}
                columnHeaderHeight={46}
                rowHeight={60}
                columns={customerColumns}
                onRowSelectionModelChange={onRowSelectionModelChange}
                // pagination
                pagination
                rowCount={tradeDataForTableWithId?.length || 0}
                paginationMode= "client" // client, server
                paginationModel={tradesPaginationModel}
                onPaginationModelChange={setTradesPaginationModel}
              />
            )}
          </div>
        ))}
      </Stack>
      <StockTradeDrawer
        apiResponse={apiResponse}
        sideDrawerOpen1={sideDrawerOpen1}
        closeSideDrawer1={closeSideDrawer1}
        recallDataOnUpdate={recallDataOnUpdate}
        productId={productId}
      />
    </Box>
  );
};

export default TradeUpdateStackTable;
