
import Dialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Image from "components/common/Image";
import Button from "components/common/Button";
import { useState } from "react";

const RiskProfileModal = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    // handleRiskProfileClick = () => {},
    // selectedRiskProfile = null,
    proceedToImportTrade = () => {},
    backToPreviewTable
  } = props;

  // State to keep track of the selected risk profile
  const [selectedProfile, setSelectedProfile] = useState([1]);

  // Function to handle risk profile click
  const handleClick = (profile) => {
    const newProfile = [...selectedProfile];
    const hasProfile = newProfile.findIndex( (p) => p === profile)
    if(hasProfile === -1){
      newProfile.push(profile);
    }else{
      newProfile.splice(hasProfile, 1);
    }
    setSelectedProfile(newProfile); // Update the selected profile state
    // handleRiskProfileClick(profile); // Pass the selected profile value to the container
  };

  return (
    <Dialog
      onClose={() => handleClose(false)}
      open={isOpen}
      enableBackdropClick={false}
      title={
        <Text
          fontSize="20px"
          fontWeight="600"
          marginBottom="20px"
          marginTop="20px"
          marginLeft="30px"
        >
          Select one risk profile of the customers to whom the following trade will apply
        </Text>
      }
      disableCloseIcon
      maxWidth="lg"
      contentComponent={() => (
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
          {/* Card 1 */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick(1)} // Pass "aggressive" when clicked
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "50px",
              width: "250px",
              height: "60px",
              cursor: "pointer",
              backgroundColor: selectedProfile.includes(1) ? "#e5e8fc" : "transparent",
            }}
          >
            <Image src="/images/aggressive.svg" />
            <Text fontSize="20px" fontWeight="500" color="red" sx={{ mt: 2 }}>
              Aggressive risk profile
            </Text>
          </Stack>

          {/* Card 2 */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick(2)} // Pass "moderate" when clicked
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "50px",
              width: "250px",
              height: "60px",
              cursor: "pointer",
              backgroundColor: selectedProfile.includes(2) ? "#e5e8fc" : "transparent",
            }}
          >
            <Image src="/images/moderate.svg" />
            <Text fontSize="20px" fontWeight="500" color="orange" sx={{ mt: 2 }}>
              Moderate risk profile
            </Text>
          </Stack>

          {/* Card 3 */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick(3)} // Pass "conservative" when clicked
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "50px",
              width: "250px",
              height: "60px",
              cursor: "pointer",
              backgroundColor: selectedProfile.includes(3) ? "#e5e8fc" : "transparent",
            }}
          >
            <Image src="/images/conservative.svg" />
            <Text fontSize="20px" fontWeight="500" color="green" sx={{ mt: 2 }}>
              Conservative risk profile
            </Text>
          </Stack>
        </Stack>
      )}
      actionComponent={() => (
        <Stack alignItems="flex-end" sx={{
          flexFlow: "row nowrap", 
          justifyContent: "end", 
          alignItems: "center", 
          gap: "20px",
          mt: "25px",
          mb: "25px"
        }}>
          <Button
            onClick={backToPreviewTable}
            variant="outlined"
            sx={{
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
            }}
          >
            Previous
          </Button>
          <Button
            sx={{
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
              mr: 6.5,
            }}
            onClick={() => proceedToImportTrade(selectedProfile)}
            disabled={!selectedProfile.length}
          >
           Proceed to next step
          </Button>
        </Stack>
      )}
    />
  );
};

export default RiskProfileModal;

