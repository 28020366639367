import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducerName = "customerOnboarding";
const initialState = {
  userId: null,
};

const customerOnboardingSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setUserId: (state, action) => {
      return {
        ...state,
        userId: action.payload.userId,
      };
    },
    resetCustomer: (state, action) => {
      return {
        ...initialState,
      };
    },
  },
});

export const customerOnboardingActions = customerOnboardingSlice.actions;
export const customerOnboardingReducers = persistReducer(
  { key: customerOnboardingSlice, storage, whitelist: [] },
  customerOnboardingSlice.reducer
);
