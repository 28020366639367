// This is container for Customer Change risk
import CustomerChangeRisk from "components/customerOnboarding/CustomerChangeRisk";
import CustomerRiskTracker from "components/customerOnboarding/CustomerRiskTracker";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { commonApiAction } from "stores/redux/apiSlices/commonApiSlice";
import { useLocation } from "react-router-dom";

function CustomerMeter({ handleStepChange,onBoardData ,resData }) {
	const location = useLocation();
	const [isChangeRisk, setIsChangeRisk] = useState(false);
	const [selectedRisk, setSelectedRisk] = useState(null);
	const [previousRisk, setPreviousRisk] = useState(null);
	const [currentRisk, setCurrentRisk] = useState({});
	const [riskProfileData, setRiskProfileData] = useState({});

	//below to line is to pass user id
	const userDetails = useSelector((state) => state.commonReducer.userDetails);

	//Redux state
	const customerRiskDetails = useSelector((state) => state?.addCustomerSlice?.customerDetails);

	const payload = {
		
	}

	//Rtk Apis
	const [updateRiskProfileAction, { data }] = addCustomerApiAction.updateRiskProfile();
	const { data: allRiskProfiles } = commonApiAction.getRiskProfiles();
	const [getCustomerRisk, riskProfile] = addCustomerApiAction.getCustomerRiskProfile();

	useEffect(() => {
		  getCustomerRisk({ userId: userDetails.user_id }, false);
	},[userDetails.user_id]);

	//Use Effects
	React.useEffect(() => {
		if (riskProfile?.data?.riskProfile?.[0]) {
			let risk = riskProfile?.data?.riskProfile?.[0];
			setRiskProfileData(risk);
			const currentRiskProfile =
				allRiskProfiles?.find((item) => item.name === risk.risk_profile_name) ?? {};
			setCurrentRisk(currentRiskProfile);
			setSelectedRisk(risk?.selected_risk_profile_id);
		}
	}, [riskProfile?.data,allRiskProfiles]);

	const changeRiskClick = () => {
		setIsChangeRisk(!isChangeRisk);
	};

	const handleRiskProfileChange = (id) => {	
		setSelectedRisk(id);

	};

	const handleRiskProfileSubmit = async () => {
		// let tempSelect = selectedRisk
		// if(tempSelect == null){
		// 	if(resData.type ==="Aggressive"){
		// 		tempSelect = 1
		// 	}
		// 	if(resData.type ==="Moderate"){
		// 		tempSelect = 2
		// 	}
		// 	if(resData.type ==="Conservative"){
		// 		tempSelect = 3
		// 	}
		// }

		try {
			const payload = {
				risk_profile_id: selectedRisk,
				userId: userDetails.user_id 
			};
			const res = await updateRiskProfileAction(payload);
			if (res) {
				handleStepChange(2);
			}
		} catch (err) {
			console.log(err);
		}
	};

	

	return (
		<>
			{!isChangeRisk ? (
				<CustomerRiskTracker
					changeRiskClick={changeRiskClick}
					currentRiskProfile={currentRisk}
					handleRiskProfileSubmit={handleRiskProfileSubmit}
					riskProfile={riskProfileData}
					handleStepChange={handleStepChange}
				/>
			) : (
				<CustomerChangeRisk
					handleRiskProfileChange={handleRiskProfileChange}
					handleRiskProfileSubmit={handleRiskProfileSubmit}
					selectedRisk={selectedRisk}
					// currentRiskProfile={resData}
				/>
			)}
		</>
	);
}

export default CustomerMeter;
