// dashboardSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const reducerName = "dashboardSlice";

const dashboardSlice = createSlice({
  name: reducerName,
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    setDashboardData: (state, action) => {
      state.data = action.payload;
    },
    setDashboardLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDashboardError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setDashboardData,
  setDashboardLoading,
  setDashboardError,
} = dashboardSlice.actions;

export const dashboardReducer = persistReducer(
  { key: reducerName, storage },
  dashboardSlice.reducer
);
