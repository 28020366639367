// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

// Create an API client named "Plans" using createApi function
const Plans = createApi({
  // Specify the name for the slice in the Redux store
  reducerPath: "Plans",

  // Use an interceptor for modifying API requests and responses
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,

  // Define tag types for cache invalidation
  tagTypes: ["GETPLANS", "CREATENEWPLAN", "UPDATEPLAN"],

  // Define API endpoints
  endpoints: (qb) => ({
    getPlansApi: qb.query({
      // Specify the API endpoint URL for fetching plans
      query: (noGlobalLoading = true) => ({
        url: `api/plan`, // Adjust the endpoint as needed
        params: { noGlobalLoading },
      }),

      // Transform the API response to extract the 'result' property
      transformResponse: (response) => response.result,

      // Provide a tag for cache invalidation associated with this endpoint
      providesTags: ["GETPLANS"],
    }),
    createNewPlanApi: qb.mutation({
      query: (data) => ({
        url: `api/plan`,
        method: "POST",
        body: {
          planName: data.planName,
          min_amount: data.min_amount,
          max_amount: data.max_amount,
          subCategories: data.subCategories.map((category) => ({
            frequency: category.frequency,
            custom: category.custom,
            custom_percent: category.custom_percent,
            algo: category.algo,
            algo_percent: category.algo_percent,
            modelportfolio: category.modelportfolio,
            modelportfolio_percent: category.modelportfolio_percent,
          })),
        },
        invalidatesTags: ["CREATENEWPLAN"],
      }),
    }),

    updateNewPlanApi: qb.mutation({
      query: (data) => ({
        url: `api/plan`,
        method: "PATCH",
        body: {
          plan_id: data.plan_id,
          planName: data.planName,
          min_amount: data.min_amount,
          max_amount: data.max_amount,
          subCategories: data.subCategories.map((category) => ({
            frequency: category.frequency,
            custom: category.custom,
            custom_percent: category.custom_percent,
            algo: category.algo,
            algo_percent: category.algo_percent,
            modelportfolio: category.modelportfolio,
            modelportfolio_percent: category.modelportfolio_percent,
          })),
        },
        invalidatesTags: ["UPDATEPLAN"],
      }),
    }),
  }),
});

// Export the reducer generated by the createApi function
export const PlansApiReducer = Plans.reducer;

// Export an object containing middleware, reducer path, and a function for making API requests
export const plansApiAction = {
  middleware: Plans.middleware,
  reducerPath: Plans.reducerPath,
  getPlansApi: Plans.useGetPlansApiQuery,
  createNewPlanApi: Plans.useCreateNewPlanApiMutation,
  updateNewPlanApi: Plans.useUpdateNewPlanApiMutation,
};
