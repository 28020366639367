import React from 'react'
import SvgIcon from "@mui/material/SvgIcon";

const FeatureIcon = (props) => {
  return (
    <SvgIcon {...props}>
        
<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1.16699" width="27" height="27" rx="13.5" fill="#F7F8FF"/>
<rect x="0.5" y="1.16699" width="27" height="27" rx="13.5" stroke="#142E56"/>
<path d="M13.9284 16.5032C13.8117 16.5032 13.695 16.4602 13.6029 16.3681L12.031 14.7961C11.8529 14.6181 11.8529 14.3233 12.031 14.1453C12.2091 13.9672 12.5038 13.9672 12.6819 14.1453L13.9284 15.3918L15.1749 14.1453C15.3529 13.9672 15.6477 13.9672 15.8258 14.1453C16.0038 14.3233 16.0038 14.6181 15.8258 14.7961L14.2538 16.3681C14.1617 16.4602 14.045 16.5032 13.9284 16.5032Z" fill="#142E56"/>
<path d="M13.9283 16.4607C13.6765 16.4607 13.4678 16.2519 13.4678 16.0002V9.75545C13.4678 9.50369 13.6765 9.29492 13.9283 9.29492C14.1801 9.29492 14.3888 9.50369 14.3888 9.75545V16.0002C14.3888 16.2519 14.1801 16.4607 13.9283 16.4607Z" fill="#142E56"/>
<path d="M14.0017 20.1507C10.8394 20.1507 8.62891 17.9402 8.62891 14.7779C8.62891 14.5262 8.83768 14.3174 9.08943 14.3174C9.34119 14.3174 9.54996 14.5262 9.54996 14.7779C9.54996 17.3998 11.3798 19.2297 14.0017 19.2297C16.6236 19.2297 18.4535 17.3998 18.4535 14.7779C18.4535 14.5262 18.6622 14.3174 18.914 14.3174C19.1657 14.3174 19.3745 14.5262 19.3745 14.7779C19.3745 17.9402 17.164 20.1507 14.0017 20.1507Z" fill="#142E56"/>
</svg>

    </SvgIcon>
  )
}

export default FeatureIcon