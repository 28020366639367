// import * as React from "react";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import ListItemText from "@mui/material/ListItemText";
// import Select from "@mui/material/Select";
// import Checkbox from "@mui/material/Checkbox";
// import Box from "components/common/Box";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 3;

// export default function DashboardMultipleSelect(props) {
//   const { dropDownData, dropDownLabel } = props;
//   const [selectedValues, setPersonName] = React.useState([]);

//   const handleChange = (event) => {
//     const {
//       target: { value },
//     } = event;
//     setPersonName(
//       // On autofill we get a stringified value.
//       typeof value === "string" ? value.split(",") : value
//     );
//   };

//   return (
//     <div>
//       <FormControl
//         sx={{
//           mr: 2,
//           width: 170,
//           backgroundColor: "white",
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center"
//         }}
//       >
//         <InputLabel
//           sx={{
//             color: "#242424",
//             fontSize: "14px",
//             fontWeight: 600,
//             position: "absolute",
//             top: "50%",
//             transform: "translateY(-50%)",
//             pointerEvents: "none",
//             zIndex: 1,
//             paddingLeft: "15px"
//           }}
//           id="demo-multiple-checkbox-label"
//         >
//           {dropDownLabel}
//         </InputLabel>

//         <Select
//           labelId="demo-multiple-checkbox-label"
//           id="demo-multiple-checkbox"
//           multiple
//           value={selectedValues}
//           onChange={handleChange}
//           renderValue={(selected) => {
//             const selectedOptions = dropDownData.filter((option) =>
//               selected.includes(option)
//             );
//             // return selectedOptions.join(", ");
//           }}
//           sx={{
//             width: 175,
//             height: 40,
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center"
//           }}
//         >
//           {dropDownData.map((name) => (
//             <MenuItem
//               sx={{ height: "40px", width: "175px" }}
//               key={name}
//               value={name}
//             >
//               <Checkbox
//                 sx={{
//                   width: "8px",
//                   height: "8px",
//                   borderRadius: "20px",
//                   mr: "7px",
//                   color: "black",
//                   "&.Mui-checked": {
//                     color: "black"
//                   }
//                 }}
//                 size="small"
//                 checked={selectedValues.indexOf(name) > -1}
//               />
//               <ListItemText
//                 sx={{ color: "#101828", fontSize: "8px", fontWeight: 200, mr: 1 }}
//                 primary={name}
//               />
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </div>
//   );
// }

import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "components/common/Box";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 3;

export default function DashboardMultipleSelect(props) {
  const { 
    dropDownData=[], 
    dropDownLabel, 
    handleChange, 
    selectedValues
  } = props;
  console.log("selectedValues => ",selectedValues, dropDownData);

 
  return (
    <div>
      <FormControl
        sx={{
          mr: 2,
          width: 170,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <InputLabel
          sx={{
            color: "#242424",
            fontSize: "14px",
            fontWeight: 600,
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            pointerEvents: "none",
            zIndex: 1,
            paddingLeft: "15px"
          }}
          id="demo-multiple-checkbox-label"
        >
          {dropDownLabel}
        </InputLabel>

        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          renderValue={(selected) => {
            const selectedOptions = dropDownData.filter((option) =>
              selected.includes(option)
            );
            // return selectedOptions.join(", ");
          }}
          sx={{
            width: 175,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {dropDownData.map((v, i) => (
            <MenuItem
              sx={{ height: "40px", width: "175px" }}
              key={`product_list_${i}`}
              value={v.id}
            >
              <Checkbox
                sx={{
                  width: "8px",
                  height: "8px",
                  borderRadius: "20px",
                  mr: "7px",
                  color: "black",
                  "&.Mui-checked": {
                    color: "black"
                  }
                }}
                size="small"
                checked={selectedValues.indexOf(v.id) > -1}
              />
              <ListItemText
                sx={{ color: "#101828", fontSize: "8px", fontWeight: 200, mr: 1 }}
                primary={v.displayName}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

