import React, { useState } from "react";
// import Tab from "components/common/Tab";
import Box from "components/common/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Text from "components/common/Text";
import DetailsTable from "./DetailsTable";
import AllocationTable from "./AllocationTable";
import SummaryTable from "./SummaryTable";
import { useLocation } from "react-router-dom";
import GainLossTable from "./GainLossTable";

const InvestmentTableTab = ({
  memberID,
  familyList,
  blockID,
  showTableTab,
  customerId,
  selectedId
}) => {
  const location = useLocation();
  const cardDataDetail = location.state && location.state.cardData;
  const productId = cardDataDetail?.id;
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#FFFFFF",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label={
                <Text
                  variant="medium"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px" }}
                >
                  Summary
                </Text>
              }
              value="1"
            />
              {(productId === 1 || productId === 2)  ? (
               <Tab
               label={
                 <Text
                   variant="small"
                   component="h5"
                   fontWeight="600"
                   style={{ marginLeft: "10px" }}
                 >
                   Details
                 </Text>
               }
               value="2"
             />
            ) : (
              ""
            )}
            {productId === 2 ? (
              <Tab
                label={
                  <Text
                    variant="small"
                    component="h5"
                    fontWeight="600"
                    style={{ marginLeft: "10px" }}
                  >
                    Allocation
                  </Text>
                }
                value="3"
              />
            ) : (
              ""
            )}
             {(productId === 1 || productId === 2)  ? (
               <Tab
               label={
                 <Text
                   variant="small"
                   component="h5"
                   fontWeight="600"
                   style={{ marginLeft: "10px" }}
                 >
                  Gain/Loss
                 </Text>
               }
               value="4"
             />
            ) : (
              ""
            )}
          </TabList>
        </Box>
        <TabPanel value="1" style={{ padding: "0px", marginTop: "25px" }}>
          <SummaryTable
            blockID={blockID}
            familyList={familyList}
            showTableTab={showTableTab}
            customerId={customerId}
          />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "0px", marginTop: "25px" }}>
          <DetailsTable
            blockID={blockID}
            familyList={familyList}
            customerId={customerId}

          />
        </TabPanel>
        <TabPanel value="3" style={{ padding: "0px", marginTop: "25px" }}>
          <AllocationTable
            memberID={memberID}
            blockID={blockID}
            familyList={familyList}
          />
        </TabPanel>
        <TabPanel value="4" style={{ padding: "0px", marginTop: "25px" }}>
          <GainLossTable
            blockID={blockID}
            familyList={familyList}
            customerId={customerId}
            selectedId={selectedId}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default InvestmentTableTab;
