import Box from "components/common/Box";
import React, { useState, useEffect } from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import { GainDetail } from "../../helpers/constants";
import Chip from "@mui/material/Chip";
import CustomGrid from "components/datagrid/CustomGrid";
import Dialog from "components/common/Dialog";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import MovingIcon from "@mui/icons-material/Moving";
import Checkbox from "components/common/Checkbox";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { uuid } from "helpers/utility";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import Card from "components/common/Card";
import { useLocation } from "react-router-dom";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";

const CustomerHeader = (modifiedgainLossTableData) => {
  const hasData = modifiedgainLossTableData?.length > 0;

  return hasData ? (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Text
          sx={{ color: "primary", mr: 1, fontWeight: 500 }}
          color="primary"
          variant="button"
        >
          Gain/Loss
        </Text>
      </Stack>
    </Box>
  ) : null;
};

const GainLossTable = ({ customerId, blockID, selectedId }) => {
  const location = useLocation();
  const select = useSelector((state) => state);
  const user_id = select?.toast?.breadCrumbData?.user_id;
  const cardDataDetail = location.state && location.state.cardData;
  const productId = cardDataDetail?.id;
  const [getPerformanceDetailDataAPI, getPerformanceDetail = []] =
    customersApiAction.getSummaryDetailApi();
  const gainLossTableData = getPerformanceDetail?.data?.data?.gainLoss ?? [];
  const gainLossTotalData =
    getPerformanceDetail?.data?.data?.gainLossTotal ?? [];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const modifiedgainLossTableData = gainLossTableData.map((row) => ({
    ...row,
    id: uuid(),
  }));
  const modifiedgainLossTotalData = gainLossTotalData.map((row) => ({
    ...row,
    id: uuid(),
  }));

  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {
    if (
      productId &&
      customerId &&
      paginationModel.page !== undefined &&
      paginationModel.pageSize !== undefined
    ) {
      getPerformanceDetailDataAPI({
        product_id: productId,
        user_id: customerId,
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      });
    }
  }, [productId, customerId]);

  const handleCancel = () => {
    setOpenModel(false);
  };
  const handleBuy = () => {
    setOpenModel(false);
  };

  const GainTableHeader = [
    {
      field: "stock_name",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "current_value",
      headerName: "Current value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.current_value
                ? `₹ ${Math.abs(params?.row?.current_value)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "short_term_unrealized_gains",
      headerName: "Short term unrealized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.short_term_unrealized_gains &&
          params?.row?.short_term_unrealized_gains !== 0 ? (
            params?.row?.short_term_unrealized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.short_term_unrealized_gains
                    ? `₹ ${Math.abs(params?.row?.short_term_unrealized_gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.short_term_unrealized_gains
                    ? `₹ ${Math.abs(params?.row?.short_term_unrealized_gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "long_term_unrealized_gains",
      headerName: "Long term unrealized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.long_term_unrealized_gains &&
          params?.row?.long_term_unrealized_gains !== 0 ? (
            params?.row?.long_term_unrealized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.long_term_unrealized_gains
                    ? `₹ ${Math.abs(params?.row?.long_term_unrealized_gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.long_term_unrealized_gains
                    ? `₹ ${Math.abs(params?.row?.long_term_unrealized_gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "short_term_realized_gains",
      headerName: "Short terms realized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.short_term_realized_gains &&
          params?.row?.short_term_realized_gains !== 0 ? (
            params?.row?.short_term_realized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.short_term_realized_gains
                    ? `₹ ${Math.abs(params?.row?.short_term_realized_gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.short_term_realized_gains
                    ? `₹ ${Math.abs(params?.row?.short_term_realized_gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "long_term_realized_gains",
      headerName: "Long terms realized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.long_term_realized_gains &&
          params?.row?.long_term_realized_gains !== 0 ? (
            params?.row?.long_term_realized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.long_term_realized_gains
                    ? `₹ ${Math.abs(params?.row?.long_term_realized_gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.long_term_realized_gains
                    ? `₹ ${Math.abs(params?.row?.long_term_realized_gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "dividends",
      headerName: "Dividends",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.dividends
                ? `₹ ${Math.abs(params?.row?.dividends)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
  ];

  const GainTotalHeader = [
    {
      field: "Total",
      headerName: "Total",
      flex: 2,
    },
    {
      field: "total_short_term_unrealized_gains",
      headerName: "Total Short term unrealized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.total_short_term_unrealized_gains &&
          params?.row?.total_short_term_unrealized_gains !== 0 ? (
            params?.row?.total_short_term_unrealized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_short_term_unrealized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_short_term_unrealized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_short_term_unrealized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_short_term_unrealized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "total_long_term_unrealized_gains",
      headerName: "Total Long term unrealized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.total_long_term_unrealized_gains &&
          params?.row?.total_long_term_unrealized_gains !== 0 ? (
            params?.row?.total_long_term_unrealized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_long_term_unrealized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_long_term_unrealized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_long_term_unrealized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_long_term_unrealized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "total_short_term_realized_gains",
      headerName: "Total Short terms realized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.total_short_term_realized_gains &&
          params?.row?.total_short_term_realized_gains !== 0 ? (
            params?.row?.total_short_term_realized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_short_term_realized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_short_term_realized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_short_term_realized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_short_term_realized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "total_long_term_realized_gains",
      headerName: "Total Long terms realized gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.total_long_term_realized_gains &&
          params?.row?.total_long_term_realized_gains !== 0 ? (
            params?.row?.total_long_term_realized_gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_long_term_realized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_long_term_realized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.total_long_term_realized_gains
                    ? `₹ ${Math.abs(
                        params?.row?.total_long_term_realized_gains
                      )}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          {blockID === 0 && selectedId !== null ? (
            <MovingIcon onClick={() => setOpenModel(true)} />
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel({
      ...paginationModel,
      page: newPaginationModel.page,
      pageSize: newPaginationModel.pageSize,
    });
  };

  //   const TableRowComponet = () => {
  //     console.log("TbleRow ");
  //     return (
  //       <TableRow hover role="checkbox" tabIndex={-1} style={{ width: "80%" }}>
  //         <TableCell>
  //           <Text style={{ fontSize: 14 }}>{InvestmentAllocation.type}</Text>
  //         </TableCell>
  //       </TableRow>
  //     );
  //   };

  return (
    <div>
      <Stack>
        <CustomGrid
          autoHeight
          list={modifiedgainLossTableData}
          getRowId={(row) => row.id}
          rowCount={modifiedgainLossTableData}
          columnHeaderHeight={46}
          rowHeight={72}
          columns={GainTableHeader}
          header={CustomerHeader}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
          pagination={false}
        />
      </Stack>

      <Stack style={{ marginTop: "50px" }}>
        <CustomGrid
          autoHeight
          list={modifiedgainLossTotalData}
          getRowId={(row) => row.id}
          rowCount={modifiedgainLossTotalData}
          columnHeaderHeight={46}
          rowHeight={62}
          columns={GainTotalHeader}
          pagination={false}
        />
      </Stack>
      <Card></Card>
      <Dialog
        style={{ borderRadius: "50%" }}
        onClose={() => setOpenModel(false)}
        open={openModel}
        title={
          <Text fontSize="18px" fontWeight="600" marginBottom="-10px">
            Manage profit and loss
          </Text>
        }
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack
            direction="column"
            spacing={1.5}
            sx={{ width: "100%" }}
            style={{ marginTop: "10px" }}
          >
            <Stack direction="row">
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "50px",
                  p: 1,
                  m: 0.5,
                }}
              >
                Book profit against profit
              </Button>
              <Button
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "50px",
                  p: 1,
                  m: 0.5,
                }}
              >
                Book profit against loss
              </Button>
            </Stack>
            <Text
              variant="small"
              fontSize="16px"
              fontWeight="500"
              marginTop="-20px"
              marginLeft="30px"
              color="#676C76"
            >
              Select long terms gains category
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginLeft: "15px", marginTop: "-1px" }}
            >
              <Grid container style={{ marginTop: "-12px" }}>
                <Grid item xs={6}>
                  <Checkbox
                    label={
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        style={{ marginLeft: "10px", textWrap: "nowrap" }}
                      >
                        Long Terms Unrealized gains
                      </Text>
                    }
                    size="small"
                    name="unrealized_gains"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    label={
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        style={{ marginLeft: "10px", textWrap: "nowrap" }}
                      >
                        Long Terms Realized gains
                      </Text>
                    }
                    size="small"
                    name="realized_gains"
                  />
                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>
            </Stack>
            <Text
              variant="small"
              fontSize="16px"
              fontWeight="500"
              marginTop="-20px"
              marginLeft="30px"
              color="#676C76"
            >
              Select short terms gains category
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginLeft: "15px", marginTop: "-1px" }}
            >
              <Grid container style={{ marginTop: "-12px" }}>
                <Grid item xs={6}>
                  <Checkbox
                    label={
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        style={{ marginLeft: "10px", textWrap: "nowrap" }}
                      >
                        Short Terms Unrealized gains
                      </Text>
                    }
                    size="small"
                    name="unrealized_gains"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    label={
                      <Text
                        fontWeight="400"
                        fontSize="14px"
                        style={{ marginLeft: "10px", textWrap: "nowrap" }}
                      >
                        Short Terms Realized gains
                      </Text>
                    }
                    size="small"
                    name="realized_gains"
                  />
                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>
            </Stack>
            {/* <Box>
              <Text
                variant="small"
                color="#676C76"
                fontSize="14px"
                fontWeight="500"
              >
                Stock Name
              </Text>
              <TextField
                placeholder="RELIANCE"
                inputProps={{ style: { fontSize: "14px" } }}
                sx={{ marginTop: "5px" }}
                disabled
              />
            </Box> */}
            <Box>
              <Text
                variant="small"
                color="#676C76"
                fontSize="14px"
                fontWeight="500"
              >
                Amount
              </Text>
              <TextField
                placeholder="Enter Amount"
                inputProps={{ style: { fontSize: "14px" } }}
                sx={{ marginTop: "5px" }}
              />
            </Box>
            <Stack direction="row" style={{ marginTop: "40px" }}>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                  marginRight: "5px",
                  // m: ,
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                  // m: 2,
                }}
                onClick={handleBuy}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        )}
      />
    </div>
  );
};

export default GainLossTable;
