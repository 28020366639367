import React, { useState } from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import { styled, Box } from "@mui/material";
import DatePicker from "components/common/DatePicker";
import FileUpload from "components/common/FileUpload";
import Radio from "components/common/Radio";
import RadioGroup from "components/common/RadioGroup";
import { FormControlLabel } from "@mui/material";

const RiaText = styled(Text)(() => ({
  marginTop: "24px",
  marginBottom: "6px",
  fontSize: "14px",
  fontWeight: 500,
  color: "#242424",
}));

const AddRia = ({ handleChange, formData, formError, setFormData }) => {
  const [userType, setUserType] = useState("");
  return (
    <>
      <RadioGroup row>
        <FormControlLabel
          value="ria"
          control={<Radio />}
          label="RIA"
          checked={userType === "ria"}
          onChange={() => {
            setUserType("ria");
            handleChange({
              name: "userType",
              value: "ria",
            });
          }}
          sx={{
            "& .MuiFormControlLabel-label": { marginLeft: "8px" },
            mr: 4,
          }}
        />
        <FormControlLabel
          value="subbroker"
          control={<Radio />}
          label="Subbroker"
          checked={userType === "subbroker"}
          onChange={() => {
            setUserType("subbroker");
            handleChange({
              name: "userType",
              value: "subbroker",
            });
          }}
          sx={{ "& .MuiFormControlLabel-label": { marginLeft: "8px" } }}
        />
      </RadioGroup>

      {userType && (
        <>
          <RiaText>
            {userType === "ria" ? "RIA name" : "Subbroker name"}
          </RiaText>
          <TextField
            placeholder="Enter name"
            name="riaName1"
            value={formData.riaName1}
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
          />
          {formError.riaName1 && (
            <Text variant="small" color="red" py={1}>
              {formError.riaName1}
            </Text>
          )}

          <RiaText>Email address</RiaText>
          <TextField
            placeholder="Enter email address"
            name="emailAddress"
            value={formData.emailAddress}
            onChange={(e) => {
              handleChange({
                name: e.target.name,
                value: e.target.value,
              });
            }}
          />
          {formError.emailAddress && (
            <Text variant="small" color="red" py={1}>
              {formError.emailAddress}
            </Text>
          )}

          <RiaText>Phone number</RiaText>
          <TextField
            placeholder="Enter phone number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={(e) => {
              // Validate input using regex to allow only 10 digits
              const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const trimmedValue = numericValue.slice(0, 10); // Limit to 10 digits
              handleChange({
                name: e.target.name,
                value: trimmedValue,
              });
            }}
          />
          {formError.phoneNumber && (
            <Text variant="small" color="red" py={1}>
              {formError.phoneNumber}
            </Text>
          )}

          <RiaText>SEBI Registration Number</RiaText>
          <TextField
            placeholder="Enter SEBI Registration Number"
            name="sebiRegistrationNumber"
            type="number" // Add input type number
            value={formData.sebiRegistrationNumber}
            onChange={(e) => {
              // Validate input to allow only numerical characters
              const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              handleChange({
                name: e.target.name,
                value: numericValue,
              });
            }}
          />
          {formError.sebiRegistrationNumber && (
            <Text variant="small" color="red" py={1}>
              {formError.sebiRegistrationNumber}
            </Text>
          )}

          <RiaText>BASL Number</RiaText>
          <TextField
            placeholder="Enter BASL Number"
            name="baslNumber"
            type="number" // Add input type number
            value={formData.baslNumber}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              handleChange({
                name: e.target.name,
                value: numericValue,
              });
            }}
          />

          {formError.baslNumber && (
            <Text variant="small" color="red" py={1}>
              {formError.baslNumber}
            </Text>
          )}

          <RiaText>Expiry Date</RiaText>
          <DatePicker
            label="Select Date"
            value={formData.expiryDate}
            onChange={(d) => {
              handleChange({ name: "expiryDate", value: d["$d"] });
            }}
          />
          {formError.expiryDate && (
            <Text variant="small" color="red" py={1}>
              {formError.expiryDate}
            </Text>
          )}

          <RiaText>
            SEBI Registration{" "}
            <span style={{ fontSize: "12px" }}>(PDF Format Only)</span>{" "}
          </RiaText>
          <FileUpload
            name="sebiRegistration"
            handleChange={(d) => {
              console.log({ d }, "INSIDE");
              handleChange(d);
            }}
            acceptedFileTypes={[
              "application/pdf",
              // "image/jpeg",
              // "image/jpg",
              // "image/png",
            ]}
          />
          {formError.sebiRegistration && (
            <Text variant="small" color="red" py={1}>
              {formError.sebiRegistration}
            </Text>
          )}

          <RiaText>
            BASL membership{" "}
            <span style={{ fontSize: "12px" }}>(PDF Format Only)</span>
          </RiaText>

          <FileUpload
            name="baslMembership"
            handleChange={handleChange}
            acceptedFileTypes={[
              "application/pdf",
              // "image/jpeg",
              // "image/jpg",
              // "image/png",
            ]}
          />
          {formError.baslMembership && (
            <Text variant="small" color="red" py={1}>
              {formError.baslMembership}
            </Text>
          )}

          <RiaText>
            Investment advisory exam 1{" "}
            <span style={{ fontSize: "12px" }}>(PDF Format Only)</span>{" "}
          </RiaText>
          <FileUpload
            name="investmentAdvisoryExam1"
            handleChange={handleChange}
            acceptedFileTypes={[
              "application/pdf",
              // "image/jpeg",
              // "image/jpg",
              // "image/png",
            ]}
          />
          {formError.investmentAdvisoryExam1 && (
            <Text variant="small" color="red" py={1}>
              {formError.investmentAdvisoryExam1}
            </Text>
          )}

          <RiaText>
            Investment advisory exam 2{" "}
            <span style={{ fontSize: "12px" }}>(PDF Format Only)</span>
          </RiaText>
          <FileUpload
            name="investmentAdvisoryExam2"
            handleChange={handleChange}
            acceptedFileTypes={[
              "application/pdf",
              // "image/jpeg",
              // "image/jpg",
              // "image/png",
            ]}
          />
          {formError.investmentAdvisoryExam2 && (
            <Text variant="small" color="red" py={1}>
              {formError.investmentAdvisoryExam2}
            </Text>
          )}
        </>
      )}
    </>
  );
};

export default AddRia;
