import MyDatePicker from "components/common/MyDatePicker";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
// import { CustomerDashboardApiAction } from "stores/redux/customerDashBoard/customerDashBoardApiSlice";

const AdminDashBoardHeader = ({ handleDateRangeChange, userName }) => {
  // const { data: invesmentTypeData = {} } =
  //   CustomerDashboardApiAction.getInvesmentTypeApi();
  // const investmentTypedata = invesmentTypeData;

  // const { data: familyMemberData = {} } =
  //   CustomerDashboardApiAction.getFamilyMemberApi();
  // const familyMemberdata = familyMemberData;
  
  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%", mb: "20px", mt: "24px" }}
      >
        <Stack sx={{ width: "70%" }}>
          <Text variant="title" color= "#242424" sx={{ mb: "5px" }}>
            Welcome {userName.name} 👋
          </Text>
          <Text variant="body2" sx={{ color: "#676C76" }}>
            We are glad to see you here, thankyou for choosing us.
          </Text>
        </Stack>

        <Stack
          sx={{
            width: "30%",
            ml: "180px",
          }}
        >
          <MyDatePicker onDateRangeChange={handleDateRangeChange} />
        </Stack>
      </Stack>
    </>
  );
};

export default AdminDashBoardHeader;
