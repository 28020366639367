import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DataAdding from "components/common/DataAdding";
import { plansApiAction } from "stores/redux/apiSlices/plans/plansApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import AddNewPlanModal from "../components/AddNewPlanModal";
import { useLocation, useNavigate } from "react-router-dom";
const initialItems = [
  {
    id: "abc",
    chargeCategory: "abcd",
    subCategories: [
      {
        id: 3,
        frequency: "Monthly",
        custom: null,
        custom_percent: null,
        modelportfolio: null,
        modelportfolio_percent: null,
        algo: null,
        algo_percent: null,
      },
      {
        id: 4,
        frequency: "Quarterly",
        custom: null,
        custom_percent: null,
        modelportfolio: null,
        modelportfolio_percent: null,
        algo: null,
        algo_percent: null,
      },
      {
        id: 5,
        frequency: "Half Yearly",
        custom: null,
        custom_percent: null,
        modelportfolio: null,
        modelportfolio_percent: null,
        algo: null,
        algo_percent: null,
      },
      {
        id: 6,
        frequency: "Yearly",
        custom: null,
        custom_percent: null,
        modelportfolio: null,
        modelportfolio_percent: null,
        algo: null,
        algo_percent: null,
      },
    ],
  },
];

const CreateNewPlansContainer = () => {
  const location = useLocation(); // Use useLocation hook
  const [items, setItems] = useState(initialItems);
  const [initialChargeType, setInitialChargeType] = useState(() => {
    const defaultValues = {
      algo: "fixedAmount",
      custom: "fixedAmount",
      modelportfolio: "fixedAmount",
    };

    const plansData = location.state?.plansData || null;
    console.log(plansData, "initialplansData");
    const selectedPlanName = location.state?.planName || "";
    console.log(selectedPlanName, "initalselectedPlanName");

    if (Array.isArray(plansData) && selectedPlanName !== "") {
      const selectedPlanData = plansData.find(
        (plan) => plan.planName === selectedPlanName
      );

      console.log(" initial Selected Plan Data:", selectedPlanData);

      if (selectedPlanData) {
        return {
          3: {
            ...defaultValues,
            algo:
              selectedPlanData.subCategories[0].algo_percent > 0
                ? "AUM"
                : "fixedAmount",
            custom:
              selectedPlanData.subCategories[0].custom_percent > 0
                ? "AUM"
                : "fixedAmount",
            modelportfolio:
              selectedPlanData.subCategories[0].modelportfolio_percent > 0
                ? "AUM"
                : "fixedAmount",
          },
          4: {
            ...defaultValues,
            algo:
              selectedPlanData.subCategories[1].algo_percent > 0
                ? "AUM"
                : "fixedAmount",
            custom:
              selectedPlanData.subCategories[1].custom_percent > 0
                ? "AUM"
                : "fixedAmount",
            modelportfolio:
              selectedPlanData.subCategories[1].modelportfolio_percent > 0
                ? "AUM"
                : "fixedAmount",
          },
          5: {
            ...defaultValues,
            algo:
              selectedPlanData.subCategories[2].algo_percent > 0
                ? "AUM"
                : "fixedAmount",
            custom:
              selectedPlanData.subCategories[2].custom_percent > 0
                ? "AUM"
                : "fixedAmount",
            modelportfolio:
              selectedPlanData.subCategories[2].modelportfolio_percent > 0
                ? "AUM"
                : "fixedAmount",
          },
          6: {
            ...defaultValues,
            algo:
              selectedPlanData.subCategories[3].algo_percent > 0
                ? "AUM"
                : "fixedAmount",
            custom:
              selectedPlanData.subCategories[3].custom_percent > 0
                ? "AUM"
                : "fixedAmount",
            modelportfolio:
              selectedPlanData.subCategories[3].modelportfolio_percent > 0
                ? "AUM"
                : "fixedAmount",
          },
        };
      }
    }

    return {
      3: { ...defaultValues },
      4: { ...defaultValues },
      5: { ...defaultValues },
      6: { ...defaultValues },
    };
  });

  const [chargeType, setChargeType] = useState(initialChargeType);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [newStockName, setNewStockName] = useState("");
  const [updatePlanId, setUpdatePlanId] = useState("");
  const [minAmountFrom, setMinAmountFrom] = useState("");
  const [maxAmountTo, setMaxAmountTo] = useState("");
  const navigate = useNavigate();
  const [saveClicked, setSaveClicked] = useState(false);

  const handlePlanModal = () => {
    setSaveClicked(true);
    // Check if all the text fields in subCategories are filled for each row in the data grid
    const isDataValid = items.every((item) =>
      item.subCategories.every((subCategory) => {
        const matchingFrequencyData =
          subCategory && subCategory.id
            ? chargeType[subCategory.id]
            : { algo: null, custom: null, modelportfolio: null };

        const isAlgoFilled =
          matchingFrequencyData.algo === "fixedAmount"
            ? subCategory.algo !== null
            : subCategory.algo_percent !== null &&
              subCategory.algo_percent !== "";
        const isCustomFilled =
          matchingFrequencyData.custom === "fixedAmount"
            ? subCategory.custom !== null
            : subCategory.custom_percent !== null &&
              subCategory.custom_percent !== "";
        const isModelPortfolioFilled =
          matchingFrequencyData.modelportfolio === "fixedAmount"
            ? subCategory.modelportfolio !== null
            : subCategory.modelportfolio_percent !== null &&
              subCategory.modelportfolio_percent !== "";

        return isAlgoFilled && isCustomFilled && isModelPortfolioFilled;
      })
    );
    console.log(isDataValid, "all data");

    // If any text field is not filled, show an error and return without opening the modal
    if (!isDataValid) {
      console.error("Please fill in all the text fields.");
      dispatch(
        toastActions.setToastData({
          message: "Please fill in all the text fields.",
          variant: "error",
        })
      );
      return;
    }
    // If all text fields are filled, open the modal
    setModalOpen(true);
  };
  useEffect(() => {
    const plansData = location.state?.plansData || null;
    const selectedPlanName = location.state?.planName || "";
    const selectedPlanId = location.state?.plan_id || "";

    console.log("selectedPlanId1", selectedPlanId);
    console.log("Selected Plan Name:", selectedPlanName);
    console.log("Entire Plans Data:", plansData);

    if (Array.isArray(plansData) && selectedPlanId !== "") {
      const selectedPlanData = plansData.find(
        (plan) => plan.planName === selectedPlanName
      );

      console.log("Selected Plan Data:", selectedPlanData);

      if (selectedPlanData) {
        setItems((prevItems) =>
          prevItems.map((item) => {
            const matchingSubCategory = selectedPlanData.subCategories.find(
              (subCategory) =>
                subCategory.frequency.toLowerCase() ===
                item.subCategories[0].frequency.toLowerCase()
            );

            return {
              ...item,
              chargeCategory: selectedPlanData.planName,
              subCategories: item.subCategories.map((subCat) => {
                const matchingFrequencyData =
                  selectedPlanData.subCategories.find(
                    (freqData) =>
                      freqData.frequency.toLowerCase() ===
                      subCat.frequency.toLowerCase()
                  );

                return {
                  ...subCat,
                  custom: matchingFrequencyData?.custom || null,
                  custom_percent: matchingFrequencyData?.custom_percent || null,
                  modelportfolio: matchingFrequencyData?.modelportfolio || null,
                  modelportfolio_percent:
                    matchingFrequencyData?.modelportfolio_percent || null,
                  algo: matchingFrequencyData?.algo || null,
                  algo_percent: matchingFrequencyData?.algo_percent || null,
                };
              }),
            };
          })
        );

        setChargeType((prevChargeType) => ({
          ...prevChargeType,
          [selectedPlanData.subCategories[0].id]: {
            algo: selectedPlanData.subCategories[0].algo || "fixedAmount",
          },
        }));
        setUpdatePlanId(selectedPlanId)
        setNewStockName(selectedPlanName);
        setMinAmountFrom(selectedPlanData.min_amount || "");
        setMaxAmountTo(selectedPlanData.max_amount || "");
      }
    }
  }, [location.state]);

  // useEffect(() => {
  //   const plansData = location.state?.plansData || null;
  //   const selectedPlanName = location.state?.planName || "";

  //   console.log("Selected Plan Name:", selectedPlanName);
  //   console.log("Entire Plans Data:", plansData);

  //   if (Array.isArray(plansData) && selectedPlanName !== "") {
  //     const selectedPlanData = plansData.find(
  //       (plan) => plan.planName === selectedPlanName
  //     );

  //     console.log("Selected Plan Data:", selectedPlanData);

  //     if (selectedPlanData) {
  //       // Update the initialItems state by merging the selected plan data for all frequencies
  //       setItems((prevItems) =>
  //         prevItems.map((item) => {
  //           const matchingSubCategory = selectedPlanData.subCategories.find(
  //             (subCategory) =>
  //               subCategory.frequency.toLowerCase() ===
  //               item.subCategories[0].frequency.toLowerCase()
  //           );

  //           return {
  //             ...item,
  //             chargeCategory: selectedPlanData.planName,
  //             subCategories: item.subCategories.map((subCat) => {
  //               const matchingFrequencyData =
  //                 selectedPlanData.subCategories.find(
  //                   (freqData) =>
  //                     freqData.frequency.toLowerCase() ===
  //                     subCat.frequency.toLowerCase()
  //                 );

  //               return {
  //                 ...subCat,
  //                 custom: matchingFrequencyData?.custom || null,
  //                 custom_percent: matchingFrequencyData?.custom_percent || null,
  //                 modelportfolio: matchingFrequencyData?.modelportfolio || null,
  //                 modelportfolio_percent:
  //                   matchingFrequencyData?.modelportfolio_percent || null,
  //                 algo: matchingFrequencyData?.algo || null,
  //                 algo_percent: matchingFrequencyData?.algo_percent || null,
  //               };
  //             }),
  //           };
  //         })
  //       );

  //       // Set chargeType state for Algo field
  //       setChargeType((prevChargeType) => ({
  //         ...prevChargeType,
  //         [selectedPlanData.subCategories[0].id]: {
  //           algo: selectedPlanData.subCategories[0].algo || "fixedAmount",
  //         },
  //       }));
  //       // Set the pre-filled values for min_amount, max_amount, and planName
  //       setNewStockName(selectedPlanName);
  //       setMinAmountFrom(selectedPlanData.min_amount || "");
  //       setMaxAmountTo(selectedPlanData.max_amount || "");
  //     }
  //   }
  // }, [location.state]);

  const getUpdatedRows = () => {
    // Use a guard clause to handle the case when items is undefined or not an array
    if (!items || !Array.isArray(items)) {
      console.error("Items is undefined or not an array");
      return [];
    }

    return items.flatMap((it) =>
      it && it.subCategories
        ? it.subCategories.map((item, index) =>
            index === 0
              ? { ...item, chargeCategory: it.chargeCategory }
              : { ...item }
          )
        : []
    );
  };

  const [handleCreatePlanApi] = plansApiAction.createNewPlanApi();
  const [handleUpdatePlanApi] = plansApiAction.updateNewPlanApi();

  const extractSubCategoriesData = (subCategories) => {
    return subCategories.map((subCategory) => ({
      id: subCategory.id,
      frequency: subCategory.frequency.toLowerCase(),
      custom: subCategory.custom,
      custom_percent: subCategory.custom_percent,
      modelportfolio: subCategory.modelportfolio,
      modelportfolio_percent: subCategory.modelportfolio_percent,
      algo: subCategory.algo,
      algo_percent: subCategory.algo_percent,
    }));
  };

  const handleSavePlan = () => {
    if (!newStockName || !minAmountFrom || !maxAmountTo) {
      console.error("Please fil all the field in plan model.");
      dispatch(
        toastActions.setToastData({
          message: "Please fil all the field in plan model.",
          variant: "error",
        })
      );
      return;
    }
    if (location.state?.plansData) {
      // Data is coming from selectedPlandata, call handleUpdatePlan
      const selectedPlanData = location.state.plansData.find(
        (plan) => plan.plan_id === updatePlanId
      );

      if (!selectedPlanData) {
        console.error("Selected plan not found for update");
        return;
      }

      const payload = {
        plan_id: selectedPlanData.plan_id, // Add the Plan_id to the payload
        planName: newStockName,
        max_amount: parseFloat(maxAmountTo),
        min_amount: parseFloat(minAmountFrom),
        subCategories: items.flatMap((it) =>
          extractSubCategoriesData(it.subCategories)
        ),
      };

      // Convert numeric values to actual numbers
      payload.subCategories.forEach((subCategory) => {
        subCategory.custom =
          subCategory.custom === null ? 0 : Number(subCategory.custom);
        subCategory.custom_percent =
          subCategory.custom_percent === null
            ? 0
            : Number(subCategory.custom_percent);
        subCategory.modelportfolio =
          subCategory.modelportfolio === null
            ? 0
            : Number(subCategory.modelportfolio);
        subCategory.modelportfolio_percent =
          subCategory.modelportfolio_percent === null
            ? 0
            : Number(subCategory.modelportfolio_percent);
        subCategory.algo =
          subCategory.algo === null ? 0 : Number(subCategory.algo);
        subCategory.algo_percent =
          subCategory.algo_percent === null
            ? 0
            : Number(subCategory.algo_percent);
      });

      console.log(payload, "update api payload");

      handleUpdatePlanApi(payload)
        .unwrap()
        .then((response) => {
          console.log("API Response:", response);

          if (response) {
            dispatch(
              toastActions.setToastData({
                message: response.message,
                variant: "error",
              })
            );

            // Log the message for further inspection
            console.log("Response Message:", response.message);
            setModalOpen(false);
            // Navigate to "/plans" after a successful API call
            navigate("/plans");

            // Check if the response message contains 'You will receive OTP shortly!'
          } else {
            console.log("ID from API is missing or invalid:", response);
          }
        })
        .catch((error) => {
          console.error("Error in API call:", error);

          // Log the complete error object for inspection
          console.log("Complete error object:", error);
        });
    } else {
      // No data is coming on start, call handleCreateNewPlan
      const payload = {
        planName: newStockName,
        max_amount: parseFloat(maxAmountTo),
        min_amount: parseFloat(minAmountFrom),
        subCategories: items.flatMap((it) =>
          extractSubCategoriesData(it.subCategories)
        ),
      };

      // Convert numeric values to actual numbers
      payload.subCategories.forEach((subCategory) => {
        subCategory.custom =
          subCategory.custom === null ? 0 : Number(subCategory.custom);
        subCategory.custom_percent =
          subCategory.custom_percent === null
            ? 0
            : Number(subCategory.custom_percent);
        subCategory.modelportfolio =
          subCategory.modelportfolio === null
            ? 0
            : Number(subCategory.modelportfolio);
        subCategory.modelportfolio_percent =
          subCategory.modelportfolio_percent === null
            ? 0
            : Number(subCategory.modelportfolio_percent);
        subCategory.algo =
          subCategory.algo === null ? 0 : Number(subCategory.algo);
        subCategory.algo_percent =
          subCategory.algo_percent === null
            ? 0
            : Number(subCategory.algo_percent);
      });

      console.log(payload, "createplan");

      handleCreatePlanApi(payload)
        .unwrap()
        .then((response) => {
          console.log("API Response:", response);

          if (response) {
            dispatch(
              toastActions.setToastData({
                message: response.message,
                variant: "error",
              })
            );

            // Log the message for further inspection
            console.log("Response Message:", response.message);
            setModalOpen(false);
            // Navigate to "/plans" after a successful API call
            navigate("/plans");

            // Check if the response message contains 'You will receive OTP shortly!'
          } else {
            console.log("ID from API is missing or invalid:", response);
          }
        })
        .catch((error) => {
          console.error("Error in API call:", error);

          // Log the complete error object for inspection
          console.log("Complete error object:", error);
        });
    }
  };

  const handleTextFieldChange = (id, field, value) =>
    setItems((prevItems) =>
      prevItems.map((item) => ({
        ...item,
        subCategories: item.subCategories.map((subCategory) =>
          subCategory.id === id
            ? { ...subCategory, [field]: value }
            : subCategory
        ),
      }))
    );

  const handleRadioChange = (rowId, field, value) => {
    setChargeType((prevChargeType) => ({
      ...prevChargeType,
      [rowId]: { ...prevChargeType[rowId], [field]: value },
    }));
  };
  const handleAllowedKeys = (e) => {
    const allowedKeys = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
    ];

    // Check if Ctrl key is pressed and allow Ctrl+C (copy), Ctrl+V (paste), and Ctrl+A (select all)
    if (e.ctrlKey && (e.key === "c" || e.key === "v" || e.key === "a")) {
      return;
    }

    // Check if the pressed key is not in the allowedKeys array
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "calc(100% + 48px)",
          margin: "0 -24px",
          marginBottom: "24px",
        }}
      >
        <Breadcrumbs link="Plans" Breadcrumb="Create new plan" href="/plans" />
      </Box>
      <div>
        <DataAdding
          headerText="Create new plans"
          subheaderText="Here you can create any plans for the customers."
          addDataText="Save plan"
          onAddDataClick={handlePlanModal}
        />
        <Box
          sx={{
            width: "auto",
            backgroundColor: "#fff",
            overflowX: "auto",
            "& .bold": { fontWeight: 600 },
            "& .super-app-theme--header": {
              backgroundColor: "#E5EEFF !important",
            },
            "& .total-background": { backgroundColor: "#F2FAFE !important" },
            "& .total-back": { backgroundColor: "#C3EAFD !important" },
          }}
        >
          <DataGrid
            style={{
              minWidth: "100%",
              "&:hover": { backgroundColor: "#fff !important" },
            }}
            rowSelection={true}
            disableColumnFilter={true}
            disableRowSelectionOnClick={true}
            autoHeight
            hideFooter
            rowHeight={150}
            columns={[
              {
                field: "algo",
                headerName: "Algo",
                headerClassName: "super-app-theme--header",
                flex: 1,
                renderCell: ({ row }) => {
                  const matchingFrequencyData =
                    row && row.id
                      ? chargeType[row.id]
                      : { algo: null, custom: null, modelportfolio: null };

                  const isAlgoFilled =
                    matchingFrequencyData.algo === "fixedAmount"
                      ? row.algo !== null
                      : row.algo_percent !== null && row.algo_percent !== "";

                  return (
                    <div>
                      <RadioGroup
                        row
                        aria-label={`chargeType-${row.id}-algo`}
                        name={`chargeType-${row.id}-algo`}
                        value={
                          (chargeType[row.id] && chargeType[row.id].algo) || ""
                        }
                        onChange={(e) =>
                          handleRadioChange(row.id, "algo", e.target.value)
                        }
                      >
                        <Box sx={{ flexDirection: "row" }}>
                          <FormControlLabel
                            value="fixedAmount"
                            control={<Radio />}
                            label="Fixed Amount"
                          />
                          <FormControlLabel
                            value="AUM"
                            control={<Radio />}
                            label="AUM"
                          />
                        </Box>
                      </RadioGroup>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          type="text"
                          style={{
                            fontSize: "14px",
                            borderRadius: "8px",
                            borderColor: "#CBCBCB",
                            border: "1px solid #CBCBCB",
                          }}
                          placeholder={
                            chargeType[row.id]?.algo === "fixedAmount"
                              ? "Enter Amount"
                              : "Enter %"
                          }
                          value={
                            chargeType[row.id]?.algo === "fixedAmount"
                              ? row.algo !== undefined
                                ? row.algo
                                : ""
                              : row.algo_percent !== undefined
                              ? row.algo_percent
                              : ""
                          }
                          onKeyDown={handleAllowedKeys}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericValue = input.match(/^\d*$/)
                              ? input
                              : null;
                            const field =
                              chargeType[row.id]?.algo === "fixedAmount"
                                ? "algo"
                                : "algo_percent";
                            handleTextFieldChange(row.id, field, numericValue);
                          }}
                        />
                        {saveClicked && !isAlgoFilled && (
                          <Typography variant="caption" color="error">
                            Please fill in this field.
                          </Typography>
                        )}
                      </Box>
                    </div>
                  );
                },
              },
              {
                field: "custom",
                headerName: "Custom",
                headerClassName: "super-app-theme--header",
                flex: 1,
                renderCell: ({ row }) => {
                  const matchingFrequencyData =
                    row && row.id
                      ? chargeType[row.id]
                      : { algo: null, custom: null, modelportfolio: null };

                  const isCustomFilled =
                    matchingFrequencyData.custom === "fixedAmount"
                      ? row.custom !== null
                      : row.custom_percent !== null &&
                        row.custom_percent !== "";

                  return (
                    <div>
                      <RadioGroup
                        row
                        aria-label={`chargeType-${row.id}-custom`}
                        name={`chargeType-${row.id}-custom`}
                        value={
                          (chargeType[row.id] && chargeType[row.id].custom) ||
                          ""
                        }
                        onChange={(e) =>
                          handleRadioChange(row.id, "custom", e.target.value)
                        }
                      >
                        <Box sx={{ flexDirection: "row" }}>
                          <FormControlLabel
                            value="fixedAmount"
                            control={<Radio />}
                            label="Fixed Amount"
                          />
                          <FormControlLabel
                            value="AUM"
                            control={<Radio />}
                            label="AUM"
                          />
                        </Box>
                      </RadioGroup>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          type="text"
                          style={{
                            fontSize: "14px",
                            borderRadius: "8px",
                            borderColor: "#CBCBCB",
                            border: "1px solid #CBCBCB",
                          }}
                          placeholder={
                            chargeType[row.id]?.custom === "fixedAmount"
                              ? "Enter Amount"
                              : "Enter %"
                          }
                          value={
                            chargeType[row.id]?.custom === "fixedAmount"
                              ? row.custom !== undefined
                                ? row.custom
                                : ""
                              : row.custom_percent !== undefined
                              ? row.custom_percent
                              : ""
                          }
                          onKeyDown={handleAllowedKeys}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericValue = input.match(/^\d*$/)
                              ? input
                              : null;
                            const field =
                              chargeType[row.id]?.custom === "fixedAmount"
                                ? "custom"
                                : "custom_percent";
                            handleTextFieldChange(row.id, field, numericValue);
                          }}
                        />
                        {saveClicked && !isCustomFilled && (
                          <Typography variant="caption" color="error">
                            Please fill in this field.
                          </Typography>
                        )}
                      </Box>
                    </div>
                  );
                },
              },
              {
                field: "modelportfolio",
                headerName: "Model Portfolio",
                headerClassName: "super-app-theme--header",
                flex: 1,
                renderCell: ({ row }) => {
                  const matchingFrequencyData =
                    row && row.id
                      ? chargeType[row.id]
                      : { algo: null, custom: null, modelportfolio: null };

                  const isModelPortfolioFilled =
                    matchingFrequencyData.modelportfolio === "fixedAmount"
                      ? row.modelportfolio !== null
                      : row.modelportfolio_percent !== null &&
                        row.modelportfolio_percent !== "";

                  return (
                    <div>
                      <RadioGroup
                        row
                        aria-label={`chargeType-${row.id}-modelportfolio`}
                        name={`chargeType-${row.id}-modelportfolio`}
                        value={
                          (chargeType[row.id] &&
                            chargeType[row.id].modelportfolio) ||
                          ""
                        }
                        onChange={(e) =>
                          handleRadioChange(
                            row.id,
                            "modelportfolio",
                            e.target.value
                          )
                        }
                      >
                        <Box sx={{ flexDirection: "row" }}>
                          <FormControlLabel
                            value="fixedAmount"
                            control={<Radio />}
                            label="Fixed Amount"
                          />
                          <FormControlLabel
                            value="AUM"
                            control={<Radio />}
                            label="AUM"
                          />
                        </Box>
                      </RadioGroup>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          type="text"
                          style={{
                            fontSize: "14px",
                            borderRadius: "8px",
                            borderColor: "#CBCBCB",
                            border: "1px solid #CBCBCB",
                          }}
                          placeholder={
                            chargeType[row.id]?.modelportfolio === "fixedAmount"
                              ? "Enter Amount"
                              : "Enter %"
                          }
                          value={
                            chargeType[row.id]?.modelportfolio === "fixedAmount"
                              ? row.modelportfolio !== undefined
                                ? row.modelportfolio
                                : ""
                              : row.modelportfolio_percent !== undefined
                              ? row.modelportfolio_percent
                              : ""
                          }
                          onKeyDown={handleAllowedKeys}
                          onChange={(e) => {
                            const input = e.target.value;
                            const numericValue = input.match(/^\d*$/)
                              ? input
                              : null;
                            const field =
                              chargeType[row.id]?.modelportfolio ===
                              "fixedAmount"
                                ? "modelportfolio"
                                : "modelportfolio_percent";
                            handleTextFieldChange(row.id, field, numericValue);
                          }}
                        />
                        {saveClicked && !isModelPortfolioFilled && (
                          <Typography variant="caption" color="error">
                            Please fill in this field.
                          </Typography>
                        )}
                      </Box>
                    </div>
                  );
                },
              },
            ]}
            rows={getUpdatedRows()}
          />

          <AddNewPlanModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSave={handleSavePlan}
            newStockName={newStockName}
            setNewStockName={setNewStockName}
            minAmountFrom={minAmountFrom}
            setMinAmountFrom={setMinAmountFrom}
            maxAmountTo={maxAmountTo}
            setMaxAmountTo={setMaxAmountTo}
          />
        </Box>
      </div>
    </>
  );
};

export default CreateNewPlansContainer;
