// import Breadcrumbs from "components/common/Breadcrumbs";
import Text from "components/common/Text";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "components/common/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Box from "components/common/Box";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


export default function CustomBreadcrumb({
  selectedId,
  handleClick,
}) {
  const select = useSelector((state) => state);
  const location=useLocation();
  const BlockUserID = location.state && location?.state?.BlockUserID;

  const BlockUserName = location.state && location.state?.BlockUserName;
  const name =  BlockUserName ? BlockUserName
 : select?.toast?.breadCrumbData?.name

 const breadcrumbs = [
  <Link
    underline="hover"
    key="1"
    color="inherit"
    href="/customers"
    // onClick={handleClick}
  >
    Customers
  </Link>,
  BlockUserName ? (
    <Text key="2">{name}</Text>
  ) : (
    <Link
      underline="hover"
      key="2" // Use a unique key for each link
      color="inherit"
      href="/customer-detail"
      // onClick={handleClick} // You can include this onClick if needed
    >
      <Text>{name}</Text>
    </Link>
  ),
  <Text key="3" color="text.primary">
    Performance
  </Text>,
];


  return (
    <Box
      sx={{
        backgroundColor: "#E5EEFF",
        width: "120%",
        marginLeft: "-24px",
        padding: "10px",
      }}
    >
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="small" style={{ color: "#9C9C9C" }} />
        }
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Box>
  );
}
