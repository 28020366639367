import React, { useEffect, useState } from "react";
import Grid from "components/common/Grid";
import InvestmentHeader from "components/customerCard/Investmentheader";
import Stack from "components/common/Stack";
import InvestmentTableTab from "components/investmentTable/InvestmentTableTab";
import CustomBreadcrumb from "components/customerCard/CustomerBreadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { useLocation } from "react-router-dom";

const InvestmentType = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const select = useSelector((state) => state);
  const BlockUserID = location.state && location.state.BlockUserID;
	const BlockUserName = location.state && location.state.BlockUserName;

  const userID = BlockUserID === null ? select?.toast?.breadCrumbData?.user_id : BlockUserID;
  const name = BlockUserName === null ?  select?.toast?.breadCrumbData?.name : BlockUserName ;
  const BlockUserStatus = location.state && location.state.BlockUserStatus;
  const [customerId, setCustomerId] = useState(
    userID
  );
  const [blockID, setBlockID] = useState(
    select?.toast?.breadCrumbData?.is_block
  );

  const [selectedId, setSelectedId] = useState(null);
  const cardDataDetail = location.state && location.state.cardData;
  const performanceDetail = location.state && location.state.performanceDetail;
  const familyIdList = performanceDetail?.family_id;
  const [getFamilyDetailData, getFamilyDetail = []] =
    customersApiAction.getFamilyUsersList();
  const familyList = getFamilyDetail?.data;
  const [showTableTab, setShowTableTab] = useState(false);
  const [memberID,setMemberID]=useState(null)

  useEffect(()=>{
  },[BlockUserStatus === null ? select?.toast?.breadCrumbData?.is_block : BlockUserStatus])

  useEffect(() => {
    if (familyIdList !== undefined && familyIdList !== 0) {
      getFamilyDetailData({ family_id: familyIdList });
    }
  }, [familyIdList]);

  const handleButtonClick = (customerId, blockID) => {
    setSelectedId(customerId);
    setBlockID(blockID);
    setCustomerId(customerId);
    setShowTableTab(true)
    setMemberID(customerId)
};



  const handleButtonAll = () => {
    setSelectedId(null);
    setMemberID(null);
    const firstCustomerId = BlockUserID === null ? select?.toast?.breadCrumbData?.user_id : BlockUserID;
    setCustomerId(firstCustomerId);
    setMemberID('')
  };

  const cardData = [
    {
      id: 1,
      name: cardDataDetail?.name,
      detail: `Here you can view ${cardDataDetail?.name} performance of all the family member`,
    },
  ];

  return (
    <div>
      <CustomBreadcrumb link='Customers' Breadcrumb1={BlockUserName === null ? select?.breadCrumbData?.name : BlockUserName} Breadcrumb2="Performance"  />
      <Stack marginTop="15px">
        <Grid sx={{ mb: 3 }}>
          {cardData.map((element, index) => (
            <Grid item xs={element.xs} key={`cards_index_${element.id}`}>
              <InvestmentHeader
                selectedId={selectedId}
                data={element}
                familyList={familyList}
                handleButtonClick={handleButtonClick}
                handleButtonAll={handleButtonAll}
                memberID={memberID}
              />
            </Grid>
          ))}
        </Grid>
        <InvestmentTableTab
          memberID={memberID}
          familyList={familyList}
          blockID={blockID}
          showTableTab={showTableTab}
          customerId={customerId}
          selectedId={selectedId}
        />
      </Stack>
    </div>
  );
};

export default InvestmentType;
