import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const customerOtherDetailsApi = createApi({
    reducerPath: "addCustomer",
    baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
    tagTypes: [

    ],

    endpoints: (builder)=>({
        
        updateOtherDetails: builder.mutation({
			query: (payload) => ({
				url: `api/customer/update-customer-other-details/${payload.userId}`,
                // /customer/update-customer-other-details/
				method: "post",
				body: {
					...payload.otherDetails,
				},
			}),
		}),
    })
})
// `api/customer/update-other-details/${payload.userId}`,

export const customerOtherDetailsApiReducer = customerOtherDetailsApi.reducer;

export const customerOtherDetailsApiAction = {
    middleware: customerOtherDetailsApi.middleware,
	reducerPath: customerOtherDetailsApi.reducerPath,
    updateOtherDetails : customerOtherDetailsApi.useUpdateOtherDetailsMutation,
}


