import ProfileCard from "components/profile/ProfileCard";
import React from "react";

const ProfileContainer = () => {
  return (
    <>
      <ProfileCard />
    </>
  );
};

export default ProfileContainer;
