import Box from "components/common/Box";
import DataAdding from "components/common/DataAdding";
import IconButton from "components/common/IconButton";
import EditIcon from "asset/icons/EditIcon";
import Stack from "components/common/Stack";
import Table from "components/common/Table";
import Text from "components/common/Text";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PlansDataGrid from "components/common/PlansDataGrid";
import { plansApiAction } from "stores/redux/apiSlices/plans/plansApiSlice";
const PlansContainer = () => {
  const navigate = useNavigate();
  const [selectedPlanName, setSelectedPlanName] = React.useState("");
  const { data: plansData = {}, refetch: getplans } =
    plansApiAction.getPlansApi();
  let plansWithIds = [];
  useEffect(() => {
    getplans();
  }, [navigate]);

  const addIdsToPlans = (plans) => {
    return plans.map((plan, index) => {
      return {
        ...plan,
        id: index + 1,
      };
    });
  };
  console.log("plansData:", plansData);

  const formatAmount = (amount) => {
    if (amount >= 10000000) {
      return (amount / 10000000).toFixed(1) + "Cr";
    } else if (amount >= 100000) {
      return (amount / 100000).toFixed(1) + "L";
    } else if (amount >= 1000) {
      return (amount / 1000).toFixed(1) + "k";
    } else {
      return amount;
    }
  };

  if (Array.isArray(plansData.plans)) {
    const plansResult = plansData.plans;
    console.log("plansResult:", plansResult);

    plansWithIds = addIdsToPlans(plansResult);
    console.log("plansWithIds:", plansWithIds);
  } else {
    console.error("The 'plans' property is not an array.");
  }
  const handleEditPlan = (planName,plan_id) => {
    console.log("Clicked planName:", planName); // Log the clicked planName
    console.log("Clicked plan_id1:", plan_id); // Log the clicked planName
    setSelectedPlanName(planName);

    navigate("/newplans", {
      state: {
        plansData,
        planName,
        plan_id,
      },
    });
  };

  const datagridColumn = [
    {
      field: "planName",
      headerName: "Plan Name",
      headerClassName: "super-app-theme--header",
      flex: 2.2,
      valueGetter: ({ row }) => {
        return row?.planName;
      },
      renderCell: ({ row }) => {
        if (!row?.planName) {
          return "";
        }
        return (
          <Stack ml={1} sx={{ flexDirection: "row", justifyContent: "center" }}>
            <Text mr={1} color="#242424" variant="small" fontWeight={500}>
              {row?.planName.slice(0, 10)}
            </Text>
            (
            <Text color="#242424" variant="small" fontWeight={300}>
              {formatAmount(row?.min_amount)}
            </Text>
            <Text color="#242424" variant="small" fontWeight={300}>
              -{formatAmount(row?.max_amount)}
            </Text>
            )
          </Stack>
        );
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      headerClassName: "super-app-theme--header",
      flex: 1.5,
      renderCell: ({ row }) => {
        if (!row?.frequency) {
          return "-";
        }
        return (
          <Stack>
            <Text variant="small" color="#676C76" fontWeight={400}>
              {row?.frequency}
            </Text>
          </Stack>
        );
      },
    },
    {
      field: "algo",
      headerName: "Algo",
      headerClassName: "super-app-theme--header",
      flex: 1.5,
      renderCell: ({ row }) => {
        if (row?.algo !== undefined && row?.algo !== null && row?.algo !== 0) {
          return (
            <Stack>
              <Text variant="small" color="#676C76" fontWeight={400}>
                {`₹ ${row?.algo}`}
              </Text>
            </Stack>
          );
        } else if (
          row?.algo_percent !== undefined &&
          row?.algo_percent !== null &&
          row?.algo_percent !== 0
        ) {
          return (
            <Stack>
              <Text variant="small" color="#676C76" fontWeight={400}>
                {`${row?.algo_percent} %`}
              </Text>
            </Stack>
          );
        } else {
          return "-";
        }
      },
    },

    // Inside the renderCell for "Custom" column
    {
      field: "custom",
      headerName: "Custom",
      headerClassName: "super-app-theme--header",
      flex: 1.5,
      renderCell: ({ row }) => {
        if (
          row?.custom !== undefined &&
          row?.custom !== null &&
          row?.custom !== 0
        ) {
          return (
            <Stack>
              <Text variant="small" color="#676C76" fontWeight={400}>
                {`₹ ${row?.custom}`}
              </Text>
            </Stack>
          );
        } else if (
          row?.custom_percent !== undefined &&
          row?.custom_percent !== null &&
          row?.custom_percent !== 0
        ) {
          return (
            <Stack>
              <Text variant="small" color="#676C76" fontWeight={400}>
                {`${row?.custom_percent} %`}
              </Text>
            </Stack>
          );
        } else {
          return "-";
        }
      },
    },

    {
      field: "modelportfolio",
      headerClassName: "super-app-theme--header",
      headerName: "Model Portfolio",
      flex: 1.5,
      renderCell: ({ row }) => {
        if (
          row?.modelportfolio !== undefined &&
          row?.modelportfolio !== null &&
          row?.modelportfolio !== 0
        ) {
          return (
            <Stack>
              <Text variant="small" color="#676C76" fontWeight={400}>
                {`₹ ${row?.modelportfolio}`}
              </Text>
            </Stack>
          );
        } else if (
          row?.modelportfolio_percent !== undefined &&
          row?.modelportfolio_percent !== null &&
          row?.modelportfolio_percent !== 0
        ) {
          return (
            <Stack>
              <Text variant="small" color="#676C76" fontWeight={400}>
                {`${row?.modelportfolio_percent} %`}
              </Text>
            </Stack>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerClassName: "super-app-theme--header",
      headerName: "Action",
      flex: 1.5,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          {params.row.planName && (
            <>
              <IconButton
                onClick={() => {
                  console.log("Clicked plan_id:", params.row.plan_id); // Log the plan_id
                  handleEditPlan(params.row.planName, params.row.plan_id);
                }}
              >
                <EditIcon sx={{ height: "20px", width: "20px" }} />
              </IconButton>
            </>
          )}
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box mt={4}>
        <DataAdding
          totalCount={plansData.length}
          shouldShowTitleAndCount
          headerText="Plans"
          subheaderText="Here you can create any plans for the customers."
          addDataText="Create New Plan"
          title="Plans"
          onAddDataClick={() => navigate("/newplans")}
        />
        <Box
          sx={{
            width: "auto",
            backgroundColor: "#fff",
            overflowX: "auto",

            "& .bold": { fontWeight: 600 },
            "& .super-app-theme--header": {
              backgroundColor: "#E5EEFF !important",
            },
            "& .total-background": { backgroundColor: "#F2FAFE !important" },
            "& .total-back": { backgroundColor: "#C3EAFD !important" },
          }}
        >
          <PlansDataGrid
            items={plansData}
            columns={datagridColumn}
            getRowId={(row) => row.id}
          />
        </Box>
      </Box>
    </>
  );
};

export default PlansContainer;
