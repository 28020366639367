import SvgIcon from "@mui/material/SvgIcon";

export default function SvgIconChildren(props) {
	return (
		<SvgIcon {...props}>
			<svg xmlns="http://www.w3.org/2000/svg" width="249" height="249" viewBox="0 0 249 249" fill="none">
  <g clip-path="url(#clip0_11067_11051)">
    <path d="M248.499 190.17H0.499023V190.294H248.499V190.17Z" fill="#EBEBEB"/>
    <path d="M223.649 198.15H207.222V198.274H223.649V198.15Z" fill="#EBEBEB"/>
    <path d="M164.784 199.5H160.474V199.624H164.784V199.5Z" fill="#EBEBEB"/>
    <path d="M206.726 193.547H197.208V193.671H206.726V193.547Z" fill="#EBEBEB"/>
    <path d="M47.9413 194.381H26.519V194.505H47.9413V194.381Z" fill="#EBEBEB"/>
    <path d="M55.5008 194.381H52.3611V194.505H55.5008V194.381Z" fill="#EBEBEB"/>
    <path d="M112.173 196.475H65.708V196.599H112.173V196.475Z" fill="#EBEBEB"/>
    <path d="M118.051 168.048H22.2786C21.5283 168.047 20.8092 167.748 20.2792 167.217C19.7491 166.686 19.4514 165.966 19.4514 165.216V30.5867C19.4579 29.8407 19.7585 29.1274 20.2879 28.6018C20.8172 28.0761 21.5326 27.7806 22.2786 27.7793H118.051C118.802 27.7793 119.523 28.0777 120.054 28.6088C120.585 29.14 120.883 29.8603 120.883 30.6115V165.216C120.883 165.967 120.585 166.687 120.054 167.219C119.523 167.75 118.802 168.048 118.051 168.048ZM22.2786 27.8785C21.5612 27.8798 20.8737 28.1657 20.3669 28.6734C19.8601 29.1812 19.5754 29.8693 19.5754 30.5867V165.216C19.5754 165.933 19.8601 166.621 20.3669 167.129C20.8737 167.637 21.5612 167.923 22.2786 167.924H118.051C118.769 167.923 119.457 167.637 119.965 167.129C120.472 166.622 120.758 165.934 120.759 165.216V30.5867C120.758 29.8688 120.472 29.1807 119.965 28.6731C119.457 28.1656 118.769 27.8798 118.051 27.8785H22.2786Z" fill="#EBEBEB"/>
    <path d="M225.341 168.048H129.563C128.812 168.047 128.093 167.748 127.562 167.217C127.031 166.686 126.732 165.967 126.731 165.216V30.5867C126.739 29.8402 127.04 29.127 127.571 28.6015C128.101 28.076 128.817 27.7806 129.563 27.7793H225.341C226.086 27.7819 226.8 28.078 227.328 28.6035C227.857 29.129 228.156 29.8415 228.163 30.5867V165.216C228.163 165.965 227.866 166.684 227.337 167.215C226.808 167.746 226.09 168.045 225.341 168.048ZM129.563 27.8785C128.845 27.8798 128.157 28.1656 127.65 28.6731C127.142 29.1807 126.856 29.8688 126.855 30.5867V165.216C126.856 165.934 127.142 166.622 127.65 167.129C128.157 167.637 128.845 167.923 129.563 167.924H225.341C226.059 167.923 226.747 167.637 227.254 167.129C227.762 166.622 228.048 165.934 228.049 165.216V30.5867C228.048 29.8688 227.762 29.1807 227.254 28.6731C226.747 28.1656 226.059 27.8798 225.341 27.8785H129.563Z" fill="#EBEBEB"/>
    <path d="M219.661 189.541V165.078H208.372V142.396H206.78V155.401H202.435V151.805H194.623V139.356H193.175V124.138H192.014V139.078H189.266V161.209H184.053V139.078H174.649V157.792H164.774V135.11H163.182V148.11H158.837V144.514H151.025V132.064H149.576V116.852H148.416V131.792H145.668V153.918H140.46V131.792H131.051V153.199H124.707V140.749H123.259V125.532H122.103V140.472H119.35V162.603H114.142V140.472H104.733V162.603H100.1V165.078H91.9908V142.396H90.3986V155.401H86.0537V151.805H78.2417V139.356H76.7934V124.138H75.6327V139.078H73.113V137.833H63.7039V139.078H58.491V137.833H55.7431V122.893H54.5875V138.111H53.1391V150.555H45.3222V154.151H40.9822V141.151H39.39V163.833H28.1011V189.541H219.661Z" fill="#EBEBEB"/>
    <path d="M74.1713 75.7237C81.2044 72.8104 84.5442 64.7473 81.631 57.7142C78.7178 50.6811 70.6547 47.3412 63.6216 50.2545C56.5884 53.1677 53.2486 61.2308 56.1618 68.2639C59.075 75.297 67.1381 78.6369 74.1713 75.7237Z" fill="#FAFAFA"/>
    <path d="M61.7504 62.837C61.7497 62.4636 61.6619 62.0955 61.494 61.762C61.3261 61.4285 61.0827 61.1388 60.7832 60.9158C60.4837 60.6929 60.1363 60.5428 59.7686 60.4777C59.4009 60.4125 59.0231 60.4341 58.6652 60.5405C58.7723 59.8196 58.7225 59.0841 58.5193 58.3843C58.3161 57.6844 57.9641 57.0366 57.4876 56.4852C57.0111 55.9338 56.4211 55.4917 55.7581 55.1892C55.095 54.8867 54.3745 54.7309 53.6457 54.7324C53.3327 54.7318 53.0204 54.7617 52.7132 54.8216C52.2145 54.0003 51.4624 53.3632 50.5702 53.0062C49.6781 52.6493 48.6941 52.5918 47.7664 52.8425C46.8388 53.0931 46.0176 53.6384 45.4266 54.3961C44.8357 55.1538 44.5068 56.0829 44.4896 57.0437H44.4152C43.3312 57.0437 42.2917 57.4743 41.5252 58.2408C40.7587 59.0072 40.3281 60.0468 40.3281 61.1308C40.3281 62.2147 40.7587 63.2543 41.5252 64.0207C42.2917 64.7872 43.3312 65.2178 44.4152 65.2178H59.4192C60.0411 65.2023 60.6326 64.945 61.0679 64.5004C61.5031 64.0559 61.748 63.4592 61.7504 62.837Z" fill="#F0F0F0"/>
    <path d="M212.678 96.0335C212.677 95.6602 212.59 95.2921 212.422 94.9586C212.254 94.6251 212.011 94.3353 211.711 94.1124C211.411 93.8894 211.064 93.7394 210.696 93.6742C210.329 93.6091 209.951 93.6306 209.593 93.7371C209.7 93.0162 209.65 92.2807 209.447 91.5808C209.244 90.8809 208.892 90.2331 208.415 89.6817C207.939 89.1303 207.349 88.6883 206.686 88.3858C206.023 88.0833 205.302 87.9274 204.574 87.9289C204.259 87.9292 203.945 87.9574 203.636 88.0132C203.133 87.1962 202.378 86.5645 201.485 86.2132C200.591 85.8618 199.608 85.8096 198.683 86.0646C197.758 86.3195 196.94 86.8677 196.353 87.627C195.766 88.3862 195.441 89.3154 195.427 90.275H195.353C194.269 90.275 193.229 90.7056 192.463 91.472C191.696 92.2385 191.266 93.2781 191.266 94.362C191.266 95.446 191.696 96.4855 192.463 97.252C193.229 98.0185 194.269 98.4491 195.353 98.4491H210.357C210.983 98.4309 211.577 98.1676 212.011 97.7159C212.446 97.2641 212.685 96.66 212.678 96.0335Z" fill="#F0F0F0"/>
    <path d="M141.715 59.6464C141.713 59.0546 141.849 58.4705 142.114 57.9412C142.379 57.4118 142.764 56.952 143.239 56.5988C143.714 56.2456 144.265 56.0088 144.848 55.9075C145.431 55.8062 146.03 55.8433 146.596 56.0157C146.54 55.6297 146.512 55.2401 146.511 54.8501C146.51 53.6705 146.769 52.5052 147.269 51.4372C147.77 50.3692 148.5 49.4249 149.408 48.6716C150.316 47.9183 151.379 47.3747 152.521 47.0795C153.663 46.7843 154.856 46.7447 156.015 46.9637C156.805 45.6611 157.997 44.651 159.412 44.0863C160.827 43.5215 162.387 43.4327 163.857 43.8333C165.326 44.2339 166.626 45.1022 167.558 46.3068C168.49 47.5114 169.005 48.9869 169.025 50.5101H169.139C170.854 50.5101 172.498 51.1913 173.711 52.4038C174.923 53.6162 175.604 55.2607 175.604 56.9755C175.604 58.6902 174.923 60.3347 173.711 61.5472C172.498 62.7597 170.854 63.4408 169.139 63.4408H145.4C144.413 63.4137 143.475 63.0018 142.786 62.293C142.098 61.5842 141.713 60.6345 141.715 59.6464Z" fill="#F0F0F0"/>
    <path d="M71.6653 100.925C71.6645 100.213 71.8302 99.5117 72.1492 98.8758C72.4682 98.2399 72.9317 97.6875 73.5024 97.2628C74.0732 96.8381 74.7355 96.5529 75.4362 96.43C76.1369 96.3072 76.8567 96.35 77.5379 96.5552C77.468 96.0922 77.4332 95.6246 77.4338 95.1564C77.4338 92.5939 78.4517 90.1363 80.2637 88.3243C82.0757 86.5123 84.5333 85.4944 87.0958 85.4944C87.6914 85.4958 88.2857 85.5506 88.8715 85.658C89.818 84.0834 91.2528 82.8609 92.9578 82.1766C94.6628 81.4923 96.5447 81.3835 98.3172 81.8669C100.09 82.3503 101.656 83.3994 102.777 84.8546C103.899 86.3098 104.514 88.0915 104.53 89.9286H104.659C105.697 89.9024 106.73 90.0842 107.697 90.4634C108.664 90.8426 109.545 91.4114 110.289 92.1364C111.032 92.8615 111.623 93.728 112.027 94.6849C112.43 95.6418 112.638 96.6698 112.638 97.7083C112.638 98.7469 112.43 99.7749 112.027 100.732C111.623 101.689 111.032 102.555 110.289 103.28C109.545 104.005 108.664 104.574 107.697 104.953C106.73 105.332 105.697 105.514 104.659 105.488H76.0896C74.9032 105.453 73.7772 104.957 72.9509 104.105C72.1247 103.253 71.6635 102.112 71.6653 100.925Z" fill="#F0F0F0"/>
    <path d="M91.8131 70.0138C91.8131 69.6191 91.6563 69.2406 91.3773 68.9616C91.0982 68.6825 90.7198 68.5258 90.3251 68.5258C90.1804 68.5241 90.0364 68.5459 89.8986 68.5902C89.9202 68.4374 89.9318 68.2833 89.9333 68.129C89.9333 67.2923 89.6009 66.4899 89.0093 65.8984C88.4177 65.3068 87.6154 64.9744 86.7787 64.9744C86.584 64.9749 86.3898 64.9932 86.1984 65.029C85.8875 64.5177 85.4188 64.1215 84.8629 63.9C84.3071 63.6785 83.6942 63.6438 83.117 63.8012C82.5397 63.9585 82.0292 64.2993 81.6626 64.7722C81.296 65.2451 81.0931 65.8244 81.0847 66.4227H81.04C80.3665 66.4227 79.7206 66.6903 79.2443 67.1665C78.768 67.6428 78.5005 68.2887 78.5005 68.9622C78.5005 69.6358 78.768 70.2817 79.2443 70.758C79.7206 71.2342 80.3665 71.5018 81.04 71.5018H90.3698C90.7566 71.4901 91.1237 71.3283 91.3932 71.0505C91.6627 70.7727 91.8133 70.4008 91.8131 70.0138Z" fill="#F0F0F0"/>
    <path d="M37.5605 115.25C37.5605 114.855 37.7173 114.477 37.9964 114.198C38.2754 113.919 38.6539 113.762 39.0485 113.762C39.1945 113.762 39.3397 113.782 39.4801 113.822C39.4558 113.671 39.4442 113.518 39.4453 113.365C39.4453 112.951 39.5269 112.541 39.6855 112.158C39.844 111.775 40.0764 111.428 40.3693 111.135C40.6622 110.842 41.01 110.609 41.3927 110.451C41.7754 110.292 42.1856 110.211 42.5999 110.211C42.7946 110.211 42.9889 110.229 43.1802 110.265C43.4911 109.754 43.9599 109.358 44.5157 109.136C45.0716 108.915 45.6844 108.88 46.2617 109.037C46.839 109.195 47.3494 109.536 47.716 110.009C48.0826 110.481 48.2855 111.061 48.294 111.659H48.3386C49.0121 111.659 49.6581 111.927 50.1343 112.403C50.6106 112.879 50.8781 113.525 50.8781 114.199C50.8781 114.872 50.6106 115.518 50.1343 115.994C49.6581 116.471 49.0121 116.738 48.3386 116.738H39.0089C38.6211 116.728 38.2528 116.566 37.9822 116.288C37.7117 116.011 37.5604 115.638 37.5605 115.25Z" fill="#F0F0F0"/>
    <path d="M177.18 120.11C177.18 119.716 177.336 119.337 177.616 119.058C177.895 118.779 178.273 118.622 178.668 118.622C178.814 118.622 178.959 118.642 179.099 118.682C179.075 118.531 179.063 118.378 179.064 118.226C179.064 117.389 179.397 116.587 179.988 115.995C180.58 115.403 181.382 115.071 182.219 115.071C182.414 115.072 182.608 115.09 182.799 115.126C183.11 114.614 183.579 114.218 184.135 113.997C184.691 113.775 185.304 113.74 185.881 113.898C186.458 114.055 186.969 114.396 187.335 114.869C187.702 115.342 187.905 115.921 187.913 116.519H187.958C188.631 116.519 189.277 116.787 189.753 117.263C190.23 117.739 190.497 118.385 190.497 119.059C190.497 119.732 190.23 120.378 189.753 120.855C189.277 121.331 188.631 121.598 187.958 121.598H178.628C178.24 121.588 177.872 121.427 177.601 121.149C177.331 120.871 177.18 120.498 177.18 120.11Z" fill="#F0F0F0"/>
    <path d="M124.5 212.569C177.613 212.569 220.67 210.055 220.67 206.955C220.67 203.854 177.613 201.34 124.5 201.34C71.3876 201.34 28.3311 203.854 28.3311 206.955C28.3311 210.055 71.3876 212.569 124.5 212.569Z" fill="#F5F5F5"/>
    <path d="M193.344 60.53L172.14 92.8147L171.208 94.2382L169.363 95.0913L103.732 125.59L110.215 147.806L111.698 152.885L106.559 157.131L62.2811 200.159H25.6416L91.8873 145.143L87.8747 123.299L86.7835 117.352L91.9121 114.991L161.858 82.7855L184.639 54.6028L178.529 53.3926L197.739 45.4219L196.752 66.1993L193.344 60.53Z" fill="#263238"/>
    <path opacity="0.6" d="M193.344 60.53L172.14 92.8147L171.208 94.2382L169.363 95.0913L103.732 125.59L110.215 147.806L111.698 152.885L106.559 157.131L62.2811 200.159H25.6416L91.8873 145.143L87.8747 123.299L86.7835 117.352L91.9121 114.991L161.858 82.7855L184.639 54.6028L178.529 53.3926L197.739 45.4219L196.752 66.1993L193.344 60.53Z" fill="#FAFAFA"/>
    <path d="M182.259 198.149H147.142C146.797 198.149 146.517 198.429 146.517 198.774V206.78C146.517 207.125 146.797 207.405 147.142 207.405H182.259C182.604 207.405 182.884 207.125 182.884 206.78V198.774C182.884 198.429 182.604 198.149 182.259 198.149Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M149.583 198.686H148.145V206.954H149.583V198.686Z" fill="black"/>
    <path opacity="0.2" d="M152.852 198.646H151.805V206.914H152.852V198.646Z" fill="black"/>
    <path opacity="0.2" d="M155.862 198.686H155.337V206.954H155.862V198.686Z" fill="black"/>
    <path opacity="0.2" d="M159.919 198.646H159.656V206.914H159.919V198.646Z" fill="black"/>
    <path opacity="0.2" d="M179.819 206.913H181.257V198.645H179.819V206.913Z" fill="black"/>
    <path opacity="0.2" d="M176.551 206.953H177.597V198.685H176.551V206.953Z" fill="black"/>
    <path opacity="0.2" d="M173.545 206.913H174.065V198.645H173.545V206.913Z" fill="black"/>
    <path opacity="0.2" d="M169.482 206.953H169.745V198.685H169.482V206.953Z" fill="black"/>
    <path opacity="0.2" d="M164.637 206.953H164.766V198.685H164.637V206.953Z" fill="black"/>
    <path opacity="0.2" d="M181.912 199.558H147.564C147.448 199.558 147.337 199.513 147.255 199.431C147.173 199.349 147.127 199.238 147.127 199.122C147.129 199.007 147.175 198.896 147.257 198.815C147.338 198.733 147.449 198.687 147.564 198.686H181.912C182.028 198.686 182.139 198.732 182.221 198.813C182.302 198.895 182.348 199.006 182.348 199.122C182.349 199.18 182.338 199.237 182.317 199.29C182.295 199.343 182.263 199.391 182.222 199.432C182.181 199.473 182.133 199.505 182.08 199.527C182.026 199.548 181.969 199.559 181.912 199.558Z" fill="white"/>
    <path d="M183.251 188.919H148.134C147.789 188.919 147.51 189.199 147.51 189.544V197.55C147.51 197.895 147.789 198.175 148.134 198.175H183.251C183.596 198.175 183.876 197.895 183.876 197.55V189.544C183.876 189.199 183.596 188.919 183.251 188.919Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M150.575 189.455H149.136V197.723H150.575V189.455Z" fill="black"/>
    <path opacity="0.2" d="M153.849 189.414H152.802V197.682H153.849V189.414Z" fill="black"/>
    <path opacity="0.2" d="M156.854 189.455H156.333V197.723H156.854V189.455Z" fill="black"/>
    <path opacity="0.2" d="M160.911 189.414H160.648V197.682H160.911V189.414Z" fill="black"/>
    <path opacity="0.2" d="M180.816 197.678H182.254V189.409H180.816V197.678Z" fill="black"/>
    <path opacity="0.2" d="M177.542 197.718H178.589V189.45H177.542V197.718Z" fill="black"/>
    <path opacity="0.2" d="M174.532 197.678H175.058V189.409H174.532V197.678Z" fill="black"/>
    <path opacity="0.2" d="M170.474 197.718H170.737V189.45H170.474V197.718Z" fill="black"/>
    <path opacity="0.2" d="M165.629 197.718H165.758V189.45H165.629V197.718Z" fill="black"/>
    <path opacity="0.2" d="M182.904 190.328H148.556C148.44 190.326 148.33 190.28 148.248 190.198C148.167 190.117 148.12 190.006 148.119 189.891C148.119 189.775 148.165 189.664 148.247 189.582C148.329 189.501 148.44 189.455 148.556 189.455H182.904C182.961 189.454 183.018 189.465 183.071 189.486C183.125 189.508 183.173 189.54 183.214 189.581C183.254 189.622 183.287 189.67 183.308 189.723C183.33 189.777 183.341 189.834 183.34 189.891C183.34 190.007 183.294 190.118 183.212 190.2C183.13 190.282 183.019 190.328 182.904 190.328Z" fill="white"/>
    <path d="M183.252 179.688H148.135C147.79 179.688 147.51 179.968 147.51 180.313V188.319C147.51 188.664 147.79 188.944 148.135 188.944H183.252C183.597 188.944 183.877 188.664 183.877 188.319V180.313C183.877 179.968 183.597 179.688 183.252 179.688Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M150.575 180.22H149.136V188.488H150.575V180.22Z" fill="black"/>
    <path opacity="0.2" d="M153.849 180.18H152.802V188.448H153.849V180.18Z" fill="black"/>
    <path opacity="0.2" d="M156.854 180.22H156.333V188.488H156.854V180.22Z" fill="black"/>
    <path opacity="0.2" d="M160.912 180.18H160.649V188.448H160.912V180.18Z" fill="black"/>
    <path opacity="0.2" d="M180.816 188.447H182.254V180.178H180.816V188.447Z" fill="black"/>
    <path opacity="0.2" d="M177.542 188.486H178.589V180.218H177.542V188.486Z" fill="black"/>
    <path opacity="0.2" d="M174.532 188.447H175.058V180.178H174.532V188.447Z" fill="black"/>
    <path opacity="0.2" d="M170.474 188.486H170.737V180.218H170.474V188.486Z" fill="black"/>
    <path opacity="0.2" d="M165.629 188.486H165.758V180.218H165.629V188.486Z" fill="black"/>
    <path opacity="0.2" d="M182.904 181.093H148.556C148.441 181.093 148.33 181.047 148.248 180.965C148.166 180.883 148.12 180.772 148.12 180.656C148.121 180.541 148.168 180.431 148.249 180.349C148.331 180.267 148.441 180.221 148.556 180.22H182.904C183.02 180.22 183.131 180.266 183.213 180.348C183.295 180.429 183.341 180.54 183.341 180.656C183.342 180.714 183.331 180.771 183.309 180.824C183.287 180.877 183.255 180.926 183.214 180.966C183.174 181.007 183.125 181.039 183.072 181.061C183.019 181.083 182.962 181.093 182.904 181.093Z" fill="white"/>
    <path d="M182.259 170.453H147.142C146.797 170.453 146.517 170.732 146.517 171.078V179.083C146.517 179.428 146.797 179.708 147.142 179.708H182.259C182.604 179.708 182.884 179.428 182.884 179.083V171.078C182.884 170.732 182.604 170.453 182.259 170.453Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M149.583 170.988H148.145V179.257H149.583V170.988Z" fill="black"/>
    <path opacity="0.2" d="M152.851 170.948H151.804V179.217H152.851V170.948Z" fill="black"/>
    <path opacity="0.2" d="M155.862 170.988H155.337V179.257H155.862V170.988Z" fill="black"/>
    <path opacity="0.2" d="M159.919 170.948H159.656V179.217H159.919V170.948Z" fill="black"/>
    <path opacity="0.2" d="M179.819 179.212H181.258V170.944H179.819V179.212Z" fill="black"/>
    <path opacity="0.2" d="M176.551 179.252H177.597V170.984H176.551V179.252Z" fill="black"/>
    <path opacity="0.2" d="M173.545 179.212H174.065V170.944H173.545V179.212Z" fill="black"/>
    <path opacity="0.2" d="M169.482 179.252H169.745V170.984H169.482V179.252Z" fill="black"/>
    <path opacity="0.2" d="M164.637 179.252H164.766V170.984H164.637V179.252Z" fill="black"/>
    <path opacity="0.2" d="M181.912 171.861H147.564C147.449 171.86 147.338 171.814 147.257 171.732C147.175 171.65 147.129 171.54 147.127 171.425C147.127 171.309 147.173 171.198 147.255 171.116C147.337 171.034 147.448 170.988 147.564 170.988H181.912C181.969 170.988 182.026 170.998 182.08 171.02C182.133 171.042 182.181 171.074 182.222 171.115C182.263 171.155 182.295 171.204 182.317 171.257C182.338 171.31 182.349 171.367 182.348 171.425C182.348 171.541 182.302 171.652 182.221 171.733C182.139 171.815 182.028 171.861 181.912 171.861Z" fill="white"/>
    <path d="M180.771 161.223H145.654C145.309 161.223 145.03 161.502 145.03 161.848V169.853C145.03 170.198 145.309 170.478 145.654 170.478H180.771C181.116 170.478 181.396 170.198 181.396 169.853V161.848C181.396 161.502 181.116 161.223 180.771 161.223Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M148.095 161.753H146.656V170.022H148.095V161.753Z" fill="black"/>
    <path opacity="0.2" d="M151.364 161.712H150.317V169.98H151.364V161.712Z" fill="black"/>
    <path opacity="0.2" d="M154.369 161.753H153.848V170.022H154.369V161.753Z" fill="black"/>
    <path opacity="0.2" d="M158.432 161.712H158.169V169.98H158.432V161.712Z" fill="black"/>
    <path opacity="0.2" d="M178.331 169.98H179.77V161.712H178.331V169.98Z" fill="black"/>
    <path opacity="0.2" d="M175.058 170.022H176.104V161.754H175.058V170.022Z" fill="black"/>
    <path opacity="0.2" d="M172.057 169.98H172.577V161.712H172.057V169.98Z" fill="black"/>
    <path opacity="0.2" d="M167.999 170.022H168.262V161.754H167.999V170.022Z" fill="black"/>
    <path opacity="0.2" d="M163.149 170.022H163.278V161.754H163.149V170.022Z" fill="black"/>
    <path opacity="0.2" d="M180.424 162.626H146.076C146.019 162.627 145.962 162.616 145.909 162.595C145.855 162.573 145.807 162.541 145.766 162.5C145.726 162.459 145.693 162.411 145.672 162.358C145.65 162.304 145.639 162.247 145.64 162.19C145.64 162.074 145.686 161.963 145.768 161.881C145.85 161.799 145.961 161.753 146.076 161.753H180.424C180.54 161.753 180.651 161.799 180.733 161.881C180.815 161.963 180.861 162.074 180.861 162.19C180.862 162.247 180.851 162.304 180.829 162.358C180.807 162.411 180.775 162.459 180.735 162.5C180.694 162.541 180.645 162.573 180.592 162.595C180.539 162.616 180.482 162.627 180.424 162.626Z" fill="white"/>
    <path d="M182.259 151.987H147.142C146.797 151.987 146.517 152.267 146.517 152.612V160.618C146.517 160.963 146.797 161.243 147.142 161.243H182.259C182.604 161.243 182.884 160.963 182.884 160.618V152.612C182.884 152.267 182.604 151.987 182.259 151.987Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M149.583 152.521H148.145V160.79H149.583V152.521Z" fill="black"/>
    <path opacity="0.2" d="M152.851 152.482H151.804V160.751H152.851V152.482Z" fill="black"/>
    <path opacity="0.2" d="M155.862 152.521H155.337V160.79H155.862V152.521Z" fill="black"/>
    <path opacity="0.2" d="M159.919 152.482H159.656V160.751H159.919V152.482Z" fill="black"/>
    <path opacity="0.2" d="M179.819 160.745H181.257V152.476H179.819V160.745Z" fill="black"/>
    <path opacity="0.2" d="M176.551 160.785H177.597V152.517H176.551V160.785Z" fill="black"/>
    <path opacity="0.2" d="M173.545 160.745H174.065V152.477H173.545V160.745Z" fill="black"/>
    <path opacity="0.2" d="M169.482 160.785H169.745V152.517H169.482V160.785Z" fill="black"/>
    <path opacity="0.2" d="M164.637 160.785H164.766V152.517H164.637V160.785Z" fill="black"/>
    <path opacity="0.2" d="M181.912 153.394H147.564C147.448 153.394 147.337 153.348 147.255 153.267C147.173 153.185 147.127 153.074 147.127 152.958C147.127 152.842 147.173 152.731 147.255 152.649C147.337 152.568 147.448 152.522 147.564 152.522H181.912C181.969 152.521 182.026 152.532 182.08 152.553C182.133 152.575 182.181 152.607 182.222 152.648C182.263 152.689 182.295 152.737 182.317 152.79C182.338 152.843 182.349 152.9 182.348 152.958C182.349 153.015 182.338 153.073 182.317 153.126C182.295 153.179 182.263 153.227 182.222 153.268C182.181 153.309 182.133 153.341 182.08 153.363C182.026 153.384 181.969 153.395 181.912 153.394Z" fill="white"/>
    <path d="M182.26 142.757H147.143C146.798 142.757 146.518 143.037 146.518 143.382V151.387C146.518 151.732 146.798 152.012 147.143 152.012H182.26C182.605 152.012 182.885 151.732 182.885 151.387V143.382C182.885 143.037 182.605 142.757 182.26 142.757Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M149.583 143.287H148.145V151.555H149.583V143.287Z" fill="black"/>
    <path opacity="0.2" d="M152.852 143.247H151.805V151.515H152.852V143.247Z" fill="black"/>
    <path opacity="0.2" d="M155.862 143.287H155.337V151.555H155.862V143.287Z" fill="black"/>
    <path opacity="0.2" d="M159.919 143.247H159.656V151.515H159.919V143.247Z" fill="black"/>
    <path opacity="0.2" d="M179.819 151.515H181.258V143.247H179.819V151.515Z" fill="black"/>
    <path opacity="0.2" d="M176.551 151.556H177.597V143.287H176.551V151.556Z" fill="black"/>
    <path opacity="0.2" d="M173.545 151.515H174.065V143.247H173.545V151.515Z" fill="black"/>
    <path opacity="0.2" d="M169.482 151.556H169.745V143.287H169.482V151.556Z" fill="black"/>
    <path opacity="0.2" d="M164.636 151.556H164.765V143.287H164.636V151.556Z" fill="black"/>
    <path opacity="0.2" d="M181.913 144.165H147.565C147.448 144.164 147.337 144.117 147.256 144.034C147.174 143.951 147.128 143.84 147.128 143.724C147.129 143.608 147.176 143.498 147.257 143.416C147.339 143.335 147.449 143.288 147.565 143.287H181.913C182.028 143.287 182.139 143.333 182.221 143.415C182.303 143.497 182.349 143.608 182.349 143.724C182.35 143.781 182.339 143.839 182.317 143.892C182.296 143.946 182.264 143.994 182.223 144.035C182.182 144.076 182.134 144.109 182.081 144.131C182.028 144.154 181.97 144.165 181.913 144.165Z" fill="white"/>
    <path d="M183.747 133.521H148.63C148.285 133.521 148.005 133.801 148.005 134.146V142.152C148.005 142.497 148.285 142.777 148.63 142.777H183.747C184.092 142.777 184.372 142.497 184.372 142.152V134.146C184.372 133.801 184.092 133.521 183.747 133.521Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M151.071 134.058H149.632V142.326H151.071V134.058Z" fill="black"/>
    <path opacity="0.2" d="M154.344 134.016H153.298V142.284H154.344V134.016Z" fill="black"/>
    <path opacity="0.2" d="M157.355 134.058H156.83V142.326H157.355V134.058Z" fill="black"/>
    <path opacity="0.2" d="M161.408 134.016H161.145V142.284H161.408V134.016Z" fill="black"/>
    <path opacity="0.2" d="M181.312 142.279H182.75V134.011H181.312V142.279Z" fill="black"/>
    <path opacity="0.2" d="M178.038 142.324H179.084V134.055H178.038V142.324Z" fill="black"/>
    <path opacity="0.2" d="M175.032 142.279H175.553V134.011H175.032V142.279Z" fill="black"/>
    <path opacity="0.2" d="M170.97 142.324H171.233V134.055H170.97V142.324Z" fill="black"/>
    <path opacity="0.2" d="M166.129 142.324H166.258V134.055H166.129V142.324Z" fill="black"/>
    <path opacity="0.2" d="M183.4 134.915H149.057C149 134.916 148.942 134.905 148.889 134.883C148.835 134.862 148.787 134.83 148.746 134.789C148.705 134.748 148.672 134.7 148.65 134.647C148.627 134.593 148.616 134.536 148.616 134.479C148.616 134.421 148.627 134.364 148.65 134.31C148.672 134.257 148.705 134.209 148.746 134.168C148.787 134.127 148.835 134.095 148.889 134.074C148.942 134.052 149 134.041 149.057 134.042H183.4C183.458 134.041 183.515 134.052 183.569 134.074C183.623 134.095 183.671 134.127 183.712 134.168C183.753 134.209 183.786 134.257 183.808 134.31C183.83 134.364 183.842 134.421 183.842 134.479C183.842 134.536 183.83 134.593 183.808 134.647C183.786 134.7 183.753 134.748 183.712 134.789C183.671 134.83 183.623 134.862 183.569 134.883C183.515 134.905 183.458 134.916 183.4 134.915Z" fill="white"/>
    <path d="M181.267 124.29H146.15C145.805 124.29 145.525 124.57 145.525 124.915V132.92C145.525 133.266 145.805 133.545 146.15 133.545H181.267C181.612 133.545 181.892 133.266 181.892 132.92V124.915C181.892 124.57 181.612 124.29 181.267 124.29Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M148.591 124.826H147.153V133.094H148.591V124.826Z" fill="black"/>
    <path opacity="0.2" d="M151.859 124.78H150.812V133.049H151.859V124.78Z" fill="black"/>
    <path opacity="0.2" d="M154.866 124.826H154.345V133.094H154.866V124.826Z" fill="black"/>
    <path opacity="0.2" d="M158.928 124.78H158.665V133.049H158.928V124.78Z" fill="black"/>
    <path opacity="0.2" d="M178.826 133.048H180.265V124.78H178.826V133.048Z" fill="black"/>
    <path opacity="0.2" d="M175.554 133.089H176.601V124.821H175.554V133.089Z" fill="black"/>
    <path opacity="0.2" d="M172.552 133.048H173.073V124.78H172.552V133.048Z" fill="black"/>
    <path opacity="0.2" d="M168.491 133.089H168.754V124.821H168.491V133.089Z" fill="black"/>
    <path opacity="0.2" d="M163.645 133.089H163.774V124.821H163.645V133.089Z" fill="black"/>
    <path opacity="0.2" d="M180.92 125.699H146.572C146.457 125.697 146.347 125.651 146.265 125.569C146.183 125.488 146.137 125.378 146.136 125.262C146.136 125.146 146.182 125.035 146.264 124.954C146.345 124.872 146.456 124.826 146.572 124.826H180.92C180.978 124.825 181.035 124.836 181.088 124.858C181.141 124.879 181.19 124.911 181.23 124.952C181.271 124.993 181.303 125.041 181.325 125.094C181.347 125.148 181.357 125.205 181.357 125.262C181.357 125.378 181.311 125.489 181.229 125.571C181.147 125.653 181.036 125.699 180.92 125.699Z" fill="white"/>
    <path d="M180.275 115.055H145.159C144.813 115.055 144.534 115.335 144.534 115.68V123.686C144.534 124.031 144.813 124.311 145.159 124.311H180.275C180.621 124.311 180.9 124.031 180.9 123.686V115.68C180.9 115.335 180.621 115.055 180.275 115.055Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M147.599 115.591H146.161V123.859H147.599V115.591Z" fill="black"/>
    <path opacity="0.2" d="M150.867 115.55H149.821V123.819H150.867V115.55Z" fill="black"/>
    <path opacity="0.2" d="M153.879 115.591H153.353V123.859H153.879V115.591Z" fill="black"/>
    <path opacity="0.2" d="M157.935 115.55H157.672V123.819H157.935V115.55Z" fill="black"/>
    <path opacity="0.2" d="M177.835 123.813H179.274V115.545H177.835V123.813Z" fill="black"/>
    <path opacity="0.2" d="M174.562 123.857H175.608V115.589H174.562V123.857Z" fill="black"/>
    <path opacity="0.2" d="M171.556 123.813H172.076V115.545H171.556V123.813Z" fill="black"/>
    <path opacity="0.2" d="M167.503 123.857H167.766V115.589H167.503V123.857Z" fill="black"/>
    <path opacity="0.2" d="M162.652 123.857H162.781V115.589H162.652V123.857Z" fill="black"/>
    <path opacity="0.2" d="M179.929 116.464H145.581C145.523 116.464 145.466 116.454 145.413 116.432C145.359 116.41 145.311 116.378 145.27 116.337C145.23 116.297 145.198 116.248 145.176 116.195C145.154 116.142 145.143 116.085 145.144 116.027C145.143 115.97 145.154 115.913 145.176 115.86C145.198 115.806 145.23 115.758 145.27 115.717C145.311 115.677 145.359 115.644 145.413 115.623C145.466 115.601 145.523 115.59 145.581 115.591H179.929C179.986 115.59 180.043 115.601 180.096 115.623C180.15 115.644 180.198 115.677 180.239 115.717C180.279 115.758 180.311 115.806 180.333 115.86C180.355 115.913 180.366 115.97 180.365 116.027C180.366 116.085 180.355 116.142 180.333 116.195C180.311 116.248 180.279 116.297 180.239 116.337C180.198 116.378 180.15 116.41 180.096 116.432C180.043 116.454 179.986 116.464 179.929 116.464Z" fill="white"/>
    <path d="M137.595 198.149H102.478C102.133 198.149 101.853 198.429 101.853 198.774V206.78C101.853 207.125 102.133 207.405 102.478 207.405H137.595C137.94 207.405 138.22 207.125 138.22 206.78V198.774C138.22 198.429 137.94 198.149 137.595 198.149Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M104.913 198.686H103.475V206.954H104.913V198.686Z" fill="black"/>
    <path opacity="0.2" d="M108.186 198.646H107.14V206.914H108.186V198.646Z" fill="black"/>
    <path opacity="0.2" d="M111.192 198.686H110.672V206.954H111.192V198.686Z" fill="black"/>
    <path opacity="0.2" d="M115.25 198.646H114.987V206.914H115.25V198.646Z" fill="black"/>
    <path opacity="0.2" d="M135.154 206.913H136.592V198.645H135.154V206.913Z" fill="black"/>
    <path opacity="0.2" d="M131.88 206.953H132.927V198.685H131.88V206.953Z" fill="black"/>
    <path opacity="0.2" d="M128.875 206.913H129.396V198.645H128.875V206.913Z" fill="black"/>
    <path opacity="0.2" d="M124.818 206.953H125.081V198.685H124.818V206.953Z" fill="black"/>
    <path opacity="0.2" d="M119.972 206.953H120.101V198.685H119.972V206.953Z" fill="black"/>
    <path opacity="0.2" d="M137.248 199.558H102.9C102.842 199.559 102.785 199.548 102.732 199.527C102.679 199.505 102.63 199.473 102.59 199.432C102.549 199.391 102.517 199.343 102.495 199.29C102.474 199.237 102.463 199.18 102.463 199.122C102.463 199.006 102.509 198.895 102.591 198.813C102.673 198.732 102.784 198.686 102.9 198.686H137.248C137.363 198.687 137.474 198.733 137.555 198.815C137.637 198.896 137.683 199.007 137.684 199.122C137.684 199.238 137.638 199.349 137.557 199.431C137.475 199.513 137.364 199.558 137.248 199.558Z" fill="white"/>
    <path d="M136.102 188.919H100.985C100.64 188.919 100.36 189.199 100.36 189.544V197.55C100.36 197.895 100.64 198.175 100.985 198.175H136.102C136.447 198.175 136.727 197.895 136.727 197.55V189.544C136.727 189.199 136.447 188.919 136.102 188.919Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M103.425 189.455H101.987V197.723H103.425V189.455Z" fill="black"/>
    <path opacity="0.2" d="M106.698 189.414H105.652V197.682H106.698V189.414Z" fill="black"/>
    <path opacity="0.2" d="M109.705 189.455H109.184V197.723H109.705V189.455Z" fill="black"/>
    <path opacity="0.2" d="M113.762 189.414H113.499V197.682H113.762V189.414Z" fill="black"/>
    <path opacity="0.2" d="M133.666 197.678H135.105V189.409H133.666V197.678Z" fill="black"/>
    <path opacity="0.2" d="M130.393 197.718H131.44V189.45H130.393V197.718Z" fill="black"/>
    <path opacity="0.2" d="M127.387 197.678H127.907V189.409H127.387V197.678Z" fill="black"/>
    <path opacity="0.2" d="M123.325 197.718H123.588V189.45H123.325V197.718Z" fill="black"/>
    <path opacity="0.2" d="M118.479 197.718H118.608V189.45H118.479V197.718Z" fill="black"/>
    <path opacity="0.2" d="M135.754 190.328H101.406C101.291 190.326 101.18 190.28 101.099 190.198C101.017 190.117 100.971 190.006 100.969 189.891C100.969 189.775 101.015 189.664 101.097 189.582C101.179 189.501 101.29 189.455 101.406 189.455H135.754C135.811 189.454 135.869 189.465 135.922 189.486C135.975 189.508 136.023 189.54 136.064 189.581C136.105 189.622 136.137 189.67 136.159 189.723C136.18 189.777 136.191 189.834 136.19 189.891C136.19 190.007 136.144 190.118 136.063 190.2C135.981 190.282 135.87 190.328 135.754 190.328Z" fill="white"/>
    <path d="M137.595 179.688H102.478C102.133 179.688 101.853 179.968 101.853 180.313V188.319C101.853 188.664 102.133 188.944 102.478 188.944H137.595C137.94 188.944 138.22 188.664 138.22 188.319V180.313C138.22 179.968 137.94 179.688 137.595 179.688Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M104.913 180.22H103.474V188.488H104.913V180.22Z" fill="black"/>
    <path opacity="0.2" d="M108.187 180.18H107.141V188.448H108.187V180.18Z" fill="black"/>
    <path opacity="0.2" d="M111.192 180.22H110.671V188.488H111.192V180.22Z" fill="black"/>
    <path opacity="0.2" d="M115.25 180.18H114.987V188.448H115.25V180.18Z" fill="black"/>
    <path opacity="0.2" d="M135.155 188.447H136.593V180.178H135.155V188.447Z" fill="black"/>
    <path opacity="0.2" d="M131.88 188.486H132.927V180.218H131.88V188.486Z" fill="black"/>
    <path opacity="0.2" d="M128.875 188.447H129.396V180.178H128.875V188.447Z" fill="black"/>
    <path opacity="0.2" d="M124.817 188.486H125.08V180.218H124.817V188.486Z" fill="black"/>
    <path opacity="0.2" d="M119.972 188.486H120.101V180.218H119.972V188.486Z" fill="black"/>
    <path opacity="0.2" d="M137.247 181.093H102.899C102.842 181.093 102.785 181.083 102.731 181.061C102.678 181.039 102.63 181.007 102.589 180.966C102.548 180.926 102.516 180.877 102.495 180.824C102.473 180.771 102.462 180.714 102.463 180.656C102.463 180.54 102.509 180.429 102.591 180.348C102.672 180.266 102.783 180.22 102.899 180.22H137.247C137.363 180.221 137.473 180.267 137.554 180.349C137.636 180.431 137.682 180.541 137.684 180.656C137.684 180.772 137.638 180.883 137.556 180.965C137.474 181.047 137.363 181.093 137.247 181.093Z" fill="white"/>
    <path d="M136.597 170.453H101.481C101.136 170.453 100.856 170.732 100.856 171.078V179.083C100.856 179.428 101.136 179.708 101.481 179.708H136.597C136.943 179.708 137.222 179.428 137.222 179.083V171.078C137.222 170.732 136.943 170.453 136.597 170.453Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M103.921 170.988H102.483V179.257H103.921V170.988Z" fill="black"/>
    <path opacity="0.2" d="M107.195 170.948H106.148V179.217H107.195V170.948Z" fill="black"/>
    <path opacity="0.2" d="M110.2 170.988H109.679V179.257H110.2V170.988Z" fill="black"/>
    <path opacity="0.2" d="M114.257 170.948H113.994V179.217H114.257V170.948Z" fill="black"/>
    <path opacity="0.2" d="M134.162 179.212H135.601V170.944H134.162V179.212Z" fill="black"/>
    <path opacity="0.2" d="M130.889 179.252H131.935V170.984H130.889V179.252Z" fill="black"/>
    <path opacity="0.2" d="M127.878 179.212H128.404V170.944H127.878V179.212Z" fill="black"/>
    <path opacity="0.2" d="M123.82 179.252H124.083V170.984H123.82V179.252Z" fill="black"/>
    <path opacity="0.2" d="M118.98 179.252H119.109V170.984H118.98V179.252Z" fill="black"/>
    <path opacity="0.2" d="M136.25 171.861H101.907C101.791 171.861 101.679 171.815 101.597 171.734C101.514 171.652 101.467 171.541 101.466 171.425C101.466 171.367 101.477 171.31 101.499 171.257C101.522 171.203 101.554 171.155 101.595 171.114C101.636 171.074 101.685 171.042 101.738 171.02C101.792 170.998 101.849 170.988 101.907 170.988H136.25C136.308 170.988 136.365 170.998 136.419 171.02C136.472 171.042 136.521 171.074 136.562 171.114C136.603 171.155 136.636 171.203 136.658 171.257C136.68 171.31 136.691 171.367 136.691 171.425C136.69 171.541 136.643 171.652 136.56 171.734C136.478 171.815 136.366 171.861 136.25 171.861Z" fill="white"/>
    <path d="M136.597 161.223H101.481C101.136 161.223 100.856 161.502 100.856 161.848V169.853C100.856 170.198 101.136 170.478 101.481 170.478H136.597C136.943 170.478 137.222 170.198 137.222 169.853V161.848C137.222 161.502 136.943 161.223 136.597 161.223Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M103.921 161.753H102.483V170.022H103.921V161.753Z" fill="black"/>
    <path opacity="0.2" d="M107.195 161.712H106.148V169.98H107.195V161.712Z" fill="black"/>
    <path opacity="0.2" d="M110.201 161.753H109.68V170.022H110.201V161.753Z" fill="black"/>
    <path opacity="0.2" d="M114.258 161.712H113.995V169.98H114.258V161.712Z" fill="black"/>
    <path opacity="0.2" d="M134.162 169.98H135.601V161.712H134.162V169.98Z" fill="black"/>
    <path opacity="0.2" d="M130.889 170.022H131.935V161.754H130.889V170.022Z" fill="black"/>
    <path opacity="0.2" d="M127.878 169.98H128.404V161.712H127.878V169.98Z" fill="black"/>
    <path opacity="0.2" d="M123.82 170.022H124.083V161.754H123.82V170.022Z" fill="black"/>
    <path opacity="0.2" d="M118.98 170.022H119.109V161.754H118.98V170.022Z" fill="black"/>
    <path opacity="0.2" d="M136.251 162.626H101.908C101.85 162.627 101.792 162.616 101.739 162.595C101.685 162.573 101.637 162.541 101.596 162.5C101.555 162.46 101.522 162.411 101.5 162.358C101.478 162.305 101.466 162.248 101.466 162.19C101.467 162.074 101.514 161.963 101.597 161.881C101.68 161.799 101.791 161.753 101.908 161.753H136.251C136.367 161.753 136.478 161.799 136.561 161.881C136.644 161.963 136.691 162.074 136.692 162.19C136.692 162.248 136.681 162.305 136.658 162.358C136.636 162.411 136.603 162.46 136.562 162.5C136.521 162.541 136.473 162.573 136.419 162.595C136.366 162.616 136.308 162.627 136.251 162.626Z" fill="white"/>
    <path d="M136.597 151.987H101.481C101.136 151.987 100.856 152.267 100.856 152.612V160.618C100.856 160.963 101.136 161.243 101.481 161.243H136.597C136.943 161.243 137.222 160.963 137.222 160.618V152.612C137.222 152.267 136.943 151.987 136.597 151.987Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M103.921 152.521H102.483V160.79H103.921V152.521Z" fill="black"/>
    <path opacity="0.2" d="M107.195 152.482H106.148V160.751H107.195V152.482Z" fill="black"/>
    <path opacity="0.2" d="M110.201 152.521H109.68V160.79H110.201V152.521Z" fill="black"/>
    <path opacity="0.2" d="M114.258 152.482H113.995V160.751H114.258V152.482Z" fill="black"/>
    <path opacity="0.2" d="M134.162 160.745H135.601V152.477H134.162V160.745Z" fill="black"/>
    <path opacity="0.2" d="M130.889 160.785H131.935V152.517H130.889V160.785Z" fill="black"/>
    <path opacity="0.2" d="M127.878 160.745H128.404V152.477H127.878V160.745Z" fill="black"/>
    <path opacity="0.2" d="M123.82 160.785H124.083V152.517H123.82V160.785Z" fill="black"/>
    <path opacity="0.2" d="M118.98 160.785H119.109V152.517H118.98V160.785Z" fill="black"/>
    <path opacity="0.2" d="M136.25 153.394H101.907C101.849 153.395 101.792 153.384 101.738 153.363C101.685 153.341 101.636 153.309 101.595 153.268C101.554 153.228 101.522 153.179 101.499 153.126C101.477 153.073 101.466 153.016 101.466 152.958C101.466 152.9 101.477 152.843 101.499 152.79C101.522 152.737 101.554 152.688 101.595 152.648C101.636 152.607 101.685 152.575 101.738 152.553C101.792 152.532 101.849 152.521 101.907 152.522H136.25C136.308 152.521 136.365 152.532 136.419 152.553C136.472 152.575 136.521 152.607 136.562 152.648C136.603 152.688 136.636 152.737 136.658 152.79C136.68 152.843 136.691 152.9 136.691 152.958C136.691 153.016 136.68 153.073 136.658 153.126C136.636 153.179 136.603 153.228 136.562 153.268C136.521 153.309 136.472 153.341 136.419 153.363C136.365 153.384 136.308 153.395 136.25 153.394Z" fill="white"/>
    <path d="M137.029 133.422H101.912C101.567 133.422 101.287 133.702 101.287 134.047V142.052C101.287 142.397 101.567 142.677 101.912 142.677H137.029C137.374 142.677 137.654 142.397 137.654 142.052V134.047C137.654 133.702 137.374 133.422 137.029 133.422Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M104.353 133.958H102.914V142.226H104.353V133.958Z" fill="black"/>
    <path opacity="0.2" d="M107.627 133.917H106.581V142.186H107.627V133.917Z" fill="black"/>
    <path opacity="0.2" d="M110.632 133.958H110.112V142.226H110.632V133.958Z" fill="black"/>
    <path opacity="0.2" d="M114.689 133.917H114.426V142.186H114.689V133.917Z" fill="black"/>
    <path opacity="0.2" d="M134.594 142.181H136.032V133.913H134.594V142.181Z" fill="black"/>
    <path opacity="0.2" d="M131.321 142.226H132.367V133.957H131.321V142.226Z" fill="black"/>
    <path opacity="0.2" d="M128.314 142.181H128.835V133.913H128.314V142.181Z" fill="black"/>
    <path opacity="0.2" d="M124.253 142.226H124.516V133.957H124.253V142.226Z" fill="black"/>
    <path opacity="0.2" d="M119.412 142.226H119.541V133.957H119.412V142.226Z" fill="black"/>
    <path opacity="0.2" d="M136.682 134.831H102.339C102.281 134.832 102.224 134.821 102.171 134.799C102.117 134.778 102.068 134.745 102.027 134.705C101.986 134.664 101.954 134.616 101.931 134.563C101.909 134.509 101.898 134.452 101.898 134.394C101.899 134.278 101.946 134.167 102.029 134.086C102.111 134.004 102.223 133.958 102.339 133.958H136.682C136.798 133.958 136.91 134.004 136.993 134.086C137.075 134.167 137.122 134.278 137.124 134.394C137.124 134.452 137.112 134.509 137.09 134.563C137.068 134.616 137.035 134.664 136.994 134.705C136.953 134.745 136.904 134.778 136.851 134.799C136.797 134.821 136.74 134.832 136.682 134.831Z" fill="white"/>
    <path d="M137.595 142.757H102.478C102.133 142.757 101.853 143.037 101.853 143.382V151.387C101.853 151.732 102.133 152.012 102.478 152.012H137.595C137.94 152.012 138.22 151.732 138.22 151.387V143.382C138.22 143.037 137.94 142.757 137.595 142.757Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M104.913 143.287H103.474V151.555H104.913V143.287Z" fill="black"/>
    <path opacity="0.2" d="M108.186 143.247H107.14V151.515H108.186V143.247Z" fill="black"/>
    <path opacity="0.2" d="M111.193 143.287H110.672V151.555H111.193V143.287Z" fill="black"/>
    <path opacity="0.2" d="M115.25 143.247H114.987V151.515H115.25V143.247Z" fill="black"/>
    <path opacity="0.2" d="M135.154 151.515H136.592V143.247H135.154V151.515Z" fill="black"/>
    <path opacity="0.2" d="M131.88 151.556H132.927V143.287H131.88V151.556Z" fill="black"/>
    <path opacity="0.2" d="M128.875 151.515H129.396V143.247H128.875V151.515Z" fill="black"/>
    <path opacity="0.2" d="M124.817 151.556H125.08V143.287H124.817V151.556Z" fill="black"/>
    <path opacity="0.2" d="M119.972 151.556H120.101V143.287H119.972V151.556Z" fill="black"/>
    <path opacity="0.2" d="M137.248 144.165H102.9C102.842 144.165 102.785 144.154 102.732 144.131C102.678 144.109 102.63 144.076 102.589 144.035C102.549 143.994 102.517 143.946 102.495 143.892C102.474 143.839 102.463 143.781 102.463 143.724C102.463 143.608 102.509 143.497 102.591 143.415C102.673 143.333 102.784 143.287 102.9 143.287H137.248C137.363 143.288 137.474 143.335 137.555 143.416C137.637 143.498 137.683 143.608 137.684 143.724C137.684 143.84 137.639 143.951 137.557 144.034C137.475 144.117 137.364 144.164 137.248 144.165Z" fill="white"/>
    <path d="M124.54 127.627L123.429 129.799L122.298 132.007L120.319 131.283L121.311 129.343L122.561 126.903L124.54 127.627Z" fill="#FFC2BC"/>
    <path opacity="0.2" d="M124.54 127.627L123.429 129.799L121.311 129.343L122.561 126.903L124.54 127.627Z" fill="black"/>
    <path d="M167.762 115.343C167.702 115.343 167.644 115.322 167.6 115.283C167.555 115.243 167.526 115.189 167.519 115.13C167.449 114.634 160.932 66.0258 153.065 44.6978C153.049 44.6667 153.04 44.6326 153.038 44.5977C153.037 44.5628 153.042 44.528 153.055 44.4954C153.067 44.4629 153.087 44.4333 153.112 44.4087C153.136 44.3842 153.166 44.3651 153.199 44.3528C153.231 44.3405 153.266 44.3352 153.301 44.3374C153.336 44.3395 153.37 44.349 153.401 44.3651C153.432 44.3813 153.459 44.4038 153.481 44.4312C153.502 44.4586 153.518 44.4903 153.527 44.5242C161.418 65.8918 167.945 114.579 168.01 115.075C168.018 115.141 168.001 115.207 167.961 115.26C167.921 115.313 167.862 115.348 167.796 115.358L167.762 115.343Z" fill="#263238"/>
    <path d="M155.202 113.944L157.31 113.86L157.722 108.969L155.609 109.054L155.202 113.944Z" fill="#FFC2BC"/>
    <path d="M157.509 112.744L155.208 112.669C155.166 112.67 155.125 112.683 155.091 112.708C155.057 112.733 155.032 112.768 155.019 112.808L154.523 114.663C154.511 114.708 154.51 114.755 154.518 114.801C154.527 114.846 154.546 114.889 154.574 114.926C154.601 114.964 154.637 114.994 154.678 115.015C154.719 115.037 154.765 115.049 154.811 115.05C155.644 115.05 156.85 115.05 157.896 115.08C159.116 115.12 159.88 115.08 161.329 115.105C162.192 115.13 162.469 114.267 162.107 114.177C160.47 113.766 159.399 113.84 158.01 112.922C157.863 112.818 157.689 112.757 157.509 112.744Z" fill="#263238"/>
    <path opacity="0.2" d="M157.722 108.969L157.509 111.489L155.401 111.578L155.609 109.054L157.722 108.969Z" fill="black"/>
    <path d="M154.345 96.2264C154.88 97.2531 154.92 108.016 154.92 110.749H158.566C159.037 107.917 161.368 92.7097 158.07 90.5373C154.439 88.1416 145.233 88.9649 145.233 88.9649L144.569 87.6208L143.239 84.9424L135.621 87.7944L133.959 88.4094L133.275 88.6673H133.215C133.215 88.6673 133.215 88.7417 133.215 88.8707C133.24 89.1683 133.309 89.7685 133.433 90.5174C133.577 91.4158 133.779 92.3039 134.038 93.176C134.743 95.4725 135.993 97.64 138.165 96.9158C138.259 96.8861 138.354 96.8464 138.448 96.8067C138.626 96.7273 138.82 96.6529 139.018 96.5785C140.76 96.0175 142.561 95.6629 144.385 95.5221C148.988 95.0856 153.938 95.4328 154.345 96.2264Z" fill="#263238"/>
    <path d="M158.73 111.067L154.469 111.181L154.444 109.807L159.126 109.613L158.73 111.067Z" fill="#1A54B9"/>
    <path d="M122.665 130.947H120.096C120.054 130.947 120.014 130.961 119.981 130.987C119.949 131.013 119.926 131.05 119.917 131.09L119.481 132.96C119.47 133.006 119.47 133.053 119.48 133.098C119.491 133.144 119.511 133.186 119.54 133.222C119.569 133.259 119.606 133.288 119.649 133.308C119.691 133.328 119.737 133.338 119.783 133.337C120.611 133.337 121.217 133.273 122.263 133.273C122.903 133.273 124.832 133.342 125.735 133.342C126.638 133.342 126.727 132.464 126.38 132.385C124.763 132.033 123.543 131.542 123.027 131.076C122.978 131.031 122.921 130.997 122.859 130.975C122.797 130.952 122.731 130.943 122.665 130.947Z" fill="#263238"/>
    <path d="M141.365 88.6479L140.923 90.1706C140.69 90.9841 140.368 92.0902 139.991 93.3748L139.049 96.5839C137.441 102.015 135.398 108.795 134.267 112.014C132.015 118.403 124.481 129.766 124.481 129.766L120.855 128.437C120.855 128.437 126.42 117.733 128.246 111.256C129.52 106.732 131.519 98.6026 132.834 93.1417L133.464 90.5228C133.677 89.63 133.85 88.9009 133.96 88.39C134.069 87.8791 134.158 87.5518 134.158 87.5518L135.621 87.775L141.365 88.6479Z" fill="#263238"/>
    <path d="M124.872 130.005L120.606 128.437L121.087 127.167L125.636 128.546L124.872 130.005Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M144.583 87.6158L141.364 91.3705L140.923 90.1702L140.427 88.8608L139.975 93.3744L134.053 93.1809L132.818 93.1413L133.448 90.5224C133.324 89.7734 133.254 89.1733 133.23 88.8757L133.269 88.6525L133.954 88.3945C134.083 87.8589 134.152 87.5563 134.152 87.5563L135.615 87.7795L143.249 84.9424L144.583 87.6158Z" fill="black"/>
    <path opacity="0.2" d="M144.404 95.5277C142.368 95.6642 140.363 96.0969 138.452 96.8123C138.358 96.852 138.264 96.8917 138.17 96.9214L138.601 94.7986C138.601 94.7986 142.316 94.625 144.404 95.5277Z" fill="black"/>
    <path d="M116.703 89.6538L115.408 91.3699L114.312 89.2818C114.312 89.2818 115.557 87.8186 116.296 88.4931L116.703 89.6538Z" fill="#FFC2BC"/>
    <path d="M113.43 92.1535L113.107 90.0157L114.313 89.2568L115.409 91.3698L113.43 92.1535Z" fill="#FFC2BC"/>
    <path d="M117.055 89.4958L116.693 89.6793L116.158 88.3202L116.733 88.0425L117.055 89.4958Z" fill="white"/>
    <path d="M131.494 77.111C131.305 77.4417 131.112 77.7591 130.913 78.0633C130.414 78.8768 129.892 79.677 129.346 80.464L128.895 81.1137C128.438 81.7685 127.957 82.4083 127.441 83.0481C127.184 83.3656 126.921 83.688 126.628 84.0054C126.334 84.3378 126.021 84.6525 125.691 84.9478C124.422 86.0278 123.026 86.9489 121.534 87.6907C120.096 88.4339 118.604 89.0673 117.07 89.5854L116.425 88.1371C117.07 87.7453 117.74 87.3435 118.38 86.9269C119.019 86.5102 119.669 86.0985 120.289 85.6621C120.909 85.2256 121.519 84.7841 122.095 84.3229C122.66 83.8798 123.197 83.4011 123.702 82.8894C124.654 81.7827 125.512 80.5981 126.266 79.348C127.094 78.0633 128.141 75.8115 128.959 74.5269L131.494 77.111Z" fill="#263238"/>
    <path opacity="0.2" d="M130.914 78.0632C130.414 78.8766 129.892 79.6768 129.346 80.4638C129.401 79.4206 129.691 78.4034 130.194 77.4878C130.493 77.5911 130.747 77.7943 130.914 78.0632Z" fill="black"/>
    <path d="M159.354 71.7685L157.916 73.1077L158.119 74.3477C158.645 75.0421 159.855 74.184 160.138 73.9658C160.183 73.933 160.231 73.9047 160.282 73.8815L161.046 73.5739C161.181 73.5178 161.293 73.4177 161.364 73.2899C161.435 73.1621 161.461 73.014 161.437 72.8696L161.289 71.957C161.275 71.8713 161.244 71.7893 161.198 71.716C161.152 71.6426 161.091 71.5794 161.019 71.5303C160.948 71.4811 160.867 71.447 160.782 71.4299C160.697 71.4129 160.61 71.4133 160.525 71.4312L159.667 71.6048C159.549 71.6277 159.44 71.6846 159.354 71.7685Z" fill="#FFC2BC"/>
    <path d="M157.583 73.1178L157.98 73.0186L158.208 74.457L157.583 74.6058V73.1178Z" fill="white"/>
    <path d="M157.916 74.6168C156.34 74.9504 154.742 75.1659 153.134 75.2616C151.478 75.3902 149.812 75.3136 148.174 75.0335C147.738 74.9501 147.308 74.8408 146.885 74.7061C146.468 74.5772 146.081 74.4383 145.694 74.2944C144.926 74.0117 144.206 73.7092 143.457 73.3768L142.58 72.98C141.915 72.6725 141.26 72.3551 140.596 72.0228C140.1 71.7847 139.638 71.5268 139.162 71.2688L140.437 68.2383C141.801 68.9228 143.413 69.8503 144.782 70.5298C146.067 71.2205 147.409 71.798 148.794 72.2559C149.489 72.4396 150.195 72.5772 150.907 72.6676C151.641 72.7717 152.395 72.8362 153.144 72.8957C153.893 72.9552 154.667 72.99 155.431 72.9999C156.195 73.0098 156.978 73.0346 157.732 73.0445L157.916 74.6168Z" fill="#263238"/>
    <path opacity="0.2" d="M142.564 72.9802C141.9 72.6727 141.245 72.3552 140.58 72.0229L140.323 70.6143C141.315 71.1278 142.105 71.9615 142.564 72.9802Z" fill="black"/>
    <path d="M129.401 74.2531C130.22 76.2697 130.874 78.3499 131.355 80.473C131.712 81.961 132.977 83.449 133.195 84.813C133.403 86.3174 133.702 87.8081 134.088 89.277C135.601 88.4635 140.179 87.4567 143.249 85.805C143.378 73.4447 140.06 68.6235 140.06 68.6235C139.217 68.8379 138.389 69.1046 137.58 69.4221L137.044 69.6453C135.567 70.3425 134.126 71.1157 132.729 71.9616C132.456 72.1253 132.183 72.2939 131.92 72.4576C130.571 73.3405 129.401 74.2531 129.401 74.2531Z" fill="#1A54B9"/>
    <path opacity="0.7" d="M129.401 74.2531C130.22 76.2697 130.874 78.3499 131.355 80.473C131.712 81.961 132.977 83.449 133.195 84.813C133.403 86.3174 133.702 87.8081 134.088 89.277C135.601 88.4635 140.179 87.4567 143.249 85.805C143.378 73.4447 140.06 68.6235 140.06 68.6235C139.217 68.8379 138.389 69.1046 137.58 69.4221L137.044 69.6453C135.567 70.3425 134.126 71.1157 132.729 71.9616C132.456 72.1253 132.183 72.2939 131.92 72.4576C130.571 73.3405 129.401 74.2531 129.401 74.2531Z" fill="white"/>
    <path d="M137.53 74.0205L137.718 72.8748L136.93 72.8252L136.449 73.3559L137.53 74.0205Z" fill="#1A54B9"/>
    <path d="M136.93 72.8256C135.472 73.6391 134.028 72.6917 133.096 72.0419L132.996 71.9725C133.408 71.4021 133.448 70.4845 133.314 69.5619C133.156 68.5425 132.883 67.5442 132.5 66.5859L135.839 68.5005C135.839 68.7287 135.799 68.932 135.789 69.1106C135.744 69.5421 135.739 69.8347 135.977 70.0232C136.348 70.2297 136.77 70.3245 137.193 70.296C137.575 70.7375 137.932 72.2651 136.93 72.8256Z" fill="#FFC2BC"/>
    <path d="M131.94 72.4741C131.94 72.4741 136.141 76.8588 139.217 79.2445C139.217 79.2445 138.988 71.492 137.6 69.4336L140.457 68.2383C140.457 68.2383 143.204 74.3093 143.596 84.4476L144.836 87.002L141.364 90.5335L140.248 87.9146L139.499 92.2596L132.337 92.8944L133.215 87.9344L128.984 74.5424L131.94 72.4741Z" fill="#263238"/>
    <path d="M137.153 73.7883C137.153 73.7883 138.145 77.171 138.076 78.9764L139.355 79.6411L139.583 78.6491C139.583 78.6491 138.458 74.2892 137.421 73.5254L137.153 73.7883Z" fill="#1A54B9"/>
    <path d="M132.089 72.111C132.089 72.111 137.337 77.8695 139.192 79.2434C139.192 79.2434 138.651 71.4463 137.446 69.4871C137.446 69.4871 138.274 68.9167 140.04 70.6378L139.157 72.2746L140.724 73.3559C140.724 73.3559 140.189 79.1988 139.157 81.9714C139.232 81.3859 139.265 80.7959 139.256 80.2057L133.101 76.7932L133.374 74.9084L131.047 74.8092C131.047 74.8092 131.305 72.5822 132.089 72.111Z" fill="#263238"/>
    <path opacity="0.2" d="M132.089 72.111C132.089 72.111 137.337 77.8695 139.192 79.2434C139.192 79.2434 138.651 71.4463 137.446 69.4871C137.446 69.4871 138.274 68.9167 140.04 70.6378L139.157 72.2746L140.724 73.3559C140.724 73.3559 140.189 79.1988 139.157 81.9714C139.232 81.3859 139.265 80.7959 139.256 80.2057L133.101 76.7932L133.374 74.9084L131.047 74.8092C131.047 74.8092 131.305 72.5822 132.089 72.111Z" fill="white"/>
    <path d="M153.457 45.0502C153.457 45.0502 150.481 44.7129 148.819 45.4966C147.158 46.2803 145.486 48.8347 142.788 49.0777C140.09 49.3208 139.549 48.4428 139.549 48.4428C139.549 48.4428 141.925 52.6588 146.493 52.6142C151.061 52.5696 153.437 51.8156 156.13 53.512C156.14 53.512 154.067 46.3646 153.457 45.0502Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M135.977 70.0135C135.7 70.8765 135.01 72.0967 133.378 72.0669C133.284 72.0747 133.19 72.0747 133.096 72.0669L132.996 71.9975C133.408 71.4271 133.448 70.5095 133.314 69.5869C134.047 69.1122 134.939 68.9464 135.794 69.1257C135.744 69.5324 135.739 69.825 135.977 70.0135Z" fill="black"/>
    <path d="M136.93 72.8247C136.956 73.4241 136.828 74.0201 136.558 74.5558C135.64 74.4268 132.897 73.0678 132.759 71.9319C132.829 71.6593 132.977 71.4129 133.185 71.2227C134.231 72.1421 135.543 72.7035 136.93 72.8247Z" fill="white"/>
    <path d="M136.93 72.8251C137.441 72.9576 137.917 73.1996 138.324 73.5343C138.582 72.4679 137.516 69.9036 137.243 69.7647C136.798 69.7216 136.35 69.7969 135.943 69.983C135.943 69.983 137.441 71.3817 136.93 72.8251Z" fill="white"/>
    <path d="M129.584 65.5149C129.53 66.3631 130.08 67.9503 131.033 67.1567C131.985 66.3631 129.679 64.0567 129.584 65.5149Z" fill="#263238"/>
    <path d="M135.521 65.1924C136.136 67.1764 136.598 68.3271 135.988 69.6911C135.065 71.7445 132.307 71.7396 131.102 69.9589C130.021 68.3618 129.192 65.366 130.824 63.8829C131.185 63.5558 131.623 63.3254 132.097 63.2132C132.57 63.1011 133.065 63.1108 133.534 63.2416C134.003 63.3724 134.432 63.6199 134.779 63.961C135.127 64.3021 135.382 64.7258 135.521 65.1924Z" fill="#FFC2BC"/>
    <path d="M135.839 62.6668C135.64 61.3673 134.098 61.5508 133.195 62.2353V64.7996C133.543 64.6341 133.875 64.4365 134.187 64.2094C134.187 64.2094 134.539 66.1289 135.715 66.6894C137.213 65.2361 137.337 62.9942 135.839 62.6668Z" fill="#263238"/>
    <path d="M136.9 66.5356C136.928 67.0569 136.75 67.5684 136.404 67.9591C135.933 68.4551 135.328 68.1327 135.154 67.4929C134.981 66.8531 135.05 65.9702 135.65 65.6974C135.788 65.6401 135.937 65.6177 136.086 65.6323C136.234 65.6468 136.376 65.6979 136.5 65.7809C136.624 65.8639 136.725 65.9763 136.795 66.108C136.865 66.2397 136.901 66.3866 136.9 66.5356Z" fill="#FFC2BC"/>
    <path d="M132.754 66.829C132.813 66.9878 132.754 67.1465 132.67 67.1862C132.585 67.2258 132.431 67.1266 132.372 66.9679C132.312 66.8092 132.372 66.6505 132.456 66.6108C132.541 66.5711 132.689 66.6703 132.754 66.829Z" fill="#263238"/>
    <path d="M130.949 67.4882C131.013 67.6469 130.974 67.8057 130.869 67.8453C130.765 67.885 130.631 67.7858 130.572 67.6271C130.512 67.4684 130.547 67.3097 130.651 67.27C130.755 67.2303 130.884 67.3295 130.949 67.4882Z" fill="#263238"/>
    <path d="M131.548 67.3599C131.528 67.9012 131.433 68.4371 131.265 68.952C131.403 68.9864 131.546 68.991 131.686 68.9653C131.825 68.9396 131.958 68.8844 132.074 68.8032L131.548 67.3599Z" fill="#ED893E"/>
    <path d="M133.16 69.1003L132.957 69.1896C132.944 69.1942 132.931 69.1937 132.919 69.1881C132.906 69.1825 132.897 69.1723 132.893 69.1598C132.888 69.1473 132.888 69.1335 132.894 69.1214C132.9 69.1094 132.91 69.1 132.922 69.0954C133.122 69.0273 133.303 68.9133 133.45 68.7625C133.597 68.6118 133.707 68.4284 133.77 68.2274C133.772 68.2208 133.774 68.2145 133.778 68.2089C133.781 68.2033 133.786 68.1986 133.792 68.1949C133.797 68.1911 133.804 68.1886 133.81 68.1874C133.817 68.1861 133.823 68.1862 133.83 68.1877C133.836 68.1887 133.843 68.1912 133.848 68.195C133.853 68.1987 133.858 68.2036 133.861 68.2093C133.865 68.2149 133.867 68.2213 133.867 68.2279C133.868 68.2344 133.867 68.241 133.865 68.2472C133.812 68.43 133.722 68.5999 133.601 68.7466C133.48 68.8934 133.33 69.0137 133.16 69.1003Z" fill="#263238"/>
    <path d="M133.091 66.0444C133.075 66.0531 133.057 66.0577 133.039 66.0577C133.02 66.0577 133.003 66.0531 132.987 66.0444C132.882 65.9735 132.763 65.9284 132.638 65.9129C132.513 65.8974 132.386 65.9119 132.267 65.9552C132.243 65.9657 132.215 65.9661 132.191 65.9563C132.166 65.9466 132.146 65.9274 132.136 65.9031C132.125 65.8788 132.125 65.8512 132.135 65.8266C132.144 65.8019 132.164 65.7822 132.188 65.7717C132.337 65.715 132.497 65.6949 132.655 65.713C132.812 65.7311 132.964 65.787 133.096 65.8758C133.117 65.8909 133.132 65.9136 133.138 65.9394C133.143 65.9652 133.139 65.9921 133.125 66.0147C133.116 66.0268 133.104 66.037 133.091 66.0444Z" fill="#263238"/>
    <path d="M129.956 67.1558C129.937 67.1631 129.916 67.1631 129.897 67.1558C129.884 67.1546 129.871 67.1508 129.859 67.1446C129.848 67.1384 129.838 67.1301 129.829 67.1199C129.821 67.1098 129.815 67.0981 129.811 67.0855C129.807 67.0729 129.806 67.0598 129.807 67.0467C129.822 66.8873 129.874 66.7337 129.96 66.5989C130.047 66.4642 130.164 66.3524 130.303 66.273C130.315 66.267 130.328 66.2635 130.341 66.2625C130.354 66.2615 130.367 66.2631 130.38 66.2673C130.392 66.2715 130.404 66.278 130.414 66.2867C130.423 66.2953 130.432 66.3058 130.437 66.3176C130.45 66.3406 130.452 66.3676 130.445 66.3927C130.438 66.4178 130.421 66.4389 130.398 66.4515C130.29 66.516 130.2 66.6057 130.135 66.7129C130.071 66.8201 130.033 66.9415 130.026 67.0666C130.025 67.0867 130.017 67.1061 130.005 67.1221C129.993 67.138 129.976 67.1498 129.956 67.1558Z" fill="#263238"/>
    <path d="M134.143 64.2247C134.143 64.2247 132.888 65.5193 131.663 65.4548C131.993 65.3046 132.294 65.0963 132.551 64.8398C132.551 64.8398 130.155 65.8764 128.974 65.3358C127.794 64.7951 127.928 64.2099 127.928 64.2099C127.928 64.2099 128.062 64.5521 129.208 64.5174C129.208 64.5174 128.216 63.8676 128.617 62.95C128.675 63.0598 128.759 63.1534 128.862 63.2219C128.965 63.2904 129.084 63.3317 129.208 63.3419C129.596 62.7433 130.118 62.2428 130.733 61.8797C131.347 61.5165 132.037 61.3006 132.749 61.2487C135.323 60.9561 135.844 61.9729 135.958 62.7367C136.072 63.5006 134.143 64.2247 134.143 64.2247Z" fill="#263238"/>
    <path d="M92.9246 198.15H57.8078C57.4627 198.15 57.1829 198.43 57.1829 198.775V206.78C57.1829 207.125 57.4627 207.405 57.8078 207.405H92.9246C93.2698 207.405 93.5496 207.125 93.5496 206.78V198.775C93.5496 198.43 93.2698 198.15 92.9246 198.15Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M60.2482 198.687H58.8098V206.955H60.2482V198.687Z" fill="black"/>
    <path opacity="0.2" d="M63.5168 198.646H62.4702V206.915H63.5168V198.646Z" fill="black"/>
    <path opacity="0.2" d="M66.5228 198.687H66.002V206.955H66.5228V198.687Z" fill="black"/>
    <path opacity="0.2" d="M70.5793 198.646H70.3164V206.915H70.5793V198.646Z" fill="black"/>
    <path opacity="0.2" d="M90.4837 206.914H91.9221V198.646H90.4837V206.914Z" fill="black"/>
    <path opacity="0.2" d="M87.2154 206.955H88.262V198.687H87.2154V206.955Z" fill="black"/>
    <path opacity="0.2" d="M84.2045 206.914H84.7253V198.646H84.2045V206.914Z" fill="black"/>
    <path opacity="0.2" d="M80.147 206.955H80.4099V198.687H80.147V206.955Z" fill="black"/>
    <path opacity="0.2" d="M75.3015 206.955H75.4304V198.687H75.3015V206.955Z" fill="black"/>
    <path opacity="0.2" d="M92.5775 199.559H58.2295C58.172 199.56 58.1149 199.549 58.0617 199.528C58.0084 199.506 57.96 199.474 57.9194 199.433C57.8787 199.392 57.8466 199.344 57.8249 199.291C57.8032 199.238 57.7923 199.181 57.793 199.123C57.793 199.007 57.839 198.896 57.9208 198.814C58.0027 198.733 58.1137 198.687 58.2295 198.687H92.5775C92.6932 198.687 92.8043 198.733 92.8861 198.814C92.968 198.896 93.014 199.007 93.014 199.123C93.0146 199.181 93.0038 199.238 92.9821 199.291C92.9604 199.344 92.9283 199.392 92.8876 199.433C92.8469 199.474 92.7986 199.506 92.7453 199.528C92.692 199.549 92.635 199.56 92.5775 199.559Z" fill="white"/>
    <path d="M93.4209 188.92H58.3042C57.959 188.92 57.6792 189.2 57.6792 189.545V197.551C57.6792 197.896 57.959 198.176 58.3042 198.176H93.4209C93.7661 198.176 94.0459 197.896 94.0459 197.551V189.545C94.0459 189.2 93.7661 188.92 93.4209 188.92Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M60.7438 189.455H59.3054V197.723H60.7438V189.455Z" fill="black"/>
    <path opacity="0.2" d="M64.0126 189.415H62.9661V197.683H64.0126V189.415Z" fill="black"/>
    <path opacity="0.2" d="M67.0181 189.455H66.4973V197.723H67.0181V189.455Z" fill="black"/>
    <path opacity="0.2" d="M71.0808 189.415H70.8179V197.683H71.0808V189.415Z" fill="black"/>
    <path opacity="0.2" d="M90.9801 197.679H92.4185V189.41H90.9801V197.679Z" fill="black"/>
    <path opacity="0.2" d="M87.7064 197.719H88.7529V189.451H87.7064V197.719Z" fill="black"/>
    <path opacity="0.2" d="M84.7011 197.679H85.2219V189.41H84.7011V197.679Z" fill="black"/>
    <path opacity="0.2" d="M80.6439 197.719H80.9067V189.451H80.6439V197.719Z" fill="black"/>
    <path opacity="0.2" d="M75.7978 197.719H75.9268V189.451H75.7978V197.719Z" fill="black"/>
    <path opacity="0.2" d="M93.0733 190.328H58.7253C58.6096 190.328 58.4985 190.282 58.4167 190.2C58.3348 190.118 58.2888 190.007 58.2888 189.892C58.2882 189.834 58.299 189.777 58.3207 189.724C58.3424 189.671 58.3745 189.622 58.4152 189.581C58.4559 189.541 58.5043 189.509 58.5575 189.487C58.6108 189.465 58.6678 189.454 58.7253 189.455H93.0733C93.1308 189.454 93.1879 189.465 93.2411 189.487C93.2944 189.509 93.3428 189.541 93.3834 189.581C93.4241 189.622 93.4562 189.671 93.4779 189.724C93.4996 189.777 93.5105 189.834 93.5098 189.892C93.5098 190.007 93.4638 190.118 93.382 190.2C93.3001 190.282 93.1891 190.328 93.0733 190.328Z" fill="white"/>
    <path d="M91.4361 179.69H56.3193C55.9741 179.69 55.6943 179.97 55.6943 180.315V188.32C55.6943 188.666 55.9741 188.945 56.3193 188.945H91.4361C91.7812 188.945 92.061 188.666 92.061 188.32V180.315C92.061 179.97 91.7812 179.69 91.4361 179.69Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M58.7548 180.22H57.3164V188.489H58.7548V180.22Z" fill="black"/>
    <path opacity="0.2" d="M62.0292 180.18H60.9827V188.448H62.0292V180.18Z" fill="black"/>
    <path opacity="0.2" d="M65.0345 180.22H64.5137V188.489H65.0345V180.22Z" fill="black"/>
    <path opacity="0.2" d="M69.0912 180.18H68.8284V188.448H69.0912V180.18Z" fill="black"/>
    <path opacity="0.2" d="M88.9913 188.447H90.4297V180.179H88.9913V188.447Z" fill="black"/>
    <path opacity="0.2" d="M85.7279 188.488H86.7744V180.22H85.7279V188.488Z" fill="black"/>
    <path opacity="0.2" d="M82.7177 188.447H83.2385V180.179H82.7177V188.447Z" fill="black"/>
    <path opacity="0.2" d="M78.6597 188.488H78.9226V180.22H78.6597V188.488Z" fill="black"/>
    <path opacity="0.2" d="M73.8132 188.488H73.9421V180.22H73.8132V188.488Z" fill="black"/>
    <path opacity="0.2" d="M91.0894 181.093H56.7414C56.6839 181.094 56.6269 181.083 56.5736 181.061C56.5204 181.04 56.472 181.007 56.4313 180.967C56.3907 180.926 56.3585 180.878 56.3368 180.825C56.3151 180.771 56.3043 180.714 56.305 180.657C56.305 180.541 56.3509 180.43 56.4328 180.348C56.5147 180.266 56.6257 180.22 56.7414 180.22H91.0894C91.2048 180.222 91.3151 180.268 91.3967 180.349C91.4782 180.431 91.5246 180.541 91.5259 180.657C91.5259 180.772 91.4799 180.883 91.3981 180.965C91.3162 181.047 91.2052 181.093 91.0894 181.093Z" fill="white"/>
    <path d="M71.6566 173.568L70.6596 176.023L69.9057 177.878L68.011 177.203L68.6905 175.537L69.7619 172.888L71.6566 173.568Z" fill="#E4897B"/>
    <path d="M112.15 160.424L110.538 161.62L109.948 160.31L108.579 157.285L110.191 156.084L111.485 158.951L112.15 160.424Z" fill="#E4897B"/>
    <path opacity="0.2" d="M69.7615 172.888L71.6563 173.567L70.6593 176.022L68.6902 175.536L69.7615 172.888Z" fill="black"/>
    <path opacity="0.2" d="M111.485 158.951L109.948 160.31L108.579 157.285L110.191 156.084L111.485 158.951Z" fill="black"/>
    <path d="M85.8769 105.593C85.9315 105.747 86.0555 105.841 86.1547 105.806C86.2539 105.772 86.2935 105.618 86.239 105.464C86.1844 105.31 86.0604 105.216 85.9612 105.251C85.862 105.286 85.8124 105.439 85.8769 105.593Z" fill="#263238"/>
    <path d="M86.0408 105.845C86.3876 106.247 86.7882 106.599 87.2312 106.891C87.1544 107.004 87.0536 107.098 86.9361 107.166C86.8186 107.235 86.6873 107.276 86.5517 107.288L86.0408 105.845Z" fill="#DE5753"/>
    <path d="M85.0178 104.942C84.9951 104.949 84.971 104.949 84.9484 104.942C84.932 104.925 84.9229 104.902 84.9229 104.878C84.9229 104.854 84.932 104.831 84.9484 104.813C85.0314 104.688 85.143 104.583 85.274 104.509C85.4051 104.435 85.5518 104.393 85.7023 104.387C85.7277 104.388 85.7517 104.399 85.7692 104.417C85.7868 104.436 85.7966 104.46 85.7965 104.486C85.7965 104.499 85.7939 104.511 85.7889 104.523C85.7839 104.535 85.7765 104.546 85.7672 104.554C85.7579 104.563 85.7469 104.57 85.7349 104.574C85.7229 104.579 85.7101 104.581 85.6973 104.58C85.5779 104.585 85.4615 104.619 85.3579 104.679C85.2543 104.738 85.1666 104.822 85.1021 104.922C85.0902 104.931 85.0765 104.938 85.062 104.941C85.0475 104.944 85.0325 104.945 85.0178 104.942Z" fill="#263238"/>
    <path d="M82.2058 108.093C82.2306 109.581 82.0421 112.289 80.8567 113.078C80.8567 113.078 80.9906 114.615 83.6789 115.091C86.6549 115.622 85.3554 113.876 85.3554 113.876C83.8129 113.207 84.0658 112.016 84.5469 110.94L82.2058 108.093Z" fill="#E4897B"/>
    <path d="M80.6682 107.065C81.442 108.9 81.8041 110.041 83.0937 110.646C85.0281 111.589 87.0617 109.912 86.8285 107.884C86.6401 106.053 85.4001 103.36 83.297 103.261C82.8343 103.237 82.3732 103.332 81.9576 103.537C81.5419 103.742 81.1855 104.049 80.9221 104.43C80.6587 104.811 80.4972 105.254 80.4527 105.715C80.4083 106.176 80.4825 106.641 80.6682 107.065Z" fill="#E4897B"/>
    <path d="M81.978 110.051C82.851 110.403 85.5939 108.439 84.6564 106.177C84.6564 106.177 85.5939 104.664 85.2566 103.201C84.9193 101.738 83.8182 101.296 82.7766 101.772C81.854 102.189 81.9532 102.71 81.9532 102.71C81.9532 102.71 77.4892 103.88 78.7094 105.592C77.1371 107.853 80.8819 109.619 81.978 110.051Z" fill="#263238"/>
    <path d="M83.922 106.694C84.1184 107.148 84.458 107.526 84.8892 107.77C85.4645 108.082 85.7968 107.646 85.6778 107.066C85.5588 106.485 85.1471 105.751 84.5519 105.677C83.9567 105.602 83.6938 106.148 83.922 106.694Z" fill="#E4897B"/>
    <path d="M80.1423 113.09C79.934 112.674 79.7058 112.198 80.2118 112.069C80.7177 111.94 84.3087 111.731 85.3354 112.069C84.9981 112.629 85.519 113.343 85.519 113.343L80.1423 113.09Z" fill="#1A54B9"/>
    <path opacity="0.5" d="M80.1423 113.09C79.934 112.674 79.7058 112.198 80.2118 112.069C80.7177 111.94 84.3087 111.731 85.3354 112.069C84.9981 112.629 85.519 113.343 85.519 113.343L80.1423 113.09Z" fill="#FAFAFA"/>
    <path d="M70.0336 177.076H67.8413C67.8015 177.076 67.7631 177.09 67.7324 177.115C67.7017 177.141 67.6806 177.176 67.6727 177.215L67.2759 179C67.2651 179.043 67.2645 179.088 67.2741 179.132C67.2836 179.175 67.3031 179.216 67.331 179.25C67.3588 179.285 67.3943 179.312 67.4347 179.331C67.475 179.35 67.5192 179.359 67.5636 179.357C68.3522 179.357 69.4979 179.298 70.4899 179.298C71.6556 179.298 72.6575 179.362 74.0215 179.362C74.8498 179.362 75.078 178.529 74.7308 178.455C73.1584 178.107 71.8787 178.073 70.5197 177.234C70.3751 177.138 70.2071 177.084 70.0336 177.076Z" fill="#263238"/>
    <path d="M84.2199 130.824C84.2199 130.824 83.595 135.035 82.8361 139.876C82.5038 141.95 82.1466 144.137 81.7945 146.161C81.4026 148.427 81.0207 150.481 80.7033 151.929C79.3194 158.228 71.9737 175.588 71.9737 175.588L68.3777 174.81C68.3777 174.81 73.5162 161.304 74.662 152.49C75.5498 145.729 77.2908 130.819 77.2908 130.819L84.2199 130.824Z" fill="#263238"/>
    <path d="M72.8315 174.383L72.1619 175.683L68.1145 174.805L68.4518 173.604L72.8315 174.383Z" fill="#1A54B9"/>
    <path opacity="0.3" d="M72.8315 174.383L72.1619 175.683L68.1145 174.805L68.4518 173.604L72.8315 174.383Z" fill="black"/>
    <path opacity="0.2" d="M82.8357 139.875C82.5034 141.949 82.1463 144.136 81.7941 146.16C80.8021 142.157 81.1245 139.761 81.1245 139.761L82.8357 139.875Z" fill="black"/>
    <path d="M78.3422 116.768C77.375 117.68 76.3582 118.648 75.4356 119.615C74.5034 120.562 73.6194 121.555 72.787 122.591C72.4318 123.104 72.112 123.641 71.8297 124.198C71.5222 124.798 71.2097 125.408 70.9121 126.033L69.0918 129.793L67.6633 129.297C67.9708 127.903 68.3627 126.544 68.7892 125.18C69.0124 124.5 69.2505 123.821 69.5134 123.141C69.7906 122.411 70.1309 121.705 70.5302 121.033C71.3362 119.804 72.242 118.642 73.2384 117.561C74.2019 116.48 75.2288 115.456 76.3136 114.496L78.3422 116.768Z" fill="#DC897C"/>
    <path d="M67.5445 132.966L68.2736 131.672L66.0515 130.997C65.9262 131.297 65.865 131.619 65.8719 131.944C65.8787 132.268 65.9534 132.588 66.0912 132.882L67.5445 132.966Z" fill="#E4897B"/>
    <path d="M69.0873 129.772L67.6638 129.311L66.0518 130.997L68.2738 131.672L69.0873 129.772Z" fill="#E4897B"/>
    <path d="M112.125 160.151L110.315 161.495C110.283 161.52 110.26 161.554 110.25 161.593C110.241 161.631 110.244 161.672 110.26 161.709L111.004 163.38C111.022 163.421 111.048 163.457 111.082 163.486C111.116 163.515 111.157 163.535 111.2 163.546C111.243 163.557 111.288 163.557 111.332 163.547C111.375 163.537 111.416 163.517 111.45 163.489C112.075 162.993 112.358 162.745 113.152 162.155C113.648 161.793 114.779 160.994 115.458 160.493C116.138 159.992 115.726 159.253 115.403 159.402C113.96 160.052 113.127 160.121 112.462 160.057C112.342 160.046 112.222 160.08 112.125 160.151Z" fill="#263238"/>
    <path d="M77.4791 113.279C75.5497 113.16 72.7026 117.332 72.7026 117.332L75.51 121.166C76.7421 120.447 77.7673 119.422 78.486 118.19C79.8202 115.938 79.4482 113.403 77.4791 113.279Z" fill="#1A54B9"/>
    <path opacity="0.6" d="M77.4791 113.279C75.5497 113.16 72.7026 117.332 72.7026 117.332L75.51 121.166C76.7421 120.447 77.7673 119.422 78.486 118.19C79.8202 115.938 79.4482 113.403 77.4791 113.279Z" fill="#FAFAFA"/>
    <path opacity="0.2" d="M78.0247 117.13C76.7599 117.809 75.9167 119.481 75.386 120.994L75.51 121.167C76.7421 120.449 77.7673 119.423 78.486 118.191C78.5653 118.062 78.6397 117.923 78.7092 117.79L78.0247 117.13Z" fill="black"/>
    <path d="M88.897 112.972C88.897 112.972 90.0526 115.879 88.029 130.828H77.2906C77.2162 129.176 78.2528 121.131 76.6606 113.364C77.8481 113.134 79.0493 112.981 80.2566 112.908C81.6306 112.843 86.2086 111.916 87.4189 112.03C89.011 112.169 88.897 112.972 88.897 112.972Z" fill="#1A54B9"/>
    <path opacity="0.6" d="M89.1499 119.564C89.021 122.208 88.6986 125.848 88.029 130.823H77.2906C77.2162 129.172 78.2528 121.126 76.6606 113.359C77.8481 113.129 79.0493 112.976 80.2566 112.903C81.6306 112.838 86.2086 111.911 87.4189 112.025C89.011 112.169 88.9069 112.972 88.9069 112.972C88.9069 112.972 89.1499 113.582 89.2144 115.68C89.2392 116.638 89.2342 117.902 89.1499 119.564Z" fill="#FAFAFA"/>
    <path d="M87.5924 129.955L89.7153 134.915C89.7153 134.915 99.9973 136.205 102.914 139.741C105.83 143.278 111.346 157.627 111.346 157.627L108.598 159.963C108.598 159.963 100.712 145.46 98.5044 143.709C96.2972 141.958 83.7335 141.963 80.9261 140.917C78.1188 139.87 77.3103 130.808 77.3103 130.808L87.5924 129.955Z" fill="#263238"/>
    <path d="M108.013 159.691L111.326 157.151L111.753 157.97L108.578 160.475L108.013 159.691Z" fill="#1A54B9"/>
    <path opacity="0.3" d="M108.013 159.691L111.326 157.151L111.753 157.97L108.578 160.475L108.013 159.691Z" fill="black"/>
    <path d="M85.3161 103.915C85.3161 103.915 85.1921 100.968 83.6346 100.869C82.0772 100.77 80.8521 102.179 80.8521 102.179C80.7012 101.991 80.5102 101.839 80.2931 101.734C80.076 101.629 79.8382 101.575 79.5972 101.574C78.7143 101.524 77.1767 103.027 77.1767 103.027C77.1767 103.027 79.0962 101.846 79.9047 102.387C79.9047 102.387 77.7273 102.992 77.1767 104.371C76.9405 105.072 76.5508 105.71 76.0359 106.241H77.1767L76.4277 107.516C76.4277 107.516 78.005 109.038 79.1954 108.785L78.8135 109.51C78.8135 109.51 81.1497 110.363 82.102 110.1C83.0543 109.837 82.1516 105.095 82.1516 105.095L85.3161 103.915Z" fill="#263238"/>
    <path opacity="0.2" d="M89.1505 119.565C87.2012 118.241 87.231 115.19 87.231 115.19L89.215 115.686C89.2398 116.639 89.2348 117.904 89.1505 119.565Z" fill="black"/>
    <path d="M71.1699 134.622L57.9217 132.395L54.4944 142.592L56.6867 146.575L71.1501 148.48L74.0814 137.369L71.1699 134.622Z" fill="#1A54B9"/>
    <path opacity="0.4" d="M54.4944 142.592L55.3475 144.145L55.5409 144.487L56.6867 146.575L71.1501 148.48L74.0814 137.369L72.8365 136.199L72.4545 135.837L71.1749 134.622L57.9217 132.395L54.4944 142.592Z" fill="black"/>
    <path opacity="0.2" d="M74.0811 137.369L60.9668 135.331L57.9214 132.395L71.1745 134.622L74.0811 137.369Z" fill="white"/>
    <path opacity="0.1" d="M60.9672 135.331L56.6867 146.575L54.4944 142.592L57.9217 132.395L60.9672 135.331Z" fill="white"/>
    <path d="M55.3474 144.146L55.5409 144.488L59.5039 133.958L72.8364 136.2L72.4544 135.838L59.2658 133.725L55.3474 144.146Z" fill="#263238"/>
    <path d="M68.3627 135.229L67.6931 134.609L64.355 134.003L64.9403 134.678L68.3627 135.229Z" fill="#263238"/>
    <path d="M69.6526 135.476C69.6526 135.476 69.8064 131.974 69.7121 131.741C69.6179 131.508 64.9208 130.749 64.5686 130.675C64.2165 130.6 62.6689 134.013 62.6689 134.013L62.9368 134.424L63.8246 134.543C63.8246 134.543 64.9505 131.602 65.0646 131.528C66.3516 131.665 67.63 131.873 68.8937 132.153C68.9929 132.232 68.6061 134.905 68.6061 134.905L68.9086 135.342L69.6526 135.476Z" fill="#263238"/>
    <path d="M55.3916 141.042L56.3836 142.317L56.4778 142.064L57.2268 140.08L57.3161 139.842L57.4748 139.415C57.4748 139.415 57.1921 139.732 56.8052 140.095C56.404 140.504 55.9228 140.826 55.3916 141.042Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M55.7146 141.135L56.4536 142.063L57.2026 140.079L57.2919 139.841C56.8808 140.396 56.339 140.84 55.7146 141.135Z" fill="white"/>
    <path opacity="0.2" d="M56.7808 140.088H57.2024L57.4504 139.424C57.4504 139.424 57.1676 139.726 56.7808 140.088Z" fill="black"/>
    <path d="M55.0302 138.547L54.8467 140.343C55.588 140.004 56.4133 139.894 57.2176 140.026L57.3118 139.778L57.8872 138.235L57.9516 138.076C56.9535 137.978 55.9469 138.14 55.0302 138.547Z" fill="#1A54B9"/>
    <path d="M56.915 135.5C57.2616 136.176 57.7174 136.789 58.2642 137.316L58.3336 137.127L58.5122 136.631L58.8098 135.813L58.9536 135.416L58.9933 135.302C58.7557 134.938 58.5499 134.555 58.3782 134.156L56.915 135.5Z" fill="#1A54B9"/>
    <path d="M59.9509 133.188C60.1318 133.472 60.2721 133.779 60.3676 134.101L60.5759 134.136L60.8636 134.18L61.3248 134.265L61.7663 134.339L61.92 134.364C61.9122 133.675 61.6549 133.013 61.1959 132.499L59.9509 133.188Z" fill="#1A54B9"/>
    <path opacity="0.2" d="M60.2283 133.238C60.3763 133.524 60.4927 133.825 60.5755 134.136L60.8632 134.181C60.8389 134.143 60.8232 134.1 60.8172 134.056C60.8112 134.011 60.815 133.966 60.8284 133.923C60.8413 133.884 60.8618 133.847 60.8888 133.816C60.9158 133.785 60.9486 133.76 60.9855 133.741C61.0224 133.723 61.0626 133.712 61.1037 133.709C61.1449 133.706 61.1862 133.711 61.2252 133.725C61.2642 133.737 61.3004 133.757 61.3315 133.783C61.3627 133.81 61.3883 133.842 61.4067 133.879C61.4252 133.915 61.4362 133.955 61.4391 133.996C61.442 134.037 61.4368 134.078 61.4236 134.116C61.4055 134.174 61.3711 134.226 61.3244 134.265L61.7659 134.34C61.7397 133.756 61.5374 133.194 61.1856 132.728L60.2283 133.238Z" fill="white"/>
    <path opacity="0.2" d="M55.1497 138.614L55.0356 140.068C55.7679 139.822 56.5415 139.722 57.3123 139.775L57.8876 138.233C56.9573 138.12 56.0137 138.251 55.1497 138.614ZM56.7419 138.495C56.8015 138.475 56.865 138.468 56.9276 138.476C56.9901 138.484 57.0501 138.506 57.1028 138.54C57.1555 138.575 57.1996 138.621 57.2316 138.675C57.2636 138.73 57.2827 138.791 57.2874 138.854C57.2922 138.916 57.2824 138.98 57.2588 139.038C57.2353 139.097 57.1986 139.149 57.1516 139.191C57.1047 139.233 57.0487 139.264 56.9879 139.281C56.9272 139.298 56.8634 139.301 56.8014 139.289C56.7418 139.31 56.6783 139.316 56.6157 139.309C56.5531 139.301 56.4932 139.279 56.4405 139.244C56.3877 139.21 56.3437 139.163 56.3117 139.109C56.2797 139.055 56.2606 138.994 56.2558 138.931C56.2511 138.868 56.2609 138.805 56.2845 138.746C56.308 138.688 56.3447 138.636 56.3917 138.594C56.4386 138.551 56.4946 138.521 56.5554 138.504C56.6161 138.487 56.6799 138.484 56.7419 138.495Z" fill="white"/>
    <path opacity="0.2" d="M57.1682 135.579C57.4943 136.139 57.8856 136.658 58.3338 137.126L58.5124 136.63C58.411 136.589 58.3294 136.511 58.285 136.411C58.2406 136.311 58.2367 136.197 58.2743 136.095C58.3055 135.993 58.3737 135.906 58.4655 135.852C58.5573 135.798 58.666 135.78 58.7703 135.802L58.9141 135.405C58.6621 135.126 58.4577 134.808 58.309 134.463L57.1682 135.579Z" fill="white"/>
    <path d="M101.55 103.598L102.314 102.324L104.001 103.921C103.802 104.178 103.552 104.39 103.267 104.545C102.982 104.699 102.667 104.793 102.344 104.819L101.55 103.598Z" fill="#E4897B"/>
    <path d="M103.495 100.617L104.69 102.299L104.001 103.921L102.314 102.323L103.495 100.617Z" fill="#E4897B"/>
    <path d="M88.3511 112.277C89.7237 111.996 91.0812 111.645 92.4183 111.226C93.7065 110.844 94.9427 110.304 96.0986 109.619C96.2041 109.553 96.3052 109.48 96.4012 109.401L96.7434 109.083C96.9815 108.855 97.2394 108.587 97.4626 108.349C97.9338 107.823 98.4199 107.283 98.8861 106.727C99.8434 105.626 100.776 104.48 101.738 103.364L102.993 104.198C102.304 105.542 101.54 106.821 100.731 108.096C100.32 108.726 99.898 109.361 99.4317 109.981C99.1986 110.288 98.9357 110.601 98.6877 110.913C98.5588 111.067 98.3951 111.226 98.2512 111.385C98.0738 111.56 97.8867 111.726 97.6908 111.881C96.3739 112.825 94.9248 113.571 93.3904 114.093C91.9234 114.624 90.4132 115.027 88.8768 115.298L88.3511 112.277Z" fill="#DC897C"/>
    <path d="M87.7119 116.613C89.8 118.423 94.388 115.318 94.388 115.318L92.5578 110.8C90.8742 110.996 89.2133 111.353 87.5978 111.866C84.6962 112.853 86.1147 115.234 87.7119 116.613Z" fill="#1A54B9"/>
    <path opacity="0.6" d="M87.7119 116.613C89.8 118.423 94.388 115.318 94.388 115.318L92.5578 110.8C90.8742 110.996 89.2133 111.353 87.5978 111.866C84.6962 112.853 86.1147 115.234 87.7119 116.613Z" fill="#FAFAFA"/>
  </g>
  <defs>
    <clipPath id="clip0_11067_11051">
      <rect width="248" height="248" fill="white" transform="translate(0.5 0.5)"/>
    </clipPath>
  </defs>
</svg>
		</SvgIcon>
	);
}
