import Box from "components/common/Box";
import Checkbox from "components/common/Checkbox";
import Text from "components/common/Text";
import React from "react";


const AddFilters = ({ selectedStatus, handleStatusChange }) => {
  const handleCheckboxChange = (status) => {
    handleStatusChange(status);
  };

  return (
    <>
      <Text sx={{ fontSize: "14px", fontWeight: 500, color: "#242424" }}>
        Status
      </Text>

      <Box sx={{ display: "flex", gap: "24px", mb: "22px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={selectedStatus === "Y"}
            onChange={() => handleCheckboxChange("Y")}
          />
          <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
            Active
          </Text>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox
            checked={selectedStatus === "N"}
            onChange={() => handleCheckboxChange("N")}
          />
          <Text sx={{ fontSize: "16px", fontWeight: 400, ml: "12px" }}>
            Inactive
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default AddFilters;