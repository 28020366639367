import React, { useEffect, useState } from "react";
import Box from "components/common/Box";
import Text from "components/common/Text";
import { modelPortFolioApiAction } from "stores/redux/apiSlices/modelPortfolio/modelPortfolio";
import CustomerSidebar from "components/riskProfile/CustomersSidebar";
import StockAllocationSidebar from "components/riskProfile/StockAllocationSidebar";
import AddNewStockModal from "components/riskProfile/AddNewStockModal";
import DeleteStockModal from "components/riskProfile/DeleteStockModal";
import ModelPortfolioCard from "components/riskProfile/ModelPortfolioCard";
import { dividendApiAction } from "stores/redux/dividend/dividendApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useDispatch } from "react-redux";
import NewDialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import CheckedDialogIcon from "asset/icons/CheckedDialogIcon";
import Button from "components/common/Button";
import useDebounce from "hooks/Debounce";

const RiskProfileContainer = () => {
  const [isAddNewStockModalOpen, setAddNewStockModalOpen] = useState({});
  const [isDeleteStockModalOpen, setDeleteStockModalOpen] = useState(false);
  const [isAllcustomerSideDrawerOpen, setAllcustomerSideDrawerOpen] =
    useState(false);
  const [isStockAllocationDrawerOpen, setStockAllocationDrawerOpen] =
    useState(false);
  const [newStockFormData, setNewStockFormData] = useState({
    newStockName: {},
    allocationPercent: "",
    profile: 0,
  });
  const dispatch = useDispatch();
  const [currentProductCategory, setCurrentProductCategory] = useState(null);
  const [currentRiskProfileId, setCurrentRiskProfileId] = useState(null);
  const [summaryRiskProfileID, setSummaryRiskProfileID] = useState(null);
  const [allocationPercentGraph, setAllocationPercentGraph] = useState(null);
  const [addModelPortfolioStockApi] = modelPortFolioApiAction.addStockApi();
  const [postCallToSendAPI] = modelPortFolioApiAction.postCallToSendAPI();
  const [UpdateModelPortfolioStockApi] =
    modelPortFolioApiAction.updateStockApi();

  const { data: modelPortFolioData = {}, refetch: getModelPortfolioDetails } =
    modelPortFolioApiAction.getModelPortFolioApi();

  const {
    data: errorModelPortFolioData = {},
    refetch: getErrorModelPortfolioDetails,
  } = modelPortFolioApiAction.getErrorModelPortFolioApi();

  console.log("errorModelPortFolioData", errorModelPortFolioData);

  const [congratsModalOpen, setCongratsModalOpen] = useState(false);
  // const { data: companyData = {} } = dividendApiAction.getCompanyApi();
  const [getAllStockName, { data: companyData }] =
    dividendApiAction.getCompanyApi();

  const data = companyData;
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  console.log(data, "companydata");

  const handleOpenTotalCustomerDrawer = (
    risk_profile_id,
    product_category_name
  ) => {
    console.log(risk_profile_id, "risk_profile_id");
    console.log(product_category_name, "product_category_name");

    setCurrentRiskProfileId(risk_profile_id);
    setCurrentProductCategory(product_category_name);

    setAllcustomerSideDrawerOpen(true);
  };

  const closeAllcustomerDrawer = () => {
    setAllcustomerSideDrawerOpen(false);
    setSelectedCustomer([]);
  };
  const modelPortFolio = modelPortFolioData;
  console.log(modelPortFolio, "modelPortFoliodataaaa");

  const [getCustomerList, { data: CustomerListData = {} }] =
    modelPortFolioApiAction.getCustomerListApi();

  const [formDataSearch, setFormDataSearch] = useState("");

  const debouncedSearchValue = useDebounce(formDataSearch, 500);

  useEffect(() => {
    let payload = {
      search: debouncedSearchValue,
      // exchange: "NSE",
    };
    getAllStockName(payload);
  }, [debouncedSearchValue]);

  useEffect(() => {
    getModelPortfolioDetails();
  }, []);

  useEffect(() => {
    getErrorModelPortfolioDetails({ noGlobalLoading: true });
  }, []);

  // useEffect(() => {
  //   getCustomerList();
  // }, [currentRiskProfileId]);

  useEffect(() => {
    if (isAllcustomerSideDrawerOpen) {
      getCustomerList(currentRiskProfileId);
    }
  }, [currentRiskProfileId, isAllcustomerSideDrawerOpen]);

  const CustomerList = CustomerListData;
  console.log(CustomerList, "CustomerList");

  const ErrorList = errorModelPortFolioData;

  const handleOpenAddNewStockModal = (profile, remainingAllocation) => {
    console.log("Risk Profile ID:", profile);
    console.log("remainingAllocation", remainingAllocation);

    setNewStockFormData({
      newStockName: {},
      allocationPercent: "",
      profile,
      remainingAllocation,
    });

    setAddNewStockModalOpen(true);
  };
  const onAddNewStockClick = (updatedProfile, remainingAllocation) => {
    console.log("Updated Profile with new stock:", updatedProfile);
  };
  const onUpdateStockPercent = async (updatedProfile, remainingAllocation) => {
    console.log("Updated Profile with new stock Percent:", updatedProfile);
    console.log("remainingAllocationupdate", remainingAllocation);

    // Call the API after updating the UI
    await handleUpdateStockApi(updatedProfile, remainingAllocation);
  };

  const handleSaveNewStock = async () => {
    const { profile, newStockName, allocationPercent, remainingAllocation } =
      newStockFormData;

    // Destructuring existing values from the profile object
    const { risk_profile_id, model_portfolio_id, model_portfolio_details } =
      profile;

    // Creating an array of existing stocks from the model_portfolio_details
    const stocks = model_portfolio_details.map((detail) => ({
      model_portfolio_detail_id: detail.model_portfolio_detail_id,
      symbol: detail.symbol,
      exchange: detail.exchange,
      allocation_percent: parseFloat(detail.allocation_percent),
      risk_profile_id: risk_profile_id,
    }));

    // Converting allocationPercent to numbers
    const allocationPercentNumber = parseFloat(allocationPercent);
    const remainingAllocationNumber = parseFloat(remainingAllocation);

    // Calculate the updated remainingAllocation after deducting the new allocation
    const updatedRemainingAllocation = isNaN(allocationPercentNumber)
      ? remainingAllocationNumber
      : remainingAllocationNumber - allocationPercentNumber;

    // Creating payload for the API call
    const payload = {
      risk_profile_id: risk_profile_id,
      cash_percent: isNaN(allocationPercentNumber)
        ? remainingAllocationNumber.toFixed(2)
        : updatedRemainingAllocation.toFixed(2), // Pass remainingAllocation as cash_percent if no stock is added
      model_portfolio_id: model_portfolio_id,
      stocks: stocks,
      addStock: [
        {
          symbol: newStockName?.symbol || "",
          exchange: newStockName?.exchange || "",
          allocation_percent: allocationPercentNumber.toFixed(2),
        },
      ],
    };

    console.log("New Stock Payload:", payload);

    try {
      // Making the API call
      const response = await UpdateModelPortfolioStockApi(payload);

      if (response) {
        const toastMessage =
          response.data.status === 1
            ? "Stock updated successfully"
            : response.message;

        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: response.data.status === 1 ? "success" : "error",
          })
        );

        console.log("Response Message:", response.message);
        console.log("API Response:", response);

        if (response.data.status === 1) {
          // Fetch the model portfolio details only once
          getModelPortfolioDetails();

          // Clear the new stock data after successfully adding it
          // setNewStockFormData({
          //   newStockName: {},
          //   allocationPercent: "",
          //   profile,
          //   remainingAllocation: updatedRemainingAllocation.toFixed(2),
          // });

          // Adding the new stock to the existing model_portfolio_details
          const updatedProfile = {
            ...profile,
            model_portfolio_details: [
              ...model_portfolio_details,
              {
                symbol: newStockName?.symbol || "",
                exchange: newStockName?.exchange || "",
                allocation_percent: allocationPercentNumber,
              },
            ],
          };

          // Callback function to handle new stock addition
          onAddNewStockClick(updatedProfile);

          // Closing the modal
          handleCloseAddNewStockModal();
        }
      }
    } catch (error) {
      console.error("Error in API call:", error);
      console.log("Complete error object:", error);

      // Handle error and set new stock data accordingly
      setNewStockFormData({
        newStockName: {},
        allocationPercent: "",
        profile,
        remainingAllocation: updatedRemainingAllocation.toFixed(2),
      });
    }
  };

  const handleUpdateStockApi = async (updatedProfile, remainingAllocation) => {
    try {
      const { newStockName, allocationPercent } = newStockFormData;
      console.log(newStockFormData, "updatenewStockFormData");

      // Destructuring existing values from the profile object
      const {
        risk_profile_id,
        cash_percent,
        model_portfolio_id,
        model_portfolio_details,
      } = updatedProfile;

      // Check if model_portfolio_details is defined
      if (model_portfolio_details) {
        // Creating an array of existing stocks from the model_portfolio_details
        const stocks = model_portfolio_details.map((detail) => ({
          model_portfolio_detail_id: detail.model_portfolio_detail_id,
          symbol: detail.symbol,
          exchange: detail.exchange,
          allocation_percent: detail.allocation_percent
            ? parseFloat(detail.allocation_percent)
            : 0,
        }));
        console.log("stocks : ", stocks);
        const allocationPercentNumber = parseFloat(allocationPercent);

        // Creating payload for the API call
        const payload = {
          risk_profile_id: risk_profile_id,
          cash_percent: remainingAllocation.toFixed(2),
          model_portfolio_id: model_portfolio_id,
          stocks: stocks,
          addStock: [
            {
              symbol: newStockName?.symbol || "",
              exchange: newStockName?.exchange || "",
              allocation_percent: allocationPercentNumber.toFixed(2),
            },
          ],
        };

        console.log("New Stock Payload:", payload);

        // Making the API call
        const response = await UpdateModelPortfolioStockApi(payload);

        if (response) {
          const toastMessage =
            response.data.status === 1
              ? "Stock updated successfully"
              : response.message;

          dispatch(
            toastActions.setToastData({
              message: toastMessage,
              variant: response.data.status === 1 ? "success" : "error",
            })
          );

          console.log("Response Message:", response.message);
          console.log("API Response:", response);

          if (response.data.status === 1) {
            getModelPortfolioDetails();
          }

          // Close the modal or perform any additional actions
          handleCloseAddNewStockModal();
        }
      } else {
        console.error("model_portfolio_details is undefined.");
      }
    } catch (error) {
      console.error("Error in API call:", error);
      console.log("Complete error object:", error);
    }
  };
  const handleCloseAddNewStockModal = () => {
    setAddNewStockModalOpen(false);

    // Reset newStockFormData to its initial empty state
    setNewStockFormData({
      newStockName: {},
      allocationPercent: "",
      profile: 0,
    });
  };

  const handleOPenStockAllocationSidebar = (profile) => {
    console.log(profile.model_portfolio_details, "profile");
    console.log(profile.risk_profile_id, "risk_Profile_ID");
    setSummaryRiskProfileID(profile?.risk_profile_id);

    setAllocationPercentGraph(profile.model_portfolio_details);
    setStockAllocationDrawerOpen(true);
  };
  const closeStockAllocationDrawer = () => {
    setStockAllocationDrawerOpen(false);
  };

  const handleSelectedCustomerIds = async (selectedIds) => {
    setSelectedCustomer(selectedIds);
  };

  const handleCustomerSubmit = async () => {
    try {
      const payload = {
        modelPortfolioId: currentRiskProfileId ?? "",
        customers: selectedCustomer ?? "",
      };

      let result = await postCallToSendAPI(payload).unwrap();
      // console.log("payload checkkkkkkkkkkkkkkkkkkkkkkkkkkkkk", payload);
      setCongratsModalOpen(false);
      setSelectedCustomer([]);
      getCustomerList(currentRiskProfileId);
      // setAllcustomerSideDrawerOpen(false);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleConfirm = () => {
    if (selectedCustomer.length > 0) {
      setCongratsModalOpen(true);
    }
  };

  const handleRemove = () => {
    // setAllcustomerSideDrawerOpen(false);
    setSelectedCustomer([]);
    setCongratsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          p: "16px 24px",
          borderRadius: "8px",
          border: "1px solid #ECECEC",
          backgroundColor: "#FFF",
          mt: "25px",
          mb: "20px",
        }}
      >
        <Text
          sx={{
            color: "#101828",
            fontSize: "18px",
            fontWeight: 500,
            mb: "4px",
          }}
        >
          Asset allocation of model portfolio
        </Text>

        <Text sx={{ fontSize: "14px", fontWeight: 400, color: "#667085" }}>
          Here you can edit the model portfolio based on the market conditions
          and your expertise.
        </Text>
      </Box>
      <ModelPortfolioCard
        result={modelPortFolio}
        onAddNewStockClick={(profile, remainingAllocation) =>
          handleOpenAddNewStockModal(profile, remainingAllocation)
        }
        onUpdateStockPercent={(profile, remainingAllocation) =>
          onUpdateStockPercent(profile, remainingAllocation)
        }
        // StockAllocationSidebar={handleOPenStockAllocationSidebar}
        StockAllocationSidebar={(profile) =>
          handleOPenStockAllocationSidebar(profile)
        }
        openTotalCustomerDrawer={(profile) =>
          handleOpenTotalCustomerDrawer(
            profile.risk_profile_id,
            profile.product_category_name,
            setAllcustomerSideDrawerOpen(true)
          )
        }
      />
      <CustomerSidebar
        handleSubmit={handleConfirm}
        handleSelectedCustomerIds={handleSelectedCustomerIds}
        customers={CustomerList}
        error={ErrorList}
        getErrorModelPortfolioDetails={getErrorModelPortfolioDetails}
        open={isAllcustomerSideDrawerOpen}
        closeDrawer={closeAllcustomerDrawer}
        productCategoryName={currentProductCategory}
      />
      <StockAllocationSidebar
        open={isStockAllocationDrawerOpen}
        risk_profile_id={summaryRiskProfileID}
        closeDrawer={closeStockAllocationDrawer}
        netLiquidValue="20000000"
        allocationPercentGraphData={allocationPercentGraph}
      />

      <AddNewStockModal
        onClose={handleCloseAddNewStockModal}
        onSave={handleSaveNewStock}
        open={isAddNewStockModalOpen}
        formData={newStockFormData}
        profile={newStockFormData.profile}
        setFormData={setNewStockFormData}
        formDataSearch={formDataSearch}
        setFormDataSearch={setFormDataSearch}
        companyData={companyData}
        remainingAllocation={newStockFormData.remainingAllocation}
      />
      <DeleteStockModal
        // onClose={handlestockDeleteModalClose}
        // onConfirm={handleDeleteStockModal}
        open={isDeleteStockModalOpen}
      />

      <NewDialog
        onClose={() => setCongratsModalOpen(false)}
        open={congratsModalOpen}
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack direction="column" sx={{ width: "100%" }}>
            <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
              {/* <CheckCircleOutlinedIcon /> */}
              <CheckedDialogIcon sx={{ width: "58px", height: "58px" }} />
            </Stack>
            <Text
              width="500px"
              fontSize="20px"
              fontWeight="600"
              alignItems="center"
              justifyContent="space-between"
              textAlign="center"
            >
              Do you wish to execute the trades for the selected customers ?
            </Text>
            <Text
              width="500px"
              fontSize="14px"
              fontWeight="400"
              color="#667085"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              textAlign="center"
            >
              By clicking on the yes button you are making sure that you want to
              execute the trade.
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="15px"
              spacing={1}
            >
              <Button
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                }}
                variant="outlined"
                onClick={handleRemove}
              >
                No
              </Button>
              <Button
                sx={{
                  width: "100%",
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                }}
                onClick={handleCustomerSubmit}
              >
                Yes, execute
              </Button>
            </Stack>
          </Stack>
        )}
      />
    </>
  );
};

export default RiskProfileContainer;
