import React, { useState, useEffect } from "react";
import Tab from "components/common/Tab";
import CustomerPortFolioSection from "./CustomerPortFolioSection";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { useSelector } from "react-redux";
import Tooltip from "components/common/Tooltip";

const MODEL_PORTFOLIO = "MODEL_PORTFOLIO";
const ALGO = "ALGO";
const CUSTOM = "CUSTOM";

const tradeTabs = [
  { label: "Model portfolio", value: MODEL_PORTFOLIO },
  { label: "Algo (F&O)", value: ALGO },
  { label: "Custom", value: CUSTOM },
];

const CustomerPerformace = () => {
  const userDetails = useSelector((state) => state.commonReducer.userDetails);
  const [tradeType, setTradeType] = useState(tradeTabs[0].value);

  const modelProductID = 2;
  const algoProductID = 3;
  const customProductID = 1;

  const payload = {
    user_id: userDetails.user_id,
  };

  const { data: productData = {}, refetch: refetchProductData } =
    addCustomerApiAction.getCustomerDetails(payload);

  useEffect(() => {
    refetchProductData(payload);
  }, []);

  const productID = productData?.investmentDetails?.[0]?.product_id;
  const isActive = productData?.investmentDetails?.[0]?.is_active;
  const productID2 = productData?.investmentDetails?.[1]?.product_id;
  const isActive2 = productData?.investmentDetails?.[1]?.is_active;
  const productID3 = productData?.investmentDetails?.[2]?.product_id;
  const isActive3 = productData?.investmentDetails?.[2]?.is_active;

  const isModelDisabled = !productID2 || isActive2 !== "Y";
  const isAlgoDisabled = !productID3 || isActive3 !== "Y";
  const isCustomDisabled = !productID || isActive !== "Y";

  // Ensure initial state is not a disabled tab
  useEffect(() => {
    if (
      (tradeType === MODEL_PORTFOLIO && isModelDisabled) ||
      (tradeType === ALGO && isAlgoDisabled) ||
      (tradeType === CUSTOM && isCustomDisabled)
    ) {
      const initialTab = tradeTabs.find((tab) => {
        if (tab.value === MODEL_PORTFOLIO && !isModelDisabled) return true;
        if (tab.value === ALGO && !isAlgoDisabled) return true;
        if (tab.value === CUSTOM && !isCustomDisabled) return true;
        return false;
      });
      setTradeType(initialTab ? initialTab.value : tradeTabs[0].value);
    }
  }, [isModelDisabled, isAlgoDisabled, isCustomDisabled, tradeType]);

  const handleTabChange = (e, newValue) => {
    const selectedTab = tradeTabs.find((tab) => tab.value === newValue);
    if (!selectedTab.disabled) {
      setTradeType(newValue);
    }
  };

  return (
    <>
      <Tab
        tabs={tradeTabs.map((tab) => {
          const isDisabled =
            (tab.value === MODEL_PORTFOLIO && isModelDisabled) ||
            (tab.value === ALGO && isAlgoDisabled) ||
            (tab.value === CUSTOM && isCustomDisabled);

          const tabLabel = (
            <span style={{ opacity: isDisabled ? 0.3 : 1 }}>{tab.label}</span>
          );

          return {
            ...tab,
            label: isDisabled ? (
              <div>
              <Tooltip title="Broker Details not found" placement="top">
                {tabLabel}
              </Tooltip>
              </div>
            ) : (
              tabLabel
            ),
            disabled: isDisabled,
          };
        })}
        onChange={handleTabChange}
        value={tradeType}
        sx={{ maxWidth: "calc(100% + 48px)", margin: "0 -24px" }}
        tabSx={{ p: "18px 48px" }}
      />
      {tradeType === MODEL_PORTFOLIO &&
        productID2 === 2 &&
        isActive2 === "Y" && (
          <CustomerPortFolioSection
            productId={modelProductID}
            tradeType={tradeType}
          />
        )}
      {tradeType === ALGO && productID3 === 3 && isActive3 === "Y" && (
        <CustomerPortFolioSection
          productId={algoProductID}
          tradeType={tradeType}
        />
      )}
      {tradeType === CUSTOM && productID === 1 && isActive === "Y" && (
        <CustomerPortFolioSection
          productId={customProductID}
          tradeType={tradeType}
        />
      )}
    </>
  );
};

export default CustomerPerformace;
