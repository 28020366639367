import React from 'react';
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { appConstants } from './constants/appConstants';


// Private Route
const PrivateRoute = ({ redirectTo, isAuth, ...props }) => {
    if(isAuth) {
        return <Outlet />
    }
    return <Navigate to={redirectTo} />;
};

// PublicRoute
const PublicRoute = ({ redirectTo, isAuth, }) => {
    if(isAuth) {
        return <Navigate to={redirectTo} />;
    }
    return <Outlet />

};

// Admin Route
const AdminRoute = ({userRoleCode, redirectTo}) =>{
    if(userRoleCode !== appConstants.userRoles.ADMIN.code){
        return <Navigate to={redirectTo}/>
    }
    return <Outlet />
}

//RIA Route
const RIARoute = ({userRoleCode, redirectTo}) =>{
    if(userRoleCode !== appConstants.userRoles.RIA.code){
        return <Navigate to={redirectTo}/>
    }
    return <Outlet />
}

//Subbroker Route
const SubbrokerRoute = ({userRoleCode, redirectTo}) =>{
    console.log(userRoleCode, appConstants.userRoles.SUBBROKER.code,"SUBBROKER.code============")
    if(userRoleCode !== appConstants.userRoles.SUBBROKER.code){
        return <Navigate to={redirectTo}/>
    }
    return <Outlet />
}

//Customer Route
const CustomerRoute = ({userRoleCode, redirectTo}) =>{
    if(userRoleCode !== appConstants.userRoles.CUSTOMER.code){
        return <Navigate to={redirectTo}/>
    }
    return <Outlet />
}

export { PrivateRoute, PublicRoute, AdminRoute, RIARoute, CustomerRoute,SubbrokerRoute };
