import SignUpContainer from 'containers/SignUpContainer'
import React from 'react'

function SignUpPage() {
  return (
    <>
    <SignUpContainer/>

    </>
  )
}

export default SignUpPage