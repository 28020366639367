import { useEffect, useState } from "react";
import { useDispatch} from "react-redux";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Divider from "components/common/Divider";
import TextField from "components/common/TextField";
import DatePicker from "components/common/DatePicker";
import { uuid } from "helpers/utility";
import dayjs from "dayjs";
import styled from "@emotion/styled";
import { adminProfileApiAction } from "stores/redux/apiSlices/admin/adminProfileApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";

const HeaderBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "16px 24px",
}));

const CancelButton = styled(Button)(() => ({
  border: "1px solid #142E56",
  borderRadius: "4px",
  color: "#142E56",
  textTransform: "capitalize",
  width: "116px",
  padding: "8px 16px",
  marginRight: "24px",
}));

const SaveButton = styled(Button)(() => ({
  border: "1px solid #142E56",
  borderRadius: "4px",
  color: "#FFF",
  textTransform: "capitalize",
  width: "116px",
  padding: "8px 16px",
}));
const EditButton = styled(Button)(() => ({
  border: "1px solid #828282",
  borderRadius: "4px",
  color: "#101828",
  textTransform: "capitalize",
}));
const AvatarBox = styled(Box)(() => ({
  width: "236px",
  height: "236px",
  borderRadius: "9px",
  backgroundColor: "#E5EEFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const LabelText = styled(Text)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
  width: "250px",
  textTransform: "uppercase",
}));
const EditTextField = styled(TextField)(() => ({
  display: "flex",
  width: "300px",
  p: "4px 16px",
  alignItems: "center",
  borderRadius: "1px solid #CBCBCB",
}));

const ValueText = styled(Text)(() => ({
  fontSize: "16px",
  color: "#101828",
  fontWeight: 500,
  flexShrink: 0,
}));

const ProfileAdminContainer = () => {
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [userDocuments, setUserDocuments] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const [newDocForm, setNewDocForm] = useState({
    display_name: null,
    file: null,
  });
  const [isDocFormOpen, setIsDocFormOpen] = useState(false);
  const dispatch = useDispatch();

  const { data: profileData, refetch: refetchProfileData } = adminProfileApiAction.getProfileApi(); // Admin Profile get api
  const [handleUpdateProfileApi] = adminProfileApiAction.updateProfileApi(); // Admin profile Post api

  useEffect(() => {
    if (profileData) {
      const userDocs = getUserDoc(profileData.documents);
      setUserDocuments(userDocs);
      const details = getUserDetails(profileData);
      setUserDetails(details);
    }
  }, [profileData]);

  useEffect(() => {
    refetchProfileData();
  }, []);
  if (!profileData) {
    return <div>Loading...</div>;
  }

  let abc = profileData.message.result.map((item) => item.name)
  
  console.log(abc,"ABC")

  const getUserDetails = (profileData) => {
    const result = profileData.message.result;
    return [
      { label: "Name", key: "name", value: result.map((item) => item.name) },
      { label: "Email", key: "email", value: result.map((item) => item.email) },
      { label: "Phone", key: "phone", value: result.map((item) => item.mobile), },
      { label: "Joining Date", key: "joiningDate",value: dayjs(result.map((item) => item.joining_date)).format("DD-MM-YYYY"),},
    ];
  };

  const getUserDoc = (docs) => {
    if (!docs) return [];
    return docs.map((item) => {
      return {
        document_id: item.document_id,
        userDocId: item.userDocId,
        display_name: item.display_name,
        fileName: item.fileName,
        isEdit: false,
        id: uuid(),
      };
    });
  };
  const handleEditClick = (rowData) => {
    setEditMode(true);
    const initialEditedValues = {};
    userDetails.forEach((detail) => {
      if (detail.key === "baslExpiry") {
        initialEditedValues[detail.key] = dayjs(detail.value) ?? null;
      } else {
        initialEditedValues[detail.key] = detail.value;
      }
    });
    setEditedValues(initialEditedValues);
  };
  const handleCancelClick = () => {
    setEditMode(false);
    setUpdatedValues({});
  };
  const handleSaveClick = () => {
    const { name, email, phone: rawPhone, joining_date } = editedValues;
    const formattedName = Array.isArray(name) ? name.join(" ") : name;
    const formattedEmail = Array.isArray(email) ? email.join(" ") : email;
    const formattedPhone = Array.isArray(rawPhone)
      ? parseInt(rawPhone.join(" "), 10)
      : parseInt(rawPhone, 10);
    const formattedJoiningDate = joining_date || "2024-04-01";

    const payload = {
      name: formattedName,
      email: formattedEmail,
      mobile: formattedPhone,
      joining_date: formattedJoiningDate,
    };
    handleUpdateProfileApi(payload)
      .unwrap()
      .then((response) => {
        console.log(response, "RESPOCE UPDATE");
        if (response) {
          const toastMessage =
            response.message.statusCode == 200
              ? response.message.message
              : "Admin Profile updated successfully";

          dispatch(
            toastActions.setToastData({
              message: "Admin Profile updated successfully",
            })
          );
        }

        if (response?.message.statusCode == 200) {
          refetchProfileData();
          console.log("refetch method is called");
          setIsDocFormOpen(false);
          setNewDocForm({});
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);
        dispatch(
          toastActions.setToastData({
            message: "Error updating profile. Please try again later.",
            variant: "error",
          })
        );
      });

    setEditMode(false);
  };

  const handleInputChange = (key, value) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
    setUpdatedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  // profileData.message.result
  const avatarText = abc.toString()
    .split(" ")
    .map((word) => word[0])
    .join("");

  return (
    <>
      <Box>
        <Card sx={{ marginTop: "25px" }}>
          <HeaderBox>
            <Box>
              <Text
                sx={{ color: "#101828", fontSize: "20px", fontWeight: 500 }}
              >
                {profileData?.name}
              </Text>
            </Box>

            <Box>
              {editMode ? (
                <>
                  <CancelButton variant="outlined" onClick={handleCancelClick}>
                    Cancel
                  </CancelButton>
                  <SaveButton variant="contained" onClick={handleSaveClick}>
                    Save
                  </SaveButton>
                </>
              ) : (
                <EditButton
                  variant="outlined"
                  onClick={() => {
                    handleEditClick();
                  }}
                >
                  Edit Details
                </EditButton>
              )}
            </Box>
          </HeaderBox>

          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "32px 40px",
            }}
          >
            <AvatarBox>
              <Text
                sx={{ fontSize: "64px", fontWeight: 600, color: "#104960" }}
              >
                {avatarText}
              </Text>
            </AvatarBox>

            <Box sx={{ marginLeft: 8 }}>
              {userDetails?.map((detail, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <LabelText>{detail.label}:</LabelText>
                  {editMode &&
                  detail.key !== "joiningDate" &&
                  detail.key !== "userType" ? (
                    detail.key === "baslExpiry" ? (
                      <DatePicker
                        onChange={(date) => handleInputChange(detail.key, date)}
                        value={editedValues[detail.key]}
                      />
                    ) : (
                      <EditTextField
                        placeholder={detail.label}
                        size="small"
                        defaultValue={
                          detail.key === "baslExpiry"
                            ? dayjs(detail.value).format("DD-MM-YYYY")
                            : editedValues[detail.key]
                        }
                        onChange={(e) =>
                          handleInputChange(detail.key, e.target.value)
                        }
                      />
                    )
                  ) : (
                    <ValueText>
                      {detail.key === "baslExpiry"
                        ? dayjs(detail.value).format("DD-MM-YYYY")
                        : detail.value}
                    </ValueText>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default ProfileAdminContainer;
