import {
  Chip,
  FormControl,
  FormControlLabel,
  Select,
  ListSubheader,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import Box from "./Box";
import TextField from "./TextField";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "./MenuItem";
import Checkbox from "./Checkbox";
import ErrorIcon from "@mui/icons-material/Error"; // Import ErrorIcon
import { uuid } from "helpers/utility";

const MultiSelect = (props) => {
  const {
    value,
    onChange,
    onClose,
    onSearchChange,
    options,
    idKeyName = "id",
    disabledKeyName = "disabled",
    listLoading = false,
  } = props;
  console.log({ props });

  return (
    <FormControl fullWidth>
      <Select
        multiple
        MenuProps={{
          autoFocus: false,
          PaperProps: { style: { maxHeight: 250 } },
        }}
        value={value}
        onChange={onChange}
        onClose={onClose}
        renderValue={(selected = []) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              overflow: "hidden",
            }}
          >
            {selected.slice(0, 3).map((value) => (
              <Chip key={uuid()} label={value.name} />
            ))}
            {selected.length > 3 ? (
              <Chip label={`More +${selected.length - 3}`} />
            ) : null}
          </Box>
        )}
      >
        <ListSubheader>
          <TextField
            autoFocus
            placeholder="Search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={onSearchChange}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {listLoading && (!options || !options.length) ? (
          <MenuItem disabled={true}>
            <Box>Loading...</Box>
          </MenuItem>
        ) : null}

        {options?.map((option, i) => {
          const hasChecked = value.some(
            (item) => item[idKeyName] === option[idKeyName]
          );
          const disabledCheck = option[disabledKeyName] || false;

          return (
            <MenuItem
              key={i}
              value={option}
              disabled={false} // Ensure the MenuItem is never disabled
              sx={{
                color: disabledCheck ? "grey" : "inherit", // Change the font color if disabledCheck is true
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasChecked}
                      sx={{ marginRight: "1px" }}
                      disabled={false} // Ensure the Checkbox is always enabled
                    />
                  }
                  label={option.name}
                  sx={{
                    color: disabledCheck ? "#a6a6a6" : "inherit", // Change the font color if disabledCheck is true
                  }}
                />
                {disabledCheck && (
                  <Tooltip title="Broker Details not found" placement="top">
                    <ErrorIcon
                      sx={{ marginRight: "10px", cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
