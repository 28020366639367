import { createApi } from "@reduxjs/toolkit/query/react";
import Customer from "pages/Customer";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";
import customerPortalParser from "../apiParser/customerPortalParser";

const CustomerDashboard = createApi ({
    reducerPath: "CustomerDashboard",
    baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
    tagTypes: [
        "GETINVESMENTTYPEAPI",
        "GETFAMILYMEMBERAPI",
        "ADDDASHBOARD",
        "ADDFILTERDASHBOARD",
        "EXECUTECUSTOMERTRADE",
        "DELETECUSTOMERTRADE"
    ],
    endpoints : (qb) => ({
        getInvesmentTypeApi: qb.query({
            query: () => ({
              url: `api/customer/products`, // Adjust the endpoint as needed
              params: { noGlobalLoading: true }
            }),
            transformResponse: (response) => response.result,
            providesTags: ["GETINVESMENTTYPEAPI"],
          }),
          getFamilyMemberApi: qb.query({
            query: ({user_id}) => {
              return `api/customer/get-family-members/${user_id}`; // Adjust the endpoint as needed
            },
            transformResponse: (response) => response.result,
            providesTags: ["GETFAMILYMEMBERAPI"],
          }),

          addDashBoardDatepickerApi: qb.mutation({
            query: ({member_ids,product_id,date_range_from,date_range_to}) => ({
              url: `api/customer/portal/dashboard`,
              method: "POST",
              body: {
                member_ids: member_ids,
                product_id: product_id,
                date_range_from: date_range_from,
                date_range_to: date_range_to,
                noGlobalLoading: true
              },
              invalidatesTags: ["ADDDASHBOARD"],
            }),
          }),

          addFilterDashBoardApi: qb.mutation({
            query: ({ member_ids, product_id, date_range_from, date_range_to, order_type, action, status, page, take }) => ({
              url: `api/customer/portal/customer-trades?page=${page}&take=${take}`,
              // ?page=${page}&take=${take}
              method: "POST",
              body: {
                member_ids: member_ids,
                product_id: product_id,
                date_range_from: date_range_from,
                date_range_to: date_range_to,
                order_type: order_type,
                action: action,
                status: status,
                noGlobalLoading: true
              },
              invalidatesTags: ["ADDFILTERDASHBOARD"],
            }),
            transformResponse: (response) => response,

            // transformResponse: (res) => {
            //   return customerPortalParser.executeCustomerTrades(res);
            // }
          }),

          executeCustomerTradeApi: qb.mutation({
            query: ({product_id,trade_id,transaction_id}) => ({
              url: `api/customer/portal/execute-customer-trades/${product_id}/${trade_id}/${transaction_id}`,
              method: "POST",
              body: {
                product_id: product_id,
                trade_id: trade_id,
                transaction_id: transaction_id
              },
              invalidatesTags: ["EXECUTECUSTOMERTRADE"],
            }),
          }),

          deleteCustomerTradeApi: qb.mutation({
            query: ({product_id,transaction_id}) => ({
              url: `api/customer/portal/delete-trade-call/${product_id}/${transaction_id}`,
              method: "DELETE",
              body: {
                product_id: product_id,
                transaction_id: transaction_id
              },
              invalidatesTags: ["DELETECUSTOMERTRADE"],
            }),
          }),
    })
})

 export const CustomerDashboardApiReducer = CustomerDashboard.reducer;

export const CustomerDashboardApiAction = {
    middleware: CustomerDashboard.middleware,
  reducerPath: CustomerDashboard.reducerPath,
  getInvesmentTypeApi : CustomerDashboard.useGetInvesmentTypeApiQuery,
  getFamilyMemberApi : CustomerDashboard.useLazyGetFamilyMemberApiQuery,
  addDashBoardDatepickerApi : CustomerDashboard.useAddDashBoardDatepickerApiMutation,
  addFilterDashBoardApi : CustomerDashboard.useAddFilterDashBoardApiMutation,
  executeCustomerTradeApi : CustomerDashboard.useExecuteCustomerTradeApiMutation,
  deleteCustomerTradeApi : CustomerDashboard.useDeleteCustomerTradeApiMutation
}