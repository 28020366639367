import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
} from "@mui/material";
import StockEditIcon from "asset/icons/StockEditIcon";
import StockCheckIcon from "asset/StockCheckIcon";

import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";
import Link from "components/common/Link";
import { useDispatch } from "react-redux";
import { toastActions } from "stores/redux/slices/toastSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ModelPortfolioCard = ({
  result,
  onAddNewStockClick,
  onUpdateStockPercent,
  StockAllocationSidebar,
  openTotalCustomerDrawer,
}) => {
  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState({});
  const [allocationPercentValues, setAllocationPercentValues] = useState({});
  const [editCashPercent, setEditCashPercent] = useState(false);
  const [cashPercentValue, setCashPercentValue] = useState({});
  const [remainingAllocation, setRemainingAllocation] = useState({});
  const [editMode, setEditMode] = useState(false);

  console.log({ allocationPercentValues });
  console.log("editStatus-->", editStatus);
  // Function to validate numeric input
  const validateNumericInput = (value) => {
    const numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

    if (numericRegex.test(value)) {
      return value;
    }
    return "";
  };
  const allowOnlyNumbers = (event) => {
    const sanitizedValue = event.target.value.replace(/[^0-9]/g, "");
    event.target.value = sanitizedValue;
  };
  // Allocation Percent Logic
  const handleEditClick = (profileIndex, stockIndex) => {
    setEditStatus((prevEditStatus) => {
      const newEditStatus = {
        ...prevEditStatus,
        [profileIndex]: {
          ...prevEditStatus[profileIndex],
          [stockIndex]: true,
        },
      };
      return newEditStatus;
    });
    setEditMode(true); // Set edit mode to true when entering edit mode
  };

  const handleCancelClick = (profileIndex, stockIndex) => {
    setEditStatus((prevEditStatus) => ({
      ...prevEditStatus,
      [profileIndex]: {
        ...prevEditStatus[profileIndex],
        [stockIndex]: false,
      },
    }));

    // Restore the previous value of the TextField
    setAllocationPercentValues((prevValues) => {
      const prevValue =
        result[profileIndex].model_portfolio_details[stockIndex]
          .allocation_percent;
      return {
        ...prevValues,
        [profileIndex]: {
          ...prevValues[profileIndex],
          [stockIndex]: prevValue,
        },
      };
    });

    setEditMode(false); // Set edit mode to false when exiting edit mode
  };

  const handleAllocationPercentChange = (profileIndex, stockIndex, value) => {
    console.log({ profileIndex, stockIndex, value });
    const numericValue = validateNumericInput(value);

    if (numericValue > 100) {
      console.log("Value greater than 100, pausing update.");
      return;
    }

    setAllocationPercentValues((prevValues) => {
      const newValues = {
        ...prevValues,
        [profileIndex]: {
          ...prevValues[profileIndex],
          [stockIndex]: numericValue, // Update with numericValue directly
        },
      };
      return newValues;
    });
  };

  useEffect(() => {
    console.log("Updated Allocation Percent Values", allocationPercentValues);
  }, [allocationPercentValues]);

  const handleAddNewStockClick = (profileIndex, remainingAllocation) => {
    const updatedProfile = {
      ...result[profileIndex],
      cash_percent: editCashPercent
        ? cashPercentValue[profileIndex]
        : result[profileIndex].cash_percent,
      model_portfolio_details: result[profileIndex].model_portfolio_details.map(
        (stock, stockIndex) => {
          const allocationPercent =
            allocationPercentValues[profileIndex]?.[stockIndex];

          return {
            ...stock,
            allocation_percent:
              allocationPercent !== undefined
                ? allocationPercent
                : stock.allocation_percent,
          };
        }
      ),
    };

    // Calculate total allocation including the new stock
    const totalAllocationPercent =
      calculateTotalAllocationPercent(profileIndex);
    const newStockAllocationPercent =
      allocationPercentValues[profileIndex]?.[
        result[profileIndex].model_portfolio_details.length
      ] || 0;

    // Update values first
    onAddNewStockClick(updatedProfile, remainingAllocation);

    const remainingPercent = 100 - totalAllocationPercent;

    // Check if the sum exceeds 100%
    if (
      totalAllocationPercent + newStockAllocationPercent + remainingPercent >
      100
    ) {
      dispatch(
        toastActions.setToastData({
          message: "Error: Total allocation percent cannot exceed 100%",
          variant: "error",
        })
      );
    } else {
      // Set editCashPercent to false when adding a new stock
      setEditCashPercent((prevEditCashPercent) => ({
        ...prevEditCashPercent,
        [profileIndex]: false,
      }));

      setEditStatus((prevEditStatus) => ({
        ...prevEditStatus,
        [profileIndex]: false,
      }));
    }
  };

  const handleUpdateStockApi = async (profileIndex, remainingAllocation) => {
    const updatedProfile = {
      ...result[profileIndex],
      cash_percent: editCashPercent
        ? cashPercentValue[profileIndex]
        : result[profileIndex].cash_percent,
      model_portfolio_details: result[profileIndex].model_portfolio_details.map(
        (stock, stockIndex) => {
          const allocationPercent =
            allocationPercentValues[profileIndex]?.[stockIndex];

          return {
            ...stock,
            allocation_percent:
              allocationPercent !== undefined
                ? allocationPercent
                : stock.allocation_percent,
          };
        }
      ),
    };

    await onUpdateStockPercent(updatedProfile, remainingAllocation);
    setAllocationPercentValues((prevValues) => {
      const newValues = { ...prevValues };
      delete newValues[profileIndex];
      return newValues;
    });

    setEditStatus((prevEditStatus) => ({
      ...prevEditStatus,
      [profileIndex]: false, // Set editStatus to false after adding a new stock
    }));
  };

  useEffect(() => {
    console.log("Updated Allocation Percent Values", allocationPercentValues);
    console.log("Updated Cash Percent Values", cashPercentValue);
  }, [allocationPercentValues, cashPercentValue]);

  useEffect(() => {
    // Calculate total allocation on component mount
    if (!Array.isArray(result)) {
      return;
    }
    const initialRemainingAllocation = {};
    result.forEach((profile, profileIndex) => {
      const totalAllocationPercent =
        calculateTotalAllocationPercent(profileIndex);
      const remainingPercent = 100 - totalAllocationPercent;
      initialRemainingAllocation[profile.risk_profile_id] = remainingPercent;
    });

    setRemainingAllocation(initialRemainingAllocation);
  }, [result]);

  const calculateTotalAllocationPercent = (profileIndex) => {
    const stockDetails = result[profileIndex].model_portfolio_details;
    let totalAllocationPercent = 0;

    stockDetails.forEach((stock, stockIndex) => {
      const updatedAllocationPercent =
        allocationPercentValues[profileIndex]?.[stockIndex];

      const allocationPercent =
        updatedAllocationPercent !== undefined
          ? updatedAllocationPercent
          : stock.allocation_percent;

      totalAllocationPercent += Number(allocationPercent);
    });

    return totalAllocationPercent;
  };
  const handleSaveClick = async (profileIndex, stockIndex) => {
    console.log({ profileIndex, stockIndex }, "RISHI");

    const totalAllocationPercent =
      calculateTotalAllocationPercent(profileIndex);
    const remainingPercent = 100 - totalAllocationPercent;

    if (totalAllocationPercent + remainingPercent !== 100) {
      dispatch(
        toastActions.setToastData({
          message:
            "Error: Total allocation percent and remaining percent do not equal 100%",
          variant: "error",
        })
      );
      return;
    }

    if (totalAllocationPercent < 0 || remainingPercent < 0) {
      dispatch(
        toastActions.setToastData({
          message: "Total Stock allocation percent is more than 100",
          variant: "error",
        })
      );
      return;
    }

    // Make API call
    try {
      // Replace the following line with your actual API call
      // await axios.post('your-api-endpoint', yourData);

      // Assuming API call was successful
      setEditMode(false); // Hide the Save button
      console.log("API call successful!");

      // Use the callback form of setRemainingAllocation to ensure the most up-to-date value
      setRemainingAllocation((prevRemaining) => {
        const updatedRemaining = {
          ...prevRemaining,
          [result[profileIndex].risk_profile_id]: remainingPercent,
        };

        // Call handleUpdateStockApi with the updated remaining allocation
        handleUpdateStockApi(
          profileIndex,
          updatedRemaining[result[profileIndex].risk_profile_id] || 0
        );

        // Set the edit status after updating the stock API
        setEditStatus((prevEditStatus) => ({
          ...prevEditStatus,
          [profileIndex]: false,
        }));

        return updatedRemaining;
      });
    } catch (error) {
      console.error("Error occurred while calling the API:", error);
      // Handle API call error here
    }
  };

  if (!result || !Array.isArray(result)) {
    return <div>Loading...</div>;
  }
  return (
    <Grid container spacing={3}>
      {result?.map((profile, profileIndex) => (
        <Grid item xs={12} sm={6} md={4} key={profileIndex}>
          <Card>
            <CardContent>
              <Box
                mx={-4}
                mt={-2}
                sx={{
                  p: "36px 48px",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `${profile.product_category_color}`,
                }}
              >
                <Text variant="body1" component="div" gutterBottom>
                  {profile.product_category_name}
                </Text>
                <Button
                  sx={{
                    mt: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: "10px 28px",
                    borderRadius: "88px",
                    width: "100%",
                    border: "1px solid #CBCBCB",
                    backgroundColor: "#F7F8FF",
                    color: "#142E56",
                    fontSize: "14px",
                    fontWeight: 500,
                    "&:hover": { backgroundColor: "#F7F8FF" },
                    textTransform: "capitalize",
                  }}
                  onClick={() => openTotalCustomerDrawer(profile)}
                >
                  <span>Total Customers: {profile.customer_count}</span>
                </Button>
              </Box>

              {/* Cash Percent Section */}
              <Box sx={{ py: "16px" }}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      padding: "12px 16px",
                      borderRadius: "8px",
                      backgroundColor: "#E5EEFF",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      sx={{
                        marginRight: 1,
                        fontSize: "16px",
                        color: "#242424",
                      }}
                    >
                      Cash components
                    </Text>
                    <TextField
                      sx={{
                        maxWidth: "108px",
                      }}
                      variant="outlined"
                      size="small"
                      value={(remainingAllocation[
                        result[profileIndex].risk_profile_id
                      ] !== undefined
                        ? remainingAllocation[
                            result[profileIndex].risk_profile_id
                          ]
                        : 0
                      ).toFixed(2)}
                      onInput={allowOnlyNumbers}
                      InputProps={{
                        style: {
                          fontSize: "14px",
                          textAlign: "center",
                          borderRadius: "8px",
                          backgroundColor: "#FFFFFF",
                        },
                        endAdornment: (
                          <>
                            <span>%</span>
                          </>
                        ),
                      }}
                      disabled={!editCashPercent[profileIndex]}
                    />
                  </Box>
                </Box>
              </Box>

              <List
                sx={{
                  minHeight: "300px",
                  maxHeight: "300px",
                  overflowY: "auto",
                }}
              >
                {profile.model_portfolio_details.map((stock, stockIndex) => (
                  <ListItem key={`portfolio_${stockIndex}`}>
                    {/* Stock Details */}
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      {/* Stock Symbol and Quantity */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          // width: "40%",
                        }}
                      >
                        <Text
                          varaint="small"
                          sx={{
                            color: "#242424",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {stock.symbol}
                        </Text>
                        <Text
                          varaint="small"
                          sx={{
                            color: "#676C76",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          ₹ {stock.price}
                        </Text>
                      </Box>

                      {/* Allocation Percentage Input */}
                      <Box
                        sx={{
                          // width: "60%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text></Text>
                        <TextField
                          sx={{ backgroundColor: "white" }}
                          variant="outlined"
                          size="small"
                          disabled={!editStatus[profileIndex]?.[stockIndex]}
                          value={
                            allocationPercentValues[profileIndex]?.[
                              stockIndex
                            ] !== undefined
                              ? allocationPercentValues[profileIndex]?.[
                                  stockIndex
                                ]
                              : stock.allocation_percent
                          }
                          onChange={(e) =>
                            handleAllocationPercentChange(
                              profileIndex,
                              stockIndex,
                              validateNumericInput(e.target.value) || ""
                            )
                          }
                          InputProps={{
                            style: {
                              fontSize: "14px",
                              textAlign: "center",
                              borderRadius: "8px",
                              minWidth: "108px",
                              maxWidth: "108px",
                              backgroundColor: "white",
                            },
                            endAdornment: (
                              <>
                                <span style={{ marginRight: "8px" }}>%</span>
                                {/* Show StockCheckIcon when in edit mode */}
                                {editStatus[profileIndex]?.[stockIndex] && (
                                  <HighlightOffIcon
                                    sx={{
                                      color: "#242424",
                                      width: "20px",
                                      height: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleCancelClick(
                                        profileIndex,
                                        stockIndex
                                      )
                                    }
                                  />
                                )}

                                {/* Show StockEditIcon and StockDeleteIcon when not in edit mode */}
                                {!editStatus[profileIndex]?.[stockIndex] && (
                                  <>
                                    <StockEditIcon
                                      sx={{
                                        color: "#242424",
                                        width: "20px",
                                        height: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleEditClick(
                                          profileIndex,
                                          stockIndex
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </>
                            ),
                          }}
                        />
                      </Box>
                    </Stack>
                  </ListItem>
                ))}
              </List>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {editStatus[profileIndex] &&
                  editMode && ( // Render the Save button only if in edit mode
                    <Button
                      children="Save"
                      sx={{
                        width: "100%",
                        mt: 2,
                        color: "white",
                        fontSize: "16px",
                        borderRadius: "8px",
                        backgroundColor: "#142E56",
                        "&:hover": { backgroundColor: "#142E56" },
                        textTransform: "capitalize",
                      }}
                      onClick={() => handleSaveClick(profileIndex)}
                    />
                  )}

                <Button
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFF",
                    color: "#142E56",
                    mt: 2,
                    fontSize: "16px",
                    border: "1px solid #142E56",
                    borderRadius: "8px",
                    "&:hover": { backgroundColor: "#FFF" },
                    textTransform: "capitalize",
                  }}
                  // onClick={() => handleAddNewStockClick(profileIndex)}
                  onClick={() =>
                    handleAddNewStockClick(
                      profileIndex,
                      remainingAllocation[
                        result[profileIndex].risk_profile_id
                      ] || 0
                    )
                  }
                >
                  Add&nbsp;
                  <span style={{ textTransform: "lowercase" }}>new stock</span>
                </Button>

                <Link
                  href="#"
                  onClick={() => StockAllocationSidebar(profile)}
                  sx={{
                    mt: 2,
                    color: "#1A54B9",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  View summary
                </Link>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ModelPortfolioCard;
