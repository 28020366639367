import React, { useState, useEffect } from "react";
import SideDrawer from "components/common/SideDrawer";
import Box from "components/common/Box";
import Text from "components/common/Text";
import { Checkbox, Chip, styled, Tab, Tabs, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorIcon from "@mui/icons-material/Error";
import Tooltip from "components/common/Tooltip";
import dayjs from "dayjs";

const CustomersBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFF",
  borderRadius: "8px",
  padding: "14px 16px",
  width: "360px",
  border: "1px solid #E2E2E2",
  gap: "12px",
  mt: "12px",
}));

const ChipStyle = styled(Chip)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background: "#FFF5E0",
  borderRadius: "152px",
  color: "#E58026",
  fontSize: "12px",
  fontWeight: 500,
  "&::before": {
    position: "relative",
    left: 6,
    bottom: 6,
    content: '"\\2022"',
    width: "6px",
    height: "6px",
    color: "#F2994A",
  },
}));

const splitTimestamp = (timestamp) => {
  const parsedDate = dayjs(timestamp);
  const date = parsedDate.format("DD-MM-YYYY");
  const time = parsedDate.format("hh:mm A");
  return { date, time };
};

const CustomerSidebar = ({
  customers,
  error,
  open,
  closeDrawer,
  productCategoryName,
  handleSubmit,
  handleSelectedCustomerIds,
  getErrorModelPortfolioDetails,
}) => {
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [isSendCallDisabled, setIsSendCallDisabled] = useState(true);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleCustomerSelect = (userId) => {
    const isSelected = selectedCustomerIds.includes(userId);
    const updatedIds = isSelected
      ? selectedCustomerIds.filter((id) => id !== userId)
      : [...selectedCustomerIds, userId];
    setSelectedCustomerIds(updatedIds);
    handleSelectedCustomerIds(updatedIds);
    setIsSelectAllChecked(
      updatedIds.length > 0 && updatedIds.length === customers.length
    );
  };

  const handleSelectAllChange = () => {
    const newSelectedCustomerIds = isSelectAllChecked
      ? []
      : customers.map((customer) => customer.user_id);
    setSelectedCustomerIds(newSelectedCustomerIds);
    handleSelectedCustomerIds(newSelectedCustomerIds);
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  useEffect(() => {
    if (!open) {
      setSelectedCustomerIds([]);
      setCurrentTab(0);
      setIsSelectAllChecked(false);
    }
  }, [open]);

  useEffect(() => {
    setIsSendCallDisabled(selectedCustomerIds.length === 0);
  }, [selectedCustomerIds]);

  useEffect(() => {
    if (currentTab === 2) {
      getErrorModelPortfolioDetails({ noGlobalLoading: true });
    }
  }, [currentTab]);

  const handleDrawerClose = () => {
    closeDrawer();
    setSelectedCustomerIds([]);
    setCurrentTab(0);
    setIsSelectAllChecked(false);
  };

  const handleSendCall = () => {
    if (!isSendCallDisabled) {
      handleSubmit(selectedCustomerIds);
    }
  };

  const errorCount = error ? error.length : 0;

  return (
    <SideDrawer
      showPrimaryButton={currentTab === 0} // Disable button when drawer is open
      showSecondaryButton={false}
      open={open}
      handleSubmit={handleSendCall}
      closeDrawer={handleDrawerClose}
      drawerWidth="440"
      title={
        <div
          style={{
            color: "#FFFFFF",
            fontSize: "24px",
            fontWeight: 600,
            marginLeft: "10px",
          }}
        >
          All customers
        </div>
      }
      subtitle={
        <div
          style={{
            color: "#FFFFFF",
            fontSize: "14px",
            fontWeight: 400,
            marginLeft: "10px",
          }}
        >
          See all the customers here having a model portfolio.
        </div>
      }
      submitButtonText="Send call"
    >
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="false"
      >
        <Tab
          label={
            <Typography style={{ fontSize: "15px" }}>
              Calls to be Sent
            </Typography>
          }
        />
        <Tab
          label={
            <Typography style={{ fontSize: "15px" }}>Action Pending</Typography>
          }
        />
        <Tab
          label={
            <Typography
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
              }}
            >
              Error
              <Typography
                style={{
                  position: "absolute",
                  bottom: 32,
                  right: 15,
                  fontSize: "0.60rem",
                  color: "red",
                  borderRadius: "50%",
                  paddingRight: "5px",
                  paddingLeft: "5px",
                  border: "0.5px solid grey",
                }}
              >
                {`${errorCount}`}
              </Typography>
            </Typography>
          }
        />
      </Tabs>

      {currentTab === 0 && (
        <>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Tooltip
              title={
                isSelectAllChecked
                  ? "Click here to unselect all customers"
                  : "Click here to select all customers"
              }
              placement="top"
            >
              <Checkbox
                checked={isSelectAllChecked}
                onChange={handleSelectAllChange}
              />
            </Tooltip>

            <Text sx={{ color: "#242424", fontSize: "14px", fontWeight: 500 }}>
              {customers && Array.isArray(customers)
                ? `${customers.length} customers of`
                : "Loading..."}
            </Text>
            <Text
              ml={1}
              sx={{ color: "#242424", fontSize: "14px", fontWeight: 500 }}
            >
              {productCategoryName}
            </Text>
          </Box>
        </>
      )}

      {customers &&
        Array.isArray(customers) &&
        customers.map((customer) => (
          <>
            {currentTab === 1 &&
            customer.Status === 1 &&
            customer.Statustext === "Action Pending" ? (
              <CustomersBox key={customer.id}>
                <Text
                  sx={{
                    fontSize: "14px",
                    color: "#242424",
                    fontWeight: 400,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {customer.name}
                  <ChipStyle label="Action Pending" size="small" />
                </Text>
              </CustomersBox>
            ) : (
              ""
            )}

            {currentTab === 0 &&
            customer.Status === 0 &&
            customer.Statustext === "Call to be taken" ? (
              <CustomersBox
                key={customer.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {customer.is_broker_missing ? (
                    <Tooltip title="Broker Details not found" placement="top">
                      <ErrorIcon
                        sx={{ marginRight: "10px", cursor: "pointer" }}
                      />
                    </Tooltip>
                  ) : null}
                  <Text
                    sx={{
                      fontSize: "14px",
                      color: "#242424",
                      fontWeight: 400,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginRight: "20px",
                    }}
                  >
                    {customer.name}
                  </Text>
                </Box>

                <Checkbox
                  checked={selectedCustomerIds.includes(customer.user_id)}
                  onChange={() => handleCustomerSelect(customer.user_id)}
                />
              </CustomersBox>
            ) : (
              ""
            )}
          </>
        ))}

      {error &&
        Array.isArray(error) &&
        error.map((err) => {
          const { date, time } = splitTimestamp(err.Timestamp);
          return (
            <>
              {currentTab === 2 && (
                <CustomersBox key={err.ErrorLogId}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Text sx={{ mr: 1 }}>{err.name}</Text>
                    <Tooltip title={err.ErrorMessage}>
                      <ErrorIcon sx={{ cursor: "pointer", color: "#142E56" }} />
                    </Tooltip>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Text sx={{ fontSize: "12px", color: "#999" }}>
                      Date: {date}
                    </Text>
                    <Text sx={{ fontSize: "12px", color: "#999" }}>
                      Time: {time}
                    </Text>
                  </Box>
                </CustomersBox>
              )}
            </>
          );
        })}
    </SideDrawer>
  );
};

export default CustomerSidebar;
