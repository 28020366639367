import React, { useState } from "react";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Chip } from "@mui/material";
import Dialog from "components/common/Dialog";
import Text from "components/common/Text";

const AddNewStockModal = ({
  onClose,
  onSave,
  open,
  formData,
  setFormData,
  profile,
  companyData,
  remainingAllocation,
  formDataSearch,
  setFormDataSearch,
}) => {
  const [nameError, setNameError] = useState(false);
  const [percentError, setPercentError] = useState(false);

  const validateNumericInput = (value) => {
    // Allow backspace to work normally
    if (value === "") {
      return value;
    }

    const numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

    if (numericRegex.test(value)) {
      const floatValue = parseFloat(value);
      if (floatValue <= remainingAllocation) {
        return value;
      }
    }

    return formData.allocationPercent || ""; // Use the existing value if new value is not valid
  };

  console.log("formData.newStockName", formData.newStockName);
  const handleSave = () => {
    // Validate the fields
    if (!formData.newStockName || !formData.newStockName.symbol) {
      setNameError(true);
    } else {
      setNameError(false);
    }

    if (!formData.allocationPercent) {
      setPercentError(true);
    } else if (parseFloat(formData.allocationPercent) > remainingAllocation) {
      setPercentError(true);
    } else {
      setPercentError(false);
    }

    // If both fields are filled, proceed with saving
    if (formData.newStockName.symbol && formData.allocationPercent) {
      onSave(formData.newStockName, formData.allocationPercent);
      onClose();
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      title=""
      disableCloseIcon
      maxWidth="sm"
      sx={{ borderRadius: "10px" }}
      contentComponent={() => (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "50px",
            }}
          >
            <Text
              sx={{
                color: "#101828",
                fontSize: "18px",
                fontWeight: 600,
              }}
            >
              Add new stock
            </Text>

            <Chip
              label={profile.product_category_name}
              sx={{
                color: "#142E56",
                borderRadius: "128px",
                backgroundColor: "#E5EEFF",
                fontSize: "14px",
                fontWeight: 500,
                ml: 1,
                mb: 4,
              }}
            />
          </Box>

          <Box>
            <Text
              sx={{
                color: "#242424",
                fontSize: "14px",
                fontWeight: 500,
                mb: "6px",
              }}
            >
              Stock name
            </Text>

            <Autocomplete
              sx={{ width: "100%", mb: "20px" }}
              placeholder="Enter stock name"
              value={formData.newStockName}
              onChange={(event, value) => {
                const selectedSymbol = value?.symbol || "";
                const selectedExchange = value?.exchange || "";
                setFormData({
                  ...formData,
                  newStockName: {
                    symbol: selectedSymbol,
                    exchange: selectedExchange,
                  },
                });
                // Clear the error when the user makes a selection
                setNameError(false);
              }}
              disablePortal
              id="combo-box-demo"
              options={companyData}
              getOptionLabel={(option) => option.symbol || ""}
              renderInput={(params) => (
                <TextField
                  onChange={(event) => {
                    setFormDataSearch(event.target.value);
                  }}
                  value={formDataSearch}
                  {...params}
                  placeholder="Enter a Stock Name"
                />
                // <TextField
                //   {...params}
                //   placeholder="Enter a Stock Name"
                //   error={nameError}
                //   helperText={nameError ? "Stock name is required" : ""}
                // />
              )}
            />
            <Text
              sx={{
                color: "#242424",
                fontSize: "14px",
                fontWeight: 500,
                mt: 2,
                mb: "6px",
              }}
            >
              % to be allocated to the stock (Remaining:{" "}
              {remainingAllocation ? remainingAllocation : 0}%)
            </Text>

            <TextField
              sx={{ width: "100%", mb: "20px" }}
              placeholder="Enter % allocated to the stock"
              value={formData.allocationPercent}
              onBlur={() => {
                if (!formData.allocationPercent) {
                  setPercentError(true);
                } else if (
                  parseFloat(formData.allocationPercent) > remainingAllocation
                ) {
                  setPercentError(true);
                } else {
                  setPercentError(false);
                }
              }}
              onChange={(e) => {
                const inputPercent = parseFloat(e.target.value);
                if (
                  !isNaN(inputPercent) &&
                  inputPercent > remainingAllocation
                ) {
                  setPercentError(true);
                } else {
                  setPercentError(false);
                  setFormData({
                    ...formData,
                    allocationPercent: validateNumericInput(
                      e.target.value,
                      remainingAllocation
                    ),
                  });
                }
              }}
              error={
                (!formData.allocationPercent && percentError) ||
                parseFloat(formData.allocationPercent) > remainingAllocation
              }
              helperText={
                !formData.allocationPercent && percentError ? (
                  "Allocation percent is required"
                ) : percentError ? (
                  <span style={{ color: "red" }}>
                    Allocation percent cannot exceed the remaining allocation
                  </span>
                ) : (
                  ""
                )
              }
            />
          </Box>
        </Box>
      )}
      actionComponent={() => (
        <Box mb={2}>
          <Button
            onClick={onClose}
            disableEndIcon={false}
            variant="outlined"
            size="large"
            m={0.5}
            sx={{
              borderRadius: "8px",
              border: "1px solid #142E56",
              fontSize: "16px",
              fontWeight: 600,
              width: "270px",
              height: "56px",
              ml: 3,
              mr: 2,
              textTransform: "capitalize",
            }}
          >
            No
          </Button>

          <Button
            onClick={handleSave}
            disableEndIcon={false}
            variant="outlined"
            size="large"
            m={0.5}
            sx={{
              borderRadius: "8px",
              border: "1px solid #142E56",
              backgroundColor: "#142E56",
              color: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 600,
              width: "270px",
              height: "56px",
              "&:hover": { backgroundColor: "#142E56" },
              textTransform: "capitalize",
            }}
          >
            Save
          </Button>
        </Box>
      )}
    />
  );
};

export default AddNewStockModal;
