import moment from 'moment';

const dateUtill = {};


dateUtill.getNextDate = (date, frequency) =>{
    let nextDate;
    if(frequency){
        frequency = frequency.toLowerCase().replaceAll(" ","_");
    }
    switch(frequency){
        case "monthly":
                nextDate = moment(date).add(1,'months');
            break;
        case "quarterly":
                nextDate = moment(date).add(3,'months');
            break;
        case "half_yearly":
                nextDate = moment(date).add(6,'months');
            break;
        case "yearly":
                nextDate = moment(date).add(1,'years');
            break;
        default:
                nextDate = moment(date).add(1,'months');
            break;
    }
    nextDate.subtract(1, 'day'); // one days back;
    return nextDate.toDate();
}









export { dateUtill };