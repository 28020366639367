
import CustomerDetailHeader from "./CustomerDetailHeader";
import DetailTab from "./DetailTab";
import Paper from "components/common/Paper";
import CustomBreadcrumb from "components/customerCard/CustomerBreadcrumb";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";


const CustomerDetailPage = () => {
  const select = useSelector((state) => state.toast);
  const location = useLocation();
  const BlockUserID = location.state && location.state.BlockUserID;
  const BlockUserName = location.state && location.state.BlockUserName;
  const [isValue,setIsValue]=useState(false)

  const BreadCrumbName = BlockUserName === null ? select?.breadCrumbData?.name : BlockUserName

  return (
    <>
      <CustomBreadcrumb link='Customers' Breadcrumb1={BreadCrumbName} Breadcrumb2="Performance" />
      <Paper
        elevation={1}
        sx={{
          alignItems: "center",
          backgroundColor: "white",
          border: "1px solid white",
          height: "auto",
          marginTop:'15px'
        }}
      >
        <CustomerDetailHeader isValue={isValue} setIsValue={setIsValue}/>
        <DetailTab isValue={isValue} setIsValue={setIsValue}/>
      </Paper>
    </>
  );
}

export default CustomerDetailPage;
