import Dialog from "components/common/Dialog";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Image from "components/common/Image";
import Button from "components/common/Button";
import { useState } from "react";

const CustomerRiskProfileModal = (props) => {
  const {
    isOpen = false,
    handleClose = () => {},
    handleRiskProfileClick = () => {},
    selectedRiskProfile = null,
    handleSubmit = () => {},
  } = props;

  // State to keep track of the selected risk profile
  const [selectedProfile, setSelectedProfile] = useState(selectedRiskProfile);

  // Function to handle risk profile click
  const handleClick = (profile) => {
    setSelectedProfile(profile); // Update the selected profile state
    handleRiskProfileClick(profile); // Pass the selected profile value to the container
  };

  return (
    <Dialog
      onClose={() => handleClose(false)}
      open={isOpen}
      enableBackdropClick={false}
      title={
        <Text
          fontSize="20px"
          fontWeight="600"
          marginBottom="20px"
          marginTop="20px"
          marginLeft="30px"
        >
          Select one risk profile of the customers to whom the following trade will apply
        </Text>
      }
      disableCloseIcon
      maxWidth="lg"
      contentComponent={() => (
        <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
          {/* Card 1 */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick("aggressive")} // Pass "aggressive" when clicked
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "50px",
              width: "250px",
              height: "60px",
              cursor: "pointer",
              backgroundColor: selectedProfile === "aggressive" ? "#F7F8FF" : "transparent",
            }}
          >
            <Image src="/images/aggressive.svg" />
            <Text fontSize="20px" fontWeight="500" color="red" sx={{ mt: 2 }}>
              Aggressive risk profile
            </Text>
          </Stack>

          {/* Card 2 */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick("moderate")} // Pass "moderate" when clicked
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "50px",
              width: "250px",
              height: "60px",
              cursor: "pointer",
              backgroundColor: selectedProfile === "moderate" ? "#F7F8FF" : "transparent",
            }}
          >
            <Image src="/images/moderate.svg" />
            <Text fontSize="20px" fontWeight="500" color="orange" sx={{ mt: 2 }}>
              Moderate risk profile
            </Text>
          </Stack>

          {/* Card 3 */}
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            onClick={() => handleClick("conservative")} // Pass "conservative" when clicked
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "50px",
              width: "250px",
              height: "60px",
              cursor: "pointer",
              backgroundColor: selectedProfile === "conservative" ? "#F7F8FF" : "transparent",
            }}
          >
            <Image src="/images/conservative.svg" />
            <Text fontSize="20px" fontWeight="500" color="green" sx={{ mt: 2 }}>
              Conservative risk profile
            </Text>
          </Stack>
        </Stack>
      )}
      actionComponent={() => (
        <Stack alignItems="flex-end">
          <Button
            sx={{
              fontWeight: 600,
              borderRadius: "8px",
              fontSize: "14px",
              p: "14px 28px",
              width: "40%",
              mb: 6,
              mr: 6,
              mt: 2,
            }}
            onClick={handleSubmit}
          >
           Proceed to update the Risk Profile
          </Button>
        </Stack>
      )}
    />
  );
};

export default CustomerRiskProfileModal;