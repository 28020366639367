import React from "react";
import { styled } from "@mui/system/esm";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import Drawer from "@mui/material/Drawer";
import Box from "components/common/Box";
import Avatar from "components/common/Avatar";
import IconButton from "components/common/IconButton";
import NewSidebarMenu from "./NewSidebarMenu";
import LogOutIcon from "asset/icons/LogOutIcon";
import DrawerIcon from "asset/icons/DrawerIcon";
import Text from "components/common/Text";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";


// Actions
import { commonActions } from "stores/redux/slices/commonSlice";

// store
import { localStore } from "stores/localStore";

const drawerWidth = 240;

const Logo = styled(Text)(() => ({
  color: "#fff",
  padding: "20px 12px",
  fontSize: "24px",
  borderBottom: "1px solid #747474",
}));

const BottomLogo = styled(Text)(() => ({
  display: "flex",
  position: "fixed",
  bottom: "0px",
  width: "100%",
  backgroundColor:"#142E56",
  color: "#fff",
  padding: "20px 12px",
  fontSize: "20px",
  borderTop: "1px solid #747474",
  cursor: "pointer",
}));

const DrawerRoot = styled("div")({
  flexShrink: 0,
});

const DrawerPaper = styled("div")(({ theme, isdrawerexpanded }) => ({
  root: {
    flexShrink: 0,
  },
  width: drawerWidth,
  border: "0 !important",
  backgroundColor: "#142E56",
  flex: `0 0 ${drawerWidth}px`,
  height: "100vh",
  ...(isdrawerexpanded == 'true' && {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(isdrawerexpanded != 'true' && {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9),
  }),
}));

const DrawerHeader = styled("div")(({ theme, isDrawerExpanded }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  ...(isDrawerExpanded
    ? {
        justifyContent: "space-between",
      }
    : {
        justifyContent: "center",
      }),
}));

const LogoWrap = styled("div")({
  display: "block",
  marginLeft: "8px",
  // maxWidth: "150px",
});

const StyledAvatar = styled(Avatar)({
  height: 38,
  width: 38,
  variant: "rounded",
  cursor: "pointer",
});

const StyledIconButton = styled(IconButton)({
  cursor: "pointer",
});

const Sidebar = (props) => {
  const theme = useTheme();

  // Hooks
  let navigate = useNavigate();
  const dispatch = useDispatch();


  const MEDIA_LG_UP = useMediaQuery(theme.breakpoints.up("lg"));
  const { isDrawerExpanded, onDrawerToggle, menuConfig = [] } = props;


  const handleLogout = () => {
	localStore.resetToken();
	dispatch(commonActions.logout());
    navigate("/");
  };
  return (
    <DrawerPaper
      variant={MEDIA_LG_UP ? "permanent" : "temporary"}
      open={isDrawerExpanded}
      isdrawerexpanded={isDrawerExpanded? "true": "false"}
      onClose={() => onDrawerToggle(false)}
    >
      <Logo>FundzUp</Logo>

      <NewSidebarMenu
        isDrawerExpanded={isDrawerExpanded}
        menuConfig={menuConfig}
      />

      <BottomLogo>
        <Box>
          <LogOutIcon sx={{ mr: 2 }} />
        </Box>
        <Box>
          {" "}
          <Text onClick={handleLogout}>Log Out</Text>
        </Box>
      </BottomLogo>
    </DrawerPaper>
  );
};

export default React.memo(Sidebar);
