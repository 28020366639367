import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import List from "components/common/List";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import ListItem from "components/common/ListItem";
import ListItemButton from "components/common/ListItemButton";
import ListItemIcon from "components/common/ListItemIcon";
import ListItemText from "components/common/ListItemText";
import Radio from "components/common/Radio";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Box from "components/common/Box";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import TextField from "components/common/TextField";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { set } from "date-fns";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useDispatch, useSelector } from "react-redux";

const StyledListItem = styled(ListItem)(({ theme, isSelected }) => ({
  border: "2px solid ",
  borderColor: isSelected ? "#1A54B9" : "#CBCBCB",
  margin: "8px 0",
  borderRadius: "8px",
  backgroundColor: isSelected ? "#EAF2FF" : "inherit",
}));
const ManageCash = ({
  getCustomerDetails,
  viewCustomerDetail,
  customer_id,
  closeManageDrawer,
  isManageCashDrawerOpen,
  setIsManageCashDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedProductValue, setSelectedProductIdValue] = React.useState("");
  const [selectSwap, setSelectSwap] = React.useState("");
  const [selectProductIdSwap, setSelectProductIdSwap] = React.useState(-1);
  const [showNewFields, setShowNewFields] = useState(false);
  const [amount, setAmount] = useState("");
  const [toAmount, setToAmount] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [swapProduct_name, setSwapProduct_name] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [fromTotalAmount, setFromTotalAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [totalAmount1, setTotalAmount1] = useState(0);
  const [totalModelAmount, setTotalModelAmount] = useState(0);
  const [errorModelMessage, setErrorModelMessage] = useState("");
  const [sendAmounts, setSendAmounts] = useState({
    transforAmount: "",
    categoryAmounts: {},
  });
  const [sendManageCash, setSendManageCash] = useState({
    transformanageAmount: "",
    categoryManageAmounts: {},
  });
  const [formState, setFormState] = useState({
    isValidAmount: true,
    errorMessage: "",
  });
  // ------------------API----------- //
  const [postManageCash] = customersApiAction.postManageCash();
  const productId = customersApiAction.getProductId();
  const productIdList = productId?.data?.products;
  // --------------------Function ----------------- //
  const handleChange = (field, value, categoryId) => {
    setSendAmounts((prevAmounts) => ({
      ...prevAmounts,
      [field]: categoryId
        ? { ...prevAmounts[field], [categoryId]: value }
        : value,
    }));
  };

  const handleCategoryChange = (categoryId, newValue) => {
    const trimmedValue = newValue.trim();
    const categoryMaxAmount =
      viewCustomerDetail?.investmentDetails[1]?.product_category.find(
        (item) => item.product_category_id === categoryId
      )?.cash;
    if (
      trimmedValue !== "" &&
      !isNaN(trimmedValue) &&
      (categoryMaxAmount === undefined ||
        parseFloat(trimmedValue) <= categoryMaxAmount)
    ) {
      setSendAmounts((prevState) => ({
        ...prevState,
        categoryAmounts: {
          ...prevState.categoryAmounts,
          [categoryId]: trimmedValue,
        },
      }));
      setErrorMessage("");
    } else {
      setErrorMessage(
        `Please enter an amount less than or equal to ${categoryMaxAmount}`
      );
      setSendAmounts((prevState) => ({
        ...prevState,
        categoryAmounts: {
          ...prevState.categoryAmounts,
          [categoryId]: "",
        },
      }));
    }
  };

  useEffect(() => {
    if (viewCustomerDetail) {
      const categoryAmounts = {};
      viewCustomerDetail?.investmentDetails[1]?.product_category?.forEach(
        (item) => {
          categoryAmounts[item.product_category_id] =
            item.cash || "";
        }
      );
      setSendAmounts((prevState) => ({
        ...prevState,
        categoryAmounts: categoryAmounts,
      }));
    }
  }, [viewCustomerDetail]);

  const handleRadioChange = (index, productId, amount, product_name) => {
    setSelectSwap(index);
    setSelectedValue((prevProductId) =>
      prevProductId === productId ? "" : productId
    );
    setAmount(amount);
    setProduct_name(product_name);
  };

  const handleRadioSelect = (index, productId, product_name) => {
    setSelectProductIdSwap(index);
    setSelectedProductIdValue(productId);
    setSwapProduct_name(product_name);
    const algoProduct = viewCustomerDetail.investmentDetails.find(
      (product) => product.product_name === product_name
    );

    if (algoProduct) {
        setToAmount(algoProduct.cash);
    } else {
        console.log("Algo product not found");
    }
  };

  const handleContinue = () => {
    if (
      selectedValue !== "" &&
      selectedProductValue !== "" &&
      selectedValue !== selectedProductValue
    ) {
      setShowNewFields(true);
    } else {
      setShowNewFields(false);
    }
  };

  const handleSubmit = async () => {
    let isValidAmount = false;
    if (selectSwap === 2) {
      isValidAmount = !isNaN(parseInt(totalAmount));
    } else if (selectProductIdSwap === 2) {
      isValidAmount = !isNaN(parseInt(totalModelAmount));
    } else {
      isValidAmount = !isNaN(parseInt(sendAmounts.transforAmount));
    }
    if (!isValidAmount) {
      setFormState({
        isValidAmount: false,
        errorMessage: "Please enter a valid amount.",
      });
      return;
    }
    if (errorModelMessage) {
      console.error("Validation error:", errorModelMessage);
      return; // Don't proceed with submitting the form
    }
    let payload = {
      user_id: customer_id,
      from: {
        product_id: selectSwap,
        amount:
          selectSwap === 2
            ? parseInt(totalAmount)
            : selectProductIdSwap === 2
            ? parseInt(totalModelAmount)
            : parseInt(sendAmounts.transforAmount),
        product_category:
          selectSwap !== undefined && selectSwap === 2
            ? Object.entries(sendAmounts.categoryAmounts)
                .filter(
                  ([product_category_id, amount]) =>
                    amount !== null && amount !== 0 && amount !== ""
                )

                .map(([product_category_id, amount]) => ({
                  product_category_id: parseInt(product_category_id),
                  amount: parseInt(amount ? amount : 0),
                }))
            : [],
      },
      to: {
        product_id: selectProductIdSwap,
        amount:
          selectSwap === 2
            ? parseInt(totalAmount)
            : selectProductIdSwap === 2
            ? parseInt(totalModelAmount)
            : parseInt(sendAmounts.transforAmount),
        product_category:
          selectProductIdSwap !== undefined && selectProductIdSwap === 2
            ? Object.entries(sendManageCash.categoryManageAmounts).map(
                ([product_category_id, amount]) => ({
                  product_category_id: parseInt(product_category_id),
                  amount: parseInt(amount),
                })
              )
            : [],
      },
    };
    // const investmentAmount = viewCustomerDetail?.investmentDetails.find(
    //   (item) => item.product_id === payload.from.product_id
    // )?.investment_amount;
    // if (payload.from.amount > investmentAmount) {
    //   setErrorMessage("Amount exceeds the investment amount.");
    //   console.error("Error: Amount exceeds the investment amount.");
    //   return;
    // }
    try {
      const res = await postManageCash(payload).unwrap();
      if (res?.status === 1) {
        setIsManageCashDrawerOpen(false);
        getCustomerDetails(customer_id);
        setSelectedValue("");
        setSelectSwap("");
        setShowNewFields(false);
      }
      if (res) {
        const toastMessage =
          res?.status === 1
            ? "Cash transferred successfully!"
            : res?.message;
        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: res?.status === 1 ? "success" : "error",
          })
        );
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleManageAmount = (categoryId, newValue, productId) => {
    const parsedValue = parseInt(newValue, 10) || 0;
    setSendManageCash((prevState) => {
      const categoryManageAmounts = {
        ...prevState.categoryManageAmounts,
        [productId]: isNaN(parsedValue) ? 0 : parsedValue,
      };

      const updatedTotalAmount = Object.values(categoryManageAmounts).reduce(
        (acc, value) => acc + value,
        0
      );
      if (updatedTotalAmount == totalModelAmount) {
        setTotalAmount1(updatedTotalAmount);
        setErrorModelMessage("");
      } else {
        setErrorModelMessage("Total amount exceeds the total model amount.");
      }
      return {
        ...prevState,
        categoryManageAmounts,
        // totalAmount,
      };
    });
  };

  //   const handleTotalModelAmountChange = (newValue) => {
  //     setTotalModelAmount(newValue);
  //     setErrorModelMessage(""); // Clear the error message if the input is valid
  // };

  const handleTotalModelAmountChange = (newValue) => {
    if (!isNaN(parseInt(newValue, 10))) {
      setErrorModelMessage("");
    }
    setTotalModelAmount(newValue);
  };

  useEffect(() => {
  }, [totalAmount, sendManageCash, toAmount]);

  useEffect(() => {
    const newFromAmount = Object.values(sendAmounts.categoryAmounts)
      .filter((amount) => !isNaN(parseInt(amount)))
      .reduce((total, amount) => total + parseInt(amount), 0);
    setFromTotalAmount(newFromAmount);
  }, [sendAmounts.categoryAmounts]);

  useEffect(() => {
    const newTotalAmount = Object.values(sendAmounts.categoryAmounts)
      .filter((amount) => !isNaN(parseInt(amount)))
      .reduce((total, amount) => total + parseInt(amount), 0);
    setTotalAmount(newTotalAmount);
  }, [sendAmounts.categoryAmounts]);

  return (
    <Stack>
      {isManageCashDrawerOpen && (
        <SideDrawer
          anchor="right"
          open={isManageCashDrawerOpen}
          closeDrawer={closeManageDrawer}
          title="Manage cash"
          contentTitle=""
          cancelButtonText="Cancel"
          submitButtonText={showNewFields ? "Save" : "Continue"}
          handleSubmit={showNewFields ? handleSubmit : handleContinue}
          subtitle="Here you can edit the Investment type."
        >
          {!showNewFields ? (
            <Box>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <Chip
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    borderRadius: "2px",
                  }}
                  color="secondary"
                  label={
                    <Text
                      variant="small"
                      fontSize="16px"
                      fontWeight="500"
                    >{`Cash transfer from :`}</Text>
                  }
                ></Chip>
                <Stack>
                  {viewCustomerDetail?.investmentDetails.map(
                    (investment, index) => (
                      <StyledListItem
                        key={index + 1}
                        style={{ padding: "10px" }}
                        isSelected={selectSwap === index + 1}
                        disablePadding
                      >
                        {investment.cash !== 0 ? (
                          <ListItemButton
                            role={undefined}
                            onClick={() =>
                              handleRadioChange(
                                index + 1,
                                investment.product_id,
                                investment.cash,
                                investment.product_name
                              )
                            }
                            dense
                          >
                            <ListItemText
                              primary={
                                <Text fontSize="14px" fontWeight="600">
                                  {investment.product_name} :{" ₹ "}
                                  {investment.cash}
                                </Text>
                              }
                            />
                            <ListItemIcon>
                              <Box style={{ marginLeft: "28px" }}>
                                <Radio
                                  checked={selectSwap === index + 1}
                                  tabIndex={-1}
                                  disableRipple
                                  sx={{
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                  }}
                                  inputProps={{
                                    "aria-labelledby": investment.product_id,
                                  }}
                                />
                              </Box>
                            </ListItemIcon>
                          </ListItemButton>
                        ) : (
                          <ListItemButton disabled dense>
                            <ListItemText
                              primary={
                                <Text
                                  fontSize="14px"
                                  fontWeight="600"
                                  color="grey"
                                  style={{ pointerEvents: "none" }}
                                >
                                  {investment.product_name} :{" ₹ "}
                                  {investment.cash}
                                </Text>
                              }
                            />
                          </ListItemButton>
                        )}
                      </StyledListItem>
                    )
                  )}
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  spacing={1}
                  sx={{ width: "100%", marginTop: "28px" }}
                  alignItems="center"
                  marginTop="25px"
                >
                  <Box style={{ color: "grey" }}>
                    <ImportExportIcon />
                  </Box>
                </Stack>
              </List>
              <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                <Chip
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    borderRadius: "2px",
                  }}
                  color="secondary"
                  label={
                    <Text
                      variant="small"
                      fontSize="16px"
                      fontWeight="500"
                    >{`Cash transfer to :`}</Text>
                  }
                ></Chip>
                {productIdList?.map((investment, index) => (
                  <StyledListItem
                    key={index}
                    style={{ padding: "10px" }}
                    isSelected={selectProductIdSwap === index + 1}
                    disablePadding
                    disabled={
                      selectSwap == investment.product_id ||
                      viewCustomerDetail?.investmentDetails[index]
                        ?.cash === 0
                    }
                  >
                    <ListItemButton
                      role={undefined}
                      onClick={() =>
                        handleRadioSelect(
                          index + 1,
                          investment.product_id,
                          investment.product_name
                        )
                      }
                      dense
                      disabled={
                        selectSwap == investment.product_id ||
                        viewCustomerDetail?.investmentDetails[index]
                          ?.cash === 0 
                      }
                    >
                      <ListItemText
                        primary={
                          <Text fontSize="14px" fontWeight="600">
                            {investment.product_name}
                          </Text>
                        }
                      />
                      <ListItemIcon>
                        <Box style={{ marginLeft: "28px" }}>
                          <Radio
                            checked={selectProductIdSwap === index + 1}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                              "&.Mui-disabled": {
                                color: "grey",
                              },
                            }}
                            inputProps={{
                              "aria-labelledby": investment.product_id,
                            }}
                          />
                        </Box>
                      </ListItemIcon>
                    </ListItemButton>
                  </StyledListItem>
                ))}
              </List>
            </Box>
          ) : (
            <div>
              <Box>
                <Stack direction="row" justifyContent="space-between">
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      height: "84px",
                      backgroundColor: "#E5EEFF",
                    }}
                  >
                    <Stack direction="column" alignItems="center">
                      <Text fontSize="14px" fontWeight="500">
                        {product_name}
                      </Text>
                      <Text fontSize="14px" fontWeight="400">
                      {" ₹ "} {amount}
                      </Text>
                    </Stack>
                  </Box>
                  <SyncAltIcon style={{ marginTop: "25px" }} />
                  <Box
                    sx={{
                      width: "200px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "5px",
                      height: "84px",
                      backgroundColor: "#E5EEFF",
                    }}
                  >
                    <Stack direction="column" alignItems="center">
                      <Text fontSize="14px" fontWeight="500">
                        {swapProduct_name}
                      </Text>
                      <Text fontSize="14px" fontWeight="400">  {" ₹ "} {toAmount}</Text>
                    </Stack>
                  </Box>
                </Stack>
                {selectSwap === 2 &&
                viewCustomerDetail &&
                viewCustomerDetail?.investmentDetails[1]?.product_category
                  ? viewCustomerDetail.investmentDetails[1].product_category.map(
                      (item) => (
                        <Stack
                          key={item.product_category_id}
                          direction="column"
                          justifyContent="space-between"
                          marginTop="24px"
                        >
                          <Box>
                            <Text
                              variant="small"
                              fontSize="14px"
                              fontWeight="500"
                            >
                              Amount to divide in {item?.category_name}
                            </Text>
                            <TextField
                              value={
                                sendAmounts?.categoryAmounts[
                                  item?.product_category_id
                                ] !== undefined
                                  ? sendAmounts.categoryAmounts[
                                      item.product_category_id
                                    ]
                                  : item?.cash || ""
                              }
                              onChange={(e) =>
                                handleCategoryChange(
                                  item.product_category_id,
                                  e.target.value
                                )
                              }
                              placeholder="Enter amount"
                              style={{ marginTop: "8px" }}
                            />
                          </Box>
                        </Stack>
                      )
                    )
                  : null}
                {selectSwap === 2 ? (
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    marginTop="24px"
                  >
                    <Text variant="small" fontSize="14px" fontWeight="500">
                      Amount to transfer
                    </Text>
                    <TextField
                      value={totalAmount}
                      name="totalAmount"
                      onChange={(e) => setTotalAmount(e.target.value)}
                      placeholder="Enter amount"
                      style={{ marginTop: "8px" }}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    {errorMessage &&
                      errorMessage !== "error not send this amount" && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                  </Stack>
                ) : selectProductIdSwap === 2 ? (
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    marginTop="24px"
                  >
                    <Text variant="small" fontSize="14px" fontWeight="500">
                      Amount to transfer
                    </Text>
                    <TextField
                      value={totalModelAmount}
                      name="totalModelAmount"
                      onChange={(e) =>
                        handleTotalModelAmountChange(e.target.value)
                      }
                      placeholder="Enter amount"
                      style={{ marginTop: "8px" }}
                    />
                    {errorModelMessage && (
                      <p style={{ color: "red" }}>{errorModelMessage}</p>
                    )}
                  </Stack>
                ) : (
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    marginTop="24px"
                  >
                    <Text variant="small" fontSize="14px" fontWeight="500">
                      Amount to transfer
                    </Text>
                    <TextField
                      value={sendAmounts.transforAmount}
                      onChange={(e) =>
                        handleChange("transforAmount", e.target.value)
                      }
                      placeholder="Enter amount"
                      style={{ marginTop: "8px" }}
                    />
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                  </Stack>
                )}
                {formState.errorMessage &&
                  errorMessage !== "error not send this amount" && (
                    <p style={{ color: "red" }}>{formState.errorMessage}</p>
                  )}
                {productIdList[1]?.category.map((item) => (
                  <Stack
                    key={item.product_category_id}
                    direction="column"
                    justifyContent="space-between"
                    marginTop="24px"
                  >
                    {selectProductIdSwap == 2 ? (
                      <Box>
                        <Text variant="small" fontSize="14px" fontWeight="500">
                          Amount to divide in {item.category_name}
                        </Text>
                        <TextField
                          onChange={(e) =>
                            handleManageAmount(
                              "categoryAmounts",
                              e.target.value,
                              item.product_category_id
                            )
                          }
                          placeholder="Enter amount"
                          style={{ marginTop: "8px" }}
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Stack>
                ))}
              </Box>
            </div>
          )}
        </SideDrawer>
      )}
    </Stack>
  );
};

export default ManageCash;
