import MTooltip from '@mui/material/Tooltip';
import * as React from 'react';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { makeStyles } from "@mui/styles";

const Tooltip = (props) =>{

    const {
        title = "",
        placement = "top",
        children,
        sx,
    } = props;

    const positionRef = React.useRef({
        x: 0,
        y: 0,
    });
    const popperRef = React.useRef(null);
    const areaRef = React.useRef(null);

    if(!title){
        return <>{children}</>
    }

    const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };
    
        if (popperRef.current != null) {
          popperRef.current.update();
        }
    };

    return (
        <MTooltip
          title={title}
          placement={placement}
          arrow
          PopperProps={{
            popperRef,
            anchorEl: {
              getBoundingClientRect: () => {
                return new DOMRect(
                  positionRef.current.x,
                  areaRef.current.getBoundingClientRect().y,
                  0,
                  0,
                );
              },
            },
          }}
        >
          <Box
            ref={areaRef}
            onMouseMove={handleMouseMove}
            sx={sx}
          >
            {children}
          </Box>
        </MTooltip>

    )
}
export default Tooltip