import { combineReducers } from "redux";

import { toastReducers } from "./slices/toastSlice";
import { addCustomerReducers } from "./slices/addCustomerSlice";
import { commonReducers } from "./slices/commonSlice";
import { customerOnboardingReducers } from "./slices/CustomerOnboardingSlice";

// RTK Query API slice
import { todoApiAction, todoApiReducer } from "./apiSlices/todoApiSlice";

import { authApiAction, authApiReducer } from "./apiSlices/authApiSlice";
import { PlansApiReducer, plansApiAction } from "./apiSlices/plans/plansApiSlice";
import { FamilyApiReducer, familyApiAction } from "./apiSlices/familyMembers/familyApiSlice";
import { DividendApiReducer, dividendApiAction } from "./dividend/dividendApiSlice";
import {
	ModelPortFolioApiReducer,
	modelPortFolioApiAction,
} from "./apiSlices/modelPortfolio/modelPortfolio";

import { customersApiAction, customersApiReducer } from "./apiSlices/customers/customersApiSlice";
import { dashboardApiAction, dashboardApiReducer } from "./apiSlices/Customer DashBoard/dashboardApiSlice";
import { ProfileApiReducer, profileApiAction } from "./riaProfile/riaProfileSlice";

import { addCustomerApiReducer, addCustomerApiAction } from "./apiSlices/addCustomerApiSlice";
import { commonApiAction, commonApiReducer } from "./apiSlices/commonApiSlice";
import { dashboardReducer } from "./slices/dashboardSlice";
import { signUpApiAction, signUpApiReducer } from "./apiSlices/SignUp/signUpApiSlice";
import { CustomerDashboardApiAction, CustomerDashboardApiReducer } from "./customerDashBoard/customerDashBoardApiSlice";
import { CustomerPortfolioApiAction, CustomerPortfolioApiReducer } from "./apiSlices/CustomerPortfolio/portfolioApiSlice";
// import {CustomerDashboardApiAction,}
import { tradesApiAction, tradesApiReducer } from "./apiSlices/trades/tradesApiSlice";
import { customerOtherDetailsApiAction, customerOtherDetailsApiReducer } from "./apiSlices/CustomerOtherDetailsApiSLice";
import { adminDashBoardApiAction, adminDashBoardApiReducer } from "./apiSlices/admin/adminDashBoardApiSlice";
import { AdminProfileApiReducer, adminProfileApiAction } from "./apiSlices/admin/adminProfileApiSlice";
import { CustomerProfileApiAction, CustomerProfileApiReducer } from "./apiSlices/CustomerPortfolio/customerProfileApiSlice";
import { CustomerPortalOnboardApiAction, CustomerPortalOnboardApiReducer } from "./slices/customerPortalOnboardSlice";

const rootReducers = combineReducers({
	toast: toastReducers,
	addCustomerSlice: addCustomerReducers,
	commonReducer: commonReducers,
	dashboard: dashboardReducer,
	customerOnboarding: customerOnboardingReducers,
	[todoApiAction.reducerPath]: todoApiReducer,
	[authApiAction.reducerPath]: authApiReducer,
	[plansApiAction.reducerPath]: PlansApiReducer,
	[familyApiAction.reducerPath]: FamilyApiReducer,
	[dividendApiAction.reducerPath]: DividendApiReducer,
	[customersApiAction.reducerPath]: customersApiReducer,
	[dividendApiAction.reducerPath]: DividendApiReducer,
	[dashboardApiAction.reducerPath]: dashboardApiReducer,
	[profileApiAction.reducerPath]: ProfileApiReducer,
	[addCustomerApiAction.reducerPath]: addCustomerApiReducer,
	[commonApiAction.reducerPath]: commonApiReducer,
	[modelPortFolioApiAction.reducerPath]: ModelPortFolioApiReducer,
	[signUpApiAction.reducerPath] : signUpApiReducer,
	[CustomerDashboardApiAction.reducerPath] : CustomerDashboardApiReducer,
	[CustomerPortfolioApiAction.reducerPath] : CustomerPortfolioApiReducer,
	[CustomerProfileApiAction.reducerPath]	: CustomerProfileApiReducer,
	[tradesApiAction.reducerPath]: tradesApiReducer,
	[customerOtherDetailsApiAction.reducerPath] : customerOtherDetailsApiReducer,
	[adminDashBoardApiAction.reducerPath] : adminDashBoardApiReducer,
	[adminProfileApiAction.reducerPath] : AdminProfileApiReducer,
	[CustomerPortalOnboardApiAction.reducerPath] : CustomerPortalOnboardApiReducer,
});

export default rootReducers;
