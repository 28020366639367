export const uuid = () => {
	return Math.floor(Math.random() * 10000000);
};

export const getRiskProfileChipColor = (riskProfileId) => {
	let color = {
		bgColor: "",
		textColor: "",
	};

	if (riskProfileId === 1) {
		color.bgColor = "#FFEDED";
		color.textColor = "#EB5757";
	}
	if (riskProfileId === 2) {
		color.bgColor = "#FFF5E0";
		color.textColor = "#E58026";
	}
	if (riskProfileId === 3) {
		color.bgColor = "#DEFFEC";
		color.textColor = "#219653";
	}
	return color;
};

export const CheckWordType = ({ word, renderComponent }) => {
	const checkWordType = /^[aeiouAEIOU]/.test(word);
	return renderComponent(checkWordType);
  };


export const getSearchedFilterData = (data=[], searchKey='') =>{
	let filteredData = data;
	if(searchKey && Array.isArray(data)){
	  const regex = new RegExp(searchKey,'ig');
	  filteredData = data?.filter((item) => {
		let text = item?.name || '';
		if(text.search(regex) != -1){
		  return {
			...item,
			value: item.user_id,
		  };
		}
		return false;
	  })
	}
	return filteredData;
}

