import SvgIcon from "@mui/material/SvgIcon";

export default function SvgIconChildren(props) {
	return (
		<SvgIcon {...props}>
			{/* credit: plus icon from https://heroicons.com/ */}
			<svg
				width="34"
				height="34"
				viewBox="0 0 28 28"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect
					x="0.5"
					y="0.5"
					width="27"
					height="27"
					rx="13.5"
					fill="#FBFBFB"
				/>
				<rect
					x="0.5"
					y="0.5"
					width="27"
					height="27"
					rx="13.5"
					stroke="#676C76"
				/>
				<path
					d="M13.9281 15.8362C13.8115 15.8362 13.6948 15.7932 13.6027 15.7011L12.0308 14.1292C11.8527 13.9511 11.8527 13.6563 12.0308 13.4783C12.2088 13.3002 12.5036 13.3002 12.6816 13.4783L13.9281 14.7248L15.1746 13.4783C15.3527 13.3002 15.6474 13.3002 15.8255 13.4783C16.0036 13.6563 16.0036 13.9511 15.8255 14.1292L14.2536 15.7011C14.1615 15.7932 14.0448 15.8362 13.9281 15.8362Z"
					fill="#242424"
				/>
				<path
					d="M13.9278 15.7937C13.6761 15.7937 13.4673 15.5849 13.4673 15.3332V9.08846C13.4673 8.8367 13.6761 8.62793 13.9278 8.62793C14.1796 8.62793 14.3883 8.8367 14.3883 9.08846V15.3332C14.3883 15.5849 14.1796 15.7937 13.9278 15.7937Z"
					fill="#242424"
				/>
				<path
					d="M14.0017 19.4837C10.8394 19.4837 8.62891 17.2732 8.62891 14.1109C8.62891 13.8592 8.83768 13.6504 9.08943 13.6504C9.34119 13.6504 9.54996 13.8592 9.54996 14.1109C9.54996 16.7328 11.3798 18.5627 14.0017 18.5627C16.6236 18.5627 18.4535 16.7328 18.4535 14.1109C18.4535 13.8592 18.6622 13.6504 18.914 13.6504C19.1657 13.6504 19.3745 13.8592 19.3745 14.1109C19.3745 17.2732 17.164 19.4837 14.0017 19.4837Z"
					fill="#242424"
				/>
			</svg>
		</SvgIcon>
	);
}
