import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import CustomerProfile from "pages/Customer/CustomerProfile";

export const routes = {
  dashboard: ["/dashboard"],
  adminDashBoard: ["/admin/dashboard"],
  addCustomer: ["/add-customer"],
  customers: ["/customers", "/customer-detail", "/investment-summary"],
  trades: ["/trades"],
  reports: ["/reports"],
  riskProfile: ["/risk-profile"],
  plans: ["/plans", "/newplans"],
  familyMembers: ["/family-members"],
  dividend: ["/dividend"],
  customerDashboard: ["/customer/dashboard"],
  customerPortfolio: ["/customer/portfolio"],
  customerReports: ["/customer/reports"],
  customerProfile: ["customer/profile"],
  logout: ["/login"],
};

export const onboardingSteps = [
  {
    id: 1,
    label: "Customer details",
  },
  {
    id: 2,
    label: "Risk assesment",
  },
  {
    id: 3,
    label: "Investment details",
  },
  {
    id: 4,
    label: "Other details",
  },
];

export const customerOnBoardingSteps = [
  {
    id: 1,
    label: "Risk assesment",
  },

  {
    id: 2,
    label: "Other details",
  },
];

export const abc = [
  {
    id: 1,
    label: "Risk assesment",
  },
  {
    id: 2,
    label: "Other details",
  },
];

export const dummyTableData = [
  {
    id: 1,
    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 2,
    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 3,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 4,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 5,
    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 6,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 7,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 8,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 9,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
  {
    id: 10,

    stockName: "Tata Steels",
    quantity: 10,
    pricePerShare: "25.67",
    dateOfPurchase: new Date("2021-09-30"),
  },
];

export const columns = [
  {
    field: "stockName",
    headerName: "Stock Name",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
  },
  {
    field: "pricePerShare",
    headerName: "Price Per Share",
    flex: 1,
  },
  {
    field: "dateOfPurchase",
    headerName: "Date Of Purchase",
    flex: 1,
  },
];

export const tableBodyData = [
  {
    id: 1,
    type: "A-M-C",
    customerName: "Ankit Arora",
    contactDetail: "ankit@gmail.com",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Active",
    approve: "false",
  },
  {
    id: 2,
    type: "M-C",
    customerName: "Rishi",
    contactDetail: "rishi@gmail",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Active",
    approve: "false",
  },
  {
    id: 3,
    type: "C",
    customerName: "Aman",
    contactDetail: "Aman@gmail.com",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Active",
    approve: "true",
  },
  {
    id: 4,
    type: "M-C",
    customerName: "Anjali",
    contactDetail: "Anjali@gmail.com",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Inactive",
    approve: "true",
  },
  {
    id: 5,
    type: "A-M-C",
    customerName: "Akash",
    contactDetail: "Contact Detail",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Active",
    approve: "true",
  },
  {
    id: 6,
    type: "C",
    customerName: "Aman",
    contactDetail: "Aman@gmail.com",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Consevativer",
    autoTradeStatus: "Inactive",
    approve: "true",
  },
  {
    id: 7,
    type: "A-M",
    customerName: "Alka",
    contactDetail: "Alka@gmail.com",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Active",
    approve: "true",
  },
  {
    id: 8,
    type: "C",
    customerName: "Sakshi",
    contactDetail: "sakshi@gmail.com",
    capital: "₹50,00,000",
    leverage: "₹50,00,00",
    currentValue: "₹50,00,00",
    riskProfiles: "Moderate",
    autoTradeStatus: "Active",
    approve: "true",
  },
];

export const importTableBody = [
  {
    id: 1,
    type: "A-M-C",
    customerName: "Ankit Arora",
    contactDetail: "ankit@gmail.com",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "false",
  },
  {
    id: 2,
    type: "M-C",
    customerName: "Rishi",
    contactDetail: "rishi@gmail",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "false",
  },
  {
    id: 3,
    type: "C",
    customerName: "Aman",
    contactDetail: "Aman@gmail.com",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "true",
  },
  {
    id: 4,
    type: "M-C",
    customerName: "Anjali",
    contactDetail: "Anjali@gmail.com",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "true",
  },
  {
    id: 5,
    type: "A-M-C",
    customerName: "Akash",
    contactDetail: "Contact Detail",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "true",
  },
  {
    id: 6,
    type: "C",
    customerName: "Aman",
    contactDetail: "Aman@gmail.com",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "true",
  },
  {
    id: 7,
    type: "A-M",
    customerName: "Alka",
    contactDetail: "Alka@gmail.com",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "true",
  },
  {
    id: 8,
    type: "C",
    customerName: "Sakshi",
    contactDetail: "sakshi@gmail.com",
    capital: "₹50,00,000",
    plancode: "M_A8k_C4K_M1 (25L-50L)",
    autoTradeStatus: "",
    action: "",
    approve: "true",
  },
];
export const CustomerdummyTableData = [
  {
    id: 1,
    stockName: "TATA Power",
    category: "Model Portfolio",
    quantity: 10,
    orderType: "Market",
    ordertype: "Buy",
    price: "₹ 100.20",
    stopLoss: "₹ 95",
    targetSell: "₹ 120",
    action: "2 Boxes",
  },
  {
    id: 2,
    stockName: "TATA Power",
    category: "Model Portfolio",
    quantity: 10,
    orderType: "Market",
    ordertype: "Buy",
    price: "₹ 100.20",
    stopLoss: "₹ 95",
    targetSell: "₹ 120",
    action: "2 Boxes",
  },
  {
    id: 3,
    stockName: "TATA Power",
    category: "Model Portfolio",
    quantity: 10,
    orderType: "Market",
    ordertype: "Buy",
    price: "₹ 100.20",
    stopLoss: "₹ 95",
    targetSell: "₹ 120",
    action: "2 Boxes",
  },
  {
    id: 4,
    stockName: "TATA Power",
    category: "Model Portfolio",
    quantity: 10,
    orderType: "Market",
    ordertype: "Buy",
    price: "₹ 100.20",
    stopLoss: "₹ 95",
    targetSell: "₹ 120",
    action: "2 Boxes",
  },
  {
    id: 5,
    stockName: "TATA Power",
    category: "Model Portfolio",
    quantity: 10,
    orderType: "Market",
    ordertype: "Buy",
    price: "₹ 100.20",
    stopLoss: "₹ 95",
    targetSell: "₹ 120",
    action: "2 Boxes",
  },
  {
    id: 6,
    stockName: "TATA Power",
    category: "Model Portfolio",
    quantity: 10,
    orderType: "Market",
    ordertype: "Buy",
    price: "₹ 100.20",
    stopLoss: "₹ 95",
    targetSell: "₹ 120",
    action: "2 Boxes",
  },
];

export const customerColumns = [
  {
    field: "stockName",
    headerName: "Stock name",
    flex: 1,
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
  },
  {
    field: "orderType",
    headerName: "Order type",
    flex: 1,
  },
  {
    field: "ordertype",
    headerName: "Order type",
    flex: 1,
  },
  {
    field: "price",
    headerName: "Price",
    flex: 1,
  },
  {
    field: "stopLoss",
    headerName: "Stop loss",
    flex: 1,
  },
  {
    field: "targetSell",
    headerName: "Target (Sell)",
    flex: 1,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
  },
];

export const qtsAndOptions = [
  {
    qtsId: 1,
    qts: "What is your age?",
    options: [
      {
        optionId: 1,
        option: "Under 30 years",
      },
      {
        optionId: 2,
        option: "31-40 years",
      },
      {
        optionId: 3,
        option: "41-50 years",
      },
      {
        optionId: 4,
        option: "41-50 years",
      },
      {
        optionId: 5,
        option: "51-60 years",
      },
      {
        optionId: 6,
        option: "Above 60 years",
      },
    ],
  },
  {
    qtsId: 2,
    qts: "Assuming normal course of action, you expect your investments to :",
    options: [
      {
        optionId: 1,
        option: "Have stable capital and modest profit",
      },
      {
        optionId: 2,
        option: "No loss to capital at all",
      },
      {
        optionId: 3,
        option: "To generate more return than market",
      },
      {
        optionId: 4,
        option: "Match market returns",
      },
    ],
  },
  {
    qtsId: 3,
    qts: "You lost 25% of your money in equity investments when markets are down by 25% too :",
    options: [
      {
        optionId: 1,
        option: "Stop invesment",
      },
      {
        optionId: 2,
        option:
          "Continue with invesment plan regularly, as in long term you will earn back it all of it.",
      },
      {
        optionId: 3,
        option:
          "Increase invesment as you will earn more when conditions improve",
      },
    ],
  },
  {
    qtsId: 4,
    qts: "How easily can you adjust your lifestyle to adverse financial situations :",
    options: [
      {
        optionId: 1,
        option: "Very easily",
      },
      {
        optionId: 2,
        option: "Easily",
      },
      {
        optionId: 3,
        option: "I can manage",
      },
      {
        optionId: 4,
        option: "Not easy to adjust lifestyle",
      },
    ],
  },
  {
    qtsId: 5,
    qts: "Describe your time horizon :",
    options: [
      {
        optionId: 1,
        option: "Short term ( <3 Years)",
      },
      {
        optionId: 2,
        option: "Intermediate term (3-5 Years)",
      },
      {
        optionId: 3,
        option: "Medium term (5-10 Years)",
      },
      {
        optionId: 4,
        option: "Long term ( >10 Years)",
      },
    ],
  },
  {
    qtsId: 6,
    qts: "Describe your liquidity (Payout) needs from investment portfolio :",
    options: [
      {
        optionId: 1,
        option: "No liquidity needs",
      },
      {
        optionId: 2,
        option: "Regular cash flows",
      },
      {
        optionId: 3,
        option: "Very minimal",
      },
    ],
  },
  {
    qtsId: 7,
    qts: "What is your total investment experience?",
    options: [
      {
        optionId: 1,
        option: "No prior experience",
      },
      {
        optionId: 2,
        option: "0 to 3 Years",
      },
      {
        optionId: 3,
        option: "3 to 5 Years",
      },
      {
        optionId: 4,
        option: "More than 5 Years",
      },
    ],
  },
  {
    qtsId: 8,
    qts: "Which of the following asset classes do you have an investment experience in?",
    options: [
      {
        optionId: 1,
        option: "Debt",
      },
      {
        optionId: 2,
        option: "Mutual Funds",
      },
      {
        optionId: 3,
        option: "Equities",
      },
      {
        optionId: 4,
        option: "Venture capital / Private equity funds",
      },
      {
        optionId: 5,
        option: "Equity Derivaties",
      },
    ],
  },
  {
    qtsId: 9,
    qts: "What is your source of income?",
    options: [
      {
        optionId: 1,
        option: "Salary",
      },
      {
        optionId: 2,
        option: "Business",
      },
      {
        optionId: 3,
        option: "Agriculture",
      },
      {
        optionId: 4,
        option: "Invesment",
      },
    ],
  },
  {
    qtsId: 10,
    qts: "What is the market value of your total investment portfolio (Excluding any real estate assets you own?)",
    options: [
      {
        optionId: 1,
        option: "Less than ₹ 1 Crore",
      },
      {
        optionId: 2,
        option: "₹ 1 Crore - ₹ 5 Crores",
      },
      {
        optionId: 3,
        option: "₹ 5 Crores - ₹ 10 Crores",
      },
      {
        optionId: 4,
        option: "More than ₹ 10 crores",
      },
    ],
  },
  {
    qtsId: 11,
    qts: "Describe your knowledge in finance : :",
    options: [
      {
        optionId: 1,
        option: "Expert",
      },
      {
        optionId: 2,
        option: "Proficient",
      },
      {
        optionId: 3,
        option: "Limited to business media",
      },
      {
        optionId: 4,
        option: "Low",
      },
      {
        optionId: 5,
        option: "None",
      },
    ],
  },
  {
    qtsId: 12,
    qts: "You are financially responsible for (Exclude those supported by your spouse's income)",
    options: [
      {
        optionId: 1,
        option: "Only myself",
      },
      {
        optionId: 2,
        option: "1 person besides myself ",
      },
      {
        optionId: 3,
        option: "2-4 person besides myself",
      },
      {
        optionId: 4,
        option: "More than 4 persons",
      },
    ],
  },
  {
    qtsId: 13,
    qts: "What describes your attitude towards invesments :",
    options: [
      {
        optionId: 1,
        option:
          "I am willing to get less return but want my portfolio to be stable and low risk",
      },
      {
        optionId: 2,
        option:
          "I prefer diversified invesments that have low risk but selectively I can take risk",
      },
      {
        optionId: 3,
        option: "Moderate risk and balanced approch",
      },
      {
        optionId: 4,
        option:
          "I want high risk as long as I've potential to earn high return than market but I'm aware that there could be higher than market downside too.",
      },
    ],
  },
  {
    qtsId: 14,
    qts: "Invesment objective:",
    options: [
      {
        optionId: 1,
        option: "Building financial asset",
      },
      {
        optionId: 2,
        option: "Tax saving",
      },
      {
        optionId: 3,
        option: "Growth in invesment",
      },
      {
        optionId: 4,
        option: "Regular income",
      },
      {
        optionId: 5,
        option: "Retirement",
      },
      {
        optionId: 6,
        option: "Other",
      },
    ],
  },
  {
    qtsId: 15,
    qts: "Have you availed any loan from a bank or financial institution that is currently outstanding? If yes,kindly provide below details :",
    options: [
      {
        optionId: 1,
        option: "Short term ( <3 Years)",
      },
      {
        optionId: 2,
        option: "Intermediate term (3-5 Years)",
      },
      {
        optionId: 3,
        option: "Medium term (5-10 Years)",
      },
      {
        optionId: 4,
        option: "Long term ( >10 Years)",
      },
    ],
  },
];

export const InvestmentDetail = [
  {
    id: 1,
    stockName: "TATA POWER",
    currentNav: "₹ 22,000",
    WeekHigh: "₹ 22,000",
    WeekLoss: "₹ 22,000",
    oneYreturn: {
      value: "0.90%",
      change: "increase",
    },
    threeYreturn: {
      value2: "0.90%",
      change2: "increase",
    },
    fiveYreturn: {
      value3: "0.90%",
      change3: "increase",
    },
    ReturnSinceInception: {
      value4: "0.90%",
      change4: "increase",
    },
  },
  {
    id: 2,
    stockName: "HDFC",
    currentNav: "₹ 22,000",
    WeekHigh: "₹ 22,000",
    WeekLoss: "₹ 22,000",
    oneYreturn: {
      value: "0.90%",
      change: "Decrease",
    },
    threeYreturn: {
      value2: "0.90%",
      change2: "Decrease",
    },
    fiveYreturn: {
      value3: "0.90%",
      change3: "Decrease",
    },
    ReturnSinceInception: {
      value4: "0.90%",
      change4: "increase",
    },
  },
  {
    id: 3,
    stockName: "TATA Power",
    currentNav: "₹ 22,000",
    WeekHigh: "₹ 22,000",
    WeekLoss: "₹ 22,000",
    oneYreturn: {
      value: "0.90%",
      change: "increase",
    },
    threeYreturn: {
      value2: "0.90%",
      change2: "increase",
    },
    fiveYreturn: {
      value3: "0.90%",
      change3: "increase",
    },
    ReturnSinceInception: {
      value4: "0.90%",
      change4: "Decrease",
    },
  },
  {
    id: 4,
    stockName: "HDFC",
    currentNav: "₹ 22,000",
    WeekHigh: "₹ 22,000",
    WeekLoss: "₹ 22,000",
    oneYreturn: {
      value: "0.90%",
      change: "Decrease",
    },
    threeYreturn: {
      value2: "0.90%",
      change2: "Decrease",
    },
    fiveYreturn: {
      value3: "0.90%",
      change3: "Decrease",
    },
    ReturnSinceInception: {
      value4: "0.90%",
      change4: "increase",
    },
  },
  {
    id: 3,
    stockName: "HDFC",
    currentNav: "₹ 22,000",
    WeekHigh: "₹ 22,000",
    WeekLoss: "₹ 22,000",
    oneYreturn: {
      value: "0.90%",
      change: "increase",
    },
    threeYreturn: {
      value2: "0.90%",
      change2: "increase",
    },
    fiveYreturn: {
      value3: "0.90%",
      change3: "increase",
    },
    ReturnSinceInception: {
      value4: "0.90%",
      change4: "Decrease",
    },
  },
];

export const InvestmentAllocation = [
  {
    id: 1,
    stockName: "TATA POWER",
    currentValue: "₹ 22,000",
    percentage: "12%",
  },
  {
    id: 2,
    stockName: "HDFC",
    currentValue: "₹ 22,000",
    percentage: "12%",
  },
  {
    id: 3,
    stockName: "TATA POWER",
    currentValue: "₹ 22,000",
    percentage: "15%",
  },
  {
    id: 4,
    stockName: "HDFC",
    currentValue: "₹ 22,000",
    percentage: "12%",
  },
  {
    id: 5,
    stockName: "TATA POWER",
    currentValue: "₹ 22,000",
    percentage: "15%",
  },
];

export const GainDetail = [
  {
    id: 1,
    stockName: "TATA POWER",
    currentValue: "₹ 22,000",
    shortTerm: {
      value: "0.90%",
      change: "increase",
    },
    longTerm: {
      value: "0.90%",
      change: "increase",
    },
    shortTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    longTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    dividends: "₹ 22,000",
    action: "",
  },
  {
    id: 2,
    stockName: "HDFC",
    currentValue: "₹ 22,000",
    shortTerm: {
      value: "0.90%",
      change: "increase",
    },
    longTerm: {
      value: "0.90%",
      change: "increase",
    },
    shortTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    longTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    dividends: "₹ 22,000",
    action: "",
  },
  {
    id: 3,
    stockName: "TATA Power",
    currentValue: "₹ 22,000",
    shortTerm: {
      value: "0.90%",
      change: "increase",
    },
    longTerm: {
      value: "0.90%",
      change: "increase",
    },
    shortTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    longTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    dividends: "₹ 22,000",
    action: "",
  },
  {
    id: 4,
    stockName: "HDFC",
    currentValue: "₹ 22,000",
    shortTerm: {
      value: "0.90%",
      change: "increase",
    },
    longTerm: {
      value: "0.90%",
      change: "increase",
    },
    shortTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    longTermRealized: {
      value: "0.90%",
      change: "increase",
    },
    dividends: "₹ 22,000",
    action: "",
  },
  {
    id: 5,
    stockName: "HDFC",
    currentValue: "₹ 22,000",
    shortTerm: {
      value: "0.90%",
      change: "decraese",
    },
    longTerm: {
      value: "0.90%",
      change: "decraese",
    },
    shortTermRealized: {
      value: "0.90%",
      change: "decraese",
    },
    longTermRealized: {
      value: "0.90%",
      change: "decraese",
    },
    dividends: "₹ 22,000",
    action: "",
  },
];
export const SummaryDetail = [
  {
    id: 1,
    stockName: "TATA POWER",
    quality: "20",
    amountInvested: "₹ 2230.00",
    totalGains: {
      value: "₹ 230.20",
      change: "increase",
    },
    // totalGains: "₹ 230.20",
    amountAfterGains: "₹ 230.20",
    todaysChange: {
      value: "0.90%",
      change: "increase",
    },
    annualizedReturns: {
      value: "0.90%",
      change: "increase",
    },
    action: "",
  },
  {
    id: 2,
    stockName: "HDFC",
    quality: "20",
    amountInvested: "₹ 2230.00",
    totalGains: {
      value: "₹ 230.20",
      change: "increase",
    },
    amountAfterGains: "₹ 230.20",
    todaysChange: {
      value: "0.90%",
      change: "increase",
    },
    annualizedReturns: {
      value: "0.90%",
      change: "increase",
    },
    action: "",
  },
  {
    id: 3,
    stockName: "TATA Power",
    quality: "20",
    amountInvested: "₹ 2230.00",
    totalGains: {
      value: "₹ 230.20",
      change: "increase",
    },
    amountAfterGains: "₹ 230.20",
    todaysChange: {
      value: "0.90%",
      change: "decrease",
    },
    annualizedReturns: {
      value: "0.90%",
      change: "decrease",
    },
    action: "",
  },
  {
    id: 4,
    stockName: "HDFC",
    quality: "20",
    amountInvested: "₹ 2230.00",
    totalGains: {
      value: "₹ 230.20",
      change: "increase",
    },
    amountAfterGains: "₹ 230.20",
    todaysChange: {
      value: "0.90%",
      change: "decrease",
    },
    annualizedReturns: {
      value: "0.90%",
      change: "decrease",
    },
    action: "",
  },
  {
    id: 5,
    stockName: "HDFC",
    quality: "20",
    amountInvested: "₹ 2230.00",
    totalGains: {
      value: "₹ 230.20",
      change: "increase",
    },
    amountAfterGains: "₹ 230.20",
    todaysChange: {
      value: "0.90%",
      change: "decrease",
    },
    annualizedReturns: {
      value: "0.90%",
      change: "decrease",
    },
    action: "",
  },
  {
    id: 6,
    stockName: "HDFC",
    quality: "20",
    amountInvested: "₹ 2230.00",
    totalGains: {
      value: "₹ 230.20",
      change: "increase",
    },
    amountAfterGains: "₹ 230.20",
    todaysChange: {
      value: "0.90%",
      change: "decrease",
    },
    annualizedReturns: "0.90%",
    action: "",
  },
];
export const riskCardData = [
  {
    id: 1,
    name: "Aggressive risk profile ",
    chart: "/images/aggressive.svg",
    des: " You are a high risk-taker, so your portfolio will be volatile in the short term and expected to deliver high returns. ",
    xs: 3,
    nameColor: "#EB5757",
  },
  {
    id: 2,
    name: "Moderate risk profile ",
    chart: "/images/moderate.svg",
    des: " You are a moderate risk-taker, so your portfolio will be less volatile and expected to deliver returns with some degree of risk and volatility without compromising on stability. ",
    xs: 3,
    nameColor: "#F2994A",
  },
  {
    id: 3,
    name: "Conservative risk profile ",
    chart: "/images/conservative.svg",
    des: " You are a conservative investor, so your portfolio will be relatively less volatile and expected to deliver returns that are stable with low volatility and yet protect your capital investment.",
    xs: 3,
    nameColor: "#219653",
  },
];

export const FamilyMemberTabledata = [
  {
    id: 1,
    name: "Pawar Family",
    membersname: ["Amit Shah", "Jay Shah", "Amit Shah", "Jay Shah"],
  },
  {
    id: 2,
    name: "More Family",
    membersname: ["Amit Shah", "Jay Shah", "Amit Shah", "Jay Shah"],
  },
  {
    id: 3,
    name: "Desai Family",
    membersname: ["Amit Shah", "Jay Shah", "Amit Shah", "Jay Shah"],
  },
  {
    id: 4,
    name: "Shah Family",
    membersname: ["Amit Shah", "Jay Shah", "Amit Shah", "Jay Shah"],
  },
];

export const warningTableRows = [
  {
    id: 1,
    name: "Rishi",
    product_id: 1,
    capital: "20,000",
    cash: "5000",
  },
  {
    id: 2,
    name: "Jeetu",
    product_id: 1,
    capital: "10,000",
    cash: "2000",
  },
  {
    id: 3,
    name: "Rishikesh",
    product_id: 1,
    capital: "15,000",
    cash: "7000",
  },
];

export const warningTableColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "capital",
    headerName: "Capital",
    flex: 1,
  },
  {
    field: "cash",
    headerName: "Cash",
    flex: 1,
  },
];

export const modelTableRows = [
  {
    id: 1,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 2,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 3,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 4,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 5,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 6,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 7,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 8,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 9,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
  {
    id: 10,
    stockName: "TATA Power",
    customerName: "Jeetu Gupta",
    quantity: 20,
    amountInvested: "2230.00",
    totalGain: "230.00",
    amountAfterGains: "2230.00",
    todaysChange: "0.90",
    tax: "12",
  },
];

export const modelImportTableRows = [
  {
    id: 1,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
  {
    id: 2,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
  {
    id: 3,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
  {
    id: 4,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
  {
    id: 5,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
  {
    id: 6,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
  {
    id: 7,
    stockName: "TATA Power",
    customerName: "Model Portfolio",
    quantity: 10,
    index: "Market",
    stopLoss: "Buy",
    target: "₹ 100.20",
    amountToInvest: "₹ 95",
  },
];

export const CustomerModelTableRows = [
  {
    id: 1,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
  {
    id: 2,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
  {
    id: 3,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
  {
    id: 4,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
  {
    id: 5,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
  {
    id: 6,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
  {
    id: 7,
    stockName: "TATA Power",
    quantity: 20,
    amountInvested: "₹ 2230.00",
    totalGain: "₹ 230.00",
    amountAfterGains: "₹ 2230.00",
    todaysChange: "0.90",
    annualizedReturns: "0.90",
  },
];

export const customerModelColumns = [
  {
    field: "stockName",
    headerName: "Stock Name",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
  },
  {
    field: "amountInvested",
    headerName: "Amount Invested",
    flex: 1,
  },
  {
    field: "totalGain",
    headerName: "Total gains",
    flex: 1,
  },
  {
    field: "amountAfterGains",
    headerName: "Amount after gains",
    flex: 1,
  },
  {
    field: "todaysChange",
    headerName: "Today's change",
    flex: 1,
  },
  {
    field: "annualizedReturns",
    headerName: "Annualized returns",
    flex: 1,
  },
];

export const CustomerCustomTableRows = [
  {
    id: 1,
    stockName: "TATA Power",
    buyDate: "Sept 23, 2023",
    sellDate: "-",
    amountInvested: "₹ 2230.00",
    profit: "₹ 2230.00 (12%)",
    amountAfterProfit: "₹ 2230.00",
  },
  {
    id: 2,
    stockName: "HDFC Bank",
    buyDate: "Sept 23, 2023",
    sellDate: "Sept 23, 2023",
    amountInvested: "₹ 2230.00",
    profit: "₹ 2230.00 (12%)",
    amountAfterProfit: "₹ 2230.00",
  },
  {
    id: 3,
    stockName: "Vedanta Limited",
    buyDate: "Sept 23, 2023",
    sellDate: "-",
    amountInvested: "₹ 2230.00",
    profit: "₹ 2230.00 (12%)",
    amountAfterProfit: "₹ 2230.00",
  },
  {
    id: 4,
    stockName: "TATA Power",
    buyDate: "Sept 23, 2023",
    sellDate: "-",
    amountInvested: "₹ 2230.00",
    profit: "₹ 2230.00 (12%)",
    amountAfterProfit: "₹ 2230.00",
  },
  {
    id: 5,
    stockName: "RELIANCE",
    buyDate: "Sept 23, 2023",
    sellDate: "Sept 23, 2023",
    amountInvested: "₹ 2230.00",
    profit: "₹ 2230.00 (12%)",
    amountAfterProfit: "₹ 2230.00",
  },
  {
    id: 6,
    stockName: "Amaraja Batteries",
    buyDate: "Sept 23, 2023",
    sellDate: "-",
    amountInvested: "₹ 2230.00",
    profit: "₹ 2230.00 (12%)",
    amountAfterProfit: "₹ 2230.00",
  },
];

export const customerCustomColumns = [
  {
    field: "stockName",
    headerName: "Stock Name",
    flex: 1,
  },
  {
    field: "buyDate",
    headerName: "Buy Date",
    flex: 1,
  },
  {
    field: "sellDate",
    headerName: "Sell Date",
    flex: 1,
  },
  {
    field: "amountInvested",
    headerName: "Amount invested",
    flex: 1,
  },
  {
    field: "profit",
    headerName: "Profit",
    flex: 1,
  },
  {
    field: "amountAfterProfit",
    headerName: "Amount after profit",
    flex: 1,
  },
];

export const CustomerAlgoTableRows = [
  {
    id: 1,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 2,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 3,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 4,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 5,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 6,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 7,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
  {
    id: 8,
    stockName: "TATA Power",
    date: "Sept 23,2023",
    amountInvested: "₹ 2230.00",
    quantity: 20,
    lotSize: 20,
    buyTime: "02:34 PM",
    sellTime: "02:34 PM",
    profit: "₹ 230.20 (12%)",
  },
];

export const customerAlgoColumns = [
  {
    field: "stockName",
    headerName: "Stock Name",
    flex: 1,
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
  },
  {
    field: "amountInvested",
    headerName: "Amount Invesment",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
  },
  {
    field: "lotSize",
    headerName: "Lot size",
    flex: 1,
  },
  {
    field: "buyTime",
    headerName: "Buy time",
    flex: 1,
  },
  {
    field: "sellTime",
    headerName: "Sell time",
    flex: 1,
  },
  {
    field: "profit",
    headerName: "Profit",
    flex: 1,
  },
];

export const CustomerReportsdummyTableData = [
  {
    id: 1,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
  {
    id: 2,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
  {
    id: 3,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
  {
    id: 4,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
  {
    id: 5,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
  {
    id: 6,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
  {
    id: 7,
    stockName: "TATA Power",
    dateOfExecution: "Sept 23, 2023",
    quantity: 30,
    orderType: "Market",
    buyPrice: "₹ 100.20",
    sellPrice: "₹ 100.20",
    drawdown: "12%",
    profit: "₹ 100.20",
  },
];
export const modelTableColumns = [
  {
    field: "stockName",
    headerName: "Stock Name",
    flex: 1,
  },
  {
    field: "customerName",
    headerName: "Customer name",
    flex: 1,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    flex: 1,
  },
  {
    field: "amountToInvest",
    headerName: "Amount Invested",
    flex: 1,
  },
  {
    field: "totalGains",
    headerName: "Total gains",
    flex: 1,
  },
  {
    field: "amountAfterGains",
    headerName: "Amount after gains",
    flex: 1,
  },
  {
    field: "todaysChange",
    headerName: "Today's change",
    flex: 1,
  },
  {
    field: "taxPl",
    headerName: "Tax P/L",
    flex: 1,
  },
];

export const reportTable1 = [
  {
    id: 1,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },
  {
    id: 2,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },

  {
    id: 3,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },

  {
    id: 4,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },

  {
    id: 5,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },

  {
    id: 6,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },

  {
    id: 7,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },

  {
    id: 8,
    customerName: "Ankit Arora",
    totalTrades: "30",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    totalProfit: "100.20",
    tax: "12%",
    drawDown: "12%",
  },
];

export const reportTable2 = [
  {
    id: 1,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 2,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 3,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 4,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 5,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 6,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 7,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
  {
    id: 8,
    stockName: "TATA Power",
    totalQuantity: "100",
    amountInvested: "₹ 4,00,000",
    unrealised: "₹ 10,000.20",
    marketValue: "₹4,10,000.20",
  },
];

export const reportTable3 = [
  {
    id: 1,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 2,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 3,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 4,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 5,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 6,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 7,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
  {
    id: 8,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    holdings: "20",
    insNumber: "ABCD123456",
    scriptName: "Lorem ipsum",
    totalQuantity: "100",
    purchaseDate: "Sept 23,2023",
  },
];

export const reportTable4 = [
  {
    id: 1,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 2,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 3,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 4,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 5,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 6,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 7,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
  {
    id: 8,
    clientName: "Ankit Arora",
    clientCode: "ABCD123456",
    report: "Sept 23,2023",
    particulars: "20",
    amount: "₹4,00,000",
    openingBalance: "₹4,00,000",
    deposit: "₹4,00,000",
    sales: "₹4,00,000",
    same: "₹2,00,000",
    fno: "₹2,00,000",
    open: "₹4,00,000",
    total: "₹4,00,000",
    payout: "₹4,00,000",
    purchase: "₹4,00,000",
    sameYearLoss: "₹2,00,000",
    cashExp: "₹4,00,000",
    fnoLoss: "₹2,00,000",
    dpCharges: "₹4,00,000",
    otherCharges: "₹4,00,000",
    openPositionOption: "₹4,00,000",
    closingBalance: "₹4,00,000",
    difference: "₹4,00,000",
  },
];

export const reportTable5 = [
  {
    id: 1,
    clientName: "Ankit Arora",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 2,
    clientName: "Rishi Mehta",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 3,
    clientName: "Manish Gupta",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 4,
    clientName: "Jeetu sharma",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 5,
    clientName: "Raj Tiwari",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 6,
    clientName: "Pratik More",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 7,
    clientName: "Santosh Gupta",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
  {
    id: 8,
    clientName: "Prem Chavan",
    uccFrom: "-",
    uccTo: "-",
    reportGenerationDate: "Sept 23,2023",
    segment: "NSE-Cash",
    tradeDate: "Sept 23,2023",
    scriptInstrument: "-",
    insNumber: "ABCD1234",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹2,00,000",
    netRate: "₹2,00,000",
    amount: "₹2,00,000",
    netPosition: "-",
    segment: "NSE-Fno",
    tradeDate: "Sept 23,2023",
    scriptInstrument1: "-",
    yourBuyQty: "20",
    yourBuyRate: "₹2,00,000",
    yourSaleQty: "20",
    yourSaleRate: "₹4,00,000",
  },
];

export const reportTable6 = [
  {
    id: 1,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 2,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 3,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 4,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 5,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 6,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 7,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 8,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
];

export const reportTable7 = [
  {
    id: 1,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 2,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 3,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 4,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 5,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 6,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 7,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
  {
    id: 8,
    clientName: "Ankit Arora",
    clientCode: "ABCD12345",
    fromDate: "Sept 23,2023",
    toDate: "Sept 23,2023",
    instrumentName: "lorem-ipsum",
    tradeDate: "Sept 23,2023",
    openQuantity: "20",
    openingRate: "₹ 2,00,000",
    boughtQuantity: "20",
    boughtRateActualRate: "₹ 2000 / ₹ 4000  ",
    tradeDate: "Sept 23,2023",
    saleQuantity: "20",
    saleRateActualSaleRate: "₹ 2000 / ₹ 4000",
    netCloseQuantity: "20/40",
    closingRate: "₹ 2,00,000",
    totalTurnover: "₹ 2,00,000",
    debitamount: "₹ 2,00,000",
    creditAmount: "₹ 2,00,000",
    profitLoss: "₹ 2,00,000",
    future: "₹ 2,00,000",
    futuresProfitLossA: "₹ 2,00,000",
    options: "₹4,00,000",
    optionsProfitLossB: "₹ 2,00,000",
    grossProfitLoss: "₹ 2,00,000",
    expenses: "₹ 2,00,000",
    brokerage: "₹ 2,00,000",
    clearingCharges: "₹ 2,00,000",
    exchangeTransactionCharges: "₹ 2,00,000",
    cgst: "18%",
    sgst: "18%",
    integratedGst: "18%",
    stt: "18%",
    sebiTurnoverFees: "18%",
    stampDuty: "₹ 2,00,000",
    ipft: "18%",
    totalAllExpD: "₹ 2,00,000",
    netProfitLoss: "₹ 2,00,000",
    openPositions: "18%",
  },
];

export const DashboardAdminTable = [
  {
    id: 1,
    name: "Ankit Arora",
    userType: "RIA",
    emailAddress: "ankitarora@gmail.com",
    phoneNumber: "88282818181",
    status: "active",
  },
  {
    id: 2,
    name: "Rishi Mehta",
    userType: "RIA",
    emailAddress: "ankitarora@gmail.com",
    phoneNumber: "88282818181",
    status: "active",
  },
  {
    id: 3,
    name: "Rishi Mehta",
    userType: "RIA",
    emailAddress: "ankitarora@gmail.com",
    phoneNumber: "88282818181",
    status: "deactive",
  },
];
