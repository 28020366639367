import React from 'react'
import CustomerProfileContainer from 'containers/Customer/CustomerProfileContainer'

function CustomerProfile() {
  return (
    <>
    <CustomerProfileContainer/>
    
    </>
  )
}

export default CustomerProfile
