import React from "react";
import { useSelector } from "react-redux";
import Stepper from "components/common/Stepper";
import { onboardingSteps } from "helpers/constants";
import Stack from "components/common/Stack";
import Summary from "components/addCustomer/Summary";
import { useLocation } from "react-router-dom";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";

function SummaryContainer({ handleStepChange }) {
	const location = useLocation();
	//hooks
	const customerRiskDetails = useSelector((state) => state.addCustomerSlice.customerDetails);

	//Apis
	const [getCustomerDetails, customerSummary] = addCustomerApiAction.getCustomerSummary();
	

	console.log("customerSummary : ", customerSummary);

	const onBoardData = location.state && location.state.onBoardData;	 
	React.useEffect(() => {
		if (onBoardData) {
		  getCustomerDetails({userId:onBoardData?.user_id}, false);
		} else if (customerRiskDetails.userId) {
		  getCustomerDetails({ userId: customerRiskDetails.userId }, false);
		}
	  }, [onBoardData, customerRiskDetails.userId]);

	return (
		<>
			<Summary handleStepChange={handleStepChange} customerSummary={customerSummary?.data} />
		</>
	);
}

export default SummaryContainer;
