import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";
// import { dividendParser } from "../apiParser/dividendParser";
import { addCustomerParser } from "../apiParser/addCustomerParser";
const CustomerOnboard = createApi({
  reducerPath: "CustomerOnboard",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: ["ADD_CUSTOMER_PORTAL"
  ],
  endpoints: (qb) => ({
    // getRiskAssesmentQuestion: builder.query({
    //     query: () => "api/customer/risk-assessment",
    //     transformResponse: response.result,
    //     providesTags: ["ADD_CUSTOMER"],
    // }),

    getRiskAssesmentQuestion: qb.query({
        query: () => {
          return `api/customer/risk-assessment`; // Adjust the endpoint as needed
        },
        transformResponse: addCustomerParser.riskAssesmentQts,
        providesTags: ["ADD_CUSTOMER_PORTAL"],
      }),
  }),
});

export const CustomerPortalOnboardApiReducer = CustomerOnboard.reducer;

export const CustomerPortalOnboardApiAction = {
  middleware: CustomerOnboard.middleware,
  reducerPath: CustomerOnboard.reducerPath,
  getRiskAssesmentQuestion:CustomerOnboard.useGetRiskAssesmentQuestionQuery
};
