import Answers from "components/Answers";
import Questions from "components/Questions";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import { Typography } from "@mui/material";

const CustomerRiskDetails = ({
	handleAnswers,
	handleSubmit,
	riskAssesmentQts,
	handlePreviousStep,
	currentQtsId,
	totalQts,
	riskPoints,
	riskDetailsAnswer,
	JumpToPrevQuestion
}) => {

	const currentOptions = riskAssesmentQts?.options?.find((item) => {
		if (item.questionId === currentQtsId) {
			return item;
		}
		return false;
	});

	console.log("currentOptionsss",currentOptions);

	return (
		<Box>
			<Grid container sx={{backgroundColor:"#F7F8FF"}}>
				<Grid item xs={12} sx={{marginLeft:"60px",marginBottom:"10px"}}>
					<Typography sx={{color:"#242424",fontSize:"24px",fontWeight:500}}>Risk Assesment</Typography>
					<Typography sx={{color:"#676C76",fontSize:"16px",fontWeight:400}}>Answer the following questions to know whether you are aggressive, moderate or conservative risk taker.</Typography>
				</Grid>
			
				<Grid item xs={6}>
					<Stack justifyContent="center" alignItems="center" height="100%">
						<Questions 
							hasInputText={currentOptions?.is_text} 
							currentQtsId={currentQtsId} 
							qts={riskAssesmentQts?.qts} 
							JumpToPrevQuestion={JumpToPrevQuestion}
							riskDetailsAnswer={riskDetailsAnswer}
							/>
					</Stack>
				</Grid>

				<Grid item xs={6}>
					<Stack justifyContent="center" alignItems="center" height="100%">
						<Answers
							hasInputText={currentOptions?.is_text} 
							qtsOptions={currentOptions}
							handleAnswers={handleAnswers}
							handlePreviousStep={handlePreviousStep}
							handleSubmit={handleSubmit}
							totalQts={totalQts}
							currentQtsId={currentQtsId}
							riskPoints={riskPoints}
							riskDetailsAnswer={riskDetailsAnswer}
						/>
					</Stack>
				</Grid>
			</Grid>
		</Box>
	);
};

export default CustomerRiskDetails;
