export const fileToBase64 = (file) => {
    if (file) {
      return new Promise((resolve) => {
        let baseURL = "";
  
        let reader = new FileReader();
  
        reader.readAsDataURL(file);
  
        reader.onload = () => {
          baseURL = reader.result.substr(reader.result.indexOf(",") + 1);
  
          resolve(baseURL);
        };
      });
    }
    return null;
  };

  export const clickAndDownloadFile = async ({base64File, fileName}) => {
    const base64 = await fetch(base64File);
    const blob = await base64.blob();
    const a = document.createElement("a");
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
  }
