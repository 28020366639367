import React, { useEffect, useState } from "react";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import TextField from "components/common/TextField";
import Text from "components/common/Text";
import CustomerFamily from "./CustomerFamily";
import { CustomerPortfolioApiAction } from "stores/redux/apiSlices/CustomerPortfolio/portfolioApiSlice";
import CustomerTableTab from "./PerformanceTable/CustomerTableTab";
import SideDrawer from "components/common/SideDrawer";
import DatePicker from "components/common/DatePicker";
import { useSelector } from "react-redux";

const ModelFilterDrawer = () => {
  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Box sx={{ flex: 1 }}>
            <Text>Profit range (%)</Text>
            <TextField placeholder="Eg. 5%" />
          </Box>
          <TextField sx={{ flex: 1 }} placeholder="Eg. 10%" />
        </Stack>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <Box sx={{ flex: 1 }}>
            <Text>Date range</Text>
            <DatePicker placeholder="Select Date" />
          </Box>
          <DatePicker sx={{ flex: 1 }} placeholder="Select Date" />
        </Stack>
      </Stack>
    </>
  );
};

const CustomerPortFolioSection = ({ productId, tradeType }) => {
  const userDetails = useSelector((state) => state.commonReducer.userDetails);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [showTableTab, setShowTableTab] = useState(false);
  const [memberID, setMemberID] = useState(userDetails?.user_id);
  const payload = {
    userId: userDetails.user_id,
    productId: productId,
  };
  const { data: familyList = {}, refetch: refetchFamilyMemberData } =
    CustomerPortfolioApiAction.getFamilyMemberApi(payload);

  const handleFilterDrawer = () => {
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  useEffect(() => {
    refetchFamilyMemberData(payload);
  }, [productId]);

  const handleButtonClick = (memberId) => {
    setShowTableTab(true);
    setMemberID(memberId);
    // setSelectedId(customerId);
    // setCustomerId(customerId);
  };

  const handleButtonAll = () => {
    setMemberID(null);
    // const firstCustomerId = userDetails.user_id;
    // setSelectedId(null);
    // setCustomerId(firstCustomerId);
  };

  const cardData = [
    {
      id: 1,
      name:
        tradeType === "MODEL_PORTFOLIO"
          ? "Model Portfolio"
          : tradeType === "CUSTOM"
          ? "Custom"
          : tradeType === "ALGO"
          ? "Algo"
          : "",
      detail:
        tradeType === "MODEL_PORTFOLIO"
          ? `Here you can view Model Portfolio performance of all the family member`
          : tradeType === "CUSTOM"
          ? `Here you can view Custom performance of all the family member`
          : tradeType === "ALGO"
          ? `Here you can view Algo performance of all the family member`
          : "",
    },
  ];

  return (
    <div>
      <SideDrawer
        open={isFilterDrawerOpen}
        closeDrawer={handleFilterDrawer}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        handleSubmit={handleFilterDrawer}
        cancelButtonText="Close"
        submitButtonText="submit"
        drawerWidth={500}
      >
        <ModelFilterDrawer />
      </SideDrawer>
      <Stack marginTop="15px">
        <Grid sx={{ mb: 3 }}>
          {cardData.map((element, index) => (
            <Grid item xs={element.xs} key={`cards_index_${element.id}`}>
              <CustomerFamily
                selectedId={memberID}
                data={element}
                familyList={familyList}
                handleFilterDrawer={handleFilterDrawer}
                handleButtonClick={handleButtonClick}
                handleButtonAll={handleButtonAll}
                memberID={memberID}
                is_admin={userDetails.is_admin}
              />
            </Grid>
          ))}
        </Grid>
        <CustomerTableTab
          productId={productId}
          memberID={memberID}
          familyList={familyList}
          //   blockID={blockID}
          showTableTab={showTableTab}
          customerId={userDetails.user_id}
        />
      </Stack>
    </div>
  );
};

export default CustomerPortFolioSection;
