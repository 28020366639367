import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import SideDrawer from "components/common/SideDrawer";
import AddRia from "components/DashboardAdmin/AddRia";
import CustomGrid from "components/datagrid/CustomGrid";
import TradeCard from "components/dashboard/TradeCard";
import AddFilters from "components/DashboardAdmin/AddFilters";
import TableDrawer from "components/DashboardAdmin/TableDrawer";
import { fileToBase64 } from "helpers/fileFormat";
import FilterIcon from "asset/icons/FilterIcon";
import Chip from "@mui/material/Chip";
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { adminDashBoardApiAction } from "stores/redux/apiSlices/admin/adminDashBoardApiSlice";
import debounce from "lodash.debounce";
import { toastActions } from "stores/redux/slices/toastSlice";
import { validationHelper } from "helpers/validation";
import useDebounce from "hooks/Debounce";
import { useSelector } from "react-redux";
import AdminDashBoardHeader from "components/DashboardAdmin/AdminDashBoardHeader";

// Styled Components
const CustomerChip = styled(Chip)(() => ({
  marginLeft: "5px",
  height: "25px",
  width: "100%",
  textAlign: "center",
  marginTop: "3px",
}));

const ActiveChip = styled(Chip)(() => ({
  backgroundColor: "#DEFFEC",
  color: "#219653",
  borderRadius: "152px",
  width: "90px",
  height: "24px",
}));

const InactiveChip = styled(Chip)(() => ({
  backgroundColor: "#FFEDED",
  color: "#EB5757",
  borderRadius: "152px",
  width: "90px",
  height: "24px",
}));

const AdminTableRowNames = styled(Typography)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  cursor: "pointer",
}));

const AddFilterBtn = styled(Button)(() => ({
  fontWeight: 500,
  borderRadius: "8px",
  borderColor: "#D0D5DD",
  padding: "8px",
  marginTop: "16px",
  marginRight: "8px",
}));

const DashboardAdminContainer = (props) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isAddRiaDrawerOpen, setisAddRiaDrawerOpen] = useState(false);
  const [isAddFilterDrawerOpen, setisAddFilterDrawerOpen] = useState(false);
  const [isTableDrawerOpen, setisTableDrawerOpen] = useState(false);
  const [dateRangeResponse, setDateRangeResponse] = useState(false);
  const [riaID, setRiaID] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState(""); // Add this line to define the search value state
  const [selectedStatus, setSelectedStatus] = useState(""); // Initialize with an empty string
  const [button, setButton] = useState(null);
  const [riaStatus, setRiaStatus] = useState(null);
  const [searchQueryList, setSearchQueryList] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const userDetails = useSelector((state) => state.commonReducer.userDetails); // Taking the data From Store
  console.log(userDetails, "ADMIN");

  const [formData, setFormData] = useState({
    riaName1: "",
    riaName2: "",
    emailAddress: "",
    phoneNumber: "",
    sebiRegistrationNumber: "",
    baslNumber: "",
    expiryDate: null,
    sebiRegistration: {},
    baslMembership: {},
    investmentAdvisoryExam1: {},
    investmentAdvisoryExam2: {},
    userType:"",
  });

  const [formError, setFormError] = useState({
    riaName1: "",
    riaName2: "",
    emailAddress: "",
    phoneNumber: "",
    sebiRegistrationNumber: "",
    baslNumber: "",
    expiryDate: null,
    sebiRegistration: [],
    baslMembership: [],
    investmentAdvisoryExam1: [],
    investmentAdvisoryExam2: [],
  });

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const debouncedSearchQueryList = useDebounce(searchQueryList, 500);
  // CustomGrid table Api Call
  const { data: riaTableData = {}, refetch: refetchRiaTableData } =
    adminDashBoardApiAction.getRiaTable({
      status: selectedStatus,
      page: paginationModel.page + 1,
      take: paginationModel.pageSize,
      searchQueryList: debouncedSearchQueryList,
    });

  const data = riaTableData?.result;
  // console.log(data, "riaTableData");

  const [handleSelectDateRange] = adminDashBoardApiAction.selectDateRange(); // DatePicker Api Call
  const [handleAddRiaApi] = adminDashBoardApiAction.addRiaUserApi(); // Add ria api calling (post)
  const [handleRiaStatusApi] = adminDashBoardApiAction.riaUpdateStatus(); // Status update api call

  const [singleRia, singleRiaData] = adminDashBoardApiAction.getSingleRia(); // here we are calling the API
  const singleDetail = singleRiaData;
  // console.log(singleRiaData, "SINGLERIADATA");

  useEffect(() => {}, [singleRiaData]);

  useEffect(() => {
    refetchRiaTableData({
      status: selectedStatus,
      page: paginationModel.page + 1,
      take: paginationModel.pageSize,
      searchQueryList: debouncedSearchQueryList,
    });
  }, []);
  useEffect(() => {
    if (riaID !== null) {
      singleRia(riaID);
    }
  }, [riaID]);

  useEffect(() => {
    if (status === "Y") {
      setButton(false);
    } else if (status === "N") {
      setButton(true);
    }
  }, [status]);

  useEffect(() => {
    if (button === true) {
      setRiaStatus("Y");
    }
    if (button === false) {
      setRiaStatus("N");
    }
  }, [button]);

  const debouncedHandleDateRangeChange = debounce(
    async (newDateRange, handleSelectDateRange) => {
      // Update the state with the new date range
      setStartDate(newDateRange[0]);
      setEndDate(newDateRange[1]);

      // Format the dates in the required format for the API payload
      const startDateFormatted = newDateRange[0]
        ? dayjs(newDateRange[0]).format("YYYY-MM-DD")
        : dayjs().subtract(1, "month").format("YYYY-MM-DD");
      const endDateFormatted = newDateRange[1]
        ? dayjs(newDateRange[1]).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD");

      // Prepare the payload for API call
      const payload = {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };

      try {
        // Call your API with the payload
        const response = await handleSelectDateRange(payload);
        // console.log("API Response:", response.data);
        setDateRangeResponse(response.data);
      } catch (error) {
        console.error("Error occurred during API call:", error);
      }
    },
    300
  ); // Debounce for 300 milliseconds

  // Use the debounced function as the event handler
  const handleDateRangeChange = useCallback(
    (newDateRange) => {
      console.log(
        "handleDateRangeChange called with date range:",
        newDateRange
      );
      debouncedHandleDateRangeChange(newDateRange, handleSelectDateRange);
    },
    [handleSelectDateRange]
  );

  // below is for kpi cards
  const cardData = [
    {
      id: 1,
      name: "Total number of RIA’s",
      value: dateRangeResponse.total_ria,
      chart: "/images/ProfitChart.svg",
      display: "column",
      xs: 4,
    },
    {
      id: 2,
      name: "Active RIA’s",
      value: dateRangeResponse.active,
      // percentage: `${dashboardData?.totalprofitpercent} %`,
      chart: "/images/ProfitChart.svg",
      display: "flex",
      xs: 4,
    },
    {
      id: 3,
      name: "Inactive RIA’s",
      value: dateRangeResponse.inactive,
      // percentage: `${dashboardData?.taxplpercent} %`,
      chart: "/images/ProfitChart.svg",
      xs: 4,
    },
  ];

  const handleAddRiaDrawer = () => {
    setisAddRiaDrawerOpen(!isAddRiaDrawerOpen);
  };
  const handleAddFilterDrawer = () => {
    setisAddFilterDrawerOpen(!isAddFilterDrawerOpen);
  };
  const handleTableDrawer = (value, status) => {
    let riaId1 = value;
    setRiaID(riaId1);
    setStatus(status);
    setisTableDrawerOpen(!isTableDrawerOpen);
  };

  const handleAddRiaDrawerClose = () => {
    setFormData({
      riaName1: null,
      riaName2: null,
      emailAddress: null,
      phoneNumber: null,
      sebiRegistrationNumber: null,
      baslNumber: null,
      expiryDate: null,
      sebiRegistration: null,
      baslMembership: null,
      investmentAdvisoryExam1: null,
      investmentAdvisoryExam2: null,
      userType:null,
    });

    setisAddRiaDrawerOpen(false);
  };
  const handleAddFilterDrawerClose = () => {
    setisAddFilterDrawerOpen(false);
  };

  const handleTableDrawerClose = async () => {
    const userId = riaID; // Replace this with your userId value
    // Prepare the payload with status
    const payload = {
      status: riaStatus,
    };
    try {
      // Call the API function with the payload
      const res = await handleRiaStatusApi({ status: payload.status, userId });
      setisTableDrawerOpen(false); // Close the table drawer
      refetchRiaTableData();
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  const handleChange = ({ name, value }) => {
    let newFormData = { ...formData };
    newFormData[name] = value;
    setFormData(newFormData);
  };

  const handleSearchInputChangeList = (event) => {
    setSearchQueryList(event.target.value);
  };

  const handleValidation = () => {
    const newFormError = { ...formError };

    const riaNameValidation = validationHelper.required(formData.riaName1);
    newFormError.riaName1 = riaNameValidation.message;

    const emailValidation = validationHelper.email(formData.emailAddress);
    newFormError.emailAddress = emailValidation.message;

    const phoneValidation = validationHelper.mobile(formData.phoneNumber);
    newFormError.phoneNumber = phoneValidation.message;

    const sebiRegValidation = validationHelper.required(
      formData.sebiRegistrationNumber
    );
    newFormError.sebiRegistrationNumber = sebiRegValidation.message;

    const baslNoValidation = validationHelper.required(formData.baslNumber);
    newFormError.baslNumber = baslNoValidation.message;

    const expiryDateValidation = validationHelper.required(formData.expiryDate);
    newFormError.expiryDate = expiryDateValidation.message;

    const sebiRegFileValidation = formData?.sebiRegistration?.length > 0;
    newFormError.sebiRegistration = sebiRegFileValidation
      ? ""
      : "This field is required";

    const baslFileValidation = formData?.baslMembership?.length > 0;
    newFormError.baslMembership = baslFileValidation
      ? ""
      : "This field is required";

    const invesmentAdvExam1Validation =
      formData?.investmentAdvisoryExam1?.length > 0;
    newFormError.investmentAdvisoryExam1 = invesmentAdvExam1Validation
      ? ""
      : "This field is required";

    const invesmentAdvExam2Validation =
      formData?.investmentAdvisoryExam2?.length > 0;
    newFormError.investmentAdvisoryExam2 = invesmentAdvExam2Validation
      ? ""
      : "This field is required";

    setFormError(newFormError);

    return (
      riaNameValidation.isValid &&
      emailValidation.isValid &&
      phoneValidation.isValid &&
      sebiRegValidation.isValid &&
      baslNoValidation.isValid &&
      expiryDateValidation.isValid &&
      sebiRegFileValidation &&
      baslFileValidation &&
      invesmentAdvExam1Validation &&
      invesmentAdvExam2Validation
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!handleValidation()) {
      return;
    }
  
    try {
      let sebiRegFileBase64 = await fileToBase64(formData.sebiRegistration[0]);
      let baslMembershipFileBase64 = await fileToBase64(
        formData.baslMembership[0]
      );
      let investmentAdvisoryExam1FileBase64 = await fileToBase64(
        formData.investmentAdvisoryExam1[0]
      );
      let investmentAdvisoryExam2FileBase64 = await fileToBase64(
        formData.investmentAdvisoryExam2[0]
      );
  
      const payload = {
        name: formData?.riaName1,
        email: formData?.emailAddress,
        mobile: parseInt(formData?.phoneNumber),
        sebi_reg_no: formData?.sebiRegistrationNumber,
        basl_no: formData?.baslNumber,
        user_type: formData?.userType,
        basl_expiry: formData?.expiryDate
          ? dayjs(formData?.expiryDate)?.format("YYYY-MM-DD")
          : null,
        files: [
          {
            sebi_reg_file: `data:application/pdf;base64,${sebiRegFileBase64}`,
            basl_membership_file: `data:application/pdf;base64,${baslMembershipFileBase64}`,
            investment_advisory_exam1_file: `data:application/pdf;base64,${investmentAdvisoryExam1FileBase64}`,
            investment_advisory_exam2_file: `data:application/pdf;base64,${investmentAdvisoryExam2FileBase64}`,
          },
        ],
      };
  
      console.log("payloadddd", payload);
      const response = await handleAddRiaApi(payload);
  
      if (response.error?.data?.status !== 0) {
        refetchRiaTableData();
        handleAddRiaDrawerClose();
      }
  
      dispatch(
        toastActions.setToastData({
          message: response.error?.data?.message || 'Operation completed successfully',
        })
      );
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };
  

  const handleAddFilter = () => {
    setisAddFilterDrawerOpen(false);
  };

  const handleRia = () => {
    setisTableDrawerOpen(false);
  };

  const customerColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box
          onClick={() => {
            handleTableDrawer(
              DashboardAdminTable?.row.user_id,
              DashboardAdminTable?.row.status
            );
          }}
        >
          <AdminTableRowNames>
            {DashboardAdminTable?.row.name}
          </AdminTableRowNames>
        </Box>
      ),
    },
    {
      field: "userType",
      headerName: "User type",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <Typography
            sx={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}
          >
            {DashboardAdminTable?.row.role_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "emailAddress",
      headerName: "Email address",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <Typography
            sx={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}
          >
            {DashboardAdminTable?.row.email}
          </Typography>
        </Box>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <Typography
            sx={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}
          >
            {DashboardAdminTable?.row.mobile}
          </Typography>
        </Box>
      ),
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          {DashboardAdminTable?.row.status === "Y" ? (
            <ActiveChip label="Active" />
          ) : (
            <InactiveChip label="Inactive" />
          )}
        </Box>
      ),
    },
  ];

  //   if (!data?.length) {
  //     return <div>...Loading</div>;
  //   }
  if (!singleRiaData) {
    return <div>...Loading</div>;
  }

  const DashboardAdminTable = Object.values(data ? data : {})?.map(
    (item, index) => ({
      ...item,
      id: index + 1,
    })
  );

  return (
    <>
      <AdminDashBoardHeader
        handleDateRangeChange={handleDateRangeChange}
        userName={userDetails}
      />

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {cardData.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_${element?.id}`}>
            <TradeCard data={element} />
          </Grid>
        ))}
      </Grid>

      <Paper sx={{ borderRadius: "16px", mb: 4, mt: 2, height: "auto" }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box style={{ padding: "15px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1.5}
              sx={{ width: "100%" }}
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Box>
                <Stack direction="row">
                  <Text fontSize="20px" fontWeight="600">
                    RIA's
                  </Text>
                  <CustomerChip
                    color="secondary"
                    label={<Text style={{ fontSize: "12px" }}>customers</Text>}
                  ></CustomerChip>
                </Stack>
              </Box>
            </Stack>
            <Text fontSize="15px" fontWeight="400" color="#667085">
              Here you can view the list of RIA’s and change their status as
              active or inactive.
            </Text>
          </Box>
          <Box style={{ padding: "25px" }}>
            <Button
              sx={{ pt: "10px", pb: "10px", pr: "16px", pl: "16px" }}
              onClick={handleAddRiaDrawer}
            >
              +Add Ria/Sub-Broker
            </Button>
          </Box>
        </Stack>

        <Box px="15px">
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTOp: "50px" }}
          >
            <Box style={{ width: "500px", marginTop: "10px" }}>
              <Stack direction="row">
                <TextField
                  sx={{ maxWidth: "500px" }}
                  placeholder="Search"
                  name="search"
                  onChange={handleSearchInputChangeList}
                  value={searchQueryList}
                />
              </Stack>
            </Box>

            <Box>
              <AddFilterBtn
                startIcon={<FilterIcon />}
                variant="outlined"
                onClick={handleAddFilterDrawer}
              >
                Add Filters
              </AddFilterBtn>
            </Box>
          </Stack>
        </Box>
        <Stack sx={{ marginTop: "24px" }}>
          <CustomGrid
            autoHeight
            list={DashboardAdminTable}
            columnHeaderHeight={46}
            rowHeight={60}
            columns={customerColumns}
            rowCount={riaTableData.total_record}
            pagination
            paginationMode="Server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPaginationModel(newPaginationModel);
            }}
            pageSizeOptions={[5, 10, 15]}
          />
        </Stack>
      </Paper>

      {/* below is Add RIA sideDrawer */}
      <SideDrawer
        open={isAddRiaDrawerOpen}
        closeDrawer={handleAddRiaDrawerClose}
        title="Add User"
        subtitle="Here you can add the RIA's"
        cancelButtonText={"Cancel"}
        submitButtonText={"Add"}
        handleSubmit={handleSubmit}
        drawerWidth={500}
      >
        <AddRia
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          formError={formError}
        />
      </SideDrawer>

      <SideDrawer
        open={isAddFilterDrawerOpen}
        closeDrawer={handleAddFilterDrawerClose}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        cancelButtonText={"Clear all"}
        submitButtonText={"Apply"}
        handleSubmit={handleAddFilter}
        drawerWidth={500}
      >
        <AddFilters
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
        />
      </SideDrawer>

      <SideDrawer
        open={isTableDrawerOpen}
        closeDrawer={handleRia}
        title="View RIA"
        subtitle="Here you can view and change status of the RIA’s."
        cancelButtonText={"Cancel"}
        handleSubmit={handleTableDrawerClose}
        submitButtonText={button ? "Mark as Active" : "Mark as Inactive"}
        drawerWidth={500}
      >
        <TableDrawer singleRiaData={singleDetail} />
      </SideDrawer>
    </>
  );
};

export default DashboardAdminContainer;
