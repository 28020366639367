import React from "react";
import Paper from "components/common/Paper";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Grid from "components/common/Grid";
import { useNavigate } from "react-router-dom";

const InvestmentHeader = ({
  selectedId,
  memberID,
  data,
  familyList,
  handleButtonClick,
  handleButtonAll,
}) => {

  const navigate = useNavigate();

  return (
    <Paper
      elevation={1}
      sx={{
        alignItems: "center",
        p: 3,
        backgroundColor: "white",
        border: "1px solid white",
        height: "auto",
      }}
    >
      <Text
        sx={{
          fontSize: "20px",
          fontWeight: 500,
          marginLeft: "14px",
          color: "#101828",
        }}
      >
        {data?.name}
      </Text>
      <Box>
        <Text
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#667085",
            marginLeft: "14px",
          }}
        >
          {data?.detail}
        </Text>
      </Box>
      <Grid>
        <Stack direction="row" m={2} flexWrap="wrap">
          <Button
            variant="outlined"
            style={{
              margin: "5px",
              width: "8%",
              backgroundColor: selectedId === null ? "#142E56" : "initial",
              color: selectedId === null ? "white" : "#142E56",
            }}
            onClick={handleButtonAll}
          >
            All
          </Button>
          {familyList?.map((member, index) => (
            <Stack key={index} direction="column" style={{ margin: "5px" }}>
              {member.is_blocked ? (
                <>
                  <Button
                    variant="outlined"
                    style={{
                      backgroundColor:
                        selectedId === member.user_id ? "#142E56" : "initial",
                      color:
                        selectedId === member.user_id ? "white" : "#142E56",
                    }}
                    onClick={() => navigate(`/customer-detail`, {
                      state: {
                        BlockUserID: member.user_id,
                        BlockUserName : member.name,
                        BlockUserStatus : member.is_blocked
                      },
                    })}
                  >
                    {member.name}
                  </Button>
                  <Text
                    style={{
                      marginLeft: "2px",
                      fontSize: "12px",
                      fontWeight: "600",
                      color: "#EB5757",
                    }}
                  >
                    blocked user....
                  </Text>
                </>
              ) : (
                <Button
                  variant="outlined"
                  style={{
                    whiteSpace: "nowrap",
                    backgroundColor:
                      selectedId === member.user_id ? "#142E56" : "initial",
                    color:
                      selectedId === member.user_id ? "white" : "#142E56",
                  }}
                  onClick={() =>
                    handleButtonClick(member?.user_id, member?.is_blocked)
                  }
                >
                  {member.name}
                </Button>
              )}
            </Stack>
          ))}
        </Stack>
      </Grid>
    </Paper>
  );
};

export default InvestmentHeader;

// import React from "react";
// import Paper from "components/common/Paper";
// import Text from "components/common/Text";
// import Box from "components/common/Box";
// import Stack from "components/common/Stack";
// import Button from "components/common/Button";
// import Grid from "components/common/Grid";
// import { useNavigate } from "react-router-dom";


// const InvestmentHeader = ({
//   selectedId,
//   memberID,
//   data,
//   familyList,
//   handleButtonClick,
//   handleButtonAll,
// }) => {

//   const navigate = useNavigate();

//   return (
//     <Paper
//       elevation={1}
//       sx={{
//         alignItems: "center",
//         p: 3,
//         backgroundColor: "white",
//         border: "1px solid white",
//         height: "auto",
//       }}
//     >
//       <Text
//         sx={{
//           fontSize: "20px",
//           fontWeight: 500,
//           marginLeft: "14px",
//           color: "#101828",
//         }}
//       >
//         {data?.name}
//       </Text>
//       <Box>
//         <Text
//           sx={{
//             fontSize: "12px",
//             fontWeight: 500,
//             color: "#667085",
//             marginLeft: "14px",
//           }}
//         >
//           {data?.detail}
//         </Text>
//       </Box>
//       <Grid>
//         <Stack direction="row" m={2} flexWrap="wrap">
//           <Button
//             variant="outlined"
//             style={{
//               margin: "5px",
//               width: "8%",
//               backgroundColor: selectedId === null ? "#142E56" : "initial",
//               color: selectedId === null ? "white" : "#142E56",
//             }}
//             onClick={handleButtonAll}
//           >
//             All
//           </Button>
//           {familyList?.map((member, index) => (
//             <Stack key={index} direction="column" style={{ margin: "5px" }}>
//               {member.is_blocked ? (
//                 <>
//                   <Button
//                     variant="outlined"
//                     style={{
//                       backgroundColor:
//                       selectedId === member.user_id ? "#142E56" : "initial",
//                     color:
//                       selectedId === member.user_id ? "white" : "#142E56",
//                     }}

//                      onClick={() => navigate("/customer-detail")}
                  
//                   >
//                     {member.name}
//                   </Button>
//                   <Text
//                       style={{
//                         marginLeft: "2px",
//                         fontSize: "12px",
//                         fontWeight: "600",
//                         color: "#EB5757",
//                       }}
//                     >
//                       blocked user....
//                     </Text>
//                 </>
//               ) : (
//                 <Button
//                   variant="outlined"
//                   style={{
//                     whiteSpace: "nowrap",
//                     backgroundColor:
//                       selectedId === member.user_id ? "#142E56" : "initial",
//                     color:
//                       selectedId === member.user_id ? "white" : "#142E56",
//                   }}
//                   onClick={() =>
//                     handleButtonClick(member?.user_id, member?.is_blocked)
//                   }
//                 >
//                   {member.name}
//                 </Button>
//               )}
//             </Stack>
//           ))}
//         </Stack>
//       </Grid>
//     </Paper>
//   );
// };

// export default InvestmentHeader;


// import React from "react";
// import Paper from "components/common/Paper";
// import Text from "components/common/Text";
// import Box from "components/common/Box";
// import Stack from "components/common/Stack";
// import Button from "components/common/Button";
// import Grid from "components/common/Grid";

// const InvestmentHeader = ({
//   selectedId,
//   memberID,
//   data,
//   familyList,
//   handleButtonClick,
//   handleButtonAll,
// }) => {
//   return (
//     <Paper
//       elevation={1}
//       sx={{
//         alignItems: "center",
//         p: 3,
//         backgroundColor: "white",
//         border: "1px solid white",
//         height: "auto",
//       }}
//     >
//       <Text
//         sx={{
//           fontSize: "20px",
//           fontWeight: 500,
//           marginLeft: "14px",
//           color: "#101828",
//         }}
//       >
//         {data?.name}
//       </Text>
//       <Box>
//         <Text
//           sx={{
//             fontSize: "12px",
//             fontWeight: 500,
//             color: "#667085",
//             marginLeft: "14px",
//           }}
//         >
//           {data?.detail}
//         </Text>
//       </Box>
//       <Grid>
//       <Stack direction="row" m={2} 
//         flexWrap="wrap">
//         <Button
//           variant="outlined"
//           style={{
//             margin: "5px",
//             width: "8%",
//             backgroundColor: selectedId === null ? "#142E56" : "initial",
//             color: selectedId === null ? "white" : "#142E56",
//           }}
//           onClick={handleButtonAll}
//         >
//           All
//         </Button>
//           {familyList?.map((member, index) => (
//             <Stack
//               key={index}
//               direction='column'
//              style={{margin:'5px'}}
//             >
//               <Button
//                 variant="outlined"
//                 style={{
//                   whiteSpace: "nowrap",
//                   backgroundColor:
//                     selectedId === member.user_id ? "#142E56" : "initial",
//                   color: selectedId === member.user_id ? "white" : "#142E56",
//                 }}
//                 onClick={() =>
//                   handleButtonClick(member?.user_id, member?.is_blocked)
//                 }
//               >
//                 {member.name}
//               </Button>
//               {member?.is_blocked === 1 && selectedId === member.user_id && (
//                 <Text
//                   style={{
//                     marginLeft:'2px',
//                     fontSize: "12px",
//                     fontWeight: "600",
//                     color: "#EB5757",
//                   }}
//                 >
//                   blocked user....
//                 </Text>
//               )}
//             </Stack>
//           ))}
//       </Stack>
//       </Grid>
//     </Paper>
//   );
// };

// export default InvestmentHeader;
