import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Grid from "components/common/Grid";
import DownloadButton from "components/common/DownloadButton";
import dayjs from "dayjs";
import { Divider, styled } from "@mui/material";
import NewDialog from "components/common/Dialog";
import CheckedDialogIcon from "asset/icons/CheckedDialogIcon";
import Checkbox from "components/common/Checkbox";
import { addCustomerApiAction } from "stores/redux/apiSlices/addCustomerApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { toastActions } from "stores/redux/slices/toastSlice";

const CustomerDetailsBox = styled(Box)(() => ({
  backgroundColor: "#E5EEFF",
  borderRadius: "8px",
  padding: "12px 16px",
  marginBottom: "20px",
}));

const CustomerDetailsText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "16px",
  fontWeight: 500,
}));

const CustomernameText = styled(Text)(() => ({
  color: "#242424",
  marginBottom: "8px",
  fontWeight: 500,
  fontSize: "15px",
}));

//   const CustomerNameKeyText = styled(Text)(() => ({
// 	color: "#242424",
// 	fontSize: "16px",
// 	fontWeight: 500,
//   }));

const ButtonBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-end",
  gap: "16px",
}));

const EditDetialBtn = styled(Button)(() => ({
  padding: "13px 28px",
  fontSize: "14px",
  fontWeight: 600,
  borderRadius: "8px",
}));

const SubmitBtn = styled(Button)(() => ({
  padding: "13px 28px",
  fontSize: "14px",
  fontWeight: 600,
  borderRadius: "8px",
}));

function Summary({ handleStepChange, customerSummary = {} }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const onBoardData = location.state && location.state.onBoardData;
  console.log(onBoardData?.user_id, "onBoardData");
  const [isStopLossChecked, setIsStopLossChecked] = useState(false);
  console.log(isStopLossChecked, "setIsStopLossChecked");
  const customerRiskDetails = useSelector(
    (state) => state.addCustomerSlice.customerDetails
  );

  // apis
  const [approveCustomer] = addCustomerApiAction.approvedCustomer();

  const navigate = useNavigate();
  console.log("customerSummary : ", customerSummary);
  const handleStopLossChange = (isChecked) => {
    setIsStopLossChecked(isChecked);
  };
  // api call
  const handleApproveCustomerApi = async () => {
    const requiredDocuments = ["pan_card", "aadhar_card", "sign_agreement"];

    // Check if all required documents are present in the documentDetails array
    const allDocumentsPresent = requiredDocuments.every((doc) =>
      customerSummary.documentDetails.some((item) => item.hasOwnProperty(doc))
    );

    // If isStopLossChecked is true and all required documents are present, then call the API
    if (isStopLossChecked && allDocumentsPresent) {
      const payload = {
        user_id: parseFloat(
          onBoardData?.user_id || customerRiskDetails?.userId
        ),
        status: "Accepted",
      };
      console.log(payload, "payyyy");
      try {
        const res = await approveCustomer(payload).unwrap();
        console.log("res : ", res.result);
        if (res) {
          navigate("/customers");
        }
      } catch (err) {
        console.log("err:", err);
      }
    } else {
      // If isStopLossChecked is true but all required documents are not present

      if (isStopLossChecked && !allDocumentsPresent) {
        dispatch(
          toastActions.setToastData({
            message: "Sign aggrement are still pending to be approved.",
            variant: "error",
          })
        );
        console.log("Error: Some document are missing.");
      } else {
        // If isStopLossChecked is false or all required documents are not present
        navigate("/customers");
      }
    }
  };

  const {
    customerDetails = {},
    riskAssessment = null,
    riskAssessmentManualRiskProfile = null,
    riskAssessmentSuggestedRiskProfile = null,
    investmentDetails = {},
    otherDetails = {},
    documentDetails = [],
  } = customerSummary;
  console.log("otherDetails : ", otherDetails);

  console.log("documentDetails : ", documentDetails);
  return (
    <Box sx={{ padding: "40px" }}>
      <Card sx={{ p: 4, borderRadius: "16px", mb: "36px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Text sx={{ color: "#242424", fontSize: "20px", fontWeight: 600 }}>
              Summary
            </Text>
          </Grid>

          <Grid item xs={12}>
            <CustomerDetailsBox>
              <CustomerDetailsText>Customer details</CustomerDetailsText>
            </CustomerDetailsBox>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Customer name</CustomernameText>
              <Text sx={{ color: "#242424" }}>{customerDetails?.name}</Text>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Email ID</CustomernameText>
              <Text sx={{ color: "#242424" }}>{customerDetails?.email}</Text>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Phone number</CustomernameText>
              <Text sx={{ color: "#242424" }}>{customerDetails?.mobile}</Text>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ mt: "28px" }}>
            <CustomerDetailsBox>
              <CustomerDetailsText>Risk assesment details</CustomerDetailsText>
            </CustomerDetailsBox>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Selected Risk profile </CustomernameText>
              <Text sx={{ color: "#242424" }}>
                {riskAssessmentManualRiskProfile} Investor{" "}
              </Text>
            </Box>
          </Grid>
          {/* we need to add here  */}

          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Assessed Risk profile </CustomernameText>
              <Text sx={{ color: "#242424" }}>
                {riskAssessmentSuggestedRiskProfile || riskAssessment} Investor{" "}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={4}></Grid>

          <Grid item xs={12} sx={{ mt: "28px" }}>
            <CustomerDetailsBox>
              <CustomerDetailsText>Invesment Details</CustomerDetailsText>
            </CustomerDetailsBox>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Capital</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                ₹ {customerDetails?.capital}
              </Text>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Custom</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {investmentDetails?.custom?.investmentAmount ||
                  investmentDetails?.custom?.investmentPercent}
              </Text>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Algo (F&O)</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {investmentDetails?.algoType?.investmentAmount ||
                  investmentDetails?.algoType?.investmentPercent}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={11}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: "1px solid #CBCBCB",
                borderRadius: "8px",
                padding: "12px 20px", // Adjusted padding
              }}
            >
              <Box sx={{ mr: "32px" }}>
                <CustomernameText>Model Portfolio</CustomernameText>
                <Text
                  sx={{
                    color: "#242424",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {`₹${investmentDetails?.modelType?.investmentAmount}` ||
                    `${investmentDetails?.modelType?.investmentPercent}%`}
                </Text>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "#E5EEFF",
                  borderRadius: "8px",
                  padding: "8px 20px", // Adjusted padding
                }}
              >
                {investmentDetails?.modelType?.product?.map((item, index) => (
                  <React.Fragment key={index}>
                    <Box sx={{ mr: "32px", display: "flex" }}>
                      <Box>
                        <Text
                          sx={{
                            color: "#676C76",
                            fontSize: "15px",
                            fontWeight: 500,
                            mb: "8px",
                          }}
                        >
                          {item.categoryName}
                        </Text>

                        <Text
                          sx={{
                            color: "#242424",
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                        >
                          {`₹${item?.investmentAmount}` ||
                            `${item?.investmentPercent}%`}
                        </Text>
                      </Box>
                      {index !==
                        investmentDetails.modelType.product.length - 1 && (
                        <Divider
                          sx={{ display: "flex", ml: "25px" }}
                          orientation="vertical"
                        />
                      )}
                    </Box>
                  </React.Fragment>
                ))}
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} sx={{ mt: "28px" }}>
            <CustomerDetailsBox>
              <CustomerDetailsText>Others Details</CustomerDetailsText>
            </CustomerDetailsBox>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Plan name</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.plan_name}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Plan code</CustomernameText>
              <Text
                sx={{
                  color: "#242424",
                  fontSize: "16px",
                  fontWeight: 500,
                  overflow: "hidden",
                }}
              >
                {otherDetails?.plan_code}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Start date</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.start_date
                  ? dayjs(otherDetails?.start_date).format("DD/MM/YYYY")
                  : ""}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Expiry date</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.end_date
                  ? dayjs(otherDetails?.end_date).format("DD/MM/YYYY")
                  : ""}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Family group name</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.family_group_name || "NA"}
              </Text>
            </Box>
          </Grid>

          {/* <Grid item xs={3} sx={{ mb: "28px" }}>
						<Box sx={{ gap: "8px", width: "265px" }}>
							<CustomernameText>Auto trade</CustomernameText>
							<Text sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}>
								{otherDetails?.auto_trade}
							</Text>
						</Box>
					</Grid> */}

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Customer residency</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.customer_residency}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Customer category</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails.customer_category}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Customer Type</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.customer_type}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={3} sx={{ mb: "28px" }}>
            <Box sx={{ gap: "8px", width: "265px" }}>
              <CustomernameText>Joining date</CustomernameText>
              <Text
                sx={{ color: "#242424", fontSize: "16px", fontWeight: 500 }}
              >
                {otherDetails?.joining_date
                  ? dayjs(otherDetails?.joining_date).format("DD/MM/YYYY")
                  : ""}
              </Text>
            </Box>
          </Grid>

          <Grid item xs={9}></Grid>

          <Grid item xs={6}>
            <DownloadButton
              children={documentDetails?.[0]?.document_name}
              isDwonloadIconVisible={false}
            />
          </Grid>
          <Grid item xs={6}>
            <DownloadButton
              children={documentDetails?.[1]?.document_name}
              isDwonloadIconVisible={false}
            />
          </Grid>

          {documentDetails?.[2]?.document_name && (
            <Grid item xs={6}>
              <DownloadButton
                children={documentDetails?.[2]?.document_name}
                isDwonloadIconVisible={false}
              />
            </Grid>
          )}

          {/* <Grid item xs={6}>
            <DownloadButton
              children={documentDetails?.[2]?.document_name}
              isDwonloadIconVisible={false}
            />
          </Grid> */}
          <Grid item xs={6}></Grid>
        </Grid>
      </Card>

      <ButtonBox>
        <EditDetialBtn
          onClick={() => {
            handleStepChange(2);
          }}
        >
          Edit Details
        </EditDetialBtn>
        <SubmitBtn
          onClick={() => {
            setIsModalOpen(true);
            // navigate("/customers");
          }}
        >
          Submit
        </SubmitBtn>
      </ButtonBox>

      <NewDialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Stack
          direction="column"
          spacing={1.5}
          sx={{ width: "100%" }}
          alignItems="center"
          justifyContent="center"
          style={{ marginBottom: "10px" }}
        >
          {/* <CheckCircleOutlinedIcon /> */}
          <CheckedDialogIcon sx={{ width: "58px", height: "58px" }} />
          <Text
            fontSize="20px"
            fontWeight="600"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            The customer's information will be securely stored!
          </Text>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#667085"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            textAlign="center"
          >
            Customer information is accessible within the customer module.
          </Text>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Checkbox
              sx={{ width: "12px", height: "12px", mt: "9px" }}
              checked={isStopLossChecked}
              onChange={(e) => handleStopLossChange(!isStopLossChecked)}
            />

            <Text
              sx={{
                ml: "12px",
                mt: "9px",
                fontSize: "16px",
                color: "#242424",
                fontWeight: 400,
              }}
            >
              All details & documents are approved by RIA
            </Text>
          </Box>

          <Button
            sx={{
              width: "489px",
              height: "56px",
              fontWeight: 600,
              borderRadius: "8px",
              p: "18px 20px",
              mt: "32px",
            }}
            // onClick={() => navigate("/customers")}
            onClick={handleApproveCustomerApi}
          >
            {isStopLossChecked ? "Approve and Submit" : "Submit"}
          </Button>
        </Stack>
        {/* <Box>
          <Text>This is the modal content</Text>
          <Button onClick={() => navigate("/customers")}>Close</Button>
        </Box> */}
      </NewDialog>
    </Box>
  );
}

export default Summary;
