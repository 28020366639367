import React, { useState, useEffect } from "react";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import Text from "components/common/Text";
import Checkbox from "components/common/Checkbox";
import TextField from "../common/TextField";
import { useDispatch, useSelector } from "react-redux";
import { validationHelper } from "helpers/validation";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import InputAdornment from '@mui/material/InputAdornment';


const InvestmentSidebar = ({
  getCustomerDetails,
  viewCustomerDetail,
  customer_id,
  closeInvestmentDrawer,
  isInvestmentDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    capital: "",
    isPercent: "N",
    isCustomInvestmentSelected: false,
    customAmount: "",
    isModelPortfolioSelected: false,
    modelPorfolioAmount: "",
    isHighRiskSelected: false,
    highRiskAmount: "",
    isMediumRiskSelected: false,
    mediumRiskAmount: "",
    isLowRiskSelected: false,
    lowRiskAmount: "",
    isAlgoSelected: false,
    algoAmount: "",
  });

  const [formError, setFormError] = useState({
    capital: "",
    customAmount: "",
    modelPorfolioAmount: "",
    highRiskAmount: "",
    mediumRiskAmount: "",
    lowRiskAmount: "",
    algoAmount: "",
  });
  const [amountError, setAmountError] = useState(null);
  const [modalAmountError, setModalAmountError] = useState(null);

  const [submitInvestmentDetails] =
    customersApiAction.updateInvestmentDetails();

  // ---------------methods------------------

  const handleSubmit = async () => {
    if (!handleValidation()) {
      return;
    }
    if (!capitalValidation()) {
      return;
    }

    const parsedPayload = payloadParser();
    let payload = {
      investmentDetails: parsedPayload,
      userId: customer_id,
    };
    try {
      const res = await submitInvestmentDetails(payload).unwrap();
      if (res?.status === 1) {
        getCustomerDetails(customer_id);
        closeInvestmentDrawer(false);
        setFormData('');
        setFormError('');
        setAmountError('');
        setModalAmountError('')
      }
      if (res) {
        const toastMessage =
          res?.status === 1
            ? "Investment details added successfully!"
            : res?.message;
        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: res?.status === 1 ? "success" : "error",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const payloadParser = () => {
    let payload = {};
    payload.capital = parseInt(formData.capital);
    payload.isPercent = formData.isPercent;
    let products = [];
    if (formData.isCustomInvestmentSelected) {
      let product = {
        product_id: 1,
        amount: parseInt(formData.customAmount ? formData.customAmount : 0),
        category: [],
      };
      products.push(product);
    }
    if (formData.isModelPortfolioSelected) {
      let product = {
        product_id: 2,
        amount: parseInt(
          formData.modelPorfolioAmount ? formData.modelPorfolioAmount : 0
        ),
      };
      let category = [];
      if (formData.isModelPortfolioSelected) {
        let categoryProd = {
          product_category_id: 1,
          amount: parseInt(
            formData?.highRiskAmount ? formData?.highRiskAmount : 0
          ),
        };
        category.push(categoryProd);
      }
      if (formData.isModelPortfolioSelected) {
        let categoryProd = {
          product_category_id: 2,
          amount: parseInt(
            formData?.mediumRiskAmount ? formData?.mediumRiskAmount : 0
          ),
        };
        category.push(categoryProd);
      }
      if (formData.isModelPortfolioSelected) {
        let categoryProd = {
          product_category_id: 3,
          amount: parseInt(
            formData?.lowRiskAmount ? formData?.lowRiskAmount : 0
          ),
        };
        category.push(categoryProd);
      }
      product.category = category;
      products.push(product);
    }
    if (formData.isAlgoSelected) {
      let product = {
        product_id: 3,
        amount: parseInt(formData.algoAmount ? formData.algoAmount : 0),
        category: [],
      };
      products.push(product);
    }
    payload.products = products;
    return payload;
  };

  const handleChange = ({ name, value }) => {
    const newFormData = { ...formData };
    if (
      [
        "customAmount",
        "modelPorfolioAmount",
        "highRiskAmount",
        "mediumRiskAmount",
        "lowRiskAmount",
        "algoAmount",
      ].includes(name)
    ) {
      value = value.trim();
      if (value !== "" && isNaN(value) ) {
        return;
      }
    }
    setFormData({ ...newFormData, [name]: value });
  };

  const capitalValidation = () => {
    let currentAmount = 0;
    if (formData.isCustomInvestmentSelected) {
      currentAmount += Number(formData.customAmount);
    }
    if (formData.isModelPortfolioSelected) {
      currentAmount += Number(formData.modelPorfolioAmount);
      let modelAmount = 0;
      modelAmount += Number(formData.highRiskAmount);
      modelAmount += Number(formData.mediumRiskAmount);
      modelAmount += Number(formData.lowRiskAmount);

      if (modelAmount > Number(formData.modelPorfolioAmount)) {
        setModalAmountError(
          "Total Model amount should be less than Model Portfolio Amount"
        );
        return 0;
      } else if (modelAmount < Number(formData.modelPorfolioAmount)) {
        setModalAmountError(
          "Total Model amount should be equal to Model Portfolio Amount"
        );
        return 0;
      } else {
        setModalAmountError(null);
      }
    } else {
      setModalAmountError(null);
    }
    if (formData.isAlgoSelected) {
      currentAmount += Number(formData.algoAmount);
    }
    if (currentAmount !== Number(formData.capital)) {
      setAmountError("Total amount should be equal to capital amount");
      return 0;
    } else {
      setAmountError(null); // Set error to null if condition is true
      return 1;
    }
  };

  const handleValidation = () => {
    const newFormError = { ...formError };

    let customValidation = { isValid: 1 };
    if (formData.isCustomInvestmentSelected) {
        customValidation = validationHelper.required(formData.customAmount);
        newFormError.customAmount = customValidation.message;
        if (formData.customAmount <= 0 || isNaN(formData.customAmount)) {
            customValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.customAmount = customValidation.message;
        }
    } else {
        newFormError.customAmount = "";
    }

    let modelPortfolioValidation = { isValid: 1 };
    if (formData.isModelPortfolioSelected) {
        const modelPortfolioAmount = formData.modelPorfolioAmount;
        if (modelPortfolioAmount <= 0 || isNaN(modelPortfolioAmount)) {
            modelPortfolioValidation = { isValid: 0, message: "Amount must be greater than zero" };
        }
        newFormError.modelPorfolioAmount = modelPortfolioValidation.message;
    } else {
        newFormError.modelPorfolioAmount = "";
    }

    let highRiskValidation = { isValid: 1 };
    if (formData.isHighRiskSelected) {
        highRiskValidation = validationHelper.required(formData.highRiskAmount);
        newFormError.highRiskAmount = highRiskValidation.message;
        if (formData.highRiskAmount <= 0 || isNaN(formData.highRiskAmount)) {
            highRiskValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.highRiskAmount = highRiskValidation.message;
        }
    } else {
        newFormError.highRiskAmount = "";
    }

    let mediumRiskValidation = { isValid: 1 };
    if (formData.isMediumRiskSelected) {
        mediumRiskValidation = validationHelper.required(formData.mediumRiskAmount);
        newFormError.mediumRiskAmount = mediumRiskValidation.message;
        if (formData.mediumRiskAmount <= 0 || isNaN(formData.mediumRiskAmount)) {
            mediumRiskValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.mediumRiskAmount = mediumRiskValidation.message;
        }
    } else {
        newFormError.mediumRiskAmount = "";
    }

    let lowRiskValidation = { isValid: 1 };
    if (formData.isLowRiskSelected) {
        lowRiskValidation = validationHelper.required(formData.lowRiskAmount);
        newFormError.lowRiskAmount = lowRiskValidation.message;
        if (formData.lowRiskAmount <= 0 || isNaN(formData.lowRiskAmount)) {
            lowRiskValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.lowRiskAmount = lowRiskValidation.message;
        }
    } else {
        newFormError.lowRiskAmount = "";
    }

    let algoValidation = { isValid: 1 };
    if (formData.isAlgoSelected) {
        algoValidation = validationHelper.required(formData.algoAmount);
        newFormError.algoAmount = algoValidation.message;
        if (formData.algoAmount <= 0 || isNaN(formData.algoAmount)) {
            algoValidation = { isValid: 0, message: "Amount must be greater than zero" };
            newFormError.algoAmount = algoValidation.message;
        }
    } else {
        newFormError.algoAmount = "";
    }

    setFormError(newFormError);

    return (
        customValidation.isValid &&
        modelPortfolioValidation.isValid &&
        highRiskValidation.isValid &&
        mediumRiskValidation.isValid &&
        lowRiskValidation.isValid &&
        algoValidation.isValid
    );
};


  const setFormDataFromViewCustomerDetail = (viewCustomerDetail) => {
    if (viewCustomerDetail) {
      const { customerDetails, investmentDetails } = viewCustomerDetail;
      setFormData({
        capital: customerDetails?.[0]?.capital || "",
        isPercent: "N",
        isCustomInvestmentSelected:
          investmentDetails?.[0]?.investment_amount !== null &&
          investmentDetails?.[0]?.investment_amount !== undefined &&
          investmentDetails?.[0]?.investment_amount > 0,
        customAmount: investmentDetails?.[0]?.investment_amount ?? "",
        isModelPortfolioSelected:
          investmentDetails?.[1]?.investment_amount !== null &&
          investmentDetails?.[1]?.investment_amount !== undefined &&
          investmentDetails?.[1]?.investment_amount > 0,
        modelPorfolioAmount: investmentDetails?.[1]?.investment_amount ?? "",
        isHighRiskSelected: false,
        highRiskAmount:
          investmentDetails?.[1]?.product_category?.[2]?.investment_amount ?? 0,
        isMediumRiskSelected: false,
        mediumRiskAmount:
          investmentDetails?.[1]?.product_category?.[1]?.investment_amount ?? 0,
        isLowRiskSelected: false,
        lowRiskAmount:
          investmentDetails?.[1]?.product_category?.[0]?.investment_amount ?? 0,
        isAlgoSelected:
          investmentDetails?.[2]?.investment_amount !== null &&
          investmentDetails?.[2]?.investment_amount !== undefined &&
          investmentDetails?.[2]?.investment_amount > 0,
        algoAmount: investmentDetails?.[2]?.investment_amount ?? "",
      });
    }
  };

  useEffect(() => {
    setFormDataFromViewCustomerDetail(viewCustomerDetail);
  }, [viewCustomerDetail]);

  return (
    <Stack>
      {isInvestmentDrawerOpen && (
        <SideDrawer
          anchor="right"
          open={isInvestmentDrawerOpen}
          closeDrawer={closeInvestmentDrawer}
          title="Investment Type"
          contentTitle=""
          handleSubmit={handleSubmit}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          subtitle="Here you can edit the Invesment type."
        >
          <Box>
            <Text variant="small" fontSize="16px" fontWeight="500">
              Capital
            </Text>
            <TextField
              type=""
              value={`₹ ${parseInt(formData.capital)}`}
              disabled
              inputProps={{ style: { fontSize: "14px" } }}
              placeholder="Enter Amount Here"
              style={{ marginTop: "10px" }}
            />
          </Box>
          <Box>
            <Text
              variant="small"
              fontSize="16px"
              fontWeight="500"
              marginTop="10px"
            >
              Invesment Type
            </Text>
            <Checkbox           
            defaultChecked={viewCustomerDetail?.customerDetails[0]?.investment_selected_type.split(',').includes('Algo')}
              checked={formData.isAlgoSelected}
              value={formData.isAlgoSelected}
              onChange={() => {
                handleChange({
                  name: "isAlgoSelected",
                  value: !formData.isAlgoSelected,
                });
              }}
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="500"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  Algo (F&O)
                </Text>
              }
              size="small"
              style={{ marginLeft: "15px", marginBottom: "10px" }}
            />

            <Box>
              <Text variant="small" fontSize="15px" fontWeight="500">
                Capital divide for algo
              </Text>
              <TextField
                placeholder="Enter Amount Here"
                style={{ marginTop: "10px" }}
                value={formData.algoAmount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ fontSize: '15px' }}>
                      <span>₹</span>
                    </InputAdornment>
                  ),
                }}
                name="algoAmount"
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                disabled={!formData.isAlgoSelected}
              />
              {formError.algoAmount && (
                <Text variant="small" color="red" py={1}>
                  {formError.algoAmount}
                </Text>
              )}
            </Box>
            <Box style={{ marginTop: "4px" }}>
              <Text variant="small" fontSize="15px" fontWeight="600">
                Leverage Percentage
              </Text>
              <TextField
                type=""
                disabled={true}
                inputProps={{ style: { fontSize: "14px" } }}
                placeholder="Enter Leverage % Here"
                style={{ marginTop: "10px" }}
              />
            </Box>
          </Box>

          <Box>
            <Checkbox
              defaultChecked={viewCustomerDetail?.customerDetails[0]?.investment_selected_type.split(',').includes('Custom')}
              onChange={() => {
                handleChange({
                  name: "isCustomInvestmentSelected",
                  value: !formData.isCustomInvestmentSelected,
                });
              }}
              value={formData.isCustomInvestmentSelected}
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  Custom{" "}
                </Text>
              }
              size="small"
              style={{ marginLeft: "15px", marginBottom: "10px" }}
            />
            <Text variant="small" fontSize="15px" fontWeight="600">
              Capital divide for custom
            </Text>
            <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ fontSize: '15px' }}>
                  <span>₹</span>
                </InputAdornment>
              ),
            }}
              onChange={(e) => {
                handleChange({
                  name: e.target.name,
                  value: e.target.value,
                });
              }}
              disabled={!formData.isCustomInvestmentSelected}
              value={formData.customAmount}
              name="customAmount"
              inputProps={{ style: { fontSize: "14px" } }}
              placeholder="Enter Amount Here"
              style={{ marginTop: "10px" }}
            />
            {formError.customAmount && (
              <Text variant="small" color="red" py={1}>
                {formError.customAmount}
              </Text>
            )}
          </Box>
          <Box>
            <Checkbox
              defaultChecked={viewCustomerDetail?.customerDetails[0]?.investment_selected_type.split(',').includes('Model Portfolio')}
              onChange={() => {
                handleChange({
                  name: "isModelPortfolioSelected",
                  value: !formData.isModelPortfolioSelected,
                });
              }}
              value={formData.isModelPortfolioSelected}
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px", marginBottom: "10px" }}
                >
                  Model Portfolio
                </Text>
              }
              size="small"
              style={{ marginLeft: "15px", marginBottom: "10px" }}
            />
            <Box>
              <Text variant="small" fontSize="15px" fontWeight="600">
                Capital Divide for Model Portfolio
              </Text>
              <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: '15px' }}>
                    <span>₹</span>
                  </InputAdornment>
                ),
              }}
                placeholder="Enter Amount Here"
                style={{ marginTop: "10px" }}
                value={formData.modelPorfolioAmount}
                name="modelPorfolioAmount"
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                disabled={!formData.isModelPortfolioSelected}
              />
              {formError.modelPorfolioAmount && (
                <Text variant="small" color="red" py={1}>
                  {formError.modelPorfolioAmount}
                </Text>
              )}
            </Box>
            <Box style={{ marginTop: "4px" }}>
              <Text variant="small" fontSize="15px" fontWeight="600">
                High Risk Product Stocks
              </Text>
              <TextField
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: '15px' }}>
                    <span>₹</span>
                  </InputAdornment>
                ),
              }}
                type=""
                value={formData.highRiskAmount}
                name="highRiskAmount"
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                disabled={!formData.isModelPortfolioSelected}
                inputProps={{ style: { fontSize: "14px" } }}
                placeholder="Enter Amount Here"
                style={{ marginTop: "10px" }}
              />
              {formError.highRiskAmount && (
                <Text variant="small" color="red" py={1}>
                  {formError.highRiskAmount}
                </Text>
              )}
            </Box>
            <Box style={{ marginTop: "4px" }}>
              <Text variant="small" fontSize="15px" fontWeight="600">
                Medium Risk Product Stocks
              </Text>
              <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: '15px' }}>
                    <span>₹</span>
                  </InputAdornment>
                ),
              }}
                inputProps={{ style: { fontSize: "14px" } }}
                placeholder="Enter Amount Here"
                style={{ marginTop: "10px" }}
                value={formData.mediumRiskAmount}
                name="mediumRiskAmount"
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                disabled={!formData.isModelPortfolioSelected}
              />
            </Box>
            <Box style={{ marginTop: "4px" }}>
              <Text variant="small" fontSize="15px" fontWeight="600">
                Low Risk Product Stocks
              </Text>
              <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ fontSize: '15px' }}>
                    <span>₹</span>
                  </InputAdornment>
                ),
              }}
                type=""
                value={formData.lowRiskAmount}
                name="lowRiskAmount"
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
                disabled={!formData.isModelPortfolioSelected}
                inputProps={{ style: { fontSize: "14px" } }}
                placeholder="Enter Amount Here"
                style={{ marginTop: "10px" }}
              />
              {formError.lowRiskAmount && (
                <Text variant="small" color="red" py={1}>
                  {formError.lowRiskAmount}
                </Text>
              )}
            </Box>
            {modalAmountError && (
              <Stack alignItems="flex-end" py={1}>
                <Text variant="small" color="red">
                  {modalAmountError}
                </Text>
              </Stack>
            )}
            {amountError && (
              <Stack alignItems="flex-end" py={1}>
                <Text variant="small" color="red">
                  {amountError}
                </Text>
              </Stack>
            )}
          </Box>
        </SideDrawer>
      )}

      {/* <Dialog
        onClose={() => setOpenModel(false)}
        open={openModel}
        disableCloseIcon
        maxWidth="sm"
        contentComponent={() => (
          <Stack
            direction="column"
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
          >
            <Avatar
              style={{ width: "50px", height: "50px" }}
              src={Featured}
            ></Avatar>
            <Text fontSize="20px" fontWeight="600" color="#101828" p={1}>
              In order to add capital to the Custom move <br /> cash from model
              portfolio or Algos.
            </Text>
            <Text
              fontSize="14px"
              fontWeight="400"
              color="#667085"
              marginTop="-10px"
            >
              If you want to add capital in SIP then first you need to move cash
              from <br /> Model Portfolio or Algo to proceed further.
            </Text>
            <Stack direction="row" style={{ padding: "0px", width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                variant="outlined"
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  width: "100%",
                  p: 2,
                  m: 2,
                }}
                onClick={handleCancel}
              >
                No
              </Button>
              <Button
                sx={{
                  fontWeight: 600,
                  borderRadius: "8px",
                  p: 2,
                  width: "100%",
                  m: 2,
                }}
                onClick={handleOkay}
              >
                Okay
              </Button>
            </Stack>
          </Stack>
        )}
      /> */}
    </Stack>
  );
};

export default InvestmentSidebar;
