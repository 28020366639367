import React from "react";
import { useSelector } from "react-redux";
import Box from "components/common/Box";
import { appConstants } from "helpers/constants/appConstants";
import CustomerIsPending from "components/dashboard/CustomerIsPending";
const USER_ROLES = appConstants.userRoles;

const BlureBackground = (props) =>{
    
    // State
    const userRoleCode = useSelector((state) => state.commonReducer.userRoleCode);
    const userDetails = useSelector((state) => state.commonReducer.userDetails);
    
    
    const roleConfig = USER_ROLES[userRoleCode];
    const onboardStatus = userDetails.onboard_status ? userDetails.onboard_status.toUpperCase() : "";
    const pendingCustomerApprv = (["PENDING","REJECTED"].includes(onboardStatus) && roleConfig.code == USER_ROLES.CUSTOMER.code)

const {
    children
} = props

    return (
        <>
            <Box sx={{filter: pendingCustomerApprv ? "blur(5px)" :""}}>
                {children}
            </Box>
            
            {pendingCustomerApprv ? (
                <CustomerIsPending onboardStatus={onboardStatus} sx={{ width: "789px", height: "480px" }} />
            ): null
            }
        </>
        
    )
}

export default BlureBackground;