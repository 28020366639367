// Import necessary functions and modules
import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const dashboard = createApi({
  reducerPath: "dashboard",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: ["GETDASHBOARD"],
  endpoints: (qb) => ({
    getDashboardApi: qb.query({
      // Modify the query function to accept parameters
      query: ({ startDate, endDate, noGlobalLoading = true }) => ({
        url: `api/dashboard`,
        params: { start_date: startDate, end_date: endDate, noGlobalLoading },
      }),
      transformResponse: (response) => response.result,
      providesTags: ["GETDASHBOARD"],
    }),
  }),
});

export const dashboardApiReducer = dashboard.reducer;

export const dashboardApiAction = {
  middleware: dashboard.middleware,
  reducerPath: dashboard.reducerPath,

  // Modify the getDashboardApi function to accept parameters
  getDashboardApi: (params) => dashboard.useLazyGetDashboardApiQuery(params), // Pass the parameters to the function
};
